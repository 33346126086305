import { doRequest } from "./http.js";
import router from "../router/index.js";
import { gerarHeaderCupom, gerarImpressao, gerarMensagemAgradecimento, gerarRodape } from "../plugins/pdfmake.js";
import { dateFormatISO } from "../utils/dateformat.js";
class StateCaixas {
	content = [];
	page = -1;
	size = 25;
	totalElements = 0;
	totalPages = 0;
	constructor(caixas) {
		if (caixas) {
			if (caixas.content) {
				this.content = caixas.content.filter(caixa => caixa.cdcaixa > 0).map(caixa => {
					return {
						cdcaixa: caixa.cdcaixa, 
						identificador: caixa.identificador,
						aberto: caixa.aberto,
						cdEmpresa: caixa.cdempresa,
						banco: caixa.banco,
						ativo: caixa.ativo,
						agencia: caixa.agencia,
						cc: caixa.cc,
						dataUltimoFechamento: caixa.dataultimofechamento,
						saldoAnterior: caixa.saldoAnterior,
						informacoes: caixa.informacoes
					};
				});
			} else {
				this.content = [];
			}
			this.page = caixas.page;
			this.size = caixas.size || 25;
			this.totalElements = caixas.totalElements || 0;
			this.totalPages = caixas.totalPages || 0;
		}
	}
}

export default {
	state: {
		Caixa: null,
		Caixas: new StateCaixas(),
		fechamentoCaixa: null,
		paginaCaixasAtual: -1,
		totalPaginasCaixas: 0,
		movimentoConta: [],
		logAjustes: []
	},

	actions: {
		async gerarExtratoVista(context, payload){
			const tabela = [];
			let tableWidths = null;
			const styles = {
				headerTabela:{
					fontSize: 10,
					bold: true,
					alignment: "left",
					noWrap: true
				},
				itemTabela: {
					fontSize: 9,
					alignment: "right",
				},
				data: {
					alignment: "left",
					fontSize: 8,
				}
			};
			const pageSize = {
				width: 200,
				height: "auto"
			};
			let defaultStyles = {
			};

			tabela.push([
				{
					style: "headerTabela",
					text: "Saldo Abertura"
				},

				{
					style: "itemTabela",
					text: `${Number(payload.saldoAnterior || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				},
			]);
			tabela.push([
				{
					style: "headerTabela",
					text: "Recarga"
				},
				{
					style: "itemTabela",
					text: `${Number(payload.movimentoCaixa.recarga || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			tabela.push([
				{
					style: "headerTabela",
					text: "Sangria"
				},
				{
					style: "itemTabela",
					text: `-${Number(payload.movimentoCaixa.pagamento || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			payload.venda.descricao ? tabela.push([
				{
					style: "headerTabela",
					text: `Vendas em ${payload.venda.descricao.toLowerCase()}`
				},

				{
					style: "itemTabela",
					text: `${Number(payload.venda.valor || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				},
			]) : "";
			payload.recebimento.descricao ? tabela.push([
				{
					style: "headerTabela",
					text: `Recebimentos em ${payload.recebimento.descricao.toLowerCase()}`
				},

				{
					style: "itemTabela",
					text: `${Number(payload.recebimento.valor || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				},
			]) : "";
			tabela.push([
				{
					style: "headerTabela",
					text: "Total Sistema"
				},
				{
					style: "itemTabela",
					text: `${Number(payload.total || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			tabela.push([
				{
					style: "headerTabela",
					text: "Total Manual",
				},

				{
					style: "itemTabela",
					text: `${Number(payload.totalManual || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			tabela.push([
				{
					style: "headerTabela",
					text: "Diferença"
				},
				{
					style: "itemTabela",
					text: `${Number(payload.diferenca || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			tabela.push([
				{
					style: "column",
					text: "Saldo Final"
				},
				{
					style: "itemTabela",
					text: `${Number(payload.saldoFinal).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
				}
			]);
			tableWidths = ["*", "auto"];
			const docDefinition = {
				info: {
					title: `Extrato Caixa ${payload.caixa.cdcaixa} - ${payload.caixa.identificador}`,
					author: "BDS Soluções em Sistemas"
				},
				pageSize,
				defaultStyles,
				styles,
				content: [
					...gerarHeaderCupom(context.state.Empresa),
					{ text: "Extatro Caixa", style: "header" },
					{ text: `${payload.caixa.cdcaixa} - ${payload.caixa.identificador}`, style: "header" },
					{ text: `Data movimentação: ${payload.data.split("-").reverse().join("/")}`, style: "data"},
					{
						table: {
							headerRows: 1,
							body: tabela,
							widths: tableWidths,
						},

						layout: "lightHorizontalLines",
					},
					{
						text: gerarMensagemAgradecimento(new Date().getHours()),
						style: "header"
					},
					...gerarRodape(),
				],

			};

			const base64Pdf = await gerarImpressao(docDefinition);
			if (!base64Pdf) {
				throw new Error("Não foi possível gerar a impressão....");
			}
			context.state.base64 = base64Pdf;
		},
		async consultarCaixas({commit, state}) {
			try {
				state.carregando = true;
				let url = `caixa/caixas?cdempresa=${state.Empresa.id}`;
				url += "&page=0&size=25&sort=identificador&sortOrder=asc";
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data) return state.carregando = false;
				if (!data.content) {
					commit("atualizarListaCaixas", {
						pages: -1,
						totalPages: 0
					});
				} else {
					commit("atualizarListaCaixas", data);
				}
				state.carregando = false;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message});
			}
		},

		async buscarCaixas({commit, state, dispatch}) {
			try {
				state.carregando = true;
				let url = `caixa/caixas${state.Usuario.usuarioPrivilegiado ? "" : `?cdempresa=${state.Empresa.id}`}`;
				url += `${state.Usuario.usuarioPrivilegiado ? "?" : "&"}page=0&size=25&sort=identificador&sortOrder=asc`;
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (response && response.content) {
					commit("atualizarListaCaixas", response);
					if (state.Usuario?.supervisor) {
						await dispatch("buscarInfomacoesTodosCaixas");
					}
					state.carregando = false;
				} else {
					state.carregando = false;
					commit("atualizarListaCaixas", {
						pages: -1,
						totalPages: 0
					});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message});
			}
		},

		async verificarCaixaExistente({commit, dispatch, state}) {
			try {
				await dispatch("buscarCaixas");
				if (!state.Caixas) {
					state.carregando = false;
					return commit("relatarErro", {mensagem: "Nenhum caixa encontrado para a empresa selecionada!"});
				}
				if (!state.Caixas.content || state.Caixas.content.filter(cx => cx.cdEmpresa == state.Empresa.id).length <= 0) {
					state.carregando = false;
					return commit("relatarErro", {mensagem: "Nenhum caixa encontrado para a empresa selecionada!"});
				}
				if ((!state.Usuario.supervisor && !state.Usuario.caixa) && state.Usuario.somenteVendaSemCaixa) {
					commit("selecionarCaixa", {
						caixa: {
							aberto: "S",
							cdcaixa: 0,
							identificador: "SEM CAIXA",
							cdempresa: state.Empresa.id
						}
					});
					await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
					if (!state.Usuario.permiteBaixarCondicional) {
						router.push({name: "Lancamento", query: {tipoVenda: "S"}});
						if(state.Usuario.usaCelular){
							router.push({name: "LancamentoMesa",});
						}
					} else {
						router.push(({name: "Principal"}));
					}
				} else {
					const caixaString = localStorage.getItem("caixaLocal");
					const caixasEmpresa = state.Caixas?.content.filter(cx => cx.cdEmpresa == state.Empresa.id);
					if (!caixaString) {
						if (!state?.Caixas || caixasEmpresa?.length < 1) {
							state.carregando = false;
							return commit("relatarErro", {
								mensagem: "Nenhum caixa encontrado para a empresa selecionada!"
							});
						}
						if (state.Usuario.supervisor || state.Usuario.caixa || state.Usuario.permiteBaixarCondicional) {
							if (state.Caixas.content.length > 1 && state.Caixas.content.filter(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S").length > 1) {
								state.carregando = false;
								return router.push({name: "SelecionaCaixa"});
							} else if (state.Caixas.content.filter(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S").length == 1 && state.Caixas.content.length == 1) {
								commit("selecionarCaixa", {
									caixa: state.Caixas.content.find(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S")
								});
								await dispatch("buscarStateInicial", {
									atualizar: true,
									caixaSelecionado: true
								});
							} else {
								state.carregando = false;
								return router.push({name: "SelecionaCaixa"});
							}
						} else {
							commit("selecionarCaixa", {
								caixa: {
									aberto: "S",
									cdcaixa: 0,
									identificador: "SEM CAIXA",
									cdempresa: state.Empresa.id
								}
							});
							await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
							if (!state.Usuario.permiteBaixarCondicional) {
								if(state.Usuario.usaCelular){
									return router.push({name: "LancamentoMesa",});
								}
								router.push({name: "Lancamento", query: {tipoVenda: "S"}});
							} else {
								router.push({name: "Principal"});
							}
						}
					} else {
						const caixa = JSON.parse(caixaString);
						if (!caixa?.caixa) {
							localStorage.clear();
							state.carregando = false;
							if (state.Usuario.supervisor || state.Usuario.caixa) {
								if (state.Caixas.content.length > 1 || state.Caixas.content.filter(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S").length < 1) {
									return router.push({name: "SelecionaCaixa"});
								} else {
									commit("selecionarCaixa", {caixa: state.Caixas.content.find(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S")});
									await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
								}
							} else {
								commit("selecionarCaixa", {
									caixa: {
										aberto: "S",
										cdcaixa: 0,
										identificador: "SEM CAIXA",
										cdempresa: state.Empresa.id
									}
								});
								await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
								if (!state.Usuario.permiteBaixarCondicional) {
									if(state.Usuario.usaCelular){
										return router.push({name: "LancamentoMesa",});
									}
									return router.push({name: "Lancamento", query: {tipoVenda: "S"}});
								} else {
									router.push({name: "Principal"});
								}	
							}
						} else {
							if (caixa.caixa.aberto == "N") {
								let caixaAberto = state.Caixas.content.find(cx => cx.cdEmpresa == state.Empresa.id && cx.aberto == "S");
								if (caixaAberto && (caixaAberto?.caixa?.cdempresa == state.Empresa.id)) {
									commit("selecionarCaixa", {caixa: caixaAberto});
									return await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
								} else {
									state.carregando = false;
									if ((caixa.caixa.cdempresa != state.Empresa.id)) {
										return router.push({name: "SelecionaCaixa"});
									}
									if (state.Usuario.supervisor || state.Usuario.caixa) {
										commit("selecionarCaixa", {caixa: caixa.caixa});
										return await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
										// return router.push({name: "SelecionaCaixa"});
									} else {
										return commit("relatarErro", {
											mensagem: "Nenhum caixa aberto. Favor solicitar abertura do caixa!"
										});
									}
								}
							}
							if ((caixa.caixa.cdempresa != state.Empresa.id)) {
								return router.push({name: "SelecionaCaixa"});
							}
							commit("selecionarCaixa", {caixa: caixa.caixa});
							await dispatch("buscarStateInicial", {atualizar: true, caixaSelecionado: true});
						}
					}
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async buscarCaixaPorIdentificador({commit, dispatch, state}, payload) {
			try {
				const {cdcaixa, data} = payload;
				let url = "caixa/informacoesCaixa";
				url += `?data=${!data ? dateFormatISO({date: null, format: "ISOdate"}) : data}`;
				url += `&identificador=${cdcaixa}`;
				doRequest(url, {method: "get", cache: "no-cache"}, commit).then(async (res) => {
					if (res && res.caixa) {
						res["data"] = !data ? dateFormatISO({date: null, format: "ISOdate"}) : data;
						commit("selecionarCaixa", res);
						localStorage.setItem("caixaLocal", JSON.stringify(res));
						if (router.currentRoute.name != "SelecionaCaixa") {
							if (state.Venda && state.Venda.vendedor) {
								state.Venda.cdIdentificador = res.caixa.cdcaixa;
							}
							return;
						}
						await dispatch("buscarStateInicial");
						if (!state.Usuario.supervisor && !state.Usuario.caixa) {
							if(state.Usuario.usaCelular){
								return router.replace({name: "LancamentoMesa"});
							}
							router.replace({name: "Lancamento", query: {tipoVenda: "S"}});
							
						} else if(state.Usuario.supervisor || state.usuarioPrivilegiado) {
							router.replace({name: "Relatorios", query: {menu: "Dashboard"}});
						} else {
							router.replace({name: "Principal"});
						}
					} else {
						commit("relatarErro", {mensagem: res.mensagem});
					}
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || error.mensagem});
			}
		},

		async atualizarInformacoesCaixa({commit, state}, payload) {
			try {
				let {cdCaixa, dataConsulta} = payload;
				if (!cdCaixa) {
					return;
				}
				let url = "caixa/informacoesCaixa?";
				url += `data=${dataConsulta ? dataConsulta : dateFormatISO({date: null, format: "ISOdate"})}&identificador=${payload?.cdCaixa || payload?.cdcaixa}`;
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response && response.caixa) {
					response["data"] = dateFormatISO({date: null, format: "ISOdate"});
					commit("selecionarCaixa", response);
					localStorage.setItem("caixaLocal", JSON.stringify(response));
					if ((!state.Usuario.supervisor && !state.Usuario.caixa) && !state.Usuario.permiteBaixarCondicional) {
						if(state.Usuario.usaCelular){
							return router.replace({name: "LancamentoMesa"});
						}
						return router.replace({name: "Lancamento", query: {tipoVenda: "S"}});
					} 
					if ((router.currentRoute.name != "Principal" && router.currentRoute.name != "Fechamento" &&
						router.currentRoute.name != "Caixa" && router.currentRoute.name != "FecharCaixa" &&
						router.currentRoute.name != "ContasReceber" && router.currentRoute.name != "Vendas" &&
						router.currentRoute.name != "Relatorios" && router.currentRoute.name != "Gerenciamento")) {

						if(state.Usuario.supervisor || state.usuarioPrivilegiado) {
							return router.replace({name: "Relatorios", query: {menu: "Dashboard"}});
						}
						return router.replace({name: "Principal"});
					}

					return;
				}
				commit("relatarErro", {mensagem: JSON.stringify(response)});
				localStorage.clear();
				if (router.currentRoute.name != "Autenticacao") {
					state.Usuario = null;
					router.replace({name: "Autenticacao"});
				}
			} catch (error) {
				console.log(error);
				localStorage.clear();
				if (router.currentRoute.name != "Autenticacao") {
					state.Usuario = null;
					router.replace({name: "Autenticacao"});
				}
			}
		},

		async abrirCaixa({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {cdCaixa, observacaoInicial, cdcaixa, dataAbertura} = payload;

				const body = JSON.stringify({
					cdIdentificador: cdCaixa || cdcaixa,
					observacaoInicial,
					cdusuario: state.Usuario.id,
					nomeusuario: state.Usuario.nome,
					dataAbertura,
				});

				const data = await doRequest("caixa/abrirCaixa", {method: "post", cache: "no-cache", body}, commit);

				if (data.mensagem || data.message) {
					commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
				} else {
					state.Caixas.content.forEach(caixa => {
						if (caixa.cdCaixa == data.cdcaixa) {
							caixa.aberto = data.aberto;
						}
					});
					await dispatch("atualizarInformacoesCaixa", {cdCaixa: data.cdcaixa});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async fecharCaixa({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {conferencia, observacaoFinal} = payload;
				const body = JSON.stringify({
					cdusuario: state.Usuario.id,
					nomeusuario: state.Usuario.nome,
					cdIdentificador: state.Caixa.caixa.cdcaixa,
					conferencia: conferencia.map(conf => {
						return {
							cdTipo: conf.cdtipo,
							tipo: conf.tipo,
							observacao: "",
							valorInformado: conf.valorInformado,
							valorSistema: conf.valorVista ? (state.Caixa[conf.tipo != "R" ? "movimentacaoVista" : "movimentacaoRecebimento"].find(sis => sis.cdtipo == conf.cdtipo) ?
								state.Caixa[conf.tipo != "R" ? "movimentacaoVista" : "movimentacaoRecebimento"].find(sis => sis.cdtipo == conf.cdtipo).valor : 0) :
								(state.Caixa[conf.tipo != "R" ? "movimentacaoPrazo" : "movimentacaoRecebimento"].find(sis => sis.cdtipo == conf.cdtipo) ?
									state.Caixa[conf.tipo != "R" ? "movimentacaoPrazo" : "movimentacaoRecebimento"].find(sis => sis.cdtipo == conf.cdtipo).valor : 0)
						};
					}),
					dataAbertura: payload.dataAbertura,
					dataFechamento: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					movimentoCaixa: state.Caixa.movimentoCaixa,
					observacaoFinal,
					saldoAnterior: state.Caixa.saldoAnterior || 0,
					saldoFinal: state.Caixa.saldoFinal || 0,
					vendasCaixa: state.Caixa.vendasCaixa
				});
				const data = await doRequest(`caixa/fecharCaixa?id=${state.Caixa.caixa.cdcaixa}`, {
					method: "post",
					cache: "no-cache",
					body
				}, commit);

				if (data.mensagem && !data.erro) {
					commit("relatarErro", {mensagem: data.mensagem});
				} else {
					state.Caixas.content.forEach(caixa => {
						if (caixa.cdcaixa == data.cdcaixa) {
							caixa.aberto = data.aberto;
						}
					});
					state.Caixa.caixa.aberto = data.aberto;
					localStorage.clear();
					localStorage.setItem("caixaLocal", JSON.stringify(data));
					await dispatch("atualizarInformacoesCaixa", {cdcaixa: data.cdcaixa});
					router.replace({name: "FecharCaixa", query: {data: payload.dataAbertura}});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async SangriaCaixa({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {valor, cdtipopgto, descricao, observacao, pagamento, data} = payload;

				const splittedData = data.split("-");
				const ano = splittedData[0];	
				const mes = splittedData[1];
				const dia = splittedData[2];

				const novaData = new Date();
				const dataBaixa = new Date(ano, mes - 1, dia,
					novaData.getHours() - 3,
					novaData.getMinutes(), 
					novaData.getSeconds()
				).toISOString().split("Z")[0];
				
				const body = JSON.stringify({
					cdIdentificador: state.Caixa.caixa.cdcaixa,
					cddoc: "0",
					cdtipopgto,
					data: dataBaixa,
					descricao,
					observacao:`${observacao} Feito por ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`,
					pagamento,
					valor,
					cdusuario: state.Usuario.id,
					nomeusuario: state.Usuario.nome,
				});
				const resposta = await doRequest("caixa/sangria", {method: "post", cache: "no-cache", body}, commit);
				if (!resposta) return;
				if (resposta.mensagem != "Sangria adicionada com sucesso") {
					commit("relatarErro", {mensagem: resposta.message || (resposta.details ? JSON.stringify(resposta.details) : JSON.stringify(resposta))});
					return null;
				}
				await dispatch("atualizarInformacoesCaixa", {cdCaixa: state.Caixa.caixa.cdcaixa});
				return true;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},

		async RecargaCaixa({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {valor, cdtipopgto, descricao, observacao, pagamento, data} = payload;
				const body = JSON.stringify({
					cdIdentificador: state.Caixa.caixa.cdcaixa,
					cddoc: "0",
					cdtipopgto,
					data: `${data}T${new Date().toISOString().split("T")[1].split("Z")[0]}`,
					descricao,
					observacao:`${observacao} Feito por ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`,
					pagamento,
					valor,
					cdusuario: state.Usuario.id,
					nomeusuario: state.Usuario.nome,
				});
				const resposta = await doRequest("caixa/reforco", {method: "post", cache: "no-cache", body}, commit);
				if (!resposta) return;
				if (resposta.mensagem != "Reforço adicionada com sucesso") {
					commit("relatarErro", {mensagem: resposta.message || (resposta.details ? JSON.stringify(resposta.details) : JSON.stringify(resposta))});
					return null;
				}
				await dispatch("atualizarInformacoesCaixa", {cdCaixa: state.Caixa.caixa.cdcaixa});
				return true;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},

		async buscarInfomacoesTodosCaixas({commit, state}) {
			try {
				for (let caixa of state.Caixas.content.filter(cx => cx.cdEmpresa == state.Empresa.id)) {
					doRequest(`caixa/informacoesCaixa?data=${dateFormatISO({date: null, format: "ISOdate"})}&identificador=${caixa.cdcaixa}`, {method: "get", cache: "no-cache"}, commit).then((info) => {
						if (info && info.caixa) {
							info["data"] = dateFormatISO({date: null, format: "ISOdate"});
							const index = state.Caixas.content.map(c => c.cdcaixa).indexOf(caixa.cdcaixa);
							if (index >= 0) {
								state.Caixas.content[index].info = {...info};
							}
						}
						if (info.details || info.mensagem) {
							commit("relatarErro", {mensagem: JSON.stringify(info)});
						}
					}).catch((err) => {
						console.log(err);
					});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async atualizarValorInformado({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {cdFormPgto, cdfechamento, observacao, valor, cdCaixa, dataConsulta, tipo} = payload;
				const body = JSON.stringify({
					cdFormPgto,
					cdfechamento,
					observacao,
					valor,
					tipo,
					userId: state.Usuario.id,
				});
				const data = await doRequest("caixa/ajusteCaixa", {
					method: "put",
					body,
					cache: "no-cache"
				}, commit);

				if (data.mensagem != "Ajuste Salvo com sucesso!") {
					commit("relatarErro", {
						mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))
					});
				} else {
					await dispatch("atualizarInformacoesCaixa", {cdCaixa, dataConsulta});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || error.mensagem});
			}
			state.carregando = false;
		},

		async atualizarObservacaoCaixa({commit, state}, payload) {
			state.carregando = true;
			try {
				const {cdfechamento, descricaoFinal, descricaoInicial} = payload;
				const body = JSON.stringify({
					cdfechamento,
					descricaoFinal, 
					descricaoInicial
				});
				const data = await doRequest("caixa/ajusta-descricao-caixa", {
					method: "put",
					body,
					cache: "no-cache"
				}, commit);
				if (data.mensagem != "Ajuste Salvo com sucesso!") {
					commit("relatarErro", {
						mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))
					});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || error.mensagem});
			}
			state.carregando = false;
		},

		async buscarLogAjustes({commit, state}, payload) {
			state.carregando = true;
			try {
				const {cdfechamento} = payload;
				let url = `caixa/ajusteCaixa?cdfechamento=${cdfechamento}`;
				const data = await doRequest(url, {
					method: "get",
					cache: "no-cache"
				}, commit);
				if (data.mensagem) {
					throw new Error(data.mensagem);
				}
				if (!data.length) {
					throw new Error(data.message || JSON.stringify(data));
				}
				commit("setLogAjustes", data);
			} catch (error) {
				console.log(error);
				commit("setLogAjustes", []);
				commit("relatarErro", {mensagem: error.message});
			}
			state.carregando = false;
		},

		async adicionarCaixa({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify({
					agencia: payload.conta.agencia,
					banco: payload.conta.banco,
					cc: payload.conta.cc,
					cdempresa: payload.cdempresa || state.Empresa.id,
					identificador: payload.identificador,
					ativo: payload.ativo,
				});
				let url = "caixa";
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.message) {
					throw new Error(JSON.stringify(data));
				}
				await dispatch("buscarCaixas");
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async editarCaixa({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify({
					agencia: payload.conta.agencia,
					banco: payload.conta.banco,
					cc: payload.conta.cc,
					cdempresa: payload.cdempresa || state.Empresa.id,
					identificador: payload.identificador,
					ativo: payload.ativo,
				});
				let url = `caixa?id=${payload.idcaixa}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.message) {
					throw new Error(JSON.stringify(data));
				}
				await dispatch("buscarCaixas");
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async filtrarCaixas({commit, state}, payload){
			try {
				state.carregando = true;
				let url = `caixa/filter${state.Usuario.usuarioPrivilegiado ? "" : `?cdempresa=${state.Empresa.id}`}`;
				url += `${state.Usuario.usuarioPrivilegiado ? "?" : "&"}identificador=${payload.todos}`;
				const data = await doRequest(url, {method: "get"}, commit);
				state.carregando = false;
				if (!data.error) {
					commit("atualizarListaCaixas", data);
				} else {
					commit("atualizarListaCaixas", null);
				}
			} catch (error) {
				console.log(error);
				commit("atualizarListaCaixas", null);
			}
		},
	},

	mutations: {
		atualizarListaCaixas(state, payload) {
			state.Caixas = new StateCaixas(payload);
			state.paginaCaixasAtual = state.Caixas.page;
			state.totalPaginasCaixas = state.Caixas.totalPages;
		},

		selecionarCaixa(state, payload) {
			state.Caixa = null;
			state.Caixa = payload;
			if (payload?.cdcaixa > 0) {
				state.Caixas.content[
					state.Caixas.content.map(caixa => caixa.cdCaixa).indexOf(payload?.caixa?.cdcaixa)
				].informacoes = payload;
			}
		},

		setLogAjustes(state, payload) {
			state.logAjustes = payload;
		}
	}
};
