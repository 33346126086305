import { Financeiro } from "../models/Financeiro.js";
import {doRequest} from "./http.js";
const BASE_URL_API_CONCILIACAO = process.env.VUE_APP_BASE_URL_API_CONCILIACAO || "http://158.220.103.17:5000";
export default {
	state: {
		Financeiro: {
			contasReceber: {
				contas: [],
				paginaAtual: -1,
				paginaMaxima: 0
			},
			contasRecebidas: {},
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
			Importacao: {
				Transactions: []
			}
		},
		Totalizadores: {},
		ListaReceitas: {
			content: [],
			paginacao: {},
			totalizadores: {}
		},
		ListaTransacoesConciliadas: []
	},

	actions: {
		async filtrarExtrato({state, commit},payload){
			state.carregando = true;
			try {
				let url = `relatorio-financeiro/extrato-conta?agencia=${payload.conta.agencia}`;
				url += `&banco=${payload.conta.banco}&conta=${payload.conta.conta}`;
				url += `&dataFinal=${payload.dataF}&dataInicio=${payload.dataI}`;
				const data = await doRequest(url, {method: "get"}, commit);
				commit("atualizarExtrato", data);
			}
			catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async buscarContasCdCliente({commit, state}, payload){
			state.carregando = true;
			try {
				commit("atualizarContasReceber", {content: [], page: 0, totalPages: 0});
				const {
					cdCliente,
					condicao,
					sort,
					sortOrder,
					size,
					page,
				} = payload;
				let url = `financeiro/contas?cdcliente=${cdCliente}&condicao=${condicao || "A"}`;
				

				url += `&sort=${condicao == "B" ? "databaixa" : sort || "datavenc"}&sortOrder=${condicao == "B" ? "desc" : sortOrder || "asc"}`;
				if (size) {
					url += `&size=${size}`;
				}
				if (page) {
					url += `&page=${page}`;
				}

				const data = await doRequest(url, {method: "get"}, commit);
				if (data?.content?.length > 0) {
					commit("atualizarContasReceber", {content: data.content, condicao, paginacao: {
						totalPages: data?.totalPages || 0,
						totalElements: data?.totalElements || 0,
						size: data?.size || 25,
						page: data?.page >= 0 ? data?.page : -1
					}});
				} else {
					commit("atualizarContasReceber", {content: [], page: 0, totalPages: 0});
				}
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},


		async filtrarReceitas({commit,}, payload) {
			try {
				let url = "financeiro/contas";
				const {
					cdcliente,
					cdnota,
					cdpedido,
					condicao,
					dataBaixaF,
					dataBaixaI,
					dataDocF,
					dataDocI,
					dataVencF,
					dataVencI,
					cdempresa,
					valor,
					size,
					sort,
					sortOrder,
					page
				} = payload;
				if(!condicao) return commit("relatarErro", {mensagem: "Para realizar o filtro é necessário a condição (Baixado, Aberto)"});
				url += `?condicao=${condicao || "A"}`;
				url += `${cdcliente ? `&cdcliente=${cdcliente}` : ""}`;
				url += `${cdnota ? `&cdnota=${cdnota}` : ""}`;
				url += `${cdpedido ? `&cdpedido=${cdpedido}` : ""}`;
				url += `${dataBaixaI ? `&dataBaixaI=${dataBaixaI}` : ""}`;
				url += `${dataBaixaF ? `&dataBaixaF=${dataBaixaF}` : ""}`;
				url += `${dataVencI ? `&dataVencI=${dataVencI}` : ""}`;
				url += `${dataVencF ? `&dataVencF=${dataVencF}` : ""}`;
				url += `${dataDocI ? `&dataDocI=${dataDocI}` : ""}`;
				url += `${dataDocF ? `&dataDocF=${dataDocF}` : ""}`;
				url += `${cdempresa ? `&empresa=${cdempresa}` : ""}`;
				url += `${size ? `&size=${size}` : ""}`;
				url += `${sort ? `&sort=${sort}` : ""}`;
				url += `${sortOrder ? `&sortOrder=${sortOrder}` : ""}`;
				url += `${page ? `&page=${page}` : ""}`;
				url += `${valor ? `&valor=${valor}` : ""}`;
				

				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				
				if(data && (data.erro || data.mensagem)) {
					commit("atualizarListaReceitas", {content: [], paginacao: {}, totalizadores: {}});
					return;
				}
				if(data && data?.content?.length > 0) {
					commit("atualizarListaReceitas", {
						content: data.content,
						paginacao: {
							last: data.last || false,
							paginaAtual: data.page || 0,
							size: data.size || 25,
							totalElements: data.totalElements || 0,
							totalPaginas: data.totalPages || 0
						},
						totalizadores: data.anexo
					});
					return;
				}

			} catch (error) {
				console.error(error);
			}
		},

		async filtrarContasReceber({commit, state}, payload){
			state.carregando = true;
			try {
				commit("atualizarContasReceber", {content: [], page: 0, totalPages: 0});
				const {
					condicao,
					dataVencI,
					dataVencF,
					sortOrder,
					sort,
					page,
					size,
					cliente,
					empresa,
					tipoPagamento,
				} = payload;
				
				let url = `financeiro/contas?condicao=${condicao}&dataVencI=${dataVencI}`;
				url += `&dataVencF=${dataVencF || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")}`;
				url += `&sort=${sort || "cdcliente"}&sortOrder=${sortOrder || "asc"}`;
				if (page) {
					url += `&page=${page}`;
				}
				if (size) {
					url += `&size=${size}`;
				}
				if (cliente) {
					url += `&cdcliente=${cliente.id}`;
				}
				if (empresa) {
					url += `&empresa=${empresa}`;
				}
				if (tipoPagamento) {
					url += `&tipoPagamento=${tipoPagamento}`;
				}
				// url += `&page=${page || 0}&size=500&sort=DATABAIXA&sortOrder=desc`;
				const data = await doRequest(url, {method: "get"}, commit);
				if (data?.content?.length > 0) {
					commit("atualizarContasReceber", {data, content: data.content, condicao, paginacao: {
						totalPages: data?.totalPages || 0,
						totalElements: data?.totalElements || 0,
						size: data?.size || 25,
						page: data?.page >= 0 ? data?.page : -1
					}});
					commit("atualizarTotalizadores", data.anexo);
				} else {
					commit("atualizarContasReceber", {content: [], page: 0, totalPages: 0});
				}
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async filtrarContasReceberPorCdPedido({commit, state}, payload){
			state.carregando = true;
			try {
				const {
					cdPedido,
					condicao,
					// page,
				} = payload;

				let url = `financeiro/contas?cdPedido=${cdPedido}&condicao=${condicao || "A"}`;
				// url += `&page=${page || 0}&size=500&sort=DATABAIXA&sortOrder=desc`;
				const data = await doRequest(url, {method: "get"}, commit);
				if (data.length > 0) {
					commit("atualizarContasReceber", {content: [], page: 0, totalPages: 0});
				} else {
					commit("atualizarContasReceber", {content: data});
				}
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async baixarContas({commit, state}, payload){
			state.carregando = true;
			try {
				const {
					parcelas,
					cliente,
					observa,
					tipoPgto,
					valorBaixa,
					valorEntreguePeloCliente,
					aumdesc,
					cheques,
					parcelasCartao,
					contaCorrente,
				} = payload;
				const date = payload.dataBaixa.split("-");
				const ano = date[0];
				const mes = Number(date[1] -1);
				const dia = date[2];
				const body = JSON.stringify({
					contaCorrente,
					acertoContasAtrasadas: false,
					aumdesc: Number(Number(aumdesc).toFixed(6) || 0),
					cdIdentificador: state.Caixa.caixa.cdcaixa,
					cdbandeira: parcelas[0]?.cdbandeira,
					codprodutoconciliadora: parcelas[0]?.codprodutoconciliadora,
					qtdeParcelaCartao: Number(parcelasCartao) || 0,
					cdcliente: cliente.id,
					cddoc: new Set(parcelas.map(p => p.cdMovi)).length == 1 ? parcelas[0].cdMovi : "",
					cdempresa: state.Empresa.id,
					cdvendedor: state.Usuario.id,
					dataBaixa: new Date(ano, mes, dia, (new Date().getHours() - 3), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() ).toISOString().split("Z")[0],
					nome: cliente.nome,
					observa,
					nsucartao: parcelas[0]?.nsucartao,
					doccartao: parcelas[0]?.doccartao,
					tipoPgto: tipoPgto.cdtipo,
					titulosABaixar: parcelas.map(parcela => {
						return {
							cdagencia: parcela.cdAgencia,
							cdbanco: parcela.cdBanco,
							cdconta: parcela.cdConta,
							cddig: parcela.cdDig,
							cdempresa: parcela.cdEmpresa,
							tipo: parcela.tipo,

						};
					}),
					valorBaixa: valorBaixa >= valorEntreguePeloCliente ? valorEntreguePeloCliente : valorBaixa,
					valorEntreguePeloCliente,
					cheques: cheques ? cheques.map((cheque, index) => ({
						banco: cheque.banco,
						agencia: cheque.agencia,
						conta: cheque.conta,
						numerocheque: cheque.numeroCheque,
						vencimento: cheque.vencimentoCheque,
						nominal: cheque.nominal,
						valor: 0,
						vlrcheque: cheque.valorCheque,
						situacao: "A",
						cdlan: index +1
					})): null
				});

				let url = `financeiro?cdusuario=${state.Usuario.id}`;
				const data = await doRequest(url, {method: "post", body}, commit);
				if (data.conta && data.baixas.length > 0) {
					return {
						empresa: state.Empresa,
						cliente: {
							id: data.conta.cdpessoa,
							nome: data.conta.nome
						},
						pagamento: state.formasPagamento.find(pgto => pgto.cdtipo == data.conta.cdtipopgto).descricao,
						parcelas: data.baixas.map(conta => new Financeiro(conta)),
						conta: data.conta,
						valorPago: data.conta.valor
					};
				} else if (!data) {
					commit("relatarErro", {mensagem:"Não foi possível gerar o recibo. Tente buscar em baixadas!"});
				} else if (data.message) {
					commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}
				state.carregando = false;
				return null;
			} catch (error) {
				console.log(error);
				state.carregando = false;
				return null;
			}
		},

		async buscarBaixa({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				const {
					cdAgencia,
					cdBanco,
					cdConta,
					cdDig,
					tipo,
					impressao
				} = payload;
				let url = `financeiro/baixa?cdagencia=${cdAgencia || state.Caixa.caixa.cdagencia}`;
				url += `&cdbanco=${cdBanco || state.Caixa.caixa.cdbanco}`;
				url += `&cdconta=${cdConta || state.Caixa.caixa.cdconta}`;
				url += `&cddig=${cdDig}&tipo=${tipo}`;
				const data = await doRequest(url, {method: "get"}, commit);
				if (data.conta && data.baixas.length > 0) {
					if (!impressao) {
						state.carregando = false;
						return {...data};
					}
					dispatch("gerarRecibo", {
						empresa: state.Empresa,
						cliente: {
							id: data.conta.cdpessoa,
							nome: data.conta.nome
						},
						pagamento: state.formasPagamento.find(pgto => pgto.cdtipo == data.conta.cdtipopgto).descricao,
						parcelas: data.baixas.map(conta => new Financeiro(conta)),
						conta: data.conta,
						valorPago: data.conta.valor
					});
				} else {
					commit("relatarErro", {mensagem:"Não foi possível encontrar a baixa solicitada. Verifique!"});
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message|| JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async estornarConta({state, commit, dispatch}, payload) {
			state.carregando = true;
			try {
				const {
					cdAgencia,
					cdBanco,
					cdConta,
					cdDig,
					tipo
				} = payload;
				let url = "financeiro/estornar";
				url += `?cdagencia=${cdAgencia || state.Caixa.caixa.cdagencia}`;
				url += `&cdbanco=${cdBanco || state.Caixa.caixa.cdbanco}`;
				url += `&cdconta=${cdConta || state.Caixa.caixa.cdconta}&cdusuario=${state.Usuario.id}`;
				url += `&cddig=${cdDig}&tipo=${tipo}`;
				const data = await doRequest(url, {method: "post"}, commit);
				if (data.mensagem && data.mensagem == "Titulos Estornados com sucesso!") {
					await dispatch("buscarContasCdCliente", {
						cdCliente: payload.cdPessoa,
						condicao: "B",
					});
				} else {
					commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data.details || data)});
				}
				await dispatch("buscarStateInicial");
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async solicitarImpressaoExtrato({commit, state, dispatch}, payload){
			state.carregando = true;
			try {
				const {
					cliente
				} = payload;
				let page = 0;
				let parcelas = [];
				let data = null;
				let url = `financeiro/contas?cdcliente=${cliente.id}&condicao=A`;
				url += `&page=${page}&size=500&sort=datavenc&sortOrder=desc`;
				data = await doRequest(url, {method: "get"}, commit);
				page += 1;
				if (data.length > 0) {
					parcelas.push(...data.map(conta => new Financeiro(conta)));
				}
				dispatch("gerarExtrato", {
					empresa: state.Empresa,
					parcelas,
					cliente,
					total: true
				});
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async realizarProcessamentoOFX({ commit, state, dispatch }, base64Data) {
			state.carregando = true;
			try {
				const body = JSON.stringify({ ofxData: base64Data });
				const response = await fetch(`${BASE_URL_API_CONCILIACAO}/process-ofx`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body
				});

				if(!response.ok) {
					throw new Error(`Erro na requisição: ${response.statusText}`);
				}

				const data = await response.json();
				if(data.success) {
					state.carregando = false;
					dispatch("buscarTitulosLancados", data.transactions);
					// commit("setTransactions", data.transactions);
				} else {
					throw new Error("Falha no processamento do arquivo OFX.");
				}
			} catch (error) {
				state.carregando = false;
				console.error(`Erro ao realizar importação: ${error.message}`);
				commit("relatarErro", {
					mensagem: `Erro ao processar o arquivo OFX: ${error.message}`
				});
			}
		},



		async addTituloReceber ({commit, state,}, payload) {
			try {
				state.carregando = true;
				const url = "contas-receber/adicionar";
				payload.cdEmpresa = state.Empresa.id;
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "POST",
					body,
				}, commit);
				state.carregando = false;
				if(data && data.length > 0) {
					for(let error of data) {
						commit("relatarErro", {
							mensagem: `Erro: O campo ${error.field === "nome" ? "fornecedor" : null}, ${error.error}`
						});
					}
					return {error: true};
				}
				if(data && (data?.cddig || data?.vlrtitulo)){
					commit("relatarErro", {
						mensagem: "Títulos adicionados com sucesso!",
						sucess: true 
						
					});
					return data;
				} else {
					commit("relatarErro", {
						mensagem: "Algo deu errado, por favor tente novamente, se o erro persistir entre em contato com o suporte!"
					});
					return {error: true};
				}
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))
				});
				console.log(error);
				return null;
			}
		},

		async adicionarTitulosReceber({commit, state}, payload) {
			try {
				const url = "contas-receber/adicionarLista";
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				if(data && (data[0]?.cdlan || data[0]?.vlrtitulo)){
					commit("relatarErro", {
						mensagem: "Títulos adicionados com sucesso!",
						sucess: true 
					});
					return data;
				}
				if(data && data.length > 0) {
					for(let error of data) {
						commit("relatarErro", {
							mensagem: `Erro: O campo ${error.field === "nome" ? "fornecedor" : null}, ${error.error}`
						});
					}
					return {error: true};
				}
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))
				});
				return null;
			}
		},

		async buscarTitulosLancados({state, commit, dispatch}, payload) {
			try {
				const transactions = payload;
				const processedTransactions = [];
				state.carregando = true;
				for(let transaction of transactions) {
					const data = await dispatch("findTransactionByValorExtenso", {
						observacao: transaction.nmrMovimentacao,
						data: transaction.data,
						valor: transaction.valor,
					});
					if(data?.cddoc) {
						const { cdbanco, cdagencia, cdconta, tipo, cddig } = data.financeiroId;
						processedTransactions.push({
							...transaction,
							cddoc: (data.cddoc.substring(0,20).replace(/\s/g, "")),
							cdbanco,
							cdagencia,
							cdconta,
							tipo,
							cddig,
						});
					} else {
						processedTransactions.push({
							...transaction,
						});	
					}
				}
				state.carregando = false;
				commit("setTransactions", processedTransactions);
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error
				});
			}
		},

		async conciliarTitulosPDF({state, commit, dispatch}, payload) {
			try {
				const formData = new FormData();
				formData.append("pdf", payload);
				
				const response = await fetch(`${BASE_URL_API_CONCILIACAO}/process-pdf`, {
					method: "POST",
					body: formData,
				});

				if(!response.ok) {
					throw new Error(`Erro na requisição: ${response.statusText}`);
				}

				const data = await response.json();

				if(data.success) {
					state.carregando = false;
					commit("atualizarListaTransactionPDF", data.transactions);

					dispatch("conciliarFornecedores", {transactions: data.transactions});
					// commit("setTransactions", data.transactions);
				} else {
					throw new Error("Falha no processamento do arquivo OFX.");
				}

			} catch (error) {
				console.log(error);	
			}
		},

		async conciliarFornecedores({dispatch, commit, state}) {
			try {
				const transactions = state.ListaTransacoesConciliadas;
				const listaTransacoesConciliadas = [];
				// const reconciledTransactions = [];
				if(transactions?.length > 0) {
					for(const transaction of transactions) {
						await dispatch("filtrarClientesPorNome", {query: transaction.remetente});

						if(state.Clientes.length > 0) {
							state.Clientes.forEach(async (cliente) => {
								state.Financeiro.contasReceber.contas = [];
								const novaTransacao = {...transaction, cdPessoa: cliente.id, nome: cliente.nome};
								await dispatch("buscarContasCdCliente", {
									cdCliente: cliente.id,
									condicao: "A",
									sort: "datavenc",
									sortOrder: "asc",
									size: 150,
								});

								const contasReceber = state.Financeiro.contasReceber.contas;
								if(contasReceber && contasReceber?.length > 0) {
									novaTransacao.titulo = {
										...contasReceber[0],
										valor: contasReceber[0].contarc,
										cdPessoa: contasReceber[0].cdPessoa,
										nome: contasReceber[0].nome,
										pagamento: contasReceber[0].pagamento
									};
								} else {
									await dispatch("buscarContasCdCliente", {
										cdCliente: cliente.id,
										condicao: "B",
										sort: "datavenc",
										sortOrder: "asc",
										size: 150,
									});
									const contasReceber = state.Financeiro.contasReceber.contas;
									console.log(contasReceber, "baixado");
									console.log(contasReceber[0].credito === transaction.valor);
									if(contasReceber && contasReceber?.length > 0) {
										novaTransacao.titulo = {
											...contasReceber[0],
											valor: contasReceber[0].credito,
											cdPessoa: contasReceber[0].cdPessoa,
											nome: contasReceber[0].nome,
											pagamento: contasReceber[0].pagamento,
											conciliado: contasReceber[0].credito === transaction.valor,
										};
									}
								}
								listaTransacoesConciliadas.push(novaTransacao);
							});
						} else {
							listaTransacoesConciliadas.push({...transaction});
						}
						
					}
					commit("atualizarListaTransactionPDF", listaTransacoesConciliadas);

				}
				
			} catch (error) {
				console.log(error);	
				
			}
		},

		async findTransactionByValorExtenso({state, commit}, payload) {
			try {
				const cdempresa = state.Empresa.id;
				const { 
					observacao, 
					valor, 
					data 
				} = payload;
				let url = `financeiro/busca-valor-extenso?empresa=${cdempresa || "ALL"}`;
				if(!observacao || !valor || !data) {
					commit("relatarErro", {
						mensagem: "Campo não encontrado, por favor tente novamente se o erro persistir entre em contato com o suporte!"
					});
					return null;
				}
				url += `${observacao ? `&observacao=${observacao}` : ""}`;
				url += `${valor ? `&valor=${valor}` : ""}`;
				url += `${data ? `&data=${data}` : ""}`;
				const res = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				return res;
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error
				});
			}
		},

		async buscarContaReceber({commit}, payload) {
			try {
				let url = "contas-receber";
				const {
					cdagencia,
					cdbanco,
					cdconta,
					cddig,
					tipo
				} = payload;

				if(!cdagencia || !cdbanco || !cdconta || !cddig || !tipo) throw new Error("Não foi possível encontrar a conta, por favor verifique!");

				url += `?cdagencia=${cdagencia}&cdbanco=${cdbanco}&cdconta=${cdconta}&cddig=${cddig}&tipo=${tipo}`;
				const data = await doRequest(url, {method: "GET"}, commit);
				
				if(data && (data.error || data.mensagem)) {
					return false;
				}
				return data;

			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
				console.error(error);
				return false;
			}
		},

		async atualizarTituloReceber({commit}, payload) {
			try {
				payload.cdEmpresa = payload.cdempresa;
				let url = "contas-receber/modificar";
				if(!payload.cddig) return commit("relatarErro", {mensagem: "Esta faltando o campo [cddig] tente novamente, se o erro persistir entre em contato com o suporte!"});
				url += `?cddig=${payload.cddig}`;

				const body = JSON.stringify(payload);

				const data = await doRequest(url, {method: "PUT", body}, commit);

				return data;
			} catch (error) {
				console.error(error);
			}
		},
	},

	

	mutations: {

		atualizarListaTransactionPDF(state, payload){
			state.ListaTransacoesConciliadas = [];
			state.ListaTransacoesConciliadas = payload;

		},

		atualizarListaReceitas(state, payload) {
			state.ListaReceitas.content = payload.content;

			if(payload.paginacao) {
				state.ListaReceitas.paginacao = payload.paginacao;
			}
			if(payload.totalizadores) {
				state.ListaReceitas.totalizadores = payload.totalizadores;
			}
		},

		atualizarTotalizadores(state, payload){
			state.Totalizadores.totalJuros = payload.totalJuros;
			state.Totalizadores.totalTitulos = payload.totalValorTitulo;
			state.Totalizadores.totalValorRecebido = payload.totalValorRecebido;

		},
		atualizarContasReceber(state, payload) {
			state.Financeiro.contasReceber = {};

			state.Financeiro.contasReceber = {
				contas: (payload.content ? payload.condicao != "A" ? payload.content.filter(conta => conta.condicao != "V") : payload.content : []).map(conta => new Financeiro(conta)),
			};

			state.Financeiro.paginacao = {
				totalPages: payload?.paginacao?.totalPages || 0,
				totalElements: payload?.paginacao?.totalElements || 0,
				size: payload?.paginacao?.size || 25,
				page: payload?.paginacao?.page >= 0 ? payload?.paginacao?.page : -1
			};
		},
		atualizarExtrato(state, payload){
			state.Extrato = payload;
		},
		ordenarContasReceber(state, payload){
			const {metodoOrdem, ordem} = payload;
			if (ordem == "desc"){
				state.Financeiro.contasReceber.contas.sort( (a, b) => new Date(a[metodoOrdem]).getTime() - new Date(b[metodoOrdem]).getTime());
			} else {
				state.Financeiro.contasReceber.contas.sort( (a, b) => new Date(b[metodoOrdem]).getTime() - new Date(a[metodoOrdem]).getTime());
			}
		},
		setTransactions(state, payload) {
			state.Financeiro.Importacao.Transactions = payload;
		}
	}
};
