<template>
  <div class="container-page">
	<div class="container-produtos">
		<div class="container-cliente">
			<div class="input-group input-cliente" v-if="editarNomeConsumidorFinal">
				<input 
					type="text"
					v-model="nomeConsumidorFinalEditado"
					placeholder=" "
					id="nomeConsumidorFinalEditado"
					@blur="editarNomeConsumidorFinal = false"
					@keydown.enter.prevent="confirmarEditarClienteVenda"
				/>
				<label for="nomeConsumidorFinalEditado">
					Nome do cliente
				</label>
			</div>
			
			<h3 v-else>
				{{ nomeCliente }}
				{{ vendaContato }}
				<i
					class="fas fa-edit" 
					style="color: var(--primario)"
					@click="handleNomeClienteVenda"
					v-if="!delivery"
				/>
			</h3>
			<h3 v-if="!editarNomeConsumidorFinal">
				<input 
					type="checkbox" 
					id="delivery"
					v-model="delivery"
					:checked="delivery"
					@change="configurarDelivery"
				/>
				<label for="delivery">
					Entrega
				</label>
				&nbsp;
				<i 
					class="fas fa-edit" 
					style="color: var(--primario)"
					@click="editClienteDelivery"
					v-if="delivery"
				/>
			</h3>
		</div>
		<div class="container-loader-produtos" v-if="isLoadingItens">
			<Loader />
		</div>
		<div class="container-tabela-produtos" v-else>
			<table>
				<thead>
					<tr>
						<th>
							Seq
						</th>
						<th>
							Produto
						</th>
						<th>
							Quantidade
						</th>
						<th>
							$ Unitário
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in Itens" :key="index">
						<td class="code">
							{{ index + 1 }}
						</td>
						<td>
							{{ item.descricao }}
						</td>
						<td class="quantidade">
							{{ item.quantidade }}
						</td>
						<td class="valor">
							{{ ( item.valorUnitario || 0 ) | formatarParaReais }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="container-totalizadores">
			<div class="campos-container">
				<div class="input-group">
					<input 
						type="text"
						id="adicional"
						style="text-align: right"
						:value="(adicional || 0) | formatarParaReais"
						readonly
					/>
					<label for="adicional">
						Adicional
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						id="total-valor"
						style="text-align: right"
						:value="(TotalItens || 0) | formatarParaReais"
						readonly
					/>
					<label for="total-valor">
						$ Total
					</label>
				</div>
			</div>
			<div class="campos-container">
				<button class="botao botao-cancelar" @click="cancelarLancamentoMesa">
					Cancelar
				</button>
			</div>
		</div>
	</div>
	<div class="container-pagamento">
		<div class="container-title">
			<h3>ADICIONAR RECEBIMENTO</h3>
		</div>
		<div class="campos-container">
			<div class="input-group">
				<select 
					id="tipo-pagamento" 
					v-model="financeiro.tipoPgto" 
					@keydown.enter.prevent="proximoCampo('valor-pagar')"
					@change="selecionarValor"
				>
					<option disabled :value="{selecionar: true}">
						SELECIONE UMA OPÇÃO
					</option>
					<option 
						v-for="(pgto, index) in FormasPagamento" 
						:key="index"
						:value="pgto.cdtipo"
					>
						{{ pgto.descricao }}
					</option>
				</select>
				<label>Tipo pagamento</label>
			</div>
			<div class="input-group">
				<input 
					type="text" 
					id="valor-pagar"
					autocomplete="off"
					v-mascaraFloat
					v-model="financeiro.valorParcela"
					@keydown.enter="addValorParcela"
				/>
				<label for="valor-pagar">Valor a Pagar</label>
			</div>
		</div>
		<div class="campos-container">
			<button class="botao botao-adicionar" id="add-pgto" @click="adicionarPagamentoParcial">
				<span v-if="indexEditando === -1">Adicionar</span> <span v-else>Alterar</span> pagamento
			</button>
		</div>
		<div class="campos-container">
			<div class="container-tabela-pagamento">
				
				<table v-if="Financeiro.length > 0">
					<thead>
						<tr>
							<th>
								Seq.
							</th>
							<th>
								Descrição
							</th>
							<th>
								Valor Pago
							</th>
							<th>
								Ação
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(fin, index) in Financeiro" :key="index">
							<td class="code">
								{{ index + 1 }}
							</td>
							<td>
								{{ (fin.descricao || fin.pagamento) }}
							</td>
							<td class="valor">
								{{ (fin.valorParcela || 0) | formatarParaReais }}
							</td>
							<td style="text-align: center">
								<i class="fas fa-edit" style="color: var(--primario)" @click="editPagamento(index, fin)" />
								<i class="fas fa-trash" style="color: var(--deletar)" @click="removerPagamento(index)"/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="campos-container">
			<div class="checked">
				<div class="selecionar">
					<label for="js-checkbox-pesquisa" v-if="descontoAcrescimo" class="pointer">
						&nbsp;
						Desconto
					</label>
					<label for="js-checkbox-pesquisa" v-else class="pointer">
						&nbsp;
						Acréscimo
					</label>
					&nbsp;
					<input
						type="checkbox"
						id="js-checkbox-pesquisa"
						:checked="descontoAcrescimo"
						@change="descontoAcrescimo = !descontoAcrescimo"

					/>
					<label class="check" for="js-checkbox-pesquisa"></label>
				</div>
			</div>
		</div>
			<div class="campos-container" v-if="descontoAcrescimo">
				<transition name="fade">
					<div class="input-group">
						<input 
							type="text"
							id="descontoPercentual"
							v-model="descontoPercentual"
							@input="atualizarDesconto('percentual')"
							autocomplete="off"
							style="text-align:right"
							@focus="(e) => e.target.select()"
							v-mascaraFloat
						/>
						<label for="descontoPercentual">
							Desc. %
						</label>
					</div>
				</transition>
				<transition name="fade">
					<div class="input-group">
						<input 
							type="text"
							
							id="descontoValor"
							v-model="descontoValor"
							@input="atualizarDesconto('valor')"
							autocomplete="off"
							style="text-align:right"
							@focus="(e) => e.target.select()"
							v-mascaraFloat
						/>
						<label for="descontoValor">
							Desc. $
						</label>
					</div>
				</transition>
			</div>
		<div class="campos-container" v-else>
			<transition name="fade">
				<div class="input-group">
					<input 
						type="text"
						id="acrescimo"
						v-model="acrescimo"
						autocomplete="off"
						style="text-align:right"
						v-mascaraFloat
					/>
					<label for="acrescimo">
						Acréscimo
					</label>
				</div>
			</transition>
		</div>
		
		<div class="campos-container">
			<div class="input-group">
				<input 
					type="text" 
					id="total-pago"
					:value="TotalPago | formatarParaReais"
					class="valor"
					readonly
				/>
				<label for="total-pago">
					$ Total Pago
				</label>
			</div>
			<div class="input-group" style="border: 3px solid var(--salvar-texto)">
				<input 
					type="text" 
					id="total-bruto"
					:value="TotalPagar | formatarParaReais"
					style="font-weight: bold;"
					class="valor"
					readonly
				/>
				<label for="total-bruto">
					$ Total a Pagar
				</label>
			</div>
		</div>
		<div class="campos-container">
			<button class="botao botao-acao" @click="finalizarLancamentoMesa">
				Finalizar
			</button>
		</div>
	</div>
	<Modal 
		v-if="solicitaPreImpressao" 
		@fecharModal="cancelarPreImpressao" 
		:dimensoes="{width: '75%', height: 'auto'}"
	>
		<PreImpressao 
			:vendaFinalizada="Venda" 
			@alterarVenda="alterarVenda"
		></PreImpressao>
	</Modal>
	<Modal 
		v-if="modalConfigDelivery" 
		@fecharModal="fecharModalConfigDelivery" 
		:dimensoes="{width: 'auto', height: 'auto'}"
	>
		<CupomDados 
			@salvarInformacoes="salvouConfiguracaoDelivery"
			@fecharModal="fecharModalConfigDelivery"
			:clienteVenda="clienteDelivery"
			:taxaEntrega="true"
		/>
	</Modal>
  </div> 
</template>

<script>
import Modal from "../components/Modal.vue";
import Loader from "../components/Loader.vue";
import CupomDados from "../components/CupomDados.vue";
import PreImpressao from "../components/PreImpressao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import { proximoCampo } from "../utils/utils";


export default {
	name: "FechamentoMesa",
	
	components: {
		Modal,
		PreImpressao,
		CupomDados,
		Loader
	},

	data() {
		return {
			financeiro: {
				valorParcela: "",
				tipoPgto: {selecionar: true}
			},
			proximoCampo,
			indexEditando: -1,
		
			delivery: false,
			modalConfigDelivery: false,

			controle: 1,

			solicitaPreImpressao: false,
			clienteDelivery: {},

			editarNomeConsumidorFinal: false,
			nomeConsumidorFinalEditado: "",
			isLoadingItens: true,

			descontoPercentual: "",
			descontoValor: "",

			valorDesconto: "",

			timeoutId: null,

			adicional: 0,
			acrescimo: "",
			descontoAcrescimo: true,

		};
	},

	computed: {
		Financeiro() {
			return this.Venda?.financeiro;
		},

		Venda() {
			return this.$store?.state?.Venda;
		},

		Itens() {
			return this.$store?.state?.itensVenda;
		},

		clientePadrao() {
			return this.$store?.state?.ClientePadrao;
		},

		TotalItens() {
			let preco = 0;
			if(this.Itens?.forEach) {
				this.Itens.forEach(p => {
					preco += p.valorUnitario * p.quantidade;
				});
			}
			return preco;
		},

		nomeCliente() {
			if(this.Venda && this.Venda?.cliente?.nome) {
				return this.Venda?.cliente.nome;
			} else if(this.Venda && this.Venda.nomeCliente) {
				return this.Venda.nomeCliente;
			} else {
				return "";
			}
		},

		vendaContato() {
			return this.Venda && this.Venda?.contato 
				? `- ${this.Venda.contato.toUpperCase()}` : "";
		},

		ValorTotal() {
			const acrescimo = parseFloat(`${this.acrescimo || 0}`.replace(",", "."));
			return (this.Venda.valorBruto - this.valorDesconto) + Number(acrescimo || 0);
		},

		ValorTotalSemDesconto() {
			const acrescimo = parseFloat(`${this.acrescimo || 0}`.replace(",", "."));
			return (this.Venda.valorBruto) + Number(acrescimo || 0) + this.adicional;
		},

		TotalPagar() {
			const totalPagar = (this.ValorTotal - this.TotalPago) ;
			return (totalPagar >= 0 ? totalPagar : 0);
		},

		TotalPago() {
			if(this.Venda && typeof this.Venda.valorPago === "number") {
				return this.Venda.valorPago;
			}
			return 0;
		},
		
		FormasPagamento() {
			return this.$store.state.formasPagamento.filter(forma => forma.mostravenda === "S");
		},

		Empresa() {
			return this.$store.state.Empresa;
		}

		
	},
	filters: {
		formatarParaReais
	},

	beforeMount() {
		this.buscarVendaId();
	},
	
	watch: {
		"Venda.nomeCliente": {
			handler(newV) {
				this.$store.state.Venda.cliente.nome = newV;
			},
			deep: true,
		},
	},

	methods: {
		calcularDesconto(tipo) {
			const valorTotal = this.ValorTotalSemDesconto;


			if(this.timeoutId !== null) {
				clearTimeout(this.timeoutId);
				this.timeoutId = null;
			}

			this.timeoutId = setTimeout(() => {
				
				if (tipo === "percentual") {
					const percentual = parseFloat(`${this.descontoPercentual || 0}`.replace(",", "."));
					this.valorDesconto = (valorTotal * percentual) / 100;
					this.descontoValor = Number(this.valorDesconto).toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				} else if(tipo === "valor") {
					const descValor = parseFloat(`${this.descontoValor}`.replace(",", "."));
					const percentualDesconto = (descValor / valorTotal) * 100;
					this.valorDesconto = descValor;
					this.descontoPercentual = Number(percentualDesconto || 0).toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
				if(this.valorDesconto > valorTotal) {
					this.valorDesconto = 0;
					this.$store.commit("relatarErro", {
						mensagem: "Não é possível aplicar um desconto maior que o valor total da venda!"
					});
					this.descontoPercentual = "";
					this.descontoValor = "";
				}
				this.timeoutId = null;
			}, 1200);
		},

		atualizarDesconto(tipo) {
			this.calcularDesconto(tipo);
		},



		async buscarVendaId() {
			const venda = await this.$store.dispatch("buscarVendaPorId", {
				cdVenda: this.Venda.cdVenda, 
				cdEmpresa: this.Empresa.id
			});
			if(venda.cdVenda) {
				this.$store.state.Venda = {...venda};
				this.$store.state.itensVenda = [...venda.itens];
				this.isLoadingItens = false;
				if(venda.valorfrete > 0) {
					this.delivery = true;
					this.adicional = venda.valorfrete;
					this.clienteDelivery = {
						...venda.cliente,
						valorFrete: venda.valorfrete,
					};
				}
				if(this.$store.state.Venda?.acrescimo > 0) {
					this.acrescimo = this.$store.state.Venda.acrescimo.toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
					this.descontoAcrescimo = false;
				}
				if(this.$store.state.Venda?.descontoMoeda > 0) {
					this.valorDesconto = this.$store.state.Venda.descontoMoeda;
					this.descontoValor = this.$store.state.Venda.descontoMoeda.toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
					this.descontoPercentual = this.$store.state.Venda.descontoPercentual.toLocaleString("pt-BR", {
						style: "decimal",
						minimumIntegerDigits: 2,
						maximumFractionDigits: 2
					});
				}
			}
		},

		async confirmarEditarClienteVenda() {
			this.editarNomeConsumidorFinal = false;
			this.$store.state.Venda.nomeCliente = `${this.nomeConsumidorFinalEditado || ""}`.toUpperCase();
			this.$store.state.Venda.cliente.nome = `${this.nomeConsumidorFinalEditado || ""}`.toUpperCase();
			await this.atualizarFinanceiroVenda();
		},

		handleNomeClienteVenda() {
			this.editarNomeConsumidorFinal = true;
			this.nomeConsumidorFinalEditado = this.$store.state.Venda.nomeCliente;
			setTimeout(() => {
				const input = document.getElementById("nomeConsumidorFinalEditado");
				if(input?.focus) {
					input.focus();
					input.select();
				}
			}, 25);
		},

		selecionarValor() {
			if(!this.financeiro.tipoPgto.selecionar && this.TotalPagar !== 0){
				proximoCampo("valor-pagar");
				this.financeiro.valorParcela = Number(this.TotalPagar).toFixed(2);
			}
		},

		editClienteDelivery() {
			this.clienteDelivery = this.Venda.cliente;
			this.clienteDelivery.fretegratis = this.Venda.fretegratis;
			this.clienteDelivery.valorFrete = Number(this.Venda.valorfrete || 0).toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			this.modalConfigDelivery = true;
		},

		fecharModalConfigDelivery() {
			this.modalConfigDelivery = false;
			this.delivery = false;
		},

		async verificarCliente(clientParam) {
			try {
				if (clientParam?.cdCliente) {
					await this.$store.dispatch("buscarClientePorId", {id: clientParam.cdCliente, venda: true});
					
					const classificacao = await this.$store.dispatch("buscarClassificacaoPadrao");

					const form = this.montarPayload(clientParam, classificacao);

					await this.$store.dispatch("atualizarCliente", {...form});

					setTimeout(() => {
						this.$store.state.carregando = false;
					},50);
				
				} else {
					const classificacao = await this.$store.dispatch("buscarClassificacaoPadrao");
				
					if (classificacao?.erro || classificacao?.mensagem) {
						this.$store.commit("relatarErro", {
							mensagem: "Classificação pardão não definida, entre em contato com o suporte."
						});
						return;
					}
					const form = this.montarPayload(clientParam, classificacao); 
					const cliente = await this.$store.dispatch("adicionarCliente", form);
					
					if(cliente?.erro || cliente?.mensagem){
						this.$store.commit("setValorFrete", {
							valorfrete: 0,
							fretegratis: true,
						});
						this.$forceUpdate();
						this.delivery = false;
						return;
					}
					
					await this.$store.dispatch("buscarClientePorId", {id: cliente.id, venda: true});
					
					setTimeout(() => {
						this.$store.state.carregando = false;
					},50);
				}
			} catch (error) {
				console.log(error);
			}
		},

		async salvouConfiguracaoDelivery(clienteParam) {
			this.delivery = true;
			this.adicional = parseFloat(`${clienteParam.valorFrete}`.replace(",", "."));
			if(clienteParam.identificador){
				this.$store.state.Venda.identificador = clienteParam.identificador;
			}
			await this.verificarCliente(clienteParam);
			if(clienteParam.fretegratis === "N") {
				this.$store.commit("setValorFrete", {
					valorfrete: parseFloat(`${clienteParam.valorFrete}`.replace(",", ".")),
					fretegratis: false,
				});

				await this.atualizarFinanceiroVenda();
				this.calcularDesconto("percentual");
			} else {
				this.$store.commit("setValorFrete", {
					valorfrete: 0,
					fretegratis: true,
				});
				this.$forceUpdate();
			}
			this.modalConfigDelivery = false;
		},

		montarPayload(informacoes, classificacao){
			return {
				id: informacoes?.cdCliente ? informacoes.cdCliente : "",
				bairro: informacoes.bairroCliente ? informacoes.bairroCliente : "",
				cep: informacoes.cepCliente ? informacoes.cepCliente : "",
				cidade: {
					nomecidade: informacoes.cidadeCliente ? informacoes.cidadeCliente : "",
					uf: informacoes.ufCliente ? informacoes.ufCliente : "",
					cdcidade: informacoes.cdcidade,
				},
				classificacao: classificacao ? {...classificacao} : "",
				tipoPessoa: informacoes.tipoPessoa ? informacoes.tipoPessoa : "",
				fone: informacoes.foneCliente ? informacoes.foneCliente : "",
				cnpj: informacoes.cnpjCliente ? informacoes.cnpjCliente : "",
				endereco: informacoes.enderecoCliente ? informacoes.enderecoCliente : "",
				numero: informacoes.numeroEndereco ? informacoes.numeroEndereco : "",
				ierg: informacoes.ierg ? informacoes.ierg : "",
				nome: informacoes.nomeCliente ? informacoes.nomeCliente : "",
				dataNascimento: informacoes.dataNascimento,
				ativo: "A"
			};
		},
		

		async configurarDelivery() {
			if(this.delivery) {
				this.modalConfigDelivery = true;
				this.clienteDelivery.cep = this.Empresa.cep;
				this.clienteDelivery.valorFrete = "5,00";
			} else {
				this.$store.state.Venda.nomeCliente = this.clientePadrao.nome,
				this.$store.state.Venda.cliente = {
					...this.clientePadrao
				};
				this.clienteDelivery = {};				
				this.$store.state.Venda.cancelaDelivery = true;
				
				await this.atualizarFinanceiroVenda();
				this.calcularDesconto("percentual");
				this.adicional = 0;
				this.$store.state.Venda.valorfrete = 0;
			}
		},
		addValorParcela() {
			if(this.controle === 1) {
				if(this.financeiro.valorParcela === "") {
					this.financeiro.valorParcela = this.TotalPagar;
					this.controle++;
				} else {
					proximoCampo("add-pgto");
				}
			} else {
				proximoCampo("add-pgto");
				this.controle--; 
			}
		},

		fecharModal() {
			this.parcelar = false;
			this.clearFormFinanceiro();
			this.$forceUpdate();
		},
		
		limparStateVenda() {
			this.$store.state.Venda = {};
			this.$store.state.itensVenda = [];
		},

		clearFormFinanceiro() {
			this.financeiro = {
				valorParcela: "",
				tipoPgto: {selecionar: true}
			};
			this.indexEditando = -1;
		},

		validatePgto() {
			try {
				this.financeiro.valorParcela = parseFloat(`${this.financeiro.valorParcela}`.replace(",", "."));
				if(
					(this.Venda.cliente?.id === this.clientePadrao.id) &&
					this.financeiro.tipoPgto.cdtipo === "0005"
				) {
					throw new Error("Não é possível receber em carteira para consumidor final!");
				}
				if(!this.financeiro?.tipoPgto || this.financeiro?.tipoPgto?.selecionar)	 {
					throw new Error("Para adicionar o pagamento é necessário informar uma forma de pagamento!");
				}

				if(!this.financeiro.valorParcela) {
					throw new Error("Para adicionar o pagamento é necessário informar o valor a pagar!");
				}

				const totalParcela = this.indexEditando !== -1
					? (this.financeiro.valorParcela - this.Financeiro[this.indexEditando].valorParcela)
					: this.financeiro.valorParcela;

				if(parseFloat(totalParcela.toFixed(2)) > parseFloat(this.TotalPagar.toFixed(2))) {
					throw new Error("Não é possível receber um valor maior que o total a pagar!");
				}

				if(this.indexEditando !== -1) return true;

				if(this.TotalPagar === 0) {
					throw new Error("Você ja recebeu o valor total da venda!");
				}
				return true;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				this.clearFormFinanceiro();
				return false;
			}
		},

		editPagamento(index, pagamento) {
			
			this.financeiro.tipoPgto = pagamento?.cdTipo || pagamento?.cdtipo || pagamento?.cdTipoPgto || pagamento?.cdtipopgto;
			
			this.financeiro.cddig = pagamento?.cdDig || 0;
			
			this.financeiro.pagamento = pagamento?.pagamento || "";
			
			this.financeiro.valorParcela = `${pagamento.valorParcela}`.toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			proximoCampo("valor-pagar");
			this.indexEditando = index;
		},
		
		cancelarLancamentoMesa() {
			this.limparStateVenda();
			this.$router.replace({name: "Principal"});
		},

		removerPagamento(index) {
			if(this.indexEditando !== -1) return;
			this.Financeiro.splice(index, 1);
			this.atualizarFinanceiroVenda();
		},

		adicionarPagamentoParcial() {
			try {
				this.financeiro.tipoPgto = this.FormasPagamento.find(forma => 
					forma.cdtipo === this.financeiro.tipoPgto);
				if(!this.validatePgto()) return;
			
				if(this.indexEditando !== -1) {
					this.Financeiro[this.indexEditando] = {
						...this.financeiro.tipoPgto,
						valorParcela: this.financeiro.valorParcela,
						cddig: this.financeiro.cddig,
						pagamento: this.financeiro.pagamento,
					};
					this.clearFormFinanceiro();
					this.atualizarFinanceiroVenda();
					proximoCampo("tipo-pagamento");
					return;
				}

				this.financeiro.tipoPgto.valorParcela = this.financeiro.valorParcela;
				
				this.incluirPagamento({
					...this.financeiro.tipoPgto,
				});

				proximoCampo("tipo-pagamento");

			} catch (error) { 
				console.log(error);
			}
		},

		async incluirPagamento(parcela){
			this.Financeiro.push({
				...parcela,
				metodoParcela: parcela?.metodoParcela ? parcela.metodoParcela : parcela?.descricao || parcela?.descricao
			});
			this.atualizarFinanceiroVenda();
			this.clearFormFinanceiro();

		},

		async atualizarFinanceiroVenda() {
			try {
				await this.$store.dispatch("atualizarFinanceiroVenda", this.Venda);
			} catch (error) {
				console.log(error);
			}
		},

		controleRota() {
			if(!this.$store.state.Usuario?.usaCelular && this.$store.state.Empresa.tiposistema === "L") {
				this.$router.replace({name: "LancamentoLanchonete"});
			} else {
				this.$router.go(-1);
			}
		},

		cancelarPreImpressao() {
			this.solicitaPreImpressao = false;
			this.controleRota();
			
		},

		async finalizarLancamentoMesa() {
			if(Number(this.TotalPagar.toFixed(2)) < 0) return this.$store.commit("relatarErro", {
				mensagem: "Você recebeu mais que o valor do pedido, por favor ajuste os valores!"
			});

			if(this.descontoValor) {
				const descontoValor = parseFloat(`${this.descontoValor}`.replace(",", "."));
				this.$store.state.Venda.descontoMoeda = descontoValor;
			}
			if(this.descontoPercentual) {
				const descontoPercentual = parseFloat(`${this.descontoPercentual}`.replace(",", "."));

				this.$store.state.Venda.descontoPercentual = descontoPercentual;
			}

			if(this.acrescimo) {
				const acrescimo = parseFloat(`${this.acrescimo}`.replace(",", "."));

				this.$store.state.Venda.acrescimo = acrescimo;
			}

			if(Number(this.TotalPagar.toFixed(2)) === 0) {
				this.solicitaPreImpressao = true;
				

				await this.$store.dispatch("confirmarVenda", {...this.Venda, impressaoRemota: "S"});
				return;
			}
			if(!this.$store.state.Usuario?.usaCelular && this.$store.state?.Empresa?.tiposistema === "L") {
				await this.$store.dispatch("atualizarVenda", {...this.Venda, impressaoRemota: "S", route: this.$route.name});
				setTimeout(() => {
					this.$router.replace({name: "LancamentoLanchonete"});
				}, 50);
				this.$store.state.itensVenda = [];
				this.$store.state.Venda = {};
			} else {
				this.$router.go(-1);
				this.$store.state.itensVenda = [];
				this.$store.state.Venda = {};
			}
		},

		alterarVenda(venda) {
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda?.cdVenda,
				dataEmissao: venda.dataEmissao,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				descontoPercentual: venda.descontoPercentual,
				descontoMoeda: venda.descontoMoeda,
				financeiro: venda?.financeiro,
				nomeCliente: venda?.nomeCliente,
			};
			this.$store.state.itensVenda = [];
			this.$store.state.itensVenda = [...venda.itens];
	
			if(this.$store.state.Empresa.tiposistema === "L" && !this.$store.state.Usuario.usaCelular) {
				this.$router.push({ name: "LancamentoLanchonete"});
				return;
			}
			this.$router.push({ name: "Lancamento", query: { tipoVenda: "S", alteracao: true } });
		},
	}

};

</script>

<style scoped>
.container-page{
	width: 100%;
	height: 92vh;
	display: flex;
	flex-direction: row;
	padding: 20px;
	gap: 30px;
}

.container-page .container-produtos,
.container-page .container-pagamento{
	width: 50%;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.container-produtos, .container-pagamento{
	padding: 20px;
}

table{
	width: 100%;
	margin-top: 10px;
}
.container-tabela-produtos{
	height: 60vh;
	overflow-y: auto;
}
table thead tr th,
table tbody tr td{
	padding: 0px 7px;
}

.code{
	text-align: center;
	color: var(--primario);
}

.quantidade{
	text-align: center;
}

.valor{
	text-align: right;
}

.container-totalizadores {
	width: 100%;
}

.campos-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-around;
	margin-top: 15px;
}

.campos-container .input-group{
	width: 50%;
}

.campos-container button{
	width: 100%;
	padding: 5px;
}

#valor-pagar{
	text-align: right;
}
.container-tabela-pagamento{
	width: 100%;
	height: 35vh;
	overflow-y: auto;
}


@media screen and (max-width: 425px) {
	.container-page{
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 5px;
	}
	.container-produtos,
	.container-pagamento{
		width: 100% !important;
		padding: 5px;
	}
}

.container-cliente{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.container-loader-produtos{
	height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-desconto{
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: red;
}

@media screen and (max-height: 650px) {
	.container-tabela-pagamento{
		height: 32vh;
	}

	.container-tabela-produtos{
		height: 57vh;
	}
}

@media screen and (max-width: 1025px) {
	.container-tabela-pagamento{
		height: 27vh;
	}
}


.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.checked input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}

.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>