<template>
	<div 
		:class="`${ativo ? 'cardAtivo' : ''} card`" 
		style="display:flex;flex-direction: row;"
		@click="selecionouItem(item)"
		:id="`item-${type === 'Grupo' ? item.cdGrupo : item.id}`"
	>
		<img :src="`https://drive.google.com/thumbnail?id=${item.idImagem}`" alt="" v-if="item.idImagem" style="height: 100%;">
		<div style="display: flex;flex-direction: column;padding-left: 5px">
			<span style="font-weight: bold;">
				{{ item.descresumido }}
			</span>
			<span style="font-size: 10px;">
				{{ item.descricao }}
			</span>
			<span >
				{{ item.vlrvista.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}
			</span>
		</div>
		
	</div>
</template>
<script>
export default{
	name: "CardProdutoCardapio",
	props: {
		item: {
			type: Object,
			required: true,
		},
		ativo: Boolean,
		type: String,
	},

	data() {
		return {
			currentIndex: this.indexSelecionado,
			delayTimer: null,
		};
	},

	watch: {
		indexSelecionado(newIndex, oldIndex) {
			if(newIndex !== oldIndex) {
				this.currentIndex = newIndex;
			}
		}
	},

	methods: {
		selecionouItem(item) {
			if(this.type === "Produto") {
				this.$emit("selecionouProduto", item);
			} 
			if(this.type === "Grupo") {
				this.$emit("selecionouGrupo", item);
			}
		},
		
	},

};
</script>
<style scoped>

.card{
	width: 300px;
	padding: 10px;
	height: 100px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	border-radius: 5px;
	
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
}

.cardAtivo{
	border: 2px solid var(--primario);
	background-color: #99afff;
	color: var(--primario);
	font-weight: bold;
}


</style>
