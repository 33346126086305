// import router from "../router/index.js";
import {doRequest} from "./http.js";
import { classificacaoAPI, CreditoClienteAPI, CreditoCliente, Crm } from "../models/Cliente.js";

export default {
	state: {
		Clientes: [],
		Classificacao: {
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
			content: []
		},

		CreditoClientes: [],
		
		paginaClientesAtual: -1,
		totalPaginasClientes: 0,
		situacaoCliente: null,
		ClientePadrao: null,

		Crm:{
			ListaCrm:[]
		}
	},

	actions: {
		async ordenarTabelaCliente({commit}, payload) {
			try {
				let url;
				if (payload) {
					const {
						campoTabela,
						ordem,
						ativo,
					} = payload;
					url = `cliente/filterInFields?page=0&size=25&somenteAtivo=${ativo}&sort=${campoTabela}&sortOrder=${ordem}`;
				}
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);
				commit("atualizarListaClientes", data);
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagm: error.message || (error.mensagem || "Favor verificar")});
			}
		},

		async excluirCreditoCliente({state, commit, dispatch }, payload){
			state.carregando = true;
			const { id } = payload;
			let url = "creditoCliente/cancelar";
			url += `?id=${id}&cdempresa=${state.Empresa.id}`;
			const data = await doRequest(url,{
				method: "DELETE",
				cache: "no-cache"
			}, commit);
			dispatch("filtrarCreditoCliente");
			state.carregando = false;
			if(data) return data;

		},
		async filtrarCreditoCliente({commit, state}, payload){
			try {
				let url;
				state.carregando = true;
				if(payload){
					const {
						datai,
						dataf,
						status,
						cdvendedor,
						cdcliente,
					} = payload;
					url = "creditoCliente/filter";
					url += `?empresa=${state.Empresa.id}`;
					url += `${datai ? `&datai=${datai}` : ""}`;
					url += `${dataf ? `&dataf=${dataf}` : ""}`;
					url += `${status ? `&status=${status}` : ""}`;
					url += `${cdcliente ? `&cdcliente=${cdcliente}` : ""}`;
					url += `${cdvendedor ? `&cdvendedor=${cdvendedor}` : ""}`;
				} else {
					url = "creditoCliente/filter";
					url += `?empresa=${state.Empresa.id}`;
				}
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);
				if(data && (data.erro || data.mensagem || data.details)){
					commit("atualizarListaCreditos", []);
					state.carregando = false;
				} else {
					commit("atualizarListaCreditos", data ? data : []);
				}
				state.carregando = false;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || "Favor verificar ")});
			}
		},
		async atualizarCreditoCliente({commit, state}, payload){
			state.carregando = true;
			try {
				const form = new CreditoClienteAPI(payload);
				const url = `creditoCliente${payload.id ? `?id=${payload.id}` : ""}`;
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "PUT",
					cache: "no-cache",
					body
				}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return null;
			}
		},
		async cadastrarCreditoCliente({commit, state}, payload){
			state.carregando = true;
			try {
				const form = new CreditoClienteAPI(payload);
				const url = "creditoCliente";
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "POST",
					cache: "no-cache",
					body
				}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return null;
			}
		},

		async buscarClientePadrao({commit, state}) {
			try {
				const url = `cliente?id=${state.Empresa.cdconsumidorfinal}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data.id) {
					if (data.mensagem) {
						throw new Error(data.mensagem);
					}
					if (data.details) {
						throw new Error(JSON.stringify(data.details));
					}
					throw new Error(JSON.stringify(data));
				}
				state.ClientePadrao = {...data};
				return state.ClientePadrao;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || "Favor verificar ")});
				state.ClientePadrao = null;
			}
		},
		async buscarClientes({commit, dispatch, state}, payload){
			try {
				state.carregando = true;
				let url;
				if (!payload || payload == undefined){
					url = "cliente/all?page=0&sort=nome&sortOrder=asc";
				} else {
					url = `cliente/filterInFields?page=${payload.page}&size=25&sort=nome&sortOrder=asc`;
					url += `&somenteAtivo=${payload.checkboxPesquisa}`;
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				await dispatch("buscarClassificacao");
				state.carregando = false;

				if (!data.content) {
					if (data.mensagem) {
						throw new Error(data.mensagem);
					}
					if (data.details) {
						throw new Error(JSON.stringify(data.details));
					}
					throw new Error(JSON.stringify(data));
				}
				commit("atualizarListaClientes", data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message});
				commit("atualizarListaClientes", {
					content: [],
					page: -1,
					totalPages: 0
				});
			}
		},
    
		async buscarClientePorId({state, commit}, payload){
			try {
				const {id, venda} = payload;
				const url = `cliente?id=${id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!venda) {
					state.Clientes.push({...data});
					return data;
				} else {
					commit("atualizarConsumidorVenda", {...data});
					return data;
				}
			} catch (error) {
				console.log(error);
				commit("atualizarListaClientes", {
					content: [],
					page: -1,
					totalPages: 0
				});
			}
		},

		async buscarUnicoCliente({state, commit}, payload){
			try {
				state.carregando = true;
				const id = payload;
				const url = `cliente?id=${id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if(!data.id){
					if(data.mensagem){
						throw new Error(data.mensagem);
					}if (data.details) {
						throw new Error(JSON.stringify(data.details));
					}
					throw new Error(JSON.stringify(data));
				}
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message});
				return null;
			}
		},
		
		async filtrarClientesPorNome({commit}, payload) {
			try {
				const {
					query
				} = payload;
	
				let url = "cliente/filterInAll?page=0&size=99999";
	
				if(!query) {
					return console.log("Faltou a query para filtrar");
				} else {
					url += `&query=${query}`;
				}
				const data = await doRequest(url, {
					methods: "GET",
					cache: "no-cache",
				}, commit);
	
				if (data.content && data.content.length > 0) {
					commit("atualizarListaClientes", data);
					return data;
				} else {
					commit("atualizarListaClientes",  {
						content: [],
						page: -1, totalPages: 0
					});
					return false;
				}
			} catch (error) {
				console.log(error);
			}

		},

		async filtrarClientes({commit, state}, payload){
			try {
				state.carregando = true;
				const {
					page, 
					checkboxPesquisa,
					query, 
					todos,
					nome,
					cdCliente,
					cnpj,
					buscarTodos,
					fone,
					niver,
					size,
				} = payload;
				// if ((!query && query != 0) || query === undefined) {
				// 	return dispatch("buscarClientes");
				// }
				let url;
				if(buscarTodos) {
					url = "cliente/filterInFields?page=0&size=999";
				} else if (todos){
					url = `cliente/filterInAll?page=${page || 0}&query=${query === undefined? "":query}&size=25&sort=nome&sortOrder=asc`;
				} else if (!niver && niver != 0) {
					url = "cliente/filterInFields?size=25&sort=nome&sortOrder=asc";
					url += `${cdCliente ? `&cdCliente=${cdCliente}` : ""}`;
					url += `${cnpj ? `&cnpj=${cnpj}` : ""}`;
					url += `${fone ? `&fone=${fone}` : ""}`;
					url += `${nome ? `&nome=${nome.toUpperCase()}` : ""}`;
					url += `${page ? `&page=${page}` : ""}`;
				} else {
					url = `cliente/filterInNiver?mes=${niver}&page=${page}&size=${size?size:"25"}&sortOrder=asc`;
				}
				url += `&somenteAtivo=${checkboxPesquisa}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content && data.content.length > 0) {
					commit("atualizarListaClientes", data);
					state.carregando = false;
					return data;
				} else {
					commit("atualizarListaClientes",  {content: [], page: -1, totalPages: 0});
					state.carregando = false;
					return false;
				}
			} catch (error) {
				state.carregando = false;
				commit("atualizarListaClientes", { content: [], page: -1, totalPages: 0});
				return false;
			}
		},

		async filtrarcnpj({commit}, payload){
			try {
				const {
					cnpj,
				} = payload;
				let url = `cliente/filter?cnpj=${cnpj}&page=0&size=25&sort=nome&sortOrder=asc`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content && data.content.length > 0) {
					return data;
				} else {
					return false;
				}
			} catch (error) {
				return false;
			}
		},
		

		async adicionarCliente({commit, dispatch}, payload){
			try {
				const body = JSON.stringify({
					ativo: payload.ativo || "A",
					avisar: payload.avisar || "N", 
					bairro: payload.bairro,
					cdAvalista: payload.avalista?.id ? payload.avalista.id : 
                    payload.avalista?.cdcliente ? payload.avalista.cdcliente : null,
					celular: payload.celular,
					cep: payload.cep,
					cidade: payload.cidade,
					classificacao: payload.classificacao?.cdclassi,
					cnpj: payload.cnpj,
					complemento: payload.complemento,
					conjugue: payload.conjugue,
					contato: payload.contato,
					cpfconj: payload.cpfconj,
					dataNascimento: payload.dataNascimento,
					datacerasa: payload.datacerasa,
					email: payload.email,
					endereco: payload.endereco,
					endtrab: payload.endtrab,
					estcivil: payload.estcivil,
					fantasia: payload.fantasia,
					fax: payload.fax,
					fone: payload.fone,
					fonetrab: payload.fonetrab,
					funcao: payload.funcao,
					ierg: payload.ierg,
					limitecredito: typeof(payload.limitecredito) == "string" ?
						Number(payload.limitecredito.replace("R$", "").replace(".", "").replace(",", ".")) : Number(payload.limitecredito),
					mae: payload.mae,
					mensagemVenda: payload.mensagemVenda ? `${ payload.mensagemVenda }`.toUpperCase() : null,
					nascconj: payload.nascconj,
					nome: payload.nome,
					numero: payload.numero,
					obscerasa: payload.obscerasa,
					observacao: payload.mensagemVenda ? `${payload.mensagemVenda}`.toUpperCase() : "",
					pai: payload.pai,
					parente: payload.parente,
					ref1: payload.ref1,
					ref2: payload.ref2,
					ref3: payload.ref3,
					ref4: payload.ref4,
					rgconj: payload.rgconj,
					sitcerasa: payload.sitcerasa,
					tempo: payload.tempo,
					tipoPessoa: payload.tipoPessoa,
					trabalho: payload.trabalho,
					id: payload.id,
					dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				});
				let url = "cliente";
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				if (!data.id) {
					if (data.mensagem) {
						throw new Error(data.mensagem);
					}
					if (data.details) {
						throw new Error(JSON.stringify(data.details));
					}
					throw new Error(JSON.stringify(data));
				}
				await dispatch("buscarClientes");
				return data;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || JSON.stringify(error))});
				return {erro: true};
			}
		},

		async atualizarCliente({commit, dispatch}, payload){
			try {
				const body = JSON.stringify({
					ativo: payload.ativo || "A",
					avisar: payload.avisar || "N", 
					bairro: payload.bairro,
					cdAvalista: payload.avalista?.id ? payload.avalista.id : 
                    payload.avalista?.cdcliente ? payload.avalista.cdcliente : null,
					celular: payload.celular,
					cep: payload.cep,
					cidade: payload.cidade,
					classificacao: payload.classificacao?.cdclassi,
					cnpj: payload.cnpj,
					complemento: payload.complemento,
					conjugue: payload.conjugue,
					contato: payload.contato,
					cpfconj: payload.cpfconj,
					dataNascimento: payload.dataNascimento,
					datacerasa: payload.datacerasa,
					email: payload.email,
					endereco: payload.endereco,
					endtrab: payload.endtrab,
					estcivil: payload.estcivil,
					fantasia: payload.fantasia,
					fax: payload.fax ? payload.fax : undefined,
					fone: payload.fone ? payload.fone : undefined,
					fonetrab: payload.fonetrab,
					funcao: payload.funcao,
					ierg: payload.ierg,
					limitecredito:payload.limitecredito === "" ? -1 : typeof(payload.limitecredito) == "string" ?
						Number(payload.limitecredito.replace("R$", "").replace(".", "").replace(",", ".")) : Number(payload.limitecredito),
					mae: payload.mae,
					mensagemVenda: payload.mensagemVenda ? `${payload.mensagemVenda}`.toUpperCase() : "",
					nascconj: payload.nascconj,
					nome: payload.nome,
					numero: payload.numero ? payload.numero : undefined,
					obscerasa: payload.obscerasa,
					observacao: payload.observacao ? `${payload.observacao}`.toUpperCase(): "",
					pai: payload.pai,
					parente: payload.parente,
					ref1: payload.ref1,
					ref2: payload.ref2,
					ref3: payload.ref3,
					ref4: payload.ref4,
					rgconj: payload.rgconj,
					sitcerasa: payload.sitcerasa,
					tempo: payload.tempo,
					tipoPessoa: payload.tipoPessoa,
					trabalho: payload.trabalho,
					dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					id: payload.id,
					idImagem: payload.idImagem,
				});
				let url = `cliente?id=${payload.id}`;
				const data = await doRequest(url, {method: "put", body}, commit);
				if (!data.id) {
					if (data.mensagem) {
						throw new Error(data.mensagem);
					}
					if (data.details) {
						throw new Error(JSON.stringify(data.details));
					}
					throw new Error(JSON.stringify(data));
				}
				await dispatch("buscarClientes");
				return data;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
				return {erro: true};
			}
		},

		async verificaSituacaoCliente({commit, state}, payload){
			state.carregando = true;
			try {
				const cliente = {...payload};
				let url = `cliente/situacaoCliente?id=${cliente.id}&empresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "get"}, commit);
				state.carregando = false;
				if (data.mensagem) {
					commit("relatarErro", {mensagem: data.mensagem});
					return false;
				}
				state.situacaoCliente = {...data, cliente};
				state.Venda.somenteVista = false;
				// if (router.currentRoute.query.tipoVenda != "C" && !cliente.alterarTipoVenda) {
				// 	if (state.situacaoCliente.diasvencido > (state.Empresa.carenciaCliente || 30) && (router.currentRoute.name.includes("Lancamento") || router.currentRoute.name.includes("Fechamento"))) {
				// 		if(!cliente.autorizado){
				// 			setTimeout(() => {
				// 				console.log("aqui dentro");
				// 				commit("relatarErro", {
				// 					mensagem: `Cliente com ${state.situacaoCliente.diasvencido} dias em atraso. ${
				// 						(!state.Usuario.liberaCliente) ? "Deseja solicitar autorização?" : ""
				// 					}`,
				// 					tipo: (!state.Usuario.liberaCliente) ? "polar" : null,
				// 					item: {
				// 						liberaCliente: true,
				// 						clienteDevedor: cliente,
				// 						solicitarAutorizacao: true,
				// 						mensagem: `${state.Usuario.nome} está solicitando autorização para liberar o cliente ${cliente.nome} que está com ${state.situacaoCliente.diasvencido} dias em atraso!`
				// 					},
				// 				});
				// 			},50);
				// 			return state.Usuario.liberaCliente;
				// 		}
				// 	}
				// 	if (state.situacaoCliente.limiteCredito == 0 && router.currentRoute.query.tipoVenda != "C") {
				// 		commit("relatarErro", {mensagem: "Cliente sem crédito disponível no sistema, venda será permitida apenas a vista!", alerta: true});
				// 		return true;
				// 	}
				// }
				return {...data};
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async adicionarClassificacao({commit, state, dispatch}, payload){
			try {
				state.carregando = true;
				const classificacao = new classificacaoAPI(payload);
				const body = JSON.stringify(classificacao);
				const url = "cliente/classificacao";
				const data = await doRequest(url, {body, method: "post", cache:"no-cache", commit});
				state.carregando = false;
				if(data.mensagem){
					throw new Error(data.mensagem);
				}
				dispatch("buscarClassificacao");
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message});
			}
		},
		async filtrarClassificacao({commit, state}, payload){
			state.carregando = true;
			const url = `cliente/listarClassificacao?${payload.page ? `page=${payload.page}` : "page=0"}${payload.descricao ? `&descricao=${payload.descricao}` : (payload.query? `&descricao=${payload.query}`:"")}
			${payload.size ? `&size=${payload.size}` : "&size=25"}&sort=cdclassi&sortOrder=asc`;

			const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
			if(data.mensagem){
				state.Classificacao.content = [];
				state.carregando = false;
				return data;
			}
			if(!data.mensagem){
				commit("atualizarListaClassificacao", {...data});
				state.carregando = false;
				return data;
			}
			state.carregando = false;

		},
		async buscarClassificacao({commit, state}){
			try {
				state.carregando = true;
				let url;
				url = "cliente/listarClassificacao?page=0&size=25&sort=cdclassi&sortOrder=asc";
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data.mensagem) {
					commit("atualizarListaClassificacao", {...data});
				}
				state.carregando = false;
			} catch (error) {
				console.log(error);
			}
		},
		async atualizarClassificacao({commit, state, dispatch}, payload){
			try {
				state.carregando = true;
				const url = `cliente/classificacao?cdclassi=${payload.cdClassificacao}`;
				const classi = new classificacaoAPI(payload);
				const body = JSON.stringify(classi);
				const data = await doRequest(url, {method: "put", cache: "no-cache", body}, commit);
				state.carregando = false;
				if(!data.mensagem){
					await dispatch("buscarClassificacao");
				}
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
				return {erro: true};
			}
		},
		async removerClassificacao($store, payload) {
			try {
				$store.state.carregando = true;
				const url = `cliente/classificacao?cdclassi=${payload}`;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, $store.commit);
				$store.state.carregando = false;
				if(data.erro){
					throw new Error(data.mensagem);
				}
				if(!data.erro){
					$store.commit("relatarErro", {mensagem: data.mensagem, sucess: true});
				}
				return data;
			} catch (error) {
				$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		async buscarClassificacaoPadrao(){
			const url = "cliente/classificacao/padrao";
			const data = await doRequest(url, {
				method: "GET",
				cache: "no-cache"
			});
			return data;
		},
		definirClassificacaoPadrao({state, commit}, payload){
			try {
				state.carregando = true;
				const { cdclassi } = payload;
				const url = `cliente/classificacao/padrao?cdclassi=${cdclassi}`;
	
				const data = doRequest(url, {
					method: "PUT",
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				
				if(data.erro){
					throw new Error(data.mensagem);
				}
				return data;
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message});
			}
		},

		async buscarClienteFoneCPF({commit, state}, payload){
			const { fone, cpfcnpj } = payload;
			let url = "cliente/filterInFields?somenteAtivo=true";
			try {
				if(fone) {
					const foneFormatado = fone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
					url += `${fone ? `&fone=${foneFormatado}` : ""}`;
				}
				if(cpfcnpj) {
					const cpfcnpjFormatado = cpfcnpj.replace(".", "").replace(".", "").replace("-", "");
					url += `${cpfcnpj ? `&cnpj=${cpfcnpjFormatado}` : ""}`;
				}
				state.carregando = true;

				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);

				state.carregando = false;
				if(data?.content?.length > 0) {
					return data.content[0];
				}

				return null;

			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		},
		
		async filtrarDependentes({commit, state}, { id }) {
			if(!id ) return;
			let url = `cliente/listar-dependentes?cdcliente=${id}`;
			state.carregando = true;
			try {
				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				if(data && data.mensagem) {
					commit("atualizarListaClientes",  {content: [], page: -1, totalPages: 0});
					throw new Error(data.mensagem);
				} else {
					commit("atualizarListaClientes",  {content: data, page: -1, totalPages: 0});
					state.carregando = false;
				}
			} catch (error) {
				state.carregando = false;
				console.error(error);
			}
		},

		async filtrarCrm({commit, state}, payload){
			try {
				state.carregando = true;
				
				const { dataI, dataF, dias, tipo } = payload;

				let url = "cliente/relatorio-crm";
				if(tipo == "D"){
					url += `${dias?"?diasSemVisita="+dias:""}`;
				}
				else{
					url += `${dataI?"?dataUltCompraI="+dataI:""}`;
					url += `${dataF?"&dataUltCompraF="+dataF:""}`;
				}	
				
				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;

				if(data && !data.erro) {
					commit("atualizarCrm", data);
					state.carregando = false;
					return;
				}
				throw new Error(
					data 
						? data.mensagem 
							? data.mensagem 
							: data.details || JSON.stringify(data) 
						: ""
				);
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
				return null;
			}
		},
	},

	mutations: {
		atualizarListaClientes(state, payload){
			state.Clientes = [];
			state.Clientes = payload.content;
			state.paginaClientesAtual = payload.page;
			state.totalPaginasClientes = payload.totalPages;
			state.totalElementsClientes = payload.totalElements;
		},
		atualizarListaCreditos(state, payload){
			state.CreditoClientes = [];
			if(payload){
				state.CreditoClientes = payload?.map(credito => new CreditoCliente(credito));
			}

		},
		atualizarListaClassificacao(state, payload){
			state.Classificacao.content = [];
			state.Classificacao.content = payload.content;
			state.Classificacao.paginacao = {
				totalPages: payload.totalPages || 0,
				totalElements: payload.totalElements || 0,
				size: payload.size || 25,
				page: payload.page >= 0 ? payload.page : -1
			};
		},
		atualizarCrm(state, payload){

			if(payload?.length > 0){
				state.Crm.ListaCrm = payload?.map(crm =>  new Crm(crm));
			} else {
				state.Crm.ListaCrm = [];
			}
		}
	}
};