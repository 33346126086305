<template>
    <div :class="`col ${!showTable ? 'table-full' : ''}`" id="listaModal" v-if="Produtos.length >= 1" :style="{'--height':height}">
        <table class="w-100" v-if="!visaoEmpresas">
            <thead>
                <tr>
                    <td style="text-align: center; max-width: 80px; min-width: 50px" v-show="troca && trocaBuscaVenda">Trocar</td>
                    <td style="color: blue;" class="text-center text-nowrap">Cód.</td>
                    <td>Descrição</td>
                    <td class="text-center" style="max-width: 80px; min-width: 50px" v-show="!troca && !impressao">Unid.</td>
                    <td class="text-center" style="max-width: 80px; min-width: 50px">{{troca || impressao ? "Quant." : "Saldo" }}</td>
					<!-- <td class="text-center" style="max-width: 100px; min-width: 50px" v-show="!troca && !impressao">Condicional</td> -->
					<td class="text-center" v-show="!troca && !impressao">Cond.</td>
                    <td class="text-center" v-show="trocaPorVenda || impressao">Valor pago</td>
                    <td class="text-center" v-show="!trocaPorVenda && !impressao" >A vista</td>
                    <td class="text-center" v-show="!trocaPorVenda && !impressao">A prazo</td>
                    <td class="text-center" style="max-width: 80px; min-width: 50px" v-show="$route.name == 'Gerenciamento' && !desabilitarAcao">Ação</td>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(produto, index) in Produtos" :key="index" @click.stop="selecionarProduto(produto)">
                    <td style="max-width: 120px; min-width: max-content;" v-show="troca && trocaBuscaVenda" class="text-center" >
                        <input type="checkbox" class="troca" :checked="produto.checked" :id="`${troca || impressao ? produto.produto ? produto.produto.id : produto.id : produto.id}${index}`"
                        @keydown.up="proximoInput(index -1)" @keydown.down="proximoInput(index +1)" @focus.prevent="produto.checked = false">
                    </td>
                    <td style="color: blue; max-width: 100px; min-width: max-content;" class="text-center text-nowrap">
                        <button :id="index" class="hand-button" v-if="index == indexProdutoSelecionado && !troca && !impressao" 
                        @keydown.tab.prevent="" >
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" ></i>
                        </button>
                        {{produto.id ? produto.id : (produto.produto ? produto.produto.id : null )}}
                    </td>
                    <td class="text-left text-nowrap">
                        {{produto.descricao ? produto.descricao : (produto.produto ? produto.produto.descricao : null)}}
                    </td>
                    <td class="text-center text-nowrap" style="max-width: 80px; min-width: 50px" v-show="!troca && !impressao">
                        {{produto.unidade}}
                    </td>
                    <td class="text-center text-nowrap" style="max-width: 80px; min-width: 50px" v-show="!troca && !impressao">
                        {{produto.saldo || "0"}}
                    </td>
					<td class="text-center text-nowrap" style="max-width: 80px; min-width: 50px" v-show="!troca && !impressao">
                        {{produto.qtdcondicional || "0"}}
                    </td>
					<!-- <td class="text-center text-nowrap" style="max-width: 80px; min-width: 50px" v-show="!troca && !impressao">
                        {{produto.saldoCondicional || "0"}}
                    </td> -->
                    <td class="text-center" v-show="troca || impressao">
                        {{(produto.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                    </td>
                    <td class="text-center" v-show="trocaPorVenda || impressao">
                        {{produto.valorUnitario ? produto.valorUnitario.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) 
                        : produto.valorVista.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}
                    </td>
                    <td class="text-center" v-show="!trocaPorVenda && !impressao" v-if="produto.cdpromocao==null || trocaListagem || (!trocaPorVenda && troca)">
                        {{valorProduto(produto,"V").valor}}
                    </td>
					<td class="text-center" v-show="!troca && !impressao" v-if="produto.cdpromocao!=null && !trocaListagem">
                        <p class="promo">{{valorProduto(produto,"V").valor}}</p>{{valorProduto(produto,"V").valorPromo}}
                    </td>
                    <td class="text-center" v-show="!trocaPorVenda && !impressao" v-if="produto.cdpromocao==null || trocaListagem || (!trocaPorVenda && troca)">
                        {{valorProduto(produto,"P").valor}}
                    </td>
					<td class="text-center" v-show="!troca && !impressao" v-if="produto.cdpromocao!=null && !trocaListagem">
                        <p class="promo">{{valorProduto(produto,"P").valor}}</p>{{valorProduto(produto,"P").valorPromo}}
                    </td>
                    <td style="text-align: center; max-width: 80px; min-width: 50px" v-show="$route.name == 'Gerenciamento' && !desabilitarAcao">                            
                        <i class="fas fa-edit" style="color: var(--confirmar)" @click="selecionarProduto(produto)"
                        @keydown.space="selecionarProduto(produto)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
		<table v-else>
			<thead>
				<tr>
					<td style="
						text-align: center;
						width: 50px;
						color: blue;
					">
						Cód.
					</td>
					<td>
						Descrição
					</td>
					<td style="
						text-align: center;
						width: 120px;
					">
						Cód. Empresa
					</td>
					<td style="
						text-align: center;
						width: 120px;
					">
						Saldo
					</td>
					<!-- <td style="
						text-align: center;
						width: 120px;
					">
						Condicional
					</td> -->
					<td style="
						text-align: center;
						width: 120px;
					">
						A vista
					</td>
					<td style="
						text-align: center;
						width: 120px;
					">
						A prazo
					</td>
					<td style="
						text-align: center;
					"
					v-show="$route.name == 'Gerenciamento' || $route.name === 'Principal' && !desabilitarAcao">
						Ação
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(produto, index) in Produtos" :key="index" @click="editarProduto(produto)">
					<td style="
						text-align: center;
						width: 50px;
						color: blue;
					">
						{{ produto.id }}
					</td>
					<td>
						{{ produto.descricao }}
					</td>
					<td style="
						text-align: center;
						width: 120px;
					">
						<tr v-for="(emp, index) in produto.empresas" :key="index" style="background-color: transparent !important; text-align: center;">
							<p style="
								text-align: center; 
								width: 120px;
								line-height: 15px;
							">
								{{ emp.cdempresa }}
							</p>
						</tr>
					</td>
					<td style="
						text-align: center;
						width: 120px;
					">
						<tr v-for="(emp, index) in produto.empresas" :key="index" style="background-color: transparent !important;">
							<p style="
								text-align: center;
								width: 120px;
								line-height: 15px;
							">
								{{ emp.saldo || 0 }}
							</p>
						</tr>
					</td>
					<!-- <td style="
						text-align: center;
						width: 120px;
					">
						<tr v-for="(emp, index) in produto.empresas" :key="index" style="background-color: transparent !important;">
							<p style="
								text-align: center;
								width: 120px;
								line-height: 15px;
							">
								{{ emp.saldoCondicional || 0 }}
							</p>
						</tr>
					</td> -->
					<td>
						<tr v-for="(emp, index) in produto.empresas" :key="index" style="background-color: transparent !important; text-align: center;">
							<p style=" text-align: center; 
								width: 120px;
								line-height: 20px;
							">
								{{ emp.vlrvista ? emp.vlrvista.toLocaleString("pt-BR", {style: "currency", currency: "brl"}) : "--" }}
							</p>
						</tr>
					</td>	
					<td>
						<tr v-for="(emp, index) in produto.empresas" :key="index" style="background-color: transparent !important; text-align: center;">
							<p style="
								text-align: center; 
								width: 120px;
								line-height: 20px;
							">
								{{ emp.pontoequi ? emp.pontoequi.toLocaleString("pt-BR", {style: "currency", currency: "brl"}) : "--" }}
							</p>
						</tr>
					</td>
					<td style="text-align: center; max-width: 80px; min-width: 50px" v-show="$route.name == 'Gerenciamento' || $route.name === 'Principal' && !desabilitarAcao">                            
                        <i class="fas fa-edit" style="color: var(--confirmar)" @click="editarProduto(produto)"
                        @keydown.space="editarProduto(produto)"></i>
                    </td>
				</tr>
			</tbody>
		</table>

        <div class="botoes" id="troca" v-show="troca">
            <button class="botao botao-cancelar" @click="$emit('cancelarTroca')">Cancelar troca</button>
            <button class="botao botao-adicionar" @click="trocarProdutos">Trocar itens</button>
        </div>
    </div>
	<div v-else-if="$store.state.PrimeiraListagemProduto && Produtos.length === 0" :class="`col center ${!showTable ? 'table-full' : ''}`" id="listaModal" :style="{'--height':height}">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="$emit('fecharModal')"></i>
        <h1>Nenhuma busca realizada.</h1>
    </div>
    <div v-else-if="$store.state.produtoSelecionado == 'naoencontrado' || Produtos.length === 0" :class="`col center ${!showTable ? 'table-full' : ''}`" id="listaModal" :style="{'--height':height}">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="$emit('fecharModal')"></i>
        <h1>Produto não encontrado no banco de dados da loja.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>

    <div v-else-if="$store.state.produtoSelecionado == 'cadastroincorreto'" :class="`col center ${!showTable ? 'table-full' : ''}`" id="listaModal" :style="{'--height':height}">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="$emit('fecharModal')"></i>
        <h1>Produto cadastrado incorretamente.</h1>
        <h2>Favor solicitar verificação de cadastro.</h2>
    </div>
</template>

<script>
export default {
	name: "ListagemProdutos",

	props: {
		Produtos : Array,
		troca: Boolean,
		impressao: Boolean,
		height: String,
		trocaBuscaVenda: Boolean,
		desabilitarAcao: Boolean,
		trocaListagem: Boolean,
		showTable: Boolean,
		trocaPorVenda: Boolean,

		cadastrandoCompra: Boolean,
	},
	data() {
		return {
			indexProdutoSelecionado: 0,
			timerSelecao: null,
			visaoEmpresas: false,
		};
	},

	methods: {
		trocarProdutos(){
			let produtosTroca = [];
			document.querySelectorAll(".troca").forEach((input, index) => {
				if (input.checked && this.Produtos.find(p => `${p.produto ? p.produto.id : p.id}${index}` == input.id).cdTipo != "T"){
					produtosTroca.push(this.Produtos.find(p => `${p.produto ? p.produto.id : p.id}${index}` == input.id));
				}
			});
			this.$emit("trocarProdutos", produtosTroca);
		},
        
		async editarProduto(produto){
			let produtoSelecionado;
			if(!this.visaoEmpresas){
				produtoSelecionado = await this.$store.dispatch("buscarProdutoPorId",produto.cdproduto);
			}else{
				produtoSelecionado =  await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: produto.id, cdempresa: produto.empresas[0].cdempresa});
			}		
			this.$emit("selecionarProduto",{...produtoSelecionado, todasEmpresas: true});
			this.$emit("fecharModal");
		},

		selecionarProduto(produto) {
			if (this.troca && this.trocaBuscaVenda) {
				if (!produto.checked) {
					produto.checked = true;
				} else {
					produto.checked = false;
				}
				return this.$forceUpdate();
			}
			this.$emit("selecionarProduto", {...produto, focar: true});
			this.$emit("fecharModal");
		},

		proximoInput(index) {
			if (index < 0 || index > document.querySelectorAll(".troca").length -1) {
				return;
			}
			document.getElementById(`${this.Produtos[index].produto.id}${index}`).focus();
		},

		checkInput(id) {
			document.getElementById(id).checked = !document.getElementById(id).checked;
		},

		eventosTeclado(e) {
			if (e.key == "ArrowUp") {
				if (document.getElementById(this.indexProdutoSelecionado) && this.indexProdutoSelecionado > 0) {
					this.indexProdutoSelecionado--;
					setTimeout(() => {
						document.getElementById(this.indexProdutoSelecionado).focus();
					}, 10);
				}
			}
			if (e.key == "ArrowDown") {
				if (document.getElementById(this.indexProdutoSelecionado) && (this.indexProdutoSelecionado < this.Produtos.length -1)) {
					this.indexProdutoSelecionado++;
					setTimeout(() => {
						document.getElementById(this.indexProdutoSelecionado).focus();
					}, 10);
				}
			}
			if(e.target.id === "textoPesquisa") return;
			if (e.key == "Enter") {
				if(this.cadastrandoCompra) return;
				clearTimeout(this.timerSelecao);
				this.timerSelecao = setTimeout(() => {
					if (!this.Produtos[this.indexProdutoSelecionado]) {
						document.getElementById("textoPesquisa").focus();
						return document.getElementById("textoPesquisa").select();
					}
					if (this.$store.state.carregando) {
						return this.eventosTeclado({key: "Enter"});
					}
					this.$store.state.carregando = true;
					this.selecionarProduto({...this.Produtos[this.indexProdutoSelecionado]});
					this.$store.state.carregando = false;
				}, 1000);
			}
			if (e.key == "Tab") {
				this.indexProdutoSelecionado = 0;
				if (document.getElementById("textoPesquisa")) {
					setTimeout(() => {
						document.getElementById("textoPesquisa").focus();
					}, 50);
				}
			}
		},
		buscarProdutosPorEmpresas(visao){
			this.visaoEmpresas = visao;
		},

		valorProduto(produto,tipo){
			var data = {valor:"",valorPromo:""};
			if(tipo==="V"){
				data.valor = produto.valorVista ? produto.valorVista.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : "R$ 0";
			}
			if(tipo==="P"){
				data.valor = produto.valorPrazo ? produto.valorPrazo.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}): "R$ 0";
			}
			if(produto.cdpromocao!=null){
				if(tipo==="V"){
					data.valorPromo = produto.valorPromocaoVista;
					data.valorPromo = data.valorPromo ? data.valorPromo.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}): "R$ 0";
				}
				if(tipo==="P"){
					data.valorPromo = produto.valorPromocaoPrazo;
					data.valorPromo = data.valorPromo ? data.valorPromo.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}): "R$ 0";
				}
			}			
			return data;
		}
	},
	mounted(){
		this.$bus.$on("buscarProdutosTodasEmpresas", this.buscarProdutosPorEmpresas);
		setTimeout(() => {
			this.$bus.$on("leitorEvento", (key) => {	
				return this.eventosTeclado(key);
			});
		}, 308);
		document.addEventListener("keydown", this.eventosTeclado);
		if (!this.troca && !this.impressao) {

			const pesquisa = document.getElementById("textoPesquisa");

			if(pesquisa) {
				pesquisa.addEventListener("focus", () => {
					this.indexProdutoSelecionado = 0;
				});
			}

		}
		if (this.troca) {
			this.Produtos.forEach((prod, index) => {
				prod["index"] = index;
				prod["checked"] = false;
			});
			this.$store.dispatch("buscarProdutos", {
				checkboxPesquisa: this.$store.state.checkboxPesquisa,
				buscaTodasEmpresas: this.visaoEmpresas
			});
		}
	},

	destroyed() {
		this.$bus.$off("leitorEvento");
		this.$bus.$off("buscarProdutosTodasEmpresas", this.buscarProdutosPorEmpresas);
		document.removeEventListener("keydown",this.eventosTeclado);
	}
};
</script>

<style scoped>
.botoes{
	justify-content: space-between;
}
.botao{
	width: 45% !important;
}
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: var(--height);
}

#listaModal.table-full{
	height: 77vh !important;
}
.btn {
    margin: 5px;
}

label {
    cursor: pointer;
}

tbody tr{
	line-height: none !important;
}
.troca {
    cursor: pointer;
}
.text-center .promo{
	font-size:10px;
	text-decoration:line-through;
}

@media screen and (max-width: 425px) {
    #troca {
        width: 100%;
        flex-direction: column;
    }

    .btn {
        width: 80%;
    }

	.botao{
		width:100% !important;
		margin-bottom: 5px;
	}
	
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}


@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 42vh !important;
	}
}

</style>