
<template>
	<!-- COMPONENTE RESPONSAVEL PELA CONSULTA DE CLIENTES -->
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@handleShowTable="handleShowTable"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@limpar="limparFiltro"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group select-group">
								<select
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="handleTipoFiltro"
								>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Opção Filtro
								</label>
							</div>
							<div 
								class="input-group select-group" 
								v-if="tipoFiltro === 'ANIVERSARIANTES'"
							>
								<select 
									id="mesesAno" 
									v-model="mesAniversario"
									@change="filtroPesquisa = mesesAno[mesAniversario - 1]"
								>
									<option disabled selected value="SELMES">
										SELECIONE UM MÊS
									</option>
									<option v-for="(mes, index) in mesesAno" :value="index + 1" :key="index">
										{{ mes }}
									</option>
								</select>
								<label for="mesesAno" style="background-color: #f8f8f8;">
									Mês aniversário
								</label>
							</div>
							<div class="input-group" v-if="tipoFiltro !== 'ANIVERSARIANTES'">
								<input 
									type="search"
									id="textoPesquisa"
									name="filtro"
									placeholder=" "
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar(null)"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisar
								</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<label for="js-checkbox-pesquisa" v-if="checkboxPesquisa" class="pointer">
										&nbsp;
										Ativos
									</label>
									<label for="js-checkbox-pesquisa" v-else class="pointer">
										&nbsp;
										Ambos
									</label>
									&nbsp;
									<input
										type="checkbox"
										id="js-checkbox-pesquisa"
										@change="checkboxPesquisa = !checkboxPesquisa"
										:checked="checkboxPesquisa"

									/>
									<label class="check" for="js-checkbox-pesquisa"></label>
								</div>
							</div>							
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<tabela-pessoas
					:tipoConteudo="'Clientes'"
					:height="height ? height : '60vh'"
					:mostrarAcao="mostrarAcao"
					:showTable="showTable"
					@selecionarPessoa="$emit('selecionarPessoa', $event)"
					v-on:consultarContas="$emit('consultarContas', $event)"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaPessoas from "../components/TabelaPessoas.vue";
export default {
	name: "ConsultaCliente",	
	components: {
		PaginacaoFiltro,
		TabelaPessoas,
	},
	data() {
		return {
			tipoFiltro: "TODOS",
			Filtros: [
				"TODOS",
				"NOME",
				"CPF",
				"CNPJ",
				"CÓDIGO",
				"TELEFONE",
				"ANIVERSARIANTES",
				"DEPENDENTES"
			],
			
			filtroPesquisa: "",
			
			checkboxPesquisa: true,


			mesesAno: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro"
			],

			mesAniversario: "SELMES",

			showTable: false
		};
	},
	props:{
		mostrarBotaoNovo: Boolean,
		mostrarAcao: Boolean,
		height: String,
		queryPorProps: String,
	},
	methods: {
		handleTipoFiltro() {
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
			
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa")?.focus();
			}, 50);
		},

		handleShowTable() {
			this.showTable = !this.showTable;
		},

		limparFiltro() {
			this.tipoFiltro = "TODOS";
			this.filtroPesquisa = "";
			this.checkboxPesquisa = true;
			this.mesAniversario = "SELMES";
			this.focarInputPesquisa();
		},

		async filtrar(page) {			
			let telefone = this.filtroPesquisa;
			if(this.tipoFiltro === "TELEFONE"){
				telefone = telefone.split(" ").join("");
				telefone = telefone.split("(").join("");
				telefone = telefone.split(")").join("");
				telefone = telefone.split("-").join("");
			}
			const filtro = {
				page: page || 0,
				checkboxPesquisa: this.checkboxPesquisa,
				
				query: this.tipoFiltro === "TODOS" ? this.filtroPesquisa : null,
				todos: this.tipoFiltro === "TODOS",

				nome: this.tipoFiltro === "NOME" ? this.filtroPesquisa : null,
				cdCliente: this.tipoFiltro === "CÓDIGO" ? this.filtroPesquisa : null,
				cnpj: this.tipoFiltro === "CNPJ" || this.tipoFiltro === "CPF" ? this.filtroPesquisa : null,
				fone: this.tipoFiltro === "TELEFONE" ? telefone : null,
				niver: this.tipoFiltro === "ANIVERSARIANTES" ? this.mesAniversario : null,
			};

			if(this.tipoFiltro === "DEPENDENTES") {
				const idAvalista = this.filtroPesquisa;
				await this.$store.dispatch("filtrarDependentes", {id: idAvalista});
				return;
			}

			await this.$store.dispatch("filtrarClientes", filtro);
		}
	},

	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaClientesAtual,
				totalPaginas: this.$store.state.totalPaginasClientes
			};
		}
	},

	beforeMount() {
		this.filtrar();
	},

	mounted() {
		if(this.queryPorProps !== "") {
			this.filtroPesquisa = this.queryPorProps;
		}
	},
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}

.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}

.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}


.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
.input-group{
	margin-right: 0px;
	margin-left: 0px;
}

@media screen and (max-width: 769px) {

	#textoPesquisa{
		width: 42vh;
	}
}

</style>
