<template>
    <div class="col around h-60 m-auto w-90">
        <div class="col between w-100 m-5">
            <h2>{{tipo}} de caixa</h2>
        </div>
        <div class="row center w-100 m-5">
            <div class="input-group">
                <input type="date" id="data" class="w-90 p-5"  v-model="data" autocomplete="off" :readonly="!$store.state.Usuario.supervisor"
				@keydown.enter="proximoCampo('usuarioCaixa')">
                <label for="data">Data de {{tipo}}</label>
            </div>
            <div class="input-group">
                <input type="text" id="usuarioCaixa" readonly class="w-90 p-5" :value="Usuario.nome" autocomplete="off"
				@keydown.enter="proximoCampo('identificacaoCaixa')">
                <label for="usuarioCaixa">Usuário</label>
            </div>
        </div>
        <div class="row center w-100 m-5">
            <div class="input-group">
                <input type="text" id="identificacaoCaixa" readonly class="w-80 p-5" :value="Caixa.caixa.identificador" autocomplete="off"
				@keydown.enter="proximoCampo('metodo')">
                <label for="identificacaoCaixa">Caixa</label>
            </div>
            <div class="input-group">
                <select id="metodo" class="w-80 p-5" v-model="metodo" @keydown.enter="proximoCampo('valor')">
                    <option value="">Selecione</option>
                    <option :value="metodo" v-for="(metodo, index) in formasPagamentoVista" :key="index">
                        {{metodo.descricao}}
                    </option>
                </select>
                <label for="metodo">Método</label>
            </div>
            <div class="input-group">
                <input type="text" id="valor" class="w-80 p-5" v-model="valor" style="text-align: right;" autocomplete="off"
                :placeholder="(0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})"
                @input="mascaraFloat" @keydown.enter="proximoCampo('descricao')">
                <label for="valor">Valor</label>
            </div>
        </div>

		<div class="row center w-100 m-5">
			<div class="input-group">
				<input type="text" id="descricao" class="w-90 p-5" v-model="descricao"  
				autocomplete="off" maxlength="50" @keydown.enter="proximoCampo('observacao')">
				<label for="descricao">Descrição</label>
			</div>
		</div>
	
		<div class="row center w-100 m-5">
			<div class="input-group">
				<input type="text" id="observacao" class="w-90 p-5" v-model="observacao"  
				autocomplete="off" maxlength="150" @keydown.enter="proximoCampo('btn-confirmar')">
				<label for="observacao">Observação</label>
			</div>
		</div>

        <div class="botoes">
            <button class="botao botao-cancelar" type="reset" @click="$emit('fecharModal')">Cancelar</button>
            <button class="botao botao-adicionar" type="submit" id="btn-confirmar" @click="confirmar">Confirmar</button>
        </div>
    </div>
</template>

<script>
// import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "SangriaRecarga",
	props: {
		Caixa: Object,
		Usuario: Object,
		tipo: String
	},

	data() {
		return {
			data: new Date().toLocaleDateString("en-CA"),
			valor: "",
			observacao: "",
			metodo: "",
			descricao: ""
		};
	},

	computed: {
		formasPagamentoVista() {
			return this.$store.state.formasPagamento.filter(metodo => (metodo.vistaprazo == 1 && metodo.deposito != "S" && metodo.cartao != "S") || metodo.cheque == "S");
		}
	},

	methods: {
		async confirmar() {
			if (!this.data) {
				return this.$store.commit("relatarErro", {mensagem: "Não foi informado a data de "+this.tipo});
			}
			if (!this.metodo) {
				return this.$store.commit("relatarErro", {mensagem: "Não foi informado o método de "+this.tipo});
			}
			const valorSangriaRecarga = Number(`${this.valor || 0}`.replace(".", "").replace(",", ".") || 0);
			if (valorSangriaRecarga <= 0 || isNaN(valorSangriaRecarga)) {
				return this.$store.commit("relatarErro", {mensagem: "Verificar valor informado de "+this.tipo});
			}
			if (!this.descricao) {
				return this.$store.commit("relatarErro", {mensagem: "Não foi informado a descrição de "+this.tipo});
			}
			const movimento = await this.$store.dispatch(`${this.tipo}Caixa`, {
				valor: valorSangriaRecarga,
				cdtipopgto: this.metodo.cdtipo,
				descricao: this.descricao,
				observacao: this.observacao,
				pagamento: this.metodo.descricao,
				data: this.data
			});
			if (!movimento) return;
			this.$emit("fecharModal");
		},

		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},
		mascaraFloat(e) {
			const id = e.target.id;
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this[id] = this[id].substring(0, this[id].length - 1);
			}
		},

		async iniciarComponente() {
			await this.$store.dispatch("buscarFormasPagamento");
			document.getElementById("metodo").click();
		}
	},
	mounted() {
		this.iniciarComponente();
	}
};
</script>
<style scoped>
.row .input-group{
	width: 100%;
}
.botoes{
	justify-content: space-between;
}
.botao{
	width: 45% !important;
}

input, select {
	text-transform: uppercase;
}
@media screen and (max-width: 768px) {
    .row.center.w-100.m-5 {
        flex-direction: column;
    }

    input, select {
        width: 100%;
    }

    button {
        width: 100%;
    }
}
@media screen and (max-width: 425px){
	.row{
		margin:0px;
	}
	.input-group{
		margin: 5px;
		width: 100%;
	}
	.botoes{
		margin-top: 10px;
		flex-direction: column;
	}
	.botao{
		width: 100% !important;
	}
}
</style>