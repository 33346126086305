<template>
	<ContainerPage>
		<ContentPage>
			<!-- @limpar="limpar(true)" -->
			<paginacao-filtro
				@filtrar="filtrar"
				@adicionarNovo="adicionarReceita"
				@handleShowTable="showFilter"
				:ControlePaginacao="Paginacao"
				@limpar="limpar(true)"
				:mostrarBotaoNovo="true"
			>
				<template v-slot:filtro>
					<div class="filtro-content">
						<div class="column-filtro">
							<div class="row-filtro">
								<div class="input-group select-group">
									<select 
										v-model="tipoFiltro" id="tipoFiltro" 
										@change="handleFocusInput(tipoFiltro)"
									>
										<option value="SEL">SELECIONE UMA OPÇÃO</option>
										<option 
											v-for="(option, index) in optionsFiltro" 
											:value="option.value" :key="index">
											{{ option.text }}
										</option>
									</select>
									<label for="tipoFiltro">
										Filtrar por
									</label>
								</div>
							</div>
						</div>
						<div class="column-filtro">
							<div class="row-filtro" v-if="tipoFiltro === 'valor'">
								<div class="input-group">
									<input 
										type="text"
										placeholder=" "
										id="valor"
										v-model="FiltroReceitas.valor"
										v-mascaraFloat
										autocomplete="off"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="valor">
										Valor
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'status'">
								<div class="input-group">
									<select 
										id="status"
										v-model="FiltroReceitas.status"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									>
										<option disabled>
											Selecione uma opção
										</option>
										<option value="A">
											Aberto
										</option>
										<option value="B">
											Baixado
										</option>
									</select>
									<label for="status">
										Status 
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'cdNota'">
								<div class="input-group">
									<input 
										type="text"  
										placeholder=" "
										id="cdnota"
										v-model="FiltroReceitas.cdnota"
										autocomplete="off"
										v-mascaraInteger
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="cdnota">
										Cód. Compra
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'empresa'">
								<div class="input-group">
									<select 
										v-model="FiltroReceitas.empresa" 
										id="empresa"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									>
										<option disabled value="SEL">
											SELECIONE UMA OPÇÃO
										</option>
										<option value="T">
											TODAS EMPRESAS
										</option>
										<option 
											v-for="(emp, index) in Empresas" 
											:key="index"
											:value="emp.id"
										>
											{{ emp.id }} - {{ emp.nome }}
										</option>
									</select>
									<label for="empresa">
										Empresa
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'cdPedido'">
								<div class="input-group">
									<input 
										type="text"  
										placeholder=" "
										id="cdpedido"
										v-model="FiltroReceitas.cdpedido"
										v-mascaraInteger
										autocomplete="off"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									
									/>
									<label for="cdpedido">
										Cód. Pedido
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'cliente'">
								<div class="input-group">
									<input 
										type="search"  
										placeholder=" "
										id="cliente"
										ref="cliente"
										autocomplete="off"
										list="lista-cliente"
										v-model="FiltroReceitas.textoBuscaCliente"
										@input="selecionarCliente"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="cliente">
										Cliente
									</label>
									<datalist id="lista-cliente">
										<option 
											v-for="(pessoa, index) in Pessoas" 
											:key="index" :value="pessoa.nome"
										>
										</option>
									</datalist>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataEmissao'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmissaoI" 
										v-model="FiltroReceitas.dataEmissaoI"
										@keydown.enter="proximoCampo('dataEmsisaoF')"
									/>
									<label for="dataEmissaoI">
										Emissão de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmsisaoF" 
										v-model="FiltroReceitas.dataEmissaoF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataEmsisaoF">
										Emissão até
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataBaixa'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaI" 
										v-model="FiltroReceitas.dataBaixaI"
										@keydown.enter="proximoCampo('dataBaixaF')"
									/>
									<label for="dataBaixaI">
										Baixa de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaF" 
										v-model="FiltroReceitas.dataBaixaF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataBaixaF">
										Baixa até
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataVenc'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencI" 
										v-model="FiltroReceitas.dataVencI"
										@keydown.enter="proximoCampo('dataVencF')"
									/>
									<label for="dataVencI">
										{{ isMobileDevice ? 'Venc. de' : 'Vencimento de' }}
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencF" 
										v-model="FiltroReceitas.dataVencF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
										@blur="validarData('dataVencF')"
									/>
									<label for="dataVencF">
										{{ isMobileDevice ? 'Venc. até' : 'Vencimento até' }}   
									</label>
								</div>
							</div>
						</div>
						<div class="column-filtro">
							<div class="row-filtro">
								<div class="input-group">
									<select 
										id="status"
										v-model="FiltroReceitas.status"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									>
										<option disabled>
											Selecione uma opção
										</option>
										<option value="A">
											Aberto
										</option>
										<option value="B">
											Baixado
										</option>
									</select>
									<label for="status">
										Status 
									</label>
								</div>
							</div>
						</div>
						<div class="colum-filtro" v-if="tipoFiltro === 'cliente' || tipoFiltro === 'empresa'">
							<div class="row-filtro">
								<div class="input-group select-group">
									<select v-model="dataFiltro" id="dataFiltro">
										<option value="SEL">
											SELECIONE UMA OPÇÃO
										</option>
										<option 
											v-for="(data, index) in DatasFiltroSeparadas"
											:key="index"
											:value="data.value"
										>
											{{ data.text }}
										</option>
									</select>
									<label for="dataFiltro">
										Filtrar por data
									</label>
								</div>
							</div>
						</div>
						<div class="colum-filtro">
							<div class="row-filtro"
								v-if="dataFiltro === 'dataEmissao' && (tipoFiltro === 'cliente' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmissaoI" 
										v-model="FiltroReceitas.dataEmissaoI"
										@keydown.enter="proximoCampo('dataEmsisaoF')"
									/>
									<label for="dataEmissaoI">
										Emissão de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmsisaoF" 
										v-model="FiltroReceitas.dataEmissaoF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataEmsisaoF">
										Emissão até
									</label>
								</div>
							</div>
							<div 
								class="row-filtro" 
								v-if="dataFiltro === 'dataBaixa' && (tipoFiltro === 'cliente' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaI" 
										v-model="FiltroReceitas.dataBaixaI"
										@keydown.enter="proximoCampo('dataBaixaF')"
									/>
									<label for="dataBaixaI">
										Baixa de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaF" 
										v-model="FiltroReceitas.dataBaixaF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataBaixaF">
										Baixa até
									</label>
								</div>
							</div>
							<div 
								class="row-filtro" 
								v-if="dataFiltro === 'dataVenc' && (tipoFiltro === 'cliente' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencI" 
										v-model="FiltroReceitas.dataVencI"
										@keydown.enter="proximoCampo('dataVencF')"
									/>
									<label for="dataVencI">
										{{ isMobileDevice ? 'Venc. de' : 'Vencimento de' }}
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencF" 
										v-model="FiltroReceitas.dataVencF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataVencF">
										{{ isMobileDevice ? 'Venc. até' : 'Vencimento até' }}   
									</label>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-slot:tabela>
					<div 
						class="container-tabela tabela-container"  
						v-if="Receitas.length > 0 && !loadingTable" 
						id="table"
					>
						<div class="header-tabela">
							<div class="linha-header-tabela">
								<div class="campo-tabela campo-acao">
									<span>Ação</span>
								</div>
								<div class="campo-tabela campo-menor-emp">
									<span>Emp</span>
									<i class="fas fa-sort" @click="reordenar('cdempresa')" v-if="FiltroReceitas.sort != 'cdempresa'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('cdempresa')" v-else-if="FiltroReceitas.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('cdempresa')" v-else></i>
								</div>
								<div class="campo-tabela campo-menor">
									<span>Número</span>
									<i class="fas fa-sort"></i>
								</div>
								<div class="campo-tabela campo-medio" id="pessoa">
									<span>Pessoa</span>
									<i class="fas fa-sort" @click="reordenar('nome')" v-if="FiltroReceitas.sort != 'nome'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('nome')" v-else-if="FiltroReceitas.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('nome')" v-else></i>
								</div>
								<div class="campo-tabela valor">
									<span>Valor Título</span>
								</div>						
								<div class="campo-tabela data row">
									<span>Emissão</span>
									<i class="fas fa-sort" @click="reordenar('datadoc')" v-if="FiltroReceitas.sort != 'datadoc'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('datadoc')" v-else-if="FiltroReceitas.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('datadoc')" v-else></i>
								</div>
								<div class="campo-tabela data row">
									<span>Vencimento</span>
									<i class="fas fa-sort" @click="reordenar('datavenc')" v-if="FiltroReceitas.sort != 'datavenc'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('datavenc')" v-else-if="FiltroReceitas.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('datavenc')" v-else></i>
								</div>
								<div class="campo-tabela valor">
									<span>V. Parcela</span>
		
								</div>
								<div class="campo-tabela valor-pago">
									<span>V. Pago</span>
								</div>
								<div class="campo-tabela valor">
									<span>V. Restante</span>
								</div>
								<div class="campo-tabela data">
									<span>Baixa</span>
									<i class="fas fa-sort" ></i>		
								</div>
								<div class="campo-tabela campo-medio">
									<span>Forma Pgto</span>
								</div>
								<div class="campo-tabela campo-medio" id="observacao">
									<span>Observação</span>
								</div>
							</div>
						</div>
						<div class="body-tabela">
							<div 
								class="linha-body-tabela" 
								v-for="(conta, index) in Receitas" 
								:key="index" :id="`linha-${index}`"
								:class="contaVencida(conta.datavenc)  && conta.situacao != 'Baixado' 
									? 'campo-tabela data linha-vermelha' 
									: 'campo-tabela data'"
							>
								<div class="campo-tabela campo-acao">
									<i class="fas fa-edit" style="	color: var(--acao)"
										@click="abrirModalEditarReceita(conta)"
									>
									</i>
									<!-- <i 
										class="fas fa-undo"
										style="color: var(--acao)"
										@click="estornarTituloBaixado(conta)"
										v-if="conta.situacao === 'Baixado'"
									></i> -->
								</div>
								<div class="campo-tabela campo-menor-emp">
									{{ conta.cdempresa }}
								</div>
								<div class="campo-tabela campo-menor">
									{{ conta.cdmovi }}
								</div>
								<div class="campo-tabela campo-medio" id="pessoa">
									{{ conta.nome }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.vlrtitulo || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela data">
									{{ formatarData(conta.datadoc) }}
								</div>
								<div class="campo-tabela data">
									{{ formatarData(conta.datavenc) }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.contarc || conta.debito || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela valor-pago">
									{{ (conta.valor || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.restante || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela data">
									{{ formatarData(conta.databaixa ? conta.databaixa.split("T")[0] : "") }}
								</div>
								<div class="campo-tabela campo-medio">
									{{ conta.pagamento }}
								</div>
								<div class="campo-tabela campo-medio" id="observacao">
									{{ conta.observa }}
								</div>
							</div>
						</div>
					</div>
					<div 
						v-else-if="loadingTable" 
						class="col center w-100 h-47vh"
					>
						<Loader 
							:width="'15vh'"
							:height="'15vh'"
						/>
					</div>
					<div 
						v-else
						class="col center w-100 triangle"
						id="table"
					>
						<i 
							class="fas fa-exclamation-triangle"
							style="color: yellow; font-size: 90pt"
						></i>
						<h3>
							Nenhuma conta encontrada no sistema.
						</h3>
						<h3>
							Tente alterar ou diminiur os parâmetros de busca.
						</h3>
					</div>
					<!-- <div class="row" style="justify-content: center;" v-if="ContasPagar.length > 0 && !loadingTable" >
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorTitulo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Título</label>
						</div>
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorParcela.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Parcela</label>
						</div>
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorPago.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Pago</label>
						</div>
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorRestante.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Restante</label>
						</div>
					</div> -->
				</template>
			</paginacao-filtro>
			<!-- <Filtro 
				:mostrarBotaoNovo="true"		
				@adicionarNovo="adicionarReceita">
			</Filtro> -->
			<Modal 
				v-if="modalAdicionarReceita"
				@fecharModal="fecharModal"
				:dimensoes="{width: '90%', height: '90%'}"
				:idModal="'adicionar-receita'"
			>
				<AdicionarTitulo 
					:tipoTitulo="'receita'"
					:Titulo="receitaEditando"
					@fecharLancamentoTitulo="fecharModal"
				/>
			</Modal>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro";
import ContainerPage from "../components/ContainerPage.vue";
import ContentPage from "../components/ContentPage.vue";
import AdicionarTitulo from "../components/AdicionarTitulo.vue";
import Modal from "../components/Modal.vue";
import Loader from "../components/Loader.vue";
// import Filtro from "../components/Filtro.vue";
import { proximoCampo } from "../utils/utils";
import formatarParaReais from "../filters/formatarParaMoeda";

export default {
	name: "Receitas",
	components: {
		ContainerPage,
		ContentPage,
		AdicionarTitulo,
		Modal,
		PaginacaoFiltro,
		Loader
		// Filtro
	},

	data() {
		return {
			proximoCampo,
			modalAdicionarReceita: false,
			FiltroReceitas: {
				status: "A",
				valor: "",
				cdnota: "",
				empresa: "SEL",
				cdpedido: "",
				textoBuscaCliente: "",
				dataEmissaoI: "",
				dataEmissaoF: "",
				dataVencI: "",
				dataVencF: "",
				dataBaixaI: "",
				dataBaixaF: "",
				clienteSelecionado: {
					nome: ""
				},
				sort: null,
				sortOrder: "ASC",
			},
			tipoFiltro: "",
			optionsFiltro: [
				{
					value: "dataBaixa",
					text: "Data baixa"
				},
				{
					value: "dataVenc",
					text: "Data vencimento"
				},
				{
					value: "dataEmissao",
					text: "Data emissão"
				},
				{
					value: "cliente",
					text: "Cliente"
				},
				{
					value: "cdPedido",
					text: "Código pedido"
				},
				{
					value: "empresa",
					text: "Empresa",
				},
				{
					value: "cdNota",
					text: "Código Compra"
				},
				{
					value: "valor",
					text: "Valor"
				},
			],
			loadingTable: false,
			dataFiltro: "SEL",
			receitaEditando: {}
		};
	},

	filters: {
		formatarParaReais
	},

	computed: {
		DatasFiltroSeparadas() {
			if(this.optionsFiltro?.length > 0) {
				const arrDatas = [];
				for(let i=0; i < this.optionsFiltro.length; i++) {
					const optFiltro = this.optionsFiltro[i];
					if(optFiltro.value.includes("data")) {
						arrDatas.push(optFiltro);
					}
				}
				return arrDatas;
			}
			return [];
		},

		Receitas() {
			return this.$store.state.ListaReceitas.content;
		},

		Paginacao() {
			return this.$store.state.ListaReceitas.paginacao;
		},

		Cliente() {
			return this.Pessoas.find(pessoa => 
				pessoa.nome === this.FiltroReceitas.textoBuscaCliente?.toUpperCase()
			);
		},
		
		Empresas(){
			return this.$store.state.Empresas;
		},
		
		Pessoas() {
			return this.$store.state.Clientes;
		},

		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},
	},
	methods: {
		fecharModal() {
			this.modalAdicionarReceita = false;
			this.receitaEditando = {};
		},
		async abrirModalEditarReceita(receita){
			this.receitaEditando = await this.buscarUnicaConta(receita);
			this.receitaEditando = this.montarPayloadModal(this.receitaEditando);
			if(!this.receitaEditando) return;
			this.modalAdicionarReceita = true;
			this.receitaEditando.editando = true;
		},


		montarPayloadModal(receita) {
			return {
				...receita,
				conta: {
					agencia: receita.cdagencia,
					banco: receita.cdbanco,
					cc: receita.cdconta,
				},
				cdTipoPgto: receita.cdtipopgto,
				valorTitulo: (receita.vlrtitulo || 0).toLocaleString("pt-BR", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				}),
				cdPessoa: receita.cdpessoa,

			};
		},

		async buscarUnicaConta(receita) {
			const res = await this.$store.dispatch("buscarContaReceber", receita);
			if(res && (!res.message || !res.erro)){
				return res;
			}
		},

		formatarData(data) {
			if(data) {
				return data.split("-").reverse().join("/");
			}
		},

		contaVencida(dataVencimento){
			if(!dataVencimento) return false;
			const dataVencimentoSeparada = dataVencimento.split("/").reverse().join("-");
			const dataVencimentoObj = new Date(dataVencimentoSeparada);
			const dataHojeObj = new Date();
			return dataVencimentoObj.getTime() < dataHojeObj.getTime();
		},

		selecionarCliente() {
			setTimeout( async () => {
				await this.$store.dispatch("filtrarClientesPorNome", {
					query: this.FiltroReceitas.textoBuscaCliente,
				});
			}, 300);
		},

		adicionarReceita() {
			this.modalAdicionarReceita = true;
		},

		handleFocusInput(tipoFiltro) {
			const campoMapeamento = {
				"dataBaixa": "dataBaixaI",
				"dataEmissao": "dataEmissaoI",
				"dataVenc": "dataVenc",
				"cliente": "cliente",
				"cdPedido": "cdpedido",
				"cdNota": "cdnota",
				"empresa": "empresa",
				"valor": "valor",
				"status": "status"
			};
			const campo = campoMapeamento[tipoFiltro];
			if(campo) {
				this.proximoCampo(campo);
			}
			this.limpar();
			this.FiltroReceitas.status = tipoFiltro == "dataBaixa"? "B":"A";
		},

		async filtrar(page) {
			try {
				this.loadingTable = true;
				setTimeout( async () => {

					const filtro = this.montarOpcoesFiltro(this.FiltroReceitas, page);
					const sort = this.FiltroReceitas.sort? this.FiltroReceitas.sort : this.tipoFiltro === "dataEmissao" ? "datadoc" : "datavenc" ;

					await this.$store.dispatch("filtrarReceitas", {...filtro, sort, sortOrder:this.FiltroReceitas.sortOrder});
					this.loadingTable = false;
				}, 50);
			} catch (error) {
				console.error(error);
			}
		},

		montarOpcoesFiltro(options, page) {
			return {
				dataVencI: options.dataVencI || null,
				dataVencF: options.dataVencF || null,
				dataBaixaI: options.dataBaixaI || null,
				dataBaixaF: options.dataBaixaF || null,
				dataDocI: options.dataEmissaoI || null,
				dataDocF: options.dataEmissaoF || null,
				cdnota: options.cdnota || null,
				cdPedido: options.cdpedido || null,
				cdcliente: options.textoBuscaCliente ? this.Cliente?.id : "",
				condicao: options.status || null,
				valor: typeof options.valor === "string" ? parseFloat(options.valor.replace(".", "").replace(",", ".")) : Number(options.valor) || null,
				cdempresa: options.empresa === "T" ? null : options.empresa === "SEL" ? null : options.empresa,
				query: !this.Cliente?.id ? options.textoBuscaCliente : null,
				page: page || 0,
			};
		},

		preencherFiltro() {
			const anoAtual = new Date().getFullYear();
			const mesAtual = new Date().getMonth();
			const diaAtual = new Date().getDate();
			this.tipoFiltro = "dataVenc";
			this.FiltroReceitas.dataVencI = new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca");
			this.FiltroReceitas.dataVencF = new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca");
			this.filtrar();
		},

		showFilter() {
			const table = document.querySelector(".tabela-container");
			if(table) {
				table.classList.toggle("fullTable");
			}
		},
		/*
			Data venc OK
			Date emissao - N/OK
			data baixa - nao ta retornando
		*/

		limpar(limparOpcoesFiltro) {
			const anoAtual = new Date().getFullYear();
			const mesAtual = new Date().getMonth();
			const diaAtual = new Date().getDate();
			this.FiltroReceitas = {
				dataVencI: this.tipoFiltro === "dataVenc" ? new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca") : null,
				dataVencF: this.tipoFiltro === "dataVenc" ? new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca") : null,
				dataBaixaI: this.tipoFiltro === "dataBaixa" ? new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca") : null,
				dataBaixaF: this.tipoFiltro === "dataBaixa" ? new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca") : null,
				dataEmissaoI: this.tipoFiltro === "dataEmissao" ? new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca") : null,
				dataEmissaoF: this.tipoFiltro === "dataEmissao" ? new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca") : null,
				textoBuscaFornecedor: "",
				empresa: "SEL",
				status: "A",
				valor: "",
				cdnota: "",
				cdpedido: "",				
				sort: null,
				sortOrder: "ASC",
			};
			if(limparOpcoesFiltro) {
				this.tipoFiltro = "SEL";
				this.dataFiltro = "SEL";
			}
		},
	},
	mounted() {
		this.preencherFiltro();
	},
};
</script>
<style scoped>
.campo-medio{
	min-width: 150px;
}
.dragging{
	opacity: 0.5;
	cursor: grab;
}
.linhaSelecionada{
	background-color:  #6170f779 !important;
	color: #FFF;
}
.botao span{
	font-size: 15px;
}
.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 55vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.container-tabela.fullTable {
	height: 65vh;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-acao {
	min-width: 45px;
	text-align: center;
}
.campo-menor{
	width: 100px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}
.campo-menor-emp{
	width: 70px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}

.data{
	min-width: 120px;
	text-align: center;
}
#pessoa{
	width: 250px;
	max-width: 275px !important;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	margin-left: 15px;
}
#observacao{
	width: 400px;
	text-align: left;
}
.valor{
	min-width: 135px;
	text-align: center;
}
.valor-pago{
	min-width: 125px;
	text-align: center;
}
.linha-header-tabela .campo-tabela i{
	margin-left: 10px;
	color: #7a7a7a;
}
.linha-vermelha{
	background-color: #ff0101c4;
	color: #FFF;
}
#baixar{
	cursor: grab;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 30px;
	left: 150px;
	border: none;	
	outline: none;
	background-color: var(--acao-background);
}
#baixar i {
	font-size: 20px;
}
#relatorio{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 15px;
	right: 30px;
	border: none;	
	outline: none;
	background-color: var(--acao-background);
}
#relatorio i {
	font-size: 20px;
}
.triangle{
	height: 40vh;
}
.tableFull.triangle {
	height: 78vh;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 10px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
}
.filtro-content{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
#fornecedor{
	width: 45vh;
}
#empresa {
	width: 45vh;
}
#changeFornecedor:hover{
	transform: rotate(180deg);
}
.row-filtro .input-group:focus-within label,
.row-filtro .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.row-filtro .input-group:focus-within label,
.row-filtro .input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.h-47vh{
	height: 47vh;
}
.campo-tabela{
	font-size: 13px;
}
@media screen and (max-width: 425px) {
	.row-filtro {
		width: 100%;
	}
	.column-filtro {
		width: 100%;
	}
	.row-filtro .input-group {
		width: 50%;
	}
	#fornecedor, #empresa {
		width: 100%;
	}
	.container-tabela {
		height: 33vh;
		width: 97vw;
	}
	.container-tabela.fullTable {
		height: 67vh;
	}
	#adicionar{
		position: absolute;
		left: 325px;
		bottom: 50px;
	}
	#baixar {
		position: absolute;
		left: 15px;
		bottom: 50px;
	}
}
@media screen and (max-height: 600px) {
	.container-tabela {
		height: 52vh;
	}
}
</style>
 