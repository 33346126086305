<template>
	<div class="cadastroCliente">
		<div class="row" id="divNomeCliente" style="margin:10px" v-if="parametroAba">
			<div id="cdCliente" class="input-group marg-top row w-30">						
				<input type="number" :value="clienteCadastrado.id" class="w-100" disabled>
				<label class="mx-5 text-right w-auto" for="nome">Código </label>
			</div>
			<div class="input-group input-group-correcao row marg-top" style="width:100%">						
				<input id="nome" type="text" @input="mascara" v-model="clienteCadastrado.nome" class="w-100"
					autocomplete="off" @keydown.enter.prevent="proximoCampo('tipoPessoa')">
				<label class="mx-5 text-right w-auto" for="nome">Nome </label>
			</div>
		</div>
		<div class="aba-controle js-aba-cliente" v-if="parametroAba">
			<h3 id="dados" class="aba abaSelecionada" @click="mudarAba">Dados do Cliente</h3>
			<h3 id="adicional" class="aba" @click="mudarAba">Informações Adicionais</h3>
		</div>
		<h1 class="titulo" v-else>Dados do Cliente</h1>
		<ClienteDados 
			:cliente="clienteCadastrado"
			@cancelarEdicao="cancelarEdicao"
			@editarCliente="editarCliente"
			v-if="mostrar == 'dados'"/>
		<ClienteAdicional
			:cliente="clienteCadastrado"
			@cancelarEdicao="cancelarEdicao"
			@editarCliente="editarCliente"
			v-if="mostrar == 'adicional'"
		/>
	</div>
</template>

<script>
// import Paginacao from "./Paginacao.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
// import TabelaCidade from "./TabelaCidade.vue";
import ClienteDados from "./ClienteDados.vue";
import ClienteAdicional from "./ClienteAdicional.vue";
import { salvarImagem } from "../utils/drive";
export default {
	name: "CadastroCliente",

	props: {
		cliente: Object,
	},

	components: {
		// Paginacao,
		// TabelaPessoas,
		// TabelaCidade,
		ClienteDados,
		ClienteAdicional,
	},

	data() {
		return {
			mostrar: "dados",
			clienteCadastrado: null,
			mudandoAba: false,
		};
	},

	computed:{
		parametroAba(){
			return this.$store.state.Empresa.validaAdicionalCliente;
		}
	},
	beforeMount() {
		if(this.cliente){
			this.clienteCadastrado = {...this.cliente};
		}
		else if (this.$route.params.cliente) {
			this.clienteCadastrado = {...this.$route.params.cliente};
		}
	},
	methods: {
		mascara(evento) {
			const id = evento.target.id;
			if (id === "nome"){
				const regex =  /[^a-zA-Z0-9\s]/;
				if (regex.test(this.clienteCadastrado[id])) {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].substring(0, this.clienteCadastrado[id].length - 1);
				}
				return this.clienteCadastrado[id] = this.clienteCadastrado[id].toUpperCase();
			}
		},
		podeMudarAba() {
			try {
				this.$bus.$emit("salvarCliente");
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", { mensagem: error.message });
				return false;
			}
		},
		mudarAba(evento) {
			this.mudandoAba = true;
			if (!this.podeMudarAba()) return;
			setTimeout(() => {
				if(this.mudandoAba){
					this.mudandoAba = false;
					return;
				}
				const h3Id = evento.target.id;
				this.mostrar = h3Id;
				const abaControle = document.querySelector(".aba-controle.js-aba-cliente");
				abaControle.querySelectorAll(".aba").forEach(aba => {
					if (aba.classList.contains("abaSelecionada")) {
						aba.classList.remove(["abaSelecionada"]);
					}
					if (aba.id == h3Id) {
						aba.classList.add(["abaSelecionada"]);
					}
				});
			}, 200);
		},
		verificaCPF(strCPF) {
			strCPF = strCPF.replace(/[^\d]+/g, "");

			if (strCPF == "") return false;

			if (strCPF.length != 11)
				return false;

			if (strCPF == "00000000000" ||
				strCPF == "11111111111" ||
				strCPF == "22222222222" ||
				strCPF == "33333333333" ||
				strCPF == "44444444444" ||
				strCPF == "55555555555" ||
				strCPF == "66666666666" ||
				strCPF == "77777777777" ||
				strCPF == "88888888888" ||
				strCPF == "99999999999")
				return false;

			let Soma;
			let Resto;
			Soma = 0;
			if (strCPF == "00000000000") return false;
			for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10))) return false;
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11))) return false;
			return true;
		},
		verificaCNPJ(cnpj) {

			cnpj = cnpj.replace(/[^\d]+/g, "");

			if (cnpj == "") return false;

			if (cnpj.length != 14)
				return false;

			if (cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999")
				return false;

			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0, tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0))
				return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0, tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1))
				return false;

			return true;
		},
		validaCampos(clienteCadastrado){
			if (clienteCadastrado.ativo == "A") {
				if (clienteCadastrado.tipoPessoa == "F") {
					if (!this.verificaCPF(clienteCadastrado.cnpj)) {
						if(this.$store.state.Usuario.controlaCliente){
							throw { mensagem: "CPF Inválido!"};
						}
						else if(clienteCadastrado.cnpj != "000.000.000-00"){
							throw { mensagem: "CPF Inválido!"};
						}
					}
				} else {
					if (!this.verificaCNPJ(clienteCadastrado.cnpj)) {
						if(this.$store.state.Usuario.controlaCliente){
							throw { mensagem: "CNPJ Inválido!"};
						}
						else if(clienteCadastrado.cnpj != "00.000.000/0000-00"){
							throw { mensagem: "CNPJ Inválido!"};
						}						
					}
				}
				if(this.$store.state.Usuario.controlaCliente){
					if (!clienteCadastrado.cidade.nomecidade) {
						throw { mensagem: "Campo cidade não pode estar vazio" };
					}
					if (!clienteCadastrado.dataNascimento && clienteCadastrado.tipoPessoa == "F") {
						throw { mensagem: "Campo data de nascimanto não pode estar vazio"};
					}
					if (!clienteCadastrado.datacerasa && clienteCadastrado.sitcerasa != "N") {
						throw { mensagem: "Campo data serasa não pode estar vazio"};
					} else if (new Date(clienteCadastrado.datacerasa).getTime() < new Date(new Date().setDate(new Date().getDate() - 90)).getTime() && clienteCadastrado.sitcerasa != "N") {
						throw { mensagem: "Situação do cliente expirou! Favor consultar o Serasa e atualizar a data!"};
					} else if (clienteCadastrado.sitcerasa != "N" && !clienteCadastrado.obscerasa) {
						throw { mensagem: "Favor informar a observação quando constar situação Serasa!"};
					}
					if (this.cliente) {
						if (this.cliente.bairro && !clienteCadastrado.bairro) {
							throw { mensagem: "Campo bairro apagado. \n Deseja confirmar?", tipo: "polar" };
						}
					} else if (this.$route.params.cliente) {
						if (this.$route.params.cliente.bairro && !clienteCadastrado.bairro) {
							throw { mensagem: "Campo bairro apagado. \n Deseja confirmar?", tipo: "polar" };
						}
					}
				}
			}
			if (!clienteCadastrado.classificacao?.cdclassi) {
				throw { mensagem: "Favor verificar a classificação!"};
			}
			if (!clienteCadastrado.nome) {
				throw { mensagem: "Campo nome não pode estar vazio"};
			}
			const regex = /[^a-zA-Z0-9\s]/;
			if (regex.test(clienteCadastrado.nome)) {
				throw { mensagem: "Favor verificar e remover caracteres especiais no nome do cliente!"};
			}

			if (!clienteCadastrado.cep) {
				throw { mensagem: "Campo cep não pode estar vazio"};
			}

			if (!clienteCadastrado.cidade.cdcidade) {
				throw { mensagem: "Campo IBGE não pode estar vazio"};
			}

			if (!clienteCadastrado.cnpj) {
				throw { mensagem: `Campo ${clienteCadastrado.tipoPessoa == "F" ? "cpf" : "cnpj"}  não pode estar vazio`};
			}
		},
		async editarCliente({clienteCadastrado, classi, classiErro, imagem}) {
			try{
				clienteCadastrado.nome = this.clienteCadastrado.nome ? this.clienteCadastrado.nome : this.cliente.nome;
				clienteCadastrado.nome = this.clienteCadastrado.nome ? clienteCadastrado.nome.toUpperCase() : this.clienteCadastrado.nome;
				clienteCadastrado.classificacao = classi;
				this.validaCampos(clienteCadastrado);			
				if (!clienteCadastrado.classificacao || classiErro) {
					throw { mensagem: "Favor selecionar a classificação"};
				}
				clienteCadastrado.email = clienteCadastrado?.email?.toLowerCase();
				if ((this.$route.name == "Lancamento" || this.$route.name == "Fechamento") && this.cliente?.id) {
					let cliente = null;
					if (this.cliente && this.cliente.novo && !this.clienteCadastrado.id) {
						cliente = await this.$store.dispatch("adicionarCliente", { ...clienteCadastrado });
					} else {
						if (JSON.stringify(clienteCadastrado) != JSON.stringify(this.cliente)) {
							cliente = await this.$store.dispatch("atualizarCliente", { ...clienteCadastrado, id: this.cliente.id || this.clienteCadastrado.id});
						}
					}
					if (!cliente) return;
					if (cliente.erro) return;
					this.clienteCadastrado = {...cliente};
					if(!this.mudandoAba){
						this.$emit("clienteCadastrado", cliente);
					}
					if(this.mudandoAba){
						this.mudandoAba = false;
					}
					return;
				}

				if (this.$route.params.cliente && this.$route.params.cliente?.id) {
					if (JSON.stringify(clienteCadastrado) != JSON.stringify(this.$route.params.cliente)) {
						if(imagem){
							try{
								this.$store.state.carregando = true;
								const data = await salvarImagem(clienteCadastrado.id,imagem);
								if(data.idImagem){
									clienteCadastrado.idImagem = data.idImagem;
								}
								this.$store.state.carregando = false;
							}
							catch (e){
								this.$store.state.carregando = false;
								return this.$store.commit("relatarErro", {mensagem: "Houve um erro ao salvar a imagem tente novamente ou entre em contato com o suporte!"});
							}
						}
						const clienteAtualizado = await this.$store.dispatch("atualizarCliente", { ...clienteCadastrado});
						this.clienteCadastrado = {...clienteAtualizado};
						if (!clienteAtualizado) return;
						if (clienteAtualizado.erro) return;
					}
					if(!this.mudandoAba){
						if(!this.parametroAba || this.mostrar != "dados"){
							if(this.$router.currentRoute.path == "/cliente"){
								this.$router.push({name: "Principal"});
							}
							else{
								this.$emit("cancelarEdicao");
							}
						}
						else if(this.mostrar == "dados"){
							this.mostrar = "adicional";
							const abaControle = document.querySelector(".aba-controle.js-aba-cliente");
							abaControle.querySelectorAll(".aba").forEach(aba => {
								if (aba.classList.contains("abaSelecionada")) {
									aba.classList.remove(["abaSelecionada"]);
								}
								if (aba.id == "adicional") {
									aba.classList.add(["abaSelecionada"]);
								}
							});
						}
					}
					if(this.mudandoAba){
						this.mudandoAba = false;
					}
					return;
				}
				let clienteAtualizado = null;
				if (this.cliente && this.cliente.novo && !this.clienteCadastrado.id) {
					clienteAtualizado = await this.$store.dispatch("adicionarCliente", { ...clienteCadastrado });
					if(imagem){
						try{
							this.$store.state.carregando = true;
							const data = await salvarImagem(clienteAtualizado.id,imagem);
							if(data.idImagem){
								clienteAtualizado.idImagem = data.idImagem;
							}
							this.$store.state.carregando = false;
						}
						catch (e){
							this.$store.state.carregando = false;
							return this.$store.commit("relatarErro", {mensagem: "Houve um erro ao salvar a imagem tente novamente ou entre em contato com o suporte!"});
						}
						clienteAtualizado = await this.$store.dispatch("atualizarCliente", { ...clienteAtualizado, id: clienteAtualizado.id});
					}
				} else {
					if (this.cliente && JSON.stringify(clienteCadastrado) != JSON.stringify(this.cliente)) {
						clienteAtualizado = await this.$store.dispatch("atualizarCliente", { ...clienteCadastrado, id: this.cliente.id || this.clienteCadastrado.id});
					}
				}
				if (!clienteAtualizado) return;
				if (clienteAtualizado.erro) return;
				this.clienteCadastrado = {...clienteAtualizado};
				if(!this.mudandoAba){
					if(!this.parametroAba || this.mostrar != "dados"){
						if (this.$route.name == "Lancamento" || this.$route.name == "Fechamento"){
							this.$emit("clienteCadastrado", clienteAtualizado);
						}
						else if(this.$router.currentRoute.path == "/cliente"){
							this.$router.push({name: "Principal"});
						}
						else{
							this.$emit("cancelarEdicao");
						}
					} 
					else if (this.mostrar == "dados"){
						this.mostrar = "adicional";
						const abaControle = document.querySelector(".aba-controle.js-aba-cliente");
						abaControle.querySelectorAll(".aba").forEach(aba => {
							if (aba.classList.contains("abaSelecionada")) {
								aba.classList.remove(["abaSelecionada"]);
							}
							if (aba.id == "adicional") {
								aba.classList.add(["abaSelecionada"]);
							}
						});
					}
				}
				if(this.mudandoAba){
					this.mudandoAba = false;
				}
			}
			catch(erro){
				console.error(erro);
				this.$store.commit("relatarErro", erro);
			}
		},
		cancelarEdicao() {
			if (this.$route.name == "CadastroCliente") {
				return this.$router.replace({ name: this.$route.params.route, query: this.$route.query, params: this.$route.params });
			} else {
				this.$emit("cancelarEdicao");
			}
		},
	},


};
</script>

<style scoped>
.cadastroCliente {
	display: flex;
	flex-direction: column;
	align-self: center;
	width: 100%;
	height: 100%;
}
.titulo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 50px;
}
@media screen and (max-width: 675px) {
	h1 {
		font-size: 28px;
	}
}
@media screen and (max-width: 425px) {
	.titulo {
		font-size: 18pt;
	}
}
</style>
