<template>
	<div style="width: 100%;">
		<div class="container-page">
			<div class="container-form">
				<div class="row" style="align-items: center;">
					<i class="fas fa-search" style="color: var(--acao-texto)" @click="listaCfop = true"/>
					<div class="input-group" style="width: 100px;">
						<input 
							type="text" placeholder=" " 
							id="cfop"
							v-model="nota.cfop"
							@keydown.enter="proximoCampo('nome')"
							@input="digitouCFOP"
							maxlength="4"
						/>
						<label for="cfop">
							CFOP:
						</label>
					</div>
					<div class="input-group" style="width: 30%;">
						<input 
							type="text" placeholder=" " 
							id="natureza"
							v-model="nota.natureza"
							readonly
						/>
						<label for="natureza">
							Natureza da Operação:
						</label>
					</div>				
					<div class="input-group" style="width: 30%;">
						<input 
							type="text" placeholder=" " 
							id="nome"
							v-model="nota.nomeCliente"
							@click="listarClientes = true"
							@keydown.enter="proximoCampo('dataEmissao')"
						/>
						<label for="nome">
							Nome/Razão Social:
						</label>
					</div>
					<div class="input-group" style="width: 30%;">
						<input 
							type="text" placeholder=" " 
							id="cpf"
							v-model="nota.cpfCliente"
							readonly
						/>
						<label for="cpf">
							CPF/CNPJ:
						</label>
					</div>
					<div class="input-group" style="width: 200px;">
						<input type="date" id="dataEmissao" v-model="nota.dataEmissao" @keydown.enter="proximoCampo('dataMovimentacao')"/>
						<label for="dataEmissao">
							Data da Emissão:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width: 30%;">
						<input type="text" id="endereco" v-model="nota.enderecoCliente" readonly/>
						<label for="endereco">
							Endereço:
						</label>
					</div>
					<div class="input-group" style="width: 30%;">
						<input type="text" id="bairro" v-model="nota.bairroCliente" readonly/>
						<label for="bairro">
							Bairro/Distrito:
						</label>
					</div>
					<div class="input-group" style="width: 230px;">
						<input type="text" id="cep" v-model="nota.cepCliente" readonly/>
						<label for="cep">
							CEP:
						</label>
					</div>
					<div class="input-group" style="width: 230px;">
						<input type="date" id="dataMovimentacao" v-model="nota.dataMovimentacao" @keydown.enter="proximoCampo('horaMovimentacao')"/>
						<label for="dataMovimentacao">
							Data da Saida/Entrada:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width:100%">
						<input type="text" id="municipio" v-model="nota.municipioCliente" readonly/>
						<label for="municipio">
							Município:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="uf" v-model="nota.ufCliente" readonly/>
						<label for="uf">
							UF:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="fone" v-model="nota.foneCliente" readonly/>
						<label for="fone">
							Fone/Fax:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="ie" v-model="nota.ieCliente" readonly/>
						<label for="ie">
							Inscrição Estadual:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="time" step="1" id="horaMovimentacao" v-model="nota.horaMovimentacao" @keydown.enter="proximoCampo('valorDoFrete')"/>
						<label for="horaMovimentacao">
							Hora da Saida/Entrada:
						</label>
					</div>
				</div>
				<label style="margin-left: 10px;">
					Cálculo do Imposto:
				</label>
				<div class="row">
					<div class="input-group" style="width:100%">
						<input type="text" id="baseCalcICMS" v-model="nota.baseCalcICMS" readonly/>
						<label for="baseCalcICMS">
							Base de Cálc. do ICMS:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorICMS" v-model="nota.valorICMS" readonly/>
						<label for="valorICMS">
							Valor do ICMS:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="baseCalcICMSST" v-model="nota.baseCalcICMSST" readonly/>
						<label for="baseCalcICMSST">
							Base de Cálc. do ICMS S.T.:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorICMSSub" v-model="nota.valorICMSSub" readonly/>
						<label for="valorICMSSub">
							Valor do ICMS Subst:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width:100%">
						<input type="text" id="valorPis" v-model="nota.valorPis" readonly/>
						<label for="valorPis">
							Valor do Pis:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorCofins" v-model="nota.valorCofins" readonly/>
						<label for="valorCofins">
							Valor da Cofins:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="vTotalProdutos" v-model="nota.vTotalProdutos" readonly/>
						<label for="vTotalProdutos">
							V. Total Produtos:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorDoFrete" v-model="nota.valorDoFrete" @keydown.enter="proximoCampo('valorDoSeguro')"/>
						<label for="valorDoFrete">
							Valor do Frete:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width:100%">
						<input type="text" id="valorDoSeguro" v-model="nota.valorDoSeguro" @keydown.enter="proximoCampo('desconto')"/>
						<label for="valorDoSeguro">
							Valor do Seguro:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="desconto" v-model="nota.desconto" @keydown.enter="proximoCampo('outrasDespesas')"/>
						<label for="desconto">
							Desconto:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="outrasDespesas" v-model="nota.outrasDespesas" @keydown.enter="proximoCampo('nomeTransporte')"/>
						<label for="outrasDespesas">
							Outras Despesas:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorTotalIPI" v-model="nota.valorTotalIPI" readonly/>
						<label for="valorTotalIPI">
							Valor Total IPI:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="vTotalNota" v-model="nota.vTotalNota" readonly/>
						<label for="vTotalNota">
							V. Total da Nota:
						</label>
					</div>
				</div>
				<label style="margin-left: 10px;">
					Transportador/Volumes Transportados:
				</label>
				<div class="row">
					<div class="input-group" style="width:40%">
						<input type="text" id="nomeTransporte" v-model="nota.nomeTransporte" @keydown.enter="proximoCampo('tipoFrete')" @click="listarClientesTransporte = true"/>
						<label for="nomeTransporte">
							Nome/Razão Social:
						</label>
					</div>
					<div class="input-group" style="width: 310px;">
						<select 
							id="tipoFrete"
							v-model="nota.tipoFrete"
							@keydown.enter="proximoCampo('quantidade')"
						>
							<option :value="0">
								0 - POR CONTA DO EMITENTE
							</option>
							<option :value="1">
								1 - POR CONTA DESTINATÁRIO/REM
							</option>
							<option :value="2">
								2 - POR CONTA DE TERCEIROS
							</option>
							<option :value="9">
								9 - SEM FRETE
							</option>
						</select>
						<label for="tipoFrete">Frete:</label>
					</div>
					<div class="input-group" style="width:30%">
						<input type="text" id="cpfTransporte" v-model="nota.cpfTransporte" readonly/>
						<label for="cpfTransporte">
							CNPJ/CPF:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width:50%">
						<input type="text" id="enderecoTransporte" v-model="nota.enderecoTransporte" readonly/>
						<label for="enderecoTransporte">
							Endereço:
						</label>
					</div>
					<div class="input-group" style="width:20%">
						<input type="text" id="municipioTransporte" v-model="nota.municipioTransporte" readonly/>
						<label for="municipioTransporte">
							Município:
						</label>
					</div>
					<div class="input-group" style="width:60px">
						<input type="text" id="ufTransporte" v-model="nota.ufTransporte" readonly/>
						<label for="ufTransporte">
							UF:
						</label>
					</div>
					<div class="input-group" style="width:20%">
						<input type="text" id="ieTransporte" v-model="nota.ieTransporte" readonly/>
						<label for="ieTransporte">
							Inscrição Estadual:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group" style="width:100%">
						<input type="text" id="quantidade" v-model="nota.quantidade" @keydown.enter="proximoCampo('especie')"/>
						<label for="quantidade">
							Quantidade:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="especie" v-model="nota.especie" @keydown.enter="proximoCampo('marca')"/>
						<label for="especie">
							Espécie:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="marca" v-model="nota.marca" @keydown.enter="proximoCampo('numeracao')"/>
						<label for="marca">
							Marca:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="numeracao" v-model="nota.numeracao" @keydown.enter="proximoCampo('pesoBruto')"/>
						<label for="numeracao">
							Numeração:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="pesoBruto" v-model="nota.pesoBruto" @keydown.enter="proximoCampo('pesoLiquido')"/>
						<label for="pesoBruto">
							Peso Bruto:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="pesoLiquido" v-model="nota.pesoLiquido" @keydown.enter="proximoCampo('codigoProduto')"/>
						<label for="pesoLiquido">
							Peso Líquido:
						</label>
					</div>
				</div>
				<label style="margin-left: 10px;">
					Dados dos Produtos/Serviços:
				</label>
				<div class="row" style="align-items: center;">
					<button class="botao botao-acao" @click="modalProdutos = true">
							Adicionar Produto
					</button>
				</div>
				<table style="margin-left:5px;margin-right: 5px">
					<thead>
						<tr>
							<th>CÓDIGO</th>
							<th>DESCRIÇÃO PRODUTO/SERVIÇO</th>
							<th>NCM/SH</th>
							<th>O/CST</th>
							<th>CFOP</th>
							<th>UN</th>
							<th>QUANT</th>
							<th>VALOR UNIT</th>
							<th>VALOR TOTAL</th>
							<th>VALOR DESC</th>
							<th>B. CÁLC ICMS</th>
							<th>VALOR ICMS</th>
							<th>VALOR IPI</th>
							<th>ALÍQ ICMS</th>
							<th>ALÍQ IPI</th>
							<th>AÇÃO</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produto, index) in produtos" :key="produto.codigo">
							<td>{{produto.codigo}}</td>
							<td>{{produto.descricao}}</td>
							<td>{{produto.ncm}}</td>
							<td>{{produto.ocst}}</td>
							<td>{{produto.cfop}}</td>
							<td>{{produto.unidade}}</td>
							<td>{{produto.quantidade}}</td>
							<td>{{produto.valorUnitario}}</td>
							<td>{{produto.valorTotal}}</td>
							<td>{{produto.valorDesc}}</td>
							<td>{{produto.baseCalculo}}</td>
							<td>{{produto.valorICMS}}</td>
							<td>{{produto.valorIPI}}</td>
							<td>{{produto.aliqICMS}}</td>
							<td>{{produto.aliqIPI}}</td>
							<td style="display:flex;flex-direction: row"><i class="fas fa-edit" style="color: var(--confirmar)" @click="editarProduto(produto, index)"></i><i class="fas fa-trash" style="color: var(--deletar)" @click="removeProduto(index)"></i></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<Modal
			v-if="modalProdutos"
			@fecharModal="modalProdutos = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'lista-produtos'"
		>
			<CadastroProduto 
				v-if="novoProduto" 
				:Produto="novoProduto" 
				@cancelarEdicao="novoProduto = null" 
			/>
			<consulta-produtos
				:Produtos="Produtos"
				:mostrarBotaoNovo="true"
				@adicionarNovo="novoProduto = true"
				@selecionarProduto="selecionarProduto"
				:height="'65vh'"
				v-else
			/>
			
		</Modal>
		<Modal
			v-if="modalProdutoTributacao"
			@fecharModal="modalProdutoTributacao = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'tributacao-produto'"
		>
			<div class="col" style="align-items: flex-start;gap:10px">
				<label style="margin-left:10px">
					Dados do Produto:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100px">
						<input type="text" id="codigo" v-model="produtoTributacao.codigo" readonly/>
						<label for="codigo">
							Codigo:
						</label>
					</div>
					<div class="input-group" style="width:70%">
						<input type="text" id="descricao" v-model="produtoTributacao.descricao" readonly/>
						<label for="descricao">
							Descrição:
						</label>
					</div>
					<div class="input-group" style="width:100px">
						<input type="text" id="ncm" v-model="produtoTributacao.ncm" @keydown.enter="proximoCampo('un')"/>
						<label for="ncm">
							NCM:
						</label>
					</div>
					<div class="input-group" style="width:100px">
						<input type="text" id="un" v-model="produtoTributacao.unidade" @keydown.enter="proximoCampo('quantidadeTributacao')"/>
						<label for="un">
							UN:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="quantidadeTributacao" v-model="produtoTributacao.quantidade" @keydown.enter="proximoCampo('valorUnitario')"/>
						<label for="quantidadeTributacao">
							Quantidade:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorUnitario" v-model="produtoTributacao.valorUnitario" @keydown.enter="proximoCampo('valorTotal')"/>
						<label for="valorUnitario">
							Valor Unitario:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorTotal" v-model="produtoTributacao.valorTotal" @keydown.enter="proximoCampo('CST')"/>
						<label for="valorTotal">
							Valor Total:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					Tributações Estaduais:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="CST" v-model="produtoTributacao.cst" @keydown.enter="proximoCampo('reducaoBC')"/>
						<label for="CST">
							CST:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="reducaoBC" v-model="produtoTributacao.reducaoBC" @keydown.enter="proximoCampo('baseCalculo')"/>
						<label for="reducaoBC">
							Redução BC:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="baseCalculo" v-model="formatedProdutoTributacaoBaseCalculo" @keydown.enter="proximoCampo('porcentagemICMS')" v-mascaraFloat/>
						<label for="baseCalculo">
							Base Cálculo:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="porcentagemICMS" v-model="formatedProdutoTributacaoAliqICMS" @keydown.enter="proximoCampo('valorICMSTributacao')"/>
						<label for="porcentagemICMS">
							% ICMS:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorICMSTributacao" v-model="formatedProdutoTributacaoValorICMS" @keydown.enter="proximoCampo('basePis')" v-mascaraFloat/>
						<label for="valorICMSTributacao">
							Valor ICMS:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					Tributações Federais:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="basePis" v-model="produtoTributacao.basePis" @keydown.enter="proximoCampo('baseIPI')"/>
						<label for="basePis">
							Base Pis/Cofins:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="baseIPI" v-model="produtoTributacao.baseIPI" @keydown.enter="proximoCampo('enquadramento')"/>
						<label for="baseIPI">
							Base IPI:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="enquadramento" v-model="produtoTributacao.enquadramento" @keydown.enter="proximoCampo('cstPis')"/>
						<label for="enquadramento">
							Enquadramento:
						</label>
					</div>
				</div>				
				<label style="margin-left:10px">
					Pis:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstPis" v-model="produtoTributacao.cstPis" @keydown.enter="proximoCampo('aliqPis')"/>
						<label for="cstPis">
							CST:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqPis" v-model="produtoTributacao.aliqPis" @keydown.enter="proximoCampo('pis')"/>
						<label for="aliqPis">
							Aliquota:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="pis" v-model="produtoTributacao.pis" @keydown.enter="proximoCampo('cstCofins')"/>
						<label for="pis">
							Valor:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					Cofins:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstCofins" v-model="produtoTributacao.cstCofins" @keydown.enter="proximoCampo('aliqCofins')"/>
						<label for="cstCofins">
							CST:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqCofins" v-model="produtoTributacao.aliqCofins" @keydown.enter="proximoCampo('cofins')"/>
						<label for="aliqCofins">
							Aliquota:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="cofins" v-model="produtoTributacao.cofins" @keydown.enter="proximoCampo('cstIpi')"/>
						<label for="cofins">
							Valor:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					IPI:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstIpi" v-model="produtoTributacao.cstIPI" @keydown.enter="proximoCampo('aliqIpi')"/>
						<label for="cstIpi">
							CST:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqIpi" v-model="produtoTributacao.aliqIPI" @keydown.enter="proximoCampo('valorIpi')"/>
						<label for="aliqIpi">
							Aliquota:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorIpi" v-model="produtoTributacao.valorIPI"/>
						<label for="valorIpi">
							Valor:
						</label>
					</div>
					
				</div>
				<div class="botoes">
					<button class="botao botao-cancelar">
						<span>Cancelar</span>
					</button>
					<button class="botao botao-adicionar" @click="salvarProdutoTributacao">
						<span>Salvar</span>
					</button>
				</div>
			</div>
		</Modal>
		<Modal 
			v-if="listaCfop"
			@fecharModal="listaCfop = false"
		>
			<ConsultaCFOP @escolheucfop="escolheCfop" />
		</Modal>
		<Modal v-if="listarClientes" @fecharModal="listarClientes = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<consultaCliente  
				@selecionarPessoa="selecionarCliente" 
				@adicionarNovo="cadastrarCliente = true"
				:mostrarBotaoNovo="true"
				v-if="!cadastrarCliente"
			/>
			<CadastroCliente @cancelarEdicao="cadastrarCliente = false" v-else :cliente="{novo: true}"/>
		</Modal>
		<Modal v-if="listarClientesTransporte" @fecharModal="listarClientesTransporte = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<consultaCliente  
				@selecionarPessoa="selecionarClienteTransporte" 
				@adicionarNovo="cadastrarClienteTransporte = true"
				:mostrarBotaoNovo="true"
				v-if="!cadastrarClienteTransporte"
			/>
			<CadastroCliente @cancelarEdicao="cadastrarClienteTransporte = false" v-else :cliente="{novo: true}"/>
		</Modal>
		<div class="botoes">
			<button class="botao botao-cancelar">
				<span class="lg-view">
					Cancelar
				</span>
				<span class="sm-view">
					Cancelar
				</span>
			</button>
			<button class="botao botao-adicionar">
				<span class="lg-view">
					Salvar [Alt + S]
				</span>
				<span class="sm-view">
					Salvar
				</span>
			</button>
		</div>
	</div>
</template>

<script>
import Modal from "../components/Modal";
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import { FormacaoPreco } from "../models/Produto.js";
import { salvarImagem } from "../utils/drive.js";
import CadastroProduto from "./CadastroProduto.vue";
import ConsultaCFOP from "./ConsultaCFOP.vue";
import ConsultaCliente from "./ConsultaCliente.vue";
import CadastroCliente from "./CadastroCliente.vue";

export default {
	name: "CadastroNFE",
	props: {
		PropProduto: Object,
		Fornecedor: Object
	},

	components: {
		Modal,
		ConsultaProdutos,
		CadastroProduto,
		ConsultaCFOP,
		ConsultaCliente,
		CadastroCliente,
	},
	data(){
		return {
			nota:{
				cfop:"",
				natureza:"",
				nomeCliente:"",
				cpfCliente:"",
				dataEmissao: new Date().toLocaleDateString("en-CA"),
				enderecoCliente:"",
				bairroCliente:"",
				cepCliente:"",
				dataMovimentacao: new Date().toLocaleDateString("en-CA"),
				municipioCliente:"",
				ufCliente:"",
				foneCliente:"",
				ieCliente:"",
				horaMovimentacao:new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
				baseCalcICMS:"",
				valorICMS:"",
				baseCalcICMSST:"",
				valorICMSSub:"",
				valorImpImportacao:"",
				vICMSUFRemet:"",
				vFCPUFDest:"",
				valorPis:"",
				vTotalProdutos:"",
				valorDoFrete:"",
				valorDoSeguro:"",
				desconto:"",
				outrasDespesas:"",
				valorTotalIPI:"",
				vICMSUFDest:"",
				vTotTrib:"",
				valorCofins:"",
				vTotalNota:"",
				nomeTransporte:"",
				tipoFrete:"0",
				cpfTransporte:"",
				enderecoTransporte:"",
				municipioTransporte:"",
				ufTransporte:"",
				ieTransporte:"",
				quantidade:"",
				especie:"",
				marca:"",
				pesoBruto:"",
				pesoLiquido:"",
			},
			produtos:[

			],
			codigoProduto:null,
			novoProduto:null,
			modalProdutos:false,
			modalProdutoTributacao:false,
			produtoTributacao:null,
			indiceProdutoTributacao:null,
			listaCfop:false,
			listarClientes:false,
			cadastrarCliente:false,
			cliente:null,
			listarClientesTransporte:false,
			cadastrarClienteTransporte:false,
			clienteTransporte:null,
		};
	},

	computed: {
		Produtos() {
			return this.$store.state.Produtos;
		},
		formatedProdutoTributacaoValorICMS:{
			get(){
				return this.produtoTributacao.valorICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorICMS = numericValue;
				}
			}
		},
		formatedProdutoTributacaoBaseCalculo:{
			get(){
				return this.produtoTributacao.baseCalculo.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.baseCalculo = numericValue;
				}
			}
		},
		formatedProdutoTributacaoAliqICMS:{
			get(){
				return this.produtoTributacao.aliqICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.aliqICMS = numericValue;
				}
			}
		},
	},
	watch:{
		produtos(){
			this.nota.baseCalcICMS=0;
			this.nota.valorICMS=0;
			this.nota.valorPis=0;
			this.nota.vTotalProdutos=0;
			this.nota.valorTotalIPI=0;
			// 	baseCalcICMSST:"",
			// 	valorICMSSub:"",
			for(const produto of this.produtos){
				this.nota.baseCalcICMS+=Number(produto.baseCalculo);
				this.nota.valorICMS+=Number(produto.valorICMS);
				this.nota.valorPis+=Number(produto.pis);
				this.nota.valorCofins+=Number(produto.cofins);
				this.nota.vTotalProdutos+=Number(produto.valorTotal);
				this.nota.valorTotalIPI+=Number(produto.valorIpi);
			}
		},
		"produtoTributacao.valorUnitario":function (newVal){
			this.produtoTributacao.valorTotal = this.produtoTributacao.quantidade * newVal;
		},
		"produtoTributacao.quantidade":function (newVal){
			this.produtoTributacao.valorTotal = newVal * this.produtoTributacao.valorUnitario;
		},
		"produtoTributacao.valorTotal":function (newVal){
			this.produtoTributacao.valorUnitario = newVal/this.produtoTributacao.quantidade;
		},
		"produtoTributacao.aliqPis":function (newVal){
			this.produtoTributacao.pis = this.produtoTributacao.basePis * (newVal/100);
		},
		"produtoTributacao.aliqCofins":function (newVal){
			this.produtoTributacao.cofins = this.produtoTributacao.basePis * (newVal/100);
		},
		"produtoTributacao.basePis":function (newVal){
			this.produtoTributacao.pis = newVal * (this.produtoTributacao.aliqPis/100);
			this.produtoTributacao.cofins = newVal * (this.produtoTributacao.aliqCofins/100);
		},
		"produtoTributacao.aliqIPI":function (newVal){
			this.produtoTributacao.valorIPI = this.produtoTributacao.baseIPI * (newVal/100);
		},
		"produtoTributacao.baseIPI":function (newVal){
			this.produtoTributacao.valorIPI = newVal * (this.produtoTributacao.aliqIPI/100);
		},
		"produtoTributacao.aliqICMS":function (newVal){
			this.produtoTributacao.valorICMS = this.produtoTributacao.baseCalculo * (newVal/100);
		},
		"produtoTributacao.baseCalculo":function (newVal){
			this.produtoTributacao.valorICMS = newVal * (this.produtoTributacao.aliqICMS/100);
		}
	},
	beforeMount() {
		window.addEventListener("click", this.handleClickOutside);
	},
	mounted(){
	},
	destroyed(){
		window.removeEventListener("click", this.handleClickOutside);
	},
	methods: {
		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
					}
					if(input.select){
						input.select();
					}
				},20);
			} catch (error) {
				console.log(error);
			}
		},
		removeProduto(indice){
			this.produtos.splice(indice,1);
		},
		async digitouCFOP(){
			if(this.nota.cfop.length == 4){
				const response = await this.$store.dispatch("buscarUnicoCFOP", this.nota.cfop);
				if(response){
					this.nota.natureza = response.descricao;
				}
				else{
					this.nota.natureza = "";
				}
			}
		},
		validarCampos() {//alterar
			try {
				if (this.produtoCadastrado.grupoSelecionado.novo) {
					throw new Error("Favor selecionar um grupo!");
				}
				if (!this.produtoCadastrado.descricao || !`${this.produtoCadastrado.descricao}`.trim().length) {
					throw new Error("Favor preencher a descrição do produto!");
				}
				if (!this.produtoCadastrado.unidade || !`${this.produtoCadastrado.unidade}`.trim().length) {
					throw new Error("Favor preencher a unidade do produto com até 3 (três) letras!");
				}
				if (!this.produtoCadastrado.embalagem || !`${this.produtoCadastrado.embalagem}`.trim().length) {
					throw new Error("Favor preencher a embalagem do produto com até 3 (três) letras!");
				}
				if (!this.produtoCadastrado.qteEmbalagem || !`${this.produtoCadastrado.qteEmbalagem}`.trim().length) {
					throw new Error("Favor preencher a quantidade da embalagem do produto!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
					alerta: true,
				});
				return false;
			}
		},
		async adicionarProduto() {//alterar
			if (!this.validarCampos()) return;
			let formacaoPreco = {};
			formacaoPreco[this.Empresa.id] = new FormacaoPreco({...this.PropProduto, ...this.produtoCadastrado});
			let ncm = null;
			if (this.produtoCadastrado.subGrupoSelecionado?.ncm) {
				ncm = this.produtoCadastrado.subGrupoSelecionado.ncm;
			} else if (this.produtoCadastrado.grupoSelecionado?.ncm) {
				ncm = this.produtoCadastrado.grupoSelecionado.ncm;
			}
			let produto = null;
			if (!this.PropProduto || this.PropProduto?.novo || !this.PropProduto?.id || !this.produtoCadastrado.cdproduto) {
				produto = await this.dispararAcao(
					{
						acao: "cadastrarProduto", 
						payload: {
							...this.$store.state.produtoSelecionado,
							...this.produtoCadastrado,
							cdgrupo: this.populaGrupo(),
							cdsubgrupo: this.populaSubgrupo(),
							ativo: this.produtoCadastrado.ativo ? "A" : "I",
							servprod: this.produtoCadastrado.servprod ? "P" : "S",
							geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N",
							mostracardapio: this.produtoCadastrado.mostracardapio ? "S" : "N",
							formacaoPreco,
							ncm,
							cdpai: null,
							pai: null,
							cdagrupamento: null,
						}
					}
				);
				if (!produto.id) return;
				if(this.imagemProduto){
					try{
						this.$store.state.carregando = true;
						const data = await salvarImagem(produto.id,this.imagemProduto);
						if(data.idImagem){
							this.produtoCadastrado.idImagem = data.idImagem;
						}
						this.$store.state.carregando = false;
					}
					catch (e){
						this.$store.state.carregando = false;
						return this.$store.commit("relatarErro", {mensagem: "Houve um erro ao salvar a imagem tente novamente ou entre em contato com o suporte!"});
					}
					await this.dispararAcao(
						{
							acao: "atualizarProduto",
							payload: {
								...this.$store.state.produtoSelecionado,
								...this.produtoCadastrado,
								ativo: this.produtoCadastrado.ativo ? "A" : "I",
								servprod: this.produtoCadastrado.servprod ? "P" : "S",
								geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N",
								mostracardapio: this.produtoCadastrado.mostracardapio ? "S" : "N",
								formacaoPreco,
								ncm,
							}
						}
					);
				}
				this.produtoCadastrado.cdproduto = produto.id;
				this.produtoCadastrado.codBarra = produto.codBarra;
				this.produtoCadastrado.codinter = produto.codinter;
			} else {	
				if(this.imagemProduto){
					try{
						this.$store.state.carregando = true;
						const data = await salvarImagem(this.produtoCadastrado.cdproduto,this.imagemProduto);
						if(data.idImagem){
							this.produtoCadastrado.idImagem = data.idImagem;
						}
						this.$store.state.carregando = false;
					}
					catch (e){
						this.$store.state.carregando = false;
						return this.$store.commit("relatarErro", {mensagem: "Houve um erro ao salvar a imagem tente novamente ou entre em contato com o suporte!"});
					}					
				}
				produto = await this.dispararAcao(
					{
						acao: "atualizarProduto",
						payload: {
							...this.$store.state.produtoSelecionado,
							...this.produtoCadastrado,
							ativo: this.produtoCadastrado.ativo ? "A" : "I",
							servprod: this.produtoCadastrado.servprod ? "P" : "S",
							geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N",
							mostracardapio: this.produtoCadastrado.mostracardapio ? "S" : "N",
							formacaoPreco,
							ncm,
						}
					}
				);
				if (!produto.id) return;
			}
			await this.bloqueiaAlterarCampos();
			await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: produto.id});
			setTimeout(() => {
				this.$emit("salvarProduto");
			}, 50);
		},
		async iniciarData() {
			this.$store.state.carregando = true;
			setTimeout(async () => {
				if (this.Fornecedor?.id) {
					this.produtoCadastrado.fornecedor = {...this.Fornecedor};
				}
				if ((this.PropProduto && !this.PropProduto?.novo) || (this.PropProduto?.novo && this.PropProduto?.duplicando)) {
					let grupoBanco = null;
					if (this.PropProduto.cdgrupo) {
						grupoBanco = await this.verificarGrupo();
					}
					this.produtoCadastrado = this.preencherProdutoProp(grupoBanco);
					if(this.produtoCadastrado.idImagem !== ""){
						this.imagemProdutoSrc = "https://drive.google.com/thumbnail?id="+this.produtoCadastrado.idImagem;
					}
					this.proximoCampo("cdproduto");
				} else {
					this.proximoCampo("buscaGrupo");
					this.produtoCadastrado.subGrupoSelecionado.nenhumSelecionado = true;
				}
				if(!this.PropProduto?.novo){
					const input = document.getElementById("cdbarrafornecedor");
					input.disabled = true;
				}
				this.bloqueiaAlterarCampos();
				this.$store.state.carregando = false;
			}, 500);
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		selecionarProduto(produto){
			this.modalProdutos = false;
			this.produtoTributacao = {
				codigo:produto.id,
				descricao:produto.descricao,
				ncm:produto.ncm,
				unidade:produto.unidade,
				quantidade:1,
				valorUnitario:produto.valorPrazo,
				valorTotal:1*produto.valorPrazo,
				cst:0,
				reducaoBC:0,
				baseCalculo:0,
				aliqICMS:0,
				valorICMS:0,
				basePis:0,
				baseIPI:0,
				enquadramento:0,
				cstPis:0,
				aliqPis:0,
				pis:0,
				cstCofins:0,
				aliqCofins:0,
				cofins:0,
				cstIPI:0,
				aliqIPI:0,
				valorIPI:produto.valoripi || 0,
				

				cfop:this.nota.cfop,

				valorDesc:0,
				
			};
			this.modalProdutoTributacao = true;
		},
		editarProduto(produto,index){
			this.produtoTributacao = produto;
			this.indiceProdutoTributacao = index;
			this.modalProdutoTributacao = true;
		},
		salvarProdutoTributacao(){	
			console.log(this.indiceProdutoTributacao,!this.indiceProdutoTributacao);
			if(!this.indiceProdutoTributacao && this.indiceProdutoTributacao!=0){
				this.produtos.push(this.produtoTributacao);
			}
			else{
				this.produtos[this.indiceProdutoTributacao]=this.produtoTributacao;
			}
			this.modalProdutoTributacao = false;
			this.indiceProdutoTributacao = null;
		},
		escolheCfop(cfop){
			this.nota.cfop = cfop.cfop;
			this.nota.natureza = cfop.descricao;
			this.listaCfop = false;
		},
		selecionarCliente(cliente){
			this.cliente = cliente;
			this.listarClientes = false;
			this.nota.nomeCliente=cliente.nome;
			this.nota.cpfCliente=cliente.cnpj;
			this.nota.enderecoCliente=cliente.endereco;
			this.nota.bairroCliente=cliente.bairro;
			this.nota.cepCliente=cliente.cep;
			this.nota.municipioCliente=cliente.cidade.nomecidade;
			this.nota.ufCliente=cliente.cidade.uf;
			this.nota.foneCliente=cliente.fone;
			this.nota.ieCliente=cliente.ierg;
			setTimeout(() => {
				document.getElementById("dataEmissao").focus();
				document.getElementById("dataEmissao").select();
			}, 50);
		},
		selecionarClienteTransporte(cliente){
			this.clienteTransporte = cliente;
			this.listarClientesTransporte = false;
			this.nota.nomeTransporte=cliente.nome;
			this.nota.cpfTransporte=cliente.cnpj;
			this.nota.enderecoTransporte=cliente.endereco;
			this.nota.municipioTransporte=cliente.cidade.nomecidade;
			this.nota.ufTransporte=cliente.cidade.uf;
			this.nota.ieTransporte=cliente.ierg;
			setTimeout(() => {
				document.getElementById("tipoFrete").focus();
				document.getElementById("tipoFrete").select();
			}, 50);
		},
	},
};
</script>

<style scoped>
.container-page{
	height: 75vh;
	width: 100%;
	overflow-y: auto;
	padding-bottom: 10px;
}
.container-form{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	gap:15px;
}
.botoes{
	gap: 40px;
}
.botoes button{
	width: 45%;
}
.sm-view {
	display: none;
}
/* MEDIA QUERIES */
@media screen and (max-width: 1260px){
	.input-group{
		width: auto;
		height: 30px;
		position: relative;
		border: 3px solid var(--border);
		border-radius: 8px;
		margin: 0px 10px;
	}
	.input-group label{
		font-size: 14px;
		padding: 5px !important;
		line-height: 10PX !important;
	}
	.input-group input{
		padding: 5px;
		height: 30px;
		font-size: 14px;
		line-height: 22px;

	}
	
}
@media screen and (max-width: 1080px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.input-group {
		width: 47%;
	}
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	#adicionarBotao {
		top: 2px;
		left: 92%;
	}
	#editarBotao {
		left: 88%;
		top: 4px;
	}
	#removerBotao {
		left: 94%;
		top: 4px;
	}
}

@media screen and (max-width: 440px) {
	.botao i{
		display: none;
	}
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.input-group {
		width: 44%;
		height: 100%;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	#adicionarBotao {
		top: 4px;
		left: 85%;
		transform: scale(1.1);
	}
	#editarBotao {
		left: 70%;
		top: 7px;
		transform: scale(1.3);
	}
	#removerBotao {
		left: 85%!important;
		top: 7px;
		transform: scale(1.3);
	}
}

@media screen and (max-width: 425px){	
	.container-form{
		grid-template-columns: auto 200px;
		gap: 10px;
	}
	.input-group{
		margin: 0px 5px;
	}
	.agroup{
		gap: 25px;
	}
	
	.input-group-switch label{
		font-size: 14px;
	}
	.track{
		width: 65px !important;
	}
	.esconder-input:checked + .thumb-container{
		transform: translateX(120%) !important;
	}
	.botoes{
		margin-top: 25px;
	}
	#removerBotao{
		position: absolute;
		width: 10%;
		top: 5px;
		left: 75%;
	}
	#editarBotao{
		position: absolute;
		width: 10%;
		top: 5px;
		left: 85%;
	}
	#adicionarBotao{
		position: absolute;
		width: 10%;
		top: 3px;
		left: 80%;
	}
}
@media screen and (max-width: 425px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.input-group {
		width: 46%;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	#adicionarBotao {
		top: 4px;
		left: 85%;
		transform: scale(1.1);
	}
	#editarBotao {
		left: 70%;
		top: 7px;
		transform: scale(1.3);
	}
	#removerBotao {
		left: 85%;
		top: 7px;
		transform: scale(1.3);
	}
}
@media screen and (max-width: 400px) {
	.botao i{
		display:none;
	}
}
@media screen and (max-width: 375px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.input-group {
		width: 45%;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	#adicionarBotao {
		top: 4px;
		left: 83%;
		transform: scale(1.1);
	}
	#editarBotao {
		left: 68%;
		top: 7px;
		transform: scale(1.3);
	}
	#removerBotao {
		left: 85%!important;
		top: 7px;
		transform: scale(1.3);
	}
}
@media screen and (max-width: 320px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.input-group {
		width: 45%;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	#adicionarBotao {
		top: 4px;
		left: 80%;
		transform: scale(1.1);
	}
	#editarBotao {
		left: 65%;
		top: 7px;
		transform: scale(1.3);
	}
	#removerBotao {
		left: 85%;
		top: 7px;
		transform: scale(1.3);
	}
}
</style>