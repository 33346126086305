<template>
	<div class="containerPage">
		<div class="containerMain">
			<div class="containerDividido" v-if="!grupoSelecionado">
				<h3 class="titleContainer">SELECIONE O GRUPO</h3>
				<div class="containerContent">
					<div class="containerCards" v-if="!cardLoadingGrupo">
						<CardGrupoProduto 
							v-for="(grupo, index) in Grupos" 
							:key="index"
							:ativo="grupoSelecionado ? grupoSelecionado.cdGrupo === grupo.cdGrupo ? true : false : false"
							:item="grupo"
							:type="'Grupo'"
							@selecionouGrupo="selecionouGrupo"					
						/>
						<ArrowNavigationGrupoProduto 
							:ArrayItens="Grupos"
							:selectedItem="grupoSelecionado"
							@selecionouGrupo="selecionouGrupo"
							:type="'Grupo'"
						/>
					</div>

					<div class="containerCards loaderCenter" v-else>
						<Loader />
					</div>
					
				</div>
			</div>
			<div class="containerDividido" v-else>
				<h3 class="titleContainer">
					SELECIONE O PRODUTO POR GRUPO
				</h3>
				<div class="containerContent">
					<transition name="fade" mode="out-in">
						<div class="containerCards" v-if="!cardLoadingProduto">
							<CardGrupoProduto 
								v-for="produto in ListaProdutosGrupo" 
								:key="produto.id"
								:ativo="produtoSelecionado.id === produto.id ? true : false"
								:item="produto"
								:type="'Produto'"
								@selecionouProduto="selecionouProduto"					
							/>
						</div>
						<div class="containerCards loaderCenter" v-else>
							<Loader />
						</div>
					</transition>
				</div>
				<div class="containerButtons">
					<button class="botao botao-acao" @click="grupoSelecionado=null">
						Voltar
					</button>
					<button class="botao botao-adicionar" @click="handleAddProduct">
						Adicionar
					</button>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="containerContent containerProdutosPedido">
				<h3 class="titleContainer">PRODUTOS NO PEDIDO</h3>
				<div class="containerTabela">
					<table v-if="produtosPedido.length > 0">
						<thead>
							<tr>
								<th>
									Produto
								</th>
								<th class="text-center">
									Qtd.
								</th>
								<th class="text-center">
									$ Unitário
								</th>
								<th class="text-center">
									Ação
								</th>
							</tr>
						</thead>
						<tbody>
							<tr 
								v-for="(produto, index) in produtosPedido"
								:key="index"
							>
								<td>
									{{ produto.descricao }} 
								</td>
								<td class="text-center" @click="handleEditProduct(produto, index)">
									{{ produto.quantidade || 1 }} 
								</td>
								<td class="text-center">
									{{ (produto.valorUnitario || 0) | formatarParaReais }} 
								</td>
								<td class="text-center">
									<i 
										style="color: var(--deletar)"
										class="fas fa-trash" 
										@click="removeProduct(index)" 
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				
			</div>
			<div class="containerTotalizadores">
				<div class="input-group">
					<input 
						type="text" 
						:value="TotalQuantidade" 
						readonly
						id="totalQtd"
						placeholder=" "
					/>
					<label for="totalQtd">
						Total Qtd.
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						:value="(TotalValor || 0) | formatarParaReais" 
						readonly
						id="totalValor"
						placeholder=" " 
					/>
					<label for="totalValor">
						$ Total
					</label>
				</div>
			</div>
			<div class="containerButtons">
				<button class="botao botao-adicionar" @click="finalizarPedido">
					Finalizar
				</button>
			</div>
		</div>
		<Modal 
			:dimensoes="{width: '80%', height: 'auto'}" 
			v-if="modalSugestoes"
			@fecharModal="closeModal"
		>
			<ProdutoSugestao 
				:produtoSelecionado="produtoSelecionado"
				@saveItem="handleAddSugestaoProduct"
				@cancelItem="closeModal"
			/>
		</Modal>
		<Modal
			:dimensoes="{width: 'auto', height: 'auto'}"
			v-if="modalQuantidade"
			@fecharModal="modalQuantidade = false"
			id="modalQuantidade"
		>
			<div class="modal-container-quantidade">
				<div class="modal-content-quantidade">
					<h3>Quantidade</h3>
					<div class="container-input-buttons-quantidade">
						<button 
							class="botao botao-cancelar" 
							@mousedown="pressionandoBotaoPlussMinus('menos')"
							@click="alterarQuantidadeProduto('menos')"
							@mouseup="clearIntervalAlterarQuantidade"
						>
							<i class="fas fa-minus" style="color: var(--cancelar-borda)" />
						</button>
						<input 
							type="text" 
							id="qtd-input" 
							v-model="produtoSelecionado.quantidade" 
							v-mascaraInteger
						/>
						<button 
							class="botao botao-adicionar" 
							@mousedown="pressionandoBotaoPlussMinus('mais')"
							@click="alterarQuantidadeProduto('mais')"
							@mouseup="clearIntervalAlterarQuantidade"

						>
							<i class="fas fa-plus" style="color: var(--salvar)" />
						</button>
					</div>
					&nbsp;
					<div class="container-input-buttons-quantidade"> 
						<button 
							class="botao botao-adicionar" 
							@click="modalQuantidade = false"
							@keydown.enter.prevent="modalQuantidade = false"
						>
							Salvar
						</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import Modal from "../components/Modal";
import ProdutoSugestao from "../components/ProdutoSugestao";
import Loader from "../components/Loader";
import formatarParaReais from "../filters/formatarParaMoeda";
import CardGrupoProduto from "../components/CardGrupoProduto";
import ArrowNavigationGrupoProduto from "../components/ArrowNavigationGrupoProduto";


export default {
	name: "LancamentoLanchonete",

	data() {
		return {
			grupoSelecionado: null,
			ListaProdutosGrupo: [],
			produtoSelecionado: {},
			produtosPedido: [],

			modalSugestoes: false,
			sugestoesEditadas: [],

			modalQuantidade: false,

			intervalQuantidade: null,
			cardLoadingProduto: false,
			cardLoadingGrupo: true,
		};
	},

	components: {
		Modal,
		ProdutoSugestao,
		CardGrupoProduto,
		ArrowNavigationGrupoProduto,
		Loader
	},

	computed: {
		Venda() {
			return this.$store?.state?.Venda;
		},
		Grupos() {
			return this.$store?.state?.grupoProduto;
		},
		TotalQuantidade() {
			let qtd = 0;
			if(this.produtosPedido?.forEach) {
				this.produtosPedido.forEach(prod => {
					qtd += prod.quantidade;
				});
			}
			return qtd;
		},
		TotalValor() {
			let total = 0;
			this.produtosPedido.forEach(prod => {
				total += (prod.valorUnitario * prod.quantidade);
			});
			return total;
		},

		itensVenda() {
			return this.$store?.state?.itensVenda;
		},
	},

	filters: {
		formatarParaReais
	},

	mounted() {
		if(this.itensVenda?.forEach) {
			this.itensVenda.forEach(item => {
				this.adicionarProduto(item);
			});
		}

		setTimeout(async () => {
			await this.$store.dispatch("buscarGrupos");
			this.cardLoadingGrupo = false;
		}, 50);

	},


	methods: {
		clearIntervalAlterarQuantidade() {
			clearInterval(this.invervalQuantidade);
		},

		pressionandoBotaoPlussMinus(operacao){
			this.invervalQuantidade = setInterval(() => {
				this.alterarQuantidadeProduto(operacao);
			}, 100);
		},

		alterarQuantidadeProduto(operacao) {
			if(operacao === "menos") {
				this.produtoSelecionado.quantidade -= 1;
				if(this.produtoSelecionado.quantidade < 1) {
					this.produtoSelecionado.quantidade = 1;
					this.$forceUpdate();
				}
			} else {
				this.produtoSelecionado.quantidade++;
				this.$forceUpdate();
			}
		},

		verificarTecla(e) {
			try {
				if(e.key !== "ArrowUp" && e.key !== "ArrowDown" && e.key !== "Enter") return;
				if(this.modalQuantidade) {
					if(e.key === "Enter") {
						this.modalQuantidade = false;
						return;
					}
					if(e.key === "ArrowUp") {
						this.alterarQuantidadeProduto("mais");
						return;
					}
					if(e.key === "ArrowDown") {
						this.alterarQuantidadeProduto("menos");
						return;
					}	
				}


			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}			
		},

		handleEditProduct(product, index) {
			this.produtoSelecionado = product;
			this.produtoSelecionado.index = index;
			if(this.produtoSelecionado?.sugestoes?.length > 0) {

				this.buscarKit(this.produtoSelecionado.id);

				this.produtoSelecionado.quantidade = 0;

				this.produtoSelecionado.sugestoes.forEach(sugestao => {
					if(sugestao.quantidade > 0) {
						this.produtoSelecionado.quantidade += (sugestao.quantidade * sugestao.multiplo);
					}
				});

				this.modalSugestoes = true;

				return;
			}

			this.abrirModalQuantidade();

		},

		abrirModalQuantidade() {
			this.modalQuantidade = true;
			window.addEventListener("keydown", this.verificarTecla);

		},

		async finalizarPedido() {
			try {
				if(this.produtosPedido?.length === 0) {
					throw new Error("Não é possivél finalizar um pedido sem produtos");
				}
				const cliente = await this.$store.dispatch("buscarClientePadrao");
	
				this.produtosPedido?.forEach((prod, index) => {
					prod.cdseq = index + 1;
					prod.cdtipo = "S";
					if(prod.sugestoes?.length > 0) {
						prod.sugestoes.forEach((sugestao) => {
							sugestao.cdseq = prod.cdseq;
						});
					}
				});
				const form = {
					...this.Venda,
					cliente: this.Venda?.cliente || cliente,
					itens: this.produtosPedido,
					vendedor: {
						id: this.$store.state.Usuario?.id,	
					},
					cdEmpresa: this.Venda?.cdEmpresa ? this.Venda?.cdEmpresa : this.$store.state.Empresa?.id,
					valorbruto: this.TotalValor,
					financeiro:  this.Venda?.financeiro ? this.Venda?.financeiro : [],
					cdIdentificador: 0,
					cdvenda: null,
					nomeCliente: this.Venda?.nomeCliente,
				};
				if(!this.Venda?.cdVenda) {
					await this.$store.dispatch("inserirVendaSemPagamento", {...form, mesa: true});
					return;
				}
				await this.$store.dispatch("atualizarVenda", {...form, cdVenda: this.Venda.cdVenda, mesa: true});
	
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		closeModal() {
			this.produtoSelecionado.quantidade = 1;
			this.modalSugestoes = false;
			this.produtoSelecionado = {};
			this.$once("hook:beforeDestroy", () => {
				window.removeEventListener("keydown", this.verificarTecla);
			});
		},

		removeProduct(index) {
			this.produtosPedido.splice(index, 1);
			this.produtoSelecionado = {};
			this.sugestoesEditadas = [];
		},

		async selecionouProduto(produto) {
			this.produtoSelecionado = produto;
			this.produtoSelecionado.sugestoes = [];
			await this.buscarKit(produto.id);
		},

		selecionouGrupo(grupo) {
			try {
				if(this.produtoSelecionado) this.produtoSelecionado = {};
				this.grupoSelecionado = grupo;

				setTimeout(() => {
					this.filtrarProdutosPorGrupo();
				}, 250);		
			} catch (error) {
				console.error(error);
			}
		},

		handleAddSugestaoProduct(sugestoes) {
			try {
				if(this.produtoSelecionado?.sugestoes.length > 0) {
					if((this.produtoSelecionado.produto.qteEmbalagem < this.produtoSelecionado.quantidade) && this.produtoSelecionado.produto.qteEmbalagem > 1) {
						throw new Error(`A quantidade máxima de itens para adicionar é ${this.produtoSelecionado.produto.qteEmbalagem}, por favor verifique`);
					}
				}
				this.produtoSelecionado.sugestoes = sugestoes;
				this.produtoSelecionado.quantidade = 1;
				const existe = this.produtosPedido.find((prod, index) => index === this.produtoSelecionado.index);
				if(existe) {
					this.produtosPedido[this.produtoSelecionado.index] = this.produtoSelecionado;
					this.produtoSelecionado = {};
					this.modalSugestoes = false;
					return;
				}
				//VERIFICAR AQUI
				this.adicionarProduto(this.produtoSelecionado);
				this.closeModal();
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
			}
		},


		async handleAddProduct() {
			if(!this.produtoSelecionado?.id) {
				return this.$store.commit("relatarErro", {
					mensagem: "É necessário selecionar um produto para adicionar"
				});
			}

			if(this.produtoSelecionado?.sugestoes?.length > 0) {
				this.formatProdutoSelecionado();
				this.produtoSelecionado.quantidade = 0;
				this.modalSugestoes = true;
				return true;
			} else {
				this.produtoSelecionado.quantidade = 1;
				this.adicionarProduto(this.produtoSelecionado);
			}
		},

		formatProdutoSelecionado() {
			this.produtoSelecionado = {
				valorUnitario: this.produtoSelecionado?.vlrvista,
				descricao: this.produtoSelecionado?.descricao,
				id: this.produtoSelecionado?.id,
				quantidade: 1,
				qtdentregue: 0,
				produto: {...this.produtoSelecionado},
				sugestoes: this.produtoSelecionado.sugestoes || []
			};
		},

		adicionarProduto(produto) {
			if(produto.valorUnitario){
				this.produtosPedido.push(produto);
				return;
			}
			this.produtosPedido.push({
				valorUnitario: produto?.promocaoVista ? (produto?.vlrvista - (produto?.vlrvista * (produto?.promocaoVista / 100))) : produto?.vlrvista,
				descricao: produto?.descricao,
				id: produto?.id,
				quantidade: produto?.quantidade || 1,
				qtdentregue: 0,
				produto: {...produto},
				sugestoes: produto?.sugestoes || [],
			});
			this.produtoSelecionado = {};
		},
		async buscarKit(id) {
			try {
				const kit = await this.$store.dispatch("buscarKitCadastrado", id);
				if (kit) {
					const sugestoesAtualizadas = [];
					kit.forEach(produto => {
						const quantidade =  0;
						const sugestaoAtualizada = {
							custo: produto?.vlrvista,
							id: produto?.id,
							quantidade: quantidade,
							descricao: produto.descricao,
							multiplo: produto?.quantidade || 1,
							unidade: produto?.unidade
						};
						sugestoesAtualizadas.push(sugestaoAtualizada);
					});

					if (this.produtoSelecionado.sugestoes?.forEach) {
						this.produtoSelecionado.sugestoes.forEach(sugestao => {
							const sugestaoAtualizada = sugestoesAtualizadas.find(sugestaoAtualizada => sugestaoAtualizada.id === sugestao.id);
							if(sugestaoAtualizada) {
								sugestaoAtualizada.quantidade = sugestao.quantidade;
							}
						});
					}
					this.produtoSelecionado.sugestoes = sugestoesAtualizadas;
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async filtrarProdutosPorGrupo () {
			this.cardLoadingProduto = true;
			const res = await this.$store.dispatch("buscarProdutoPorGrupo", {
				grupo: this.grupoSelecionado.cdGrupo
			});
			if(res && !res.erro) {
				this.ListaProdutosGrupo = res;
				setTimeout(() => {
					this.cardLoadingProduto = false;	
				}, 50);
			}
		}
	}
};
</script>
<style scoped>
.containerPage {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;
	padding: 10px;
}

.container{
	width: 40%;
	height: 85vh;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.containerContent{
	width: 100%;
	display: flex;
	height: 100%;
	flex-direction: column;
	overflow-y: auto;
}

.containerProdutosPedido{
	width: 100%;
	display: flex;
	height: 70vh;
	flex-direction: column;
	overflow-y: auto;
}


.containerCards{
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 5px;
}


.containerMain{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 60%;
	gap: 10px;
}

.containerDividido{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	border-radius: 10px;
	width: 100%;
	height: 85vh;
}

.containerButtons{
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: 100%;
	padding: 10px;
}
.containerButtons button{
	width: 100%;
}

.containerTabela {
	height: 62vh;
	overflow: auto;
	padding: 10px;
}
.containerTabela table{
	width: 100%;
}

.text-center{
	text-align: center;
}

@media screen and (max-width: 425px) {
	.containerPage{
		display: flex;
		flex-direction: column;
		height: 100vh;
		width: 100%;
	}
	.containerPage .container {
		width: 100%;
	}
}

.containerTotalizadores{
	display: flex;
	gap: 10px;
}

.containerTotalizadores input{
	text-align: right;
}

.titleContainer{
	padding: 5px;
	width: 100%;
	text-align: center;
	background-color: #cecece;
	border-radius: 10px;
}

@media screen and (max-width: 425px) {
	.containerMain{
		width: 100%;
	}
}


.modal-container-quantidade{
	padding: 5px;
}

.container-input-buttons-quantidade{
	display: flex;
	width: 100%;
	gap: 5px;
}

#qtd-input{
	width: 52px;
	border-left: none;
	border-right: none;
	border-top: none;
	text-align: center;
}

.container-input-buttons-quantidade button{
	width: 100%;
}

.loaderCenter{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
