import { render, staticRenderFns } from "./CadastroCliente.vue?vue&type=template&id=dd62836c&scoped=true"
import script from "./CadastroCliente.vue?vue&type=script&lang=js"
export * from "./CadastroCliente.vue?vue&type=script&lang=js"
import style0 from "./CadastroCliente.vue?vue&type=style&index=0&id=dd62836c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd62836c",
  null
  
)

export default component.exports