<template>
	<div class="container-page">
		<Filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div class="input-group select-group" style="min-width: 150px;">
							<select 
								v-model="tipoFiltro"
								id="tipoFiltro"
								@change="handleTipoFiltro"
							>
								<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
									{{filtro}}
								</option>
							</select>
							<label for="tipoFiltro" style="background-color: #f8f8f8;">
								Opção Filtro
							</label>
						</div>
						<div class="input-group">
							<input 
								type="search" 
								id="textoPesquisa" 
								name="filtro" 
								placeholder=" " 
								autocomplete="off"
								v-model="filtroPesquisa"
							/>
							<label for="textoPesquisa" style="background-color: #f8f8f8;">
								Pesquisar
							</label>
						</div>
					</div>
				</div>
			</div>		
		</Filtro>
		<TabelaCfop
			@escolheucfop="$emit('escolheucfop', $event)"
		/>
	</div>
</template>

<script>
import Filtro from "../components/Filtro.vue";
import TabelaCfop from "./TabelaCFOp.vue";

export default{
	name: "ConsultaCFOP",
	components: {
		Filtro,
		TabelaCfop
	},
	data() {
		return {
			tipoFiltro: "Codigo",
			Filtros: [
				"Codigo",
				"Natureza",
			],
			filtroPesquisa: "",
		};
	},
	methods:{
		limparFiltro() {
			this.tipoFiltro = "Codigo";
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
			this.$store.dispatch("buscarCfop");
		},
		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},
		async filtrar() {		
			let filtro = {
				codigo: this.tipoFiltro == "Codigo"? this.filtroPesquisa:"",
				descricao: this.tipoFiltro == "Natureza"? this.filtroPesquisa:"",
			};
			await this.$store.dispatch("buscarCfopFiltro", filtro);
		},
		handleTipoFiltro(){
			this.filtroPesquisa = "";
			console.log(true);
			this.focarInputPesquisa();
		}
	}
};
</script>
<style scoped>
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}

</style>