export const dateFormatISO = ({ date, format }) => {
	if (format == "ISOdate") {
		if (!date || date === null)
			return new Date().toISOString().substring(0, 10);
		if (date?.includes("/")) {
			if (date?.indexOf("/") !== -1) {
				const newDate = new Date(date)
					.toLocaleDateString("pt-BR")
					.split("/")
					.reverse()
					.join("-");
				if (newDate === "Invalid Date") {
					return date
						.split("/")
						.reverse()
						.join("-");
				}
				return newDate;
			}
			return date;
		}
		return date;
	}
	if (format === "ISOhour") {
		try {
			if (!date) {
				let novaData = new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate(),
					new Date().getHours()-3,
					new Date().getMinutes(),
					new Date().getSeconds(),
					new Date().getMilliseconds()
				).toISOString();

				if (novaData.includes("Z")) {
					novaData = novaData.split("Z")[0];
					return novaData;
				}
				return novaData;
			}
		} catch (error) {
			console.log(error);
		}
	}
};

export const dateFormatBR = date => {
	if (!date || date === null) {
		date = new Date().toISOString().substring(0, 10);
	}
	const data = date.split("-");
	const dia = data[2];
	const mes = data[1];
	const ano = data[0];
	return `${dia}/${mes}/${ano}`;
};
