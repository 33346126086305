<template>
	<div 
		:class="`${ativo ? 'cardAtivo' : ''} card`" 
		style="display:flex;flex-direction: column;"
		@click="selecionouItem(item)"
		:id="`item-${type === 'Grupo' ? item.cdGrupo : item.id}`"
	>
		<img :src="`https://drive.google.com/thumbnail?id=${item.idImagem}`" alt="" v-if="item.idImagem" style="height: 100%;">
		<span style="font-size: 10px;font-weight: bold;">
			{{ item.descricao }}
		</span>
	</div>
</template>
<script>
export default{
	name: "CardGrupoProduto",
	props: {
		item: {
			type: Object,
			required: true,
		},
		ativo: Boolean,
		type: String,
	},

	data() {
		return {
			currentIndex: this.indexSelecionado,
			delayTimer: null,
		};
	},

	watch: {
		indexSelecionado(newIndex, oldIndex) {
			if(newIndex !== oldIndex) {
				this.currentIndex = newIndex;
			}
		}
	},

	methods: {
		selecionouItem(item) {
			if(this.type === "Produto") {
				this.$emit("selecionouProduto", item);
			} 
			if(this.type === "Grupo") {
				this.$emit("selecionouGrupo", item);
			}
		},
	
		// selecionarGrupoTecla(e) {
		// 	if(e.key !== "ArrowRight" && e.key !== "ArrowLeft") return;

		// 	if(e.key === "ArrowRight") {
		// 		this.currentIndex = (this.currentIndex + 1) % this.ArrayItens.length;
		// 	} else if (e.key === "ArrowLeft") {
		// 		this.currentIndex = (this.currentIndex - 1 + this.ArrayItens.length) % this.ArrayItens.length;
		// 	}

		// 	this.$emit(`selecionou${this.type}`, this.ArrayItens[this.currentIndex]);
		// 	this.delayTimer = setInterval(() => {
		// 		clearInterval(this.delayTimer);
		// 		this.delayTimer = null;
		// 	}, 500);

		// 	this.$once("hook:beforeDestroy", () => {
		// 		window.removeEventListener("keydown", this.selecionarGrupoTecla);
		// 	});
		// }
	},

	// mounted() {
	// 	window.addEventListener("keydown", this.selecionarGrupoTecla);
	// },

	// beforeDestroy() {
	// 	window.removeEventListener("keydown", this.selecionarGrupoTecla);
	// },
};
</script>
<style scoped>

.card{
	width: 125px;
	padding: 10px;
	height: 100px;

	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	border-radius: 5px;
	
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
}

.cardAtivo{
	border: 2px solid var(--primario);
	background-color: #99afff;
	color: var(--primario);
	font-weight: bold;
}


</style>
