<template>
	<div id="gridPrincipal">
		<MenuLateral
			tipo="Gerenciamento"
			v-on:consultarClientes="voltarTabela('Clientes')"
			v-on:consultarProdutos="voltarTabela('Produtos')"
			v-on:consultarVendedores="voltarTabela('Vendedores')"
			v-on:consultarGrupos="voltarTabela('Grupos')"
			v-on:consultarBandeiras="voltarTabela('Bandeiras')"
			v-on:consultarContas="voltarTabela('Contas')"
			v-on:consultarCaixas="voltarTabela('Caixas')"
			v-on:consultarCompras="voltarTabela('Compras')"
			v-on:consultarConsorcio="voltarTabela('Consorcio')"
			v-on:consultarFormasPagamento="voltarTabela('FormasPagamento')"
			v-on:consultarPromocao="voltarTabela('Promocao')"
			v-on:consultarTransferirEstoque="voltarTabela('TransferirEstoque')"
			v-on:consultarCadastroClassificacao="voltarTabela('CadastroClassificacao')"
			v-on:consultarConciliadora="voltarTabela('Conciliadora')"
			v-on:consultarConsultaPix="voltarTabela('ConsultarPix')"
			v-on:consultarCredito="voltarTabela('Credito')"
			v-on:consultarPrevisaoCompra="voltarTabela('PrevisaoCompra')"
			v-on:consultarConsultarNcm="voltarTabela('ConsultarNcm')"
			/>
		<div id="TabelaGerenciamento">
			<section class="tabela-atual" v-if="mostrar=='Clientes'">
				<CadastroCliente 
					v-if="clienteSelecionado"
					:cliente="clienteSelecionado" 
					@cancelarEdicao="gerenciarCliente(null)" 
				/>
				<consulta-cliente  
					@selecionarPessoa="gerenciarCliente" 
					@adicionarNovo="gerenciarCliente({novo: true})"
					:mostrarBotaoNovo="true"
					:height="'65vh'"
					v-else
				/>
				<!-- <Paginacao tipoConteudo="Clientes" v-on:adicionarNovo="gerenciarCliente({novo: true})" v-else>
					<TabelaPessoas tipoConteudo="Clientes" v-on:selecionarPessoa="gerenciarCliente" />
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Vendedores'">
				<CadastroVendedor v-if="vendedorSelecionado" v-bind:vendedor="vendedorSelecionado" v-on:cancelarEdicao="gerenciarVendedor(null)" />
				<consulta-vendedores 
					:mostrarBotaoNovo="true"
					@selecionarPessoa="gerenciarVendedor"
					@adicionarNovo="gerenciarVendedor({novo: true})"
					:height="'65vh'"
					v-else 
				/>
				<!-- <Paginacao tipoConteudo="Vendedores" v-on:adicionarNovo="gerenciarVendedor({novo:true})" v-else>
					<TabelaPessoas tipoConteudo="Vendedores" v-on:selecionarPessoa="gerenciarVendedor" />
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Produtos'">
				<CadastroProduto 
					v-if="produtoSelecionado" 
					:Produto="produtoSelecionado" 
					v-on:cancelarEdicao="gerenciarProduto(null)" 
				/>
				<consulta-produtos 
					v-else
					:Produtos="produtos"
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarProduto({novo: true})"
					@selecionarProduto="gerenciarProduto"
					:height="'65vh'"
				/>
				
				<!-- <FiltroPaginacao :conteudo="'Produtos'" @adicionarNovo="gerenciarProduto({novo: true})" :visaoTodasEmpresas="true" v-else>
					<ListagemProdutos tipoConteudo="Produto" :Produtos="produtos" v-on:selecionarProduto="gerenciarProduto"/>
				</FiltroPaginacao> -->
				<!-- <Paginacao id="ListagemProdutos" tipoConteudo="Produtos" v-on:adicionarNovo="gerenciarProduto({novo:true})" :visaoTodasEmpresas="true" v-else>
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Promocao'">
				<CadastroPromocao v-if="promocaoSelecionada" :promocao="promocaoSelecionada" v-on:cancelarEdicao="gerenciarPromocao(null)" />
				<consulta-promocao
					v-else
					:mostrarBotaoNovo="true"
					@selecionarPromocao="gerenciarPromocao"
					@adicionarNovo="gerenciarPromocao({novo: true})"
				/>
				<!-- <TabelaPromocao v-on:selecionarPromocao="gerenciarPromocao" v-on:adicionarNovo="gerenciarPromocao({novo: true})" v-else /> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Grupos'">
				<CadastroGrupoProduto v-if="GrupoSelecionado" v-on:voltarlista="gerenciarGrupos({grupo:null,subgrupo:null})" :grupoProduto="GrupoSelecionado"/>
				<ConsultaGrupos 
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarGrupos({grupo:{novo: true}, subgrupo:null})"
					@selecionarGrupos="gerenciarGrupos"
					:heightTabela="'65vh'"
				/>
				<!-- <Paginacao tipoConteudo="Grupos" v-on:adicionarNovo="gerenciarGrupos({novo: true}, null)" v-else>
					<TabelaGrupoProduto v-on:selecionarGrupos="gerenciarGrupos"/>
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Compras'">
				<!-- <CadastroCompraNovo v-if="CompraSelecionada" /> -->
				<CadastroCompras 
					v-if="CompraSelecionada" 
					:alteracompra="CompraSelecionada" 
					v-on:cancelarEdicao="gerenciarCompra(null)"
				/>
				<consulta-compras
					v-else
					@selecionarCompra="gerenciarCompra"
					:height="'65vh'"
				/>
				<!-- <TabelaCompras v-on:selecionarCompra="gerenciarCompra" v-else/> -->
			</section>
			<section class="tabela-atual" v-if="mostrar === 'PrevisaoCompra'">
				<CadastroPrevisaoCompra 
					v-if="PrevisaoCompraSelecionada"
					:PrevisaoCompra="PrevisaoCompraSelecionada"
					@cancelarCadastro="gerenciarPrevisaoCompra(null)"
				/>
				<consulta-previsao-compra
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarPrevisaoCompra({novo: true})"
					@selecionarPrevisaoCompra="gerenciarPrevisaoCompra"
				/>
				<!-- <TabelaPrevisaoCompra  
					@adicionarNovo="gerenciarPrevisaoCompra({novo: true})" v-else 
					@selecionarPrevisaoCompra="gerenciarPrevisaoCompra"
				/> -->
			</section>
			<section class="tabela-atual" v-if="mostrar=='Consorcio'">
				<CadastroConsorcio 
					v-if="ConsorcioSelecionado"
					:Consorcio="ConsorcioSelecionado" 
					v-on:cancelouCadastro="gerenciarConsorcio(null)"
				/>
				<consulta-consorcio
					v-else 
					@selecionouConsorcio="gerenciarConsorcio" 
				/>
				<!-- <TabelaConsorcio
					v-else 
					v-on:selecionouConsorcio="gerenciarConsorcio" 
				/> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Caixas'">
				<CadastroCaixa v-if="CaixaSelecionado" :Caixa="CaixaSelecionado" v-on:cancelarEdicao="gerenciarCaixas(null)" />
				<consulta-caixas
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarCaixas({novo:true})"
					@selecionarConta="gerenciarCaixas"
				/>
				<!-- <Paginacao tipoConteudo="Caixas" v-on:adicionarNovo="gerenciarCaixas({novo:true})" v-else>
					<TabelaContas tipoConteudo="Caixas" v-on:selecionarConta="gerenciarCaixas" />
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Contas'">
				<CadastroContas v-if="ContaSelecionada" :Conta="ContaSelecionada" v-on:cancelarEdicao="gerenciarContas(null)"/>
				<consulta-contas
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarContas({novo:true})"
					@selecionarConta="gerenciarContas"
				/>
				<!-- <Paginacao tipoConteudo="Contas" v-on:adicionarNovo="gerenciarContas({novo:true})" v-else>
					<TabelaContas tipoConteudo="Contas" v-on:selecionarConta="gerenciarContas" />
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='Bandeiras'">
				<CadastroBandeira v-if="bandeiraSelecionada" :bandeiraSelecionada="bandeiraSelecionada" v-on:cancelarEdicao="gerenciarBandeiras(null)"/>
				<consulta-bandeiras
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarBandeiras({novo:true})"
					@selecionarBandeira="gerenciarBandeiras"
				/>
				<!-- <Paginacao tipoConteudo="Bandeiras" v-on:adicionarNovo="gerenciarBandeiras({novo: true})" v-else>
					<TabelaBandeira tipoConteudo="Bandeiras" v-on:selecionarBandeira="gerenciarBandeiras"/>
				</Paginacao> -->
			</section>

			<section class="tabela-atual" v-if="mostrar=='FormasPagamento'">
				<CadastroFormasPagamentos v-if="FormaPagamentoSelecionado" :FormaPagamento="FormaPagamentoSelecionado" v-on:cancelarEdicao="gerenciarFormaPagamento(null)"/>
				<consulta-formas-pagamentos
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarFormaPagamento({novo:true})"
					@selecionarFormaPagamento="gerenciarFormaPagamento"
				/>
				<!-- <Paginacao tipoConteudo="FormasPagamento" v-on:adicionarNovo="gerenciarFormaPagamento({novo:true})" v-else>
					<TabelaFormasPagamentos v-on:selecionarFormaPagamento="gerenciarFormaPagamento"/>
				</Paginacao> -->
			</section>
			<section class="tabela-atual transferencia-estoque" v-if="mostrar=='TransferirEstoque'">
				<ProdutoTransferencia v-if="transferenciaEstoqueSelecionado" :novaTransferencia="transferenciaEstoqueSelecionado" v-on:cancelarTransferencia="gerenciarTransferenciaEstoque(null)"/>
				<consulta-transferencia-estoque 
					v-else
					:mostrarBotaoNovo="true"
					@selecionarTransferencia="gerenciarTransferenciaEstoque" 
					@abrirCadastro="gerenciarTransferenciaEstoque({novo: true})"
				/>
				<!-- <TabelaTransferenciaEstoque v-on:selecionarTransferencia="gerenciarTransferenciaEstoque" v-on:abrirCadastro="gerenciarTransferenciaEstoque({novo: true})" v-else/> -->
			</section>
			<section class="tabela-atual" v-if="mostrar == 'CadastroClassificacao'">
				<CadastroClassificacao v-if="CadastroClassificacaoSelecionado" :classificacaoEditar="CadastroClassificacaoSelecionado" v-on:cancelarCadastroClassificacao="gerenciarCadastroClassificacao(null)"></CadastroClassificacao>
				<consulta-classificacao
					v-else
					:mostrarBotaoNovo="true"
					@adicionarNovo="gerenciarCadastroClassificacao({novo: true})"
					@selecionarClassificacao="gerenciarCadastroClassificacao" 
				/>
				<!-- <Paginacao  tipoConteudo="Classificacao" v-on:adicionarNovo="gerenciarCadastroClassificacao({novo: true}) "  v-else>
					<ConsultaClassificacao v-on:selecionarClassificacao="gerenciarCadastroClassificacao"></ConsultaClassificacao>
				</Paginacao> -->
			</section>
			<section class="tabela-atual" v-if="mostrar == 'Conciliadora'">
				<Conciliadora />
			</section>
			<section class="tabela-atual" v-if="mostrar == 'ConsultarPix'">
				<CobrancaSelecionado v-if="CobrancaSelecionado" :cobranca="CobrancaSelecionado" @voltarConsulta="selecionarCobranca(null)"/>
				<ConsultarPix @selecionarCobranca="selecionarCobranca"   v-else />
			</section>
			<section class="tabela-atual" v-if="mostrar == 'Credito'">
				<CadastroCreditoCliente  v-if="cadastroCreditoSelecionado" :credito="cadastroCreditoSelecionado" 
				@cancelarCreditoCliente="gerenciarCadastroCreditoCliente(null)" />
				<ConsultaCreditoCliente @abrirCadastro="gerenciarCadastroCreditoCliente({novo: true})" 
				@selecionarCreditoCliente="gerenciarCadastroCreditoCliente" @editarCreditoCliente="selecionarCreditoCliente"  v-else/>
			</section>
			<section class="tabela-atual" v-if="mostrar === 'ConsultarNcm'">
				<ConsultarNCM />
			</section>
			<section class="tabela-atual" v-if="mostrar === 'CadastrarNFE'">
				<CadastroNFE />
			</section>
		</div>
	</div>
</template>

<script>
// NOVOS COMPONENTES //
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import ConsultaGrupos from "../components/ConsultaGrupos.vue";
import ConsultaCaixas from "../components/ConsultaCaixas.vue";
import ConsultaContas from "../components/ConsultaContas.vue";
import ConsultaBandeiras from "../components/ConsultaBandeiras.vue";
import ConsultaFormasPagamentos from "../components/ConsultaFormasPagamentos.vue";
import ConsultaPromocao from "../components/ConsultaPromocao.vue";
import ConsultaPrevisaoCompra from "../components/ConsultaPrevisaoCompra.vue";
import ConsultaTransferenciaEstoque from "../components/ConsultaTransferenciaEstoque.vue";
import ConsultaConsorcio from "../components/ConsultaConsorcio.vue";
import ConsultaCompras from "../components/ConsultaCompras.vue";

import MenuLateral from "../components/MenuLateral.vue";
import CadastroCliente from "../components/CadastroCliente.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
// import Paginacao from "../components/Paginacao.vue";
// import ListagemProdutos from "../components/ListagemProdutos.vue";
import CadastroVendedor from "../components/CadastroVendedor.vue";
// import TabelaBandeira from "../components/TabelaBandeira.vue";
import CadastroBandeira from "../components/CadastroBandeira.vue";
import CadastroProduto from "../components/CadastroProduto.vue";
// import TabelaGrupoProduto from "../components/TabelaGrupoProduto.vue";
import CadastroGrupoProduto from "../components/CadastroGrupoProduto.vue";
import CadastroContas from "../components/CadastroContas.vue";
import CadastroCaixa from "../components/CadastroCaixa.vue";
// import TabelaContas from "../components/TabelaContas.vue";
// import TabelaCompras from "../components/TabelaCompras.vue";
import CadastroCompras from "../components/CadastroCompras.vue";
// import TabelaConsorcio from "../components/TabelaConsorcio.vue";
import CadastroConsorcio from "../components/CadastroConsorcio.vue";
import CadastroPromocao from "../components/CadastroPromocao.vue";
// import TabelaPromocao from "../components/TabelaPromocao.vue";
import CadastroFormasPagamentos from "../components/CadastroFormasPagamento.vue";
// import TabelaFormasPagamentos from "../components/TabelaFormasPagamento.vue";
import ProdutoTransferencia from "../components/ProdutoTransferencia.vue";
// import TabelaTransferenciaEstoque from "../components/TabelaTransferenciaEstoque.vue";
import CadastroClassificacao from "../components/CadastroClassificacao.vue";
import ConsultaClassificacao from "../components/ConsultaClassificacao.vue";
import Conciliadora from "../components/Conciliadora.vue";
import ConsultarPix from "../components/ConsultarPix.vue";
import CobrancaSelecionado from "../components/CobrancaSelecionado.vue";
import CadastroCreditoCliente from "../components/CadastroCreditoCliente.vue";
import ConsultaCreditoCliente from "../components/ConsultaCreditoCliente.vue";
// import TabelaPrevisaoCompra from "../components/TabelaPrevisaoCompra.vue";
import CadastroPrevisaoCompra from "../components/CadastroPrevisaoCompra.vue";
import ConsultarNCM from "../components/ConsultarNCM.vue";
import CadastroNFE from "../components/CadastroNFE.vue";
// import FiltroPaginacao from "../components/FiltroPaginacao.vue";




export default {
	name: "Gerenciamento",

	components: {
		MenuLateral,
		CadastroCliente,
		// TabelaPessoas,
		// Paginacao,
		// ListagemProdutos,
		CadastroVendedor,
		// TabelaBandeira,
		CadastroBandeira,
		CadastroProduto,
		CadastroGrupoProduto,
		CadastroContas,
		CadastroCaixa,
		// TabelaContas,
		// TabelaCompras,
		CadastroCompras,
		// TabelaConsorcio,
		CadastroConsorcio,
		CadastroPromocao,
		// TabelaPromocao,
		// TabelaGrupoProduto,
		CadastroFormasPagamentos,
		// TabelaFormasPagamentos,
		ProdutoTransferencia,
		// TabelaTransferenciaEstoque,
		CadastroClassificacao,
		ConsultaClassificacao,
		Conciliadora,
		ConsultarPix,
		CobrancaSelecionado,
		CadastroCreditoCliente,
		ConsultaCreditoCliente,
		// TabelaPrevisaoCompra,
		CadastroPrevisaoCompra,
		// FiltroPaginacao,
		
		
		ConsultaCliente,
		ConsultaVendedores,
		ConsultaProdutos,
		ConsultaGrupos,
		ConsultaCaixas,
		ConsultaContas,
		ConsultaBandeiras,
		ConsultaFormasPagamentos,
		ConsultaPromocao,
		ConsultaPrevisaoCompra,
		ConsultaTransferenciaEstoque,
		ConsultaConsorcio,
		ConsultaCompras,
		ConsultarNCM,
		CadastroNFE,
	},

	computed: {
		produtos() {
			return this.$store.state.Produtos;
		}
	},

	data(){
		return {
			mostrar: "Clientes",
			clienteSelecionado: null,
			vendedorSelecionado: null,
			produtoSelecionado: null,
			bandeiraSelecionada: null,
			GrupoSelecionado: null,
			ContaSelecionada: null,
			CaixaSelecionado: null,
			CompraSelecionada : null,
			ConsorcioSelecionado: null,
			promocaoSelecionada: null,
			FormaPagamentoSelecionado: null,
			transferenciaEstoqueSelecionado: null,
			CadastroClassificacaoSelecionado: null,
			ConciliadoraSelecionado: null,
			CobrancaSelecionado: null,
			cadastroCreditoSelecionado: null,
			PrevisaoCompraSelecionada: null,
		};
	},

	methods: {
		
		mudarAba(selecionarAba, mostrar){
			this[selecionarAba] = mostrar;
			this.removerSelecionados(selecionarAba);

		},
		removerSelecionados(selecionado) {
			Object.keys(this._data).filter(k => k != selecionado).forEach(key => {
				if (key != "mostrar") {
					this[key] = null;
				}
			});
		},
		voltarTabela(mostrar) {
			this.mostrar = mostrar;
			this.removerSelecionados();
			if (document.querySelector("div#MenuLateral.open-menu")) {
				this.abrirMenu();
			}
			this.$bus.$emit("AlteraCombo", mostrar);
		},
		abrirMenu() {
			document.querySelector("i.fas.fa-eject").style.transform = document.querySelector("div#MenuLateral").classList.contains("open-menu") ?
				"rotate(90deg)" : "rotate(-90deg)";
			document.querySelector("div#MenuLateral").classList.toggle("open-menu");
		},

		// Clientes
		gerenciarCliente(cliente) {
			if (!cliente) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Clientes"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Clientes", cadastro: true}, params: {cliente}});
			}
			this.verificarMenuInQuery();
		},
		selecionarCliente(cliente) {
			this.mudarAba("clienteSelecionado", cliente);
		},
		routeClientes() {
			const {cliente} = this.$route.params;
			if (cliente) {
				this.selecionarCliente(cliente);
			} else {
				this.selecionarCliente(null);
			}
		},

		// Vendedores
		gerenciarVendedor(vendedor) {
			if (!vendedor) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Vendedores"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Vendedores", cadastro: true}, params: {vendedor}});
			}
			this.verificarMenuInQuery();
		},
		selecionarVendedor(vendedor) {
			this.mudarAba("vendedorSelecionado", vendedor);
		},
		routeVendedores() {
			const {vendedor} = this.$route.params;
			if (vendedor) {
				this.selecionarVendedor(vendedor);
			} else {
				this.selecionarVendedor(null);
			}
		},

		// Produto
		async gerenciarProduto(produto) {
			if (!produto) {
				this.$store.state.Filtro ? (this.$store.state.Filtro.retorno = true) : null;
				this.$router.replace({name: "Gerenciamento", query: {menu: "Produtos"}});
			} else {
				if (produto.pai) {
					let cdempresa;
					if(produto.empresas?.length > 0){
						cdempresa = produto.empresas[0].cdempresa;
					}
					const prodPai = await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: produto.pai, cdempresa: cdempresa ? cdempresa : produto.cdEmpresa});
					if (prodPai) {
						this.$router.replace({name: "Gerenciamento", query: {menu: "Produtos", cadastro: true}, params: {produto: prodPai}});
					}
				} else {
					this.$router.replace({name: "Gerenciamento", query: {menu: "Produtos", cadastro: true}, params: {produto}});
				}
			}
			this.verificarMenuInQuery();
		},
		selecionarProduto(produto) {
			this.$store.commit("selecionarProduto", !produto?.novo ? produto : null);
			this.mudarAba("produtoSelecionado", produto);
		},
		routeProdutos() {
			const {produto} = this.$route.params;
			if (produto) {
				this.selecionarProduto(produto);
			} else {
				this.selecionarProduto(null);
			}
		},

		// Promocao
		gerenciarPromocao(promocao) {
			if (!promocao) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Promocao"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Promocao", cadastro: true}, params: {promocao}});
			}
			this.verificarMenuInQuery();
		},
		selecionarPromocao(promocao) {
			this.mudarAba("promocaoSelecionada", promocao);
		},
		routePromocao() {
			const {promocao} = this.$route.params;
			if (promocao) {
				this.selecionarPromocao(promocao);
			} else {
				this.selecionarPromocao(null);
			}
		},

		// Grupos
		gerenciarGrupos({grupo, subgrupo}) {
			if (!grupo) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Grupos"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Grupos", cadastro: true}, params: {grupo, subgrupo}});
			}
			this.verificarMenuInQuery();
		},
		selecionarGrupos(grupo) {
			if (typeof grupo != "number") {
				this.mudarAba("GrupoSelecionado", grupo);
			} else {
				this.mudarAba("GrupoSelecionado", {grupo});
			}
		},
		routeGrupos() {
			const {grupo} = this.$route.params;
			if (grupo) {
				this.selecionarGrupos(grupo);
			} else {
				this.selecionarGrupos(null, null);
			}
		},

		// Compras
		gerenciarCompra(compra) {
			if (!compra) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Compras"}});
			} 
			else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Compras", cadastro: true}, params: {compra}});
			}
			this.verificarMenuInQuery();
		},
		selecionarCompra(compra) {
			this.mudarAba("CompraSelecionada", compra);
		},
		routeCompras() {
			const {compra} = this.$route.params;
			if (compra) {
				this.selecionarCompra(compra);
			} else {
				this.selecionarCompra(null);
			}
		},

		//PREVISAO COMPRA
		gerenciarPrevisaoCompra(pedido) {
			if(!pedido) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "PrevisaoCompra"}});
			} else {
				this.$router.replace({
					name: "Gerenciamento", 
					query: {menu: "PrevisaoCompra", cadastro: true}, 
					params: {pedido}
				});
			}
			this.verificarMenuInQuery();
		},

		selecionarPrevisaoCompra(pedido){
			this.mudarAba("PrevisaoCompraSelecionada", pedido);
		},

		routePrevisaoCompra() {
			const { pedido } = this.$route.params;
			if (pedido) {
				this.selecionarPrevisaoCompra(pedido);
			} else {
				this.selecionarPrevisaoCompra(null);
			}
		},

		// Consorcio
		gerenciarConsorcio(consorcio) {
			if (!consorcio) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Consorcio"}});
			} else {
				this.$router.replace({
					name: "Gerenciamento", 
					query: {menu: "Consorcio", cadastro: true}, 
					params: {consorcio}
				});
			}
			this.verificarMenuInQuery();
		},
		selecionarConsorcio(Consorcio) {
			this.mudarAba("ConsorcioSelecionado", Consorcio);
		},
		routeConsorcio() {
			const {consorcio} = this.$route.params;
			if (consorcio) {
				this.selecionarConsorcio(consorcio);
			} else {
				this.selecionarConsorcio(null);
			}
		},

		// Caixas
		gerenciarCaixas(caixa) {
			if (!caixa) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Caixas"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Caixas", cadastro: true}, params: {caixa}});
			}
			this.verificarMenuInQuery();
		},
		selecionarCaixas(caixa) {
			this.mudarAba("CaixaSelecionado", caixa);
		},
		routeCaixas() {
			const {caixa} = this.$route.params;
			if (caixa) {
				this.selecionarCaixas(caixa);
			} else {
				this.selecionarCaixas(null);
			}
		},

		// Contas
		gerenciarContas(conta) {
			if (!conta) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Contas"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Contas", cadastro: true}, params: {conta}});
			}
			this.verificarMenuInQuery();
		},

		async selecionarContas(conta) {
			if(conta && !conta?.novo) {
				const data = await this.$store.dispatch("buscarContaBancoUnica", {
					agencia: conta.agencia,
					cc: conta.cc,
					banco: conta.banco
				});
				if(!data) return;
				this.mudarAba("ContaSelecionada", data);
			} else {
				this.mudarAba("ContaSelecionada", conta);
			}
		},
		routeContas() {
			const {conta} = this.$route.params;
			if (conta) {
				this.selecionarContas(conta);
			} else {
				this.selecionarContas(null);
			}
		},

		// Bandeiras
		gerenciarBandeiras(bandeira) {
			if (!bandeira) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Bandeiras"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Bandeiras", cadastro: true}, params: {bandeira}});
			}
			this.verificarMenuInQuery();
		},
		selecionarBandeiras(bandeira) {
			this.mudarAba("bandeiraSelecionada", bandeira);
		},
		routeBandeiras() {
			const {bandeira} = this.$route.params;
			if (bandeira) {
				this.selecionarBandeiras(bandeira);
			} else {
				this.selecionarBandeiras(null);
			}
		},

		// FormaPagamento
		gerenciarFormaPagamento(formaPagamento) {
			if (!formaPagamento) {
				this.$router.replace({name: "Gerenciamento", query: {menu: "FormasPagamento"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "FormasPagamento", cadastro: true}, params: {formaPagamento}});
			}
			this.verificarMenuInQuery();
		},
		selecionarFormasPagamento(formaPagamento) {
			this.mudarAba("FormaPagamentoSelecionado", formaPagamento);
		},
		routeFormasPagamento() {
			const {formaPagamento} = this.$route.params;
			if (formaPagamento) {
				this.selecionarFormasPagamento(formaPagamento);
			} else {
				this.selecionarFormasPagamento(null);
			}
		},

		// TransferirEstoque
		gerenciarTransferenciaEstoque(transferenciaEstoque){
			if(!transferenciaEstoque){
				this.$router.replace({name: "Gerenciamento", query: {menu: "TransferirEstoque"}});
			}else{
				this.$router.replace({name: "Gerenciamento", query: {menu: "TransferirEstoque", cadastro: true}, params: {transferenciaEstoque}});
			}
			this.verificarMenuInQuery();

		},
		selecionarTransferenciaEstoque(transferenciaEstoque){
			this.mudarAba("transferenciaEstoqueSelecionado", transferenciaEstoque);
		},
		routeTransferirEstoque(){
			const {transferenciaEstoque} = this.$route.params;
			if(transferenciaEstoque) {
				this.selecionarTransferenciaEstoque(transferenciaEstoque);
			}else{
				this.selecionarTransferenciaEstoque(null);
			}
		},
		//cadastroClassificacao
		gerenciarCadastroClassificacao(CadastroClassificacao){
			if(!CadastroClassificacao){
				this.$router.replace({name: "Gerenciamento", query: {menu: "CadastroClassificacao"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "CadastroClassificacao", cadastro: true}, params: {CadastroClassificacao}});
			}
			this.verificarMenuInQuery();
		},
		selecionarCadastroClassificacao(cadastroClassificacao){
			this.mudarAba("CadastroClassificacaoSelecionado", cadastroClassificacao);
		},
		routeCadastroClassificacao(){
			const {CadastroClassificacao} = this.$route.params;
			if(CadastroClassificacao){
				this.selecionarCadastroClassificacao(CadastroClassificacao);
			} else {
				this.selecionarCadastroClassificacao(null);
			}
		},
		// gerenciamento/consultaPix
		selecionarCobranca(cobranca){
			this.mudarAba("CobrancaSelecionado", cobranca);
		},
		routeCobrancaSelecionado(){
			const { cobranca } = this.$route.params;
			if(cobranca){
				this.selecionarCobranca(cobranca);
			}
		},
		//Crédito Cliente
		gerenciarCadastroCreditoCliente(CadastroCredito){
			if(!CadastroCredito){
				this.$router.replace({name: "Gerenciamento", query: {menu: "Credito"}});
			} else {
				this.$router.replace({name: "Gerenciamento", query: {menu: "Credito", cadastro: true}, params: { CadastroCredito }});
			}
			this.verificarMenuInQuery();
		},
		selecionarCreditoCliente(cadastroCredito){
			this.mudarAba("cadastroCreditoSelecionado", cadastroCredito);
		},
		routeCredito(){
			const {CadastroCredito} = this.$route.params;
			if(CadastroCredito){
				this.selecionarCreditoCliente(CadastroCredito);
			} else {
				this.selecionarCreditoCliente(null);
			}
		},

		verificarMenuInQuery() {
			if (this.$route.query) {
				const {menu} = this.$route.query;
				if (menu) {
					this.mostrar = menu;
					const stringFunction = `route${menu}`;
					if (this[stringFunction] != null && this[stringFunction] != undefined) {
						this[stringFunction]();
					}
				}
			}
		},
	},

	mounted() {
		this.verificarMenuInQuery();
	},
};
</script>

<style scoped>
.filtrocompras{
	width: 100%;
	height: 80px;
}

#gridPrincipal {
	margin: 0px;
	display: grid;
	width: 100vw;
	grid-template-columns: 120px 1fr;
	grid-template-rows: 90vh;
	grid-template-areas: "menu conteudo";
	overflow: hidden;
}

#MenuLateral {
	grid-area: menu;
}

#TabelaGerenciamento {
	grid-area: conteudo;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: calc(100vw - 120px);
}

.tabela-atual {
	display: flex;
	flex-grow: 1;
	height: 100%;
	max-height: 90vh;
	width: 100%;
	margin: 0px;
	overflow: auto;
}

@media screen and (max-width: 768px) {
	#gridPrincipal {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 55px calc(100% - 55px);
		grid-template-areas: "menu"
							"conteudo";
		margin: 0px;
		width: 100%;
		height: 100%;
		position: relative;
	}

	#TabelaGerenciamento {
		display: flex;
		width: 100vw;
		height: auto;
	}

	.tabela-atual {
		display: flex;
		flex-grow: 1;
		height: 100%;
		max-height: 100vh;
		width: 100%;
		max-width: 100vw;
		margin: 0px;
		overflow: hidden;
	}
}
@media screen and (max-width: 425px){
	.transferencia-estoque{
		overflow: auto;
	}
}
</style>
