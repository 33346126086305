<template>
	<container-page>
		<content-page>
			<div class="header-page">
				<div class="container-type">
					<button
						class="botao-import clicked"
						@click="handleChangeFileType('ofx')"
					>
						Importar OFX
					</button>
					<button
						class="botao-import"
						@click="handleChangeFileType('pdf')"
					>
						Importar PDF
					</button>
				</div>
				<div class="container-input-import">
					<input 
						type="file"
						id="import-input"
						@change="handleSelectFile"
					/>
					<label
						for="import-input"
						class="label-import"
					>
						<i class="fas fa-file-download"></i>
						<span v-if="!arquivoSelecionado">
							Selecionar arquivo
						</span>
						<span v-else>
							{{ arquivoSelecionado.name }}
						</span>
					</label>
				</div>
				<div class="input-group">
					<select
						v-model="contaSelecionada"
						placeholder=" "
						id="conta"
					>
						<option selected disabled>
							SELECIONE UMA OPÇÃO
						</option>
						<option
							v-for="(conta, index) in Contas"
							:key="index"
							:value="conta"
						>
							{{ conta.nome }}
						</option>
					</select>
					<label for="conta">
						Conta
					</label>
				</div>
				<button
					class="btn-import"
					v-if="arquivoSelecionado"
					@click="realizarImportacao"
				>
					Importar
				</button>
			</div>
			<div
				class="container-tabela"
				v-if="Transactions && Transactions.length > 0 ? true : false"
			>
				<div class="header-tabela">
					<div class="linha-header-tabela">
						<div class="campo-tabela campo-pequeno">Ação</div>
						<div class="campo-tabela campo-maior">N° Movimentação</div>
						<div class="campo-tabela campo-medio">Observação</div>
						<div class="campo-tabela campo-valor">Valor</div>
						<div class="campo-tabela campo-data">Data</div>
						<div class="campo-tabela campo-metodo">Método Pagamento</div>
						<div class="campo-tabela campo-pequeno">Tipo</div>
					</div>
				</div>
				<div class="body-tabela">
					<div
						class="linha-body-tabela"
						v-for="(transaction, index) in Transactions"
						:key="index"
						:class="transaction && transaction.cddoc ? 'title-lancado' : ''"
					>
						<div class="campo-tabela campo-pequeno">
							<i
								class="fas fa-edit"
								@click="handleEditTitle(transaction)"
							/>
							<i class="fas fa-return" />
						</div>
						<div class="campo-tabela campo-maior">
							{{ transaction.nmrMovimentacao }}
						</div>
						<div class="campo-tabela campo-medio">
							{{ transaction.observacao }}
						</div>
						<div class="campo-tabela campo-valor">
							{{ transaction.valor | formatarParaReais }}
						</div>
						<div class="campo-tabela campo-data">
							{{ transaction.data.split("-").reverse().join("/") }}
						</div>
						<div class="campo-tabela campo-metodo">
							{{ transaction.metodoPgto }}
						</div>
						<div class="campo-tabela campo-pequeno">
							{{ 
								transaction.tipo == 3 ? "DESPESA" : "RECEBIMENTO"
							}}
						</div>
					</div>
				</div>
			</div>
			<div class="container-transactions" v-if="ListaTransacoesConciliadas && ListaTransacoesConciliadas.length > 0">
				<div 
					v-for="(trans, index) in ListaTransacoesConciliadas"
					:key="index"
				>
					<div class="container-card">
						<div class="card-transaction">
							<h3>
								{{ trans.cdPessoa ? `${trans.cdPessoa } - ` : "" }}
								{{ trans.remetente }}
							</h3>
							<div class="card-info-container">
								<div class="card-info">
									<span class="card-info-title">Valor</span>
									<span class="card-info-value">{{ trans.valor | formatarParaReais }}</span>
								</div>
								<div class="card-info">
									<span class="card-info-title">Tipo</span>
									<span class="card-info-value">{{ trans.tipo }}</span>
								</div>
								<div class="card-info">
									<span class="card-info-title">Data</span>
									<span class="card-info-value">{{ trans.data.split("-").reverse().join("/") }}</span>
								</div>
								<div class="card-info">
									<span class="card-info-title">
									</span>
									<span class="card-info-value">
										<i 
											class="fas fa-search"
											@click="consultarContas(trans, index)"
										/>
									</span>
								</div>
							</div>
						</div>
						<div class="line-card" v-if="trans.titulo && trans.titulo.cdPessoa">
							<span class="card-icon">
								<span
									class="icon-container"
									:style="`${trans.titulo.conciliado ? 'background-color: green !important;' : ''}`"
									@click="conciliarTitulo(trans, index)"
								>
									<i class="fas fa-check" />
								</span>
							</span>
						</div>
						<div class="card-fornecedor" v-if="trans.titulo && trans.titulo.cdPessoa">
							<div class="title-icon">
								<span>
									<span>{{ trans.titulo.cdPessoa }}</span>
									-
									<span>{{ trans.titulo.nome }}</span>
								</span>
								<i
									class="fas fa-times"
									@click="handleCancelarConcilaoTitulo(index)"
								/>
							</div>
							<div class="card-info-container">
								<div class="card-info">
									<span class="card-info-title">Pagamento</span>
									<span class="card-info-value">
										{{ trans.titulo.pagamento }}
									</span>
								</div>
								<div class="card-info">
									<span class="card-info-title">Valor</span>
									<span class="card-info-value">
										{{ trans.titulo.valor | formatarParaReais }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</content-page>
		<Modal
			v-if="modalConsultarTitulos"
			@fecharModal="modalConsultarTitulos = false"
		>
			<div>
				<div class="container-inputs">
					<div class="input-group">
						<input
							type="text"
							id="clienteSel"
							readonly
							:value="`${clienteSelecionadoConciliar.idCliente} - ${clienteSelecionadoConciliar.nome}`"
						/>
					</div>
					<div class="container-radios">
						<div>
							<input
								type="radio"
								value="A"
								name="condicao"
								id="condicao-aberto"
								v-model="condicaoFiltro"
							/>
							<label for="condicao-aberto">Aberto</label>
						</div>
						<div>
							<input
								type="radio"
								value="B"
								name="condicao"
								id="condicao-baixado"
								v-model="condicaoFiltro"
								@change="handleChangeCondicao"
							/>
							<label for="condicao-baixado">Baixado</label>
						</div>
					</div>
				</div>
				<div class="container-tabela-contas">
					<table v-if="ContasReceber && ContasReceber.length > 0">
						<thead>
							<tr>
								<th>
									Ação
								</th>
								<th>
									Empresa
								</th>
								<th>
									Parcela
								</th>
								<th>
									N° Doc
								</th>
								<th>
									Valor pedido
								</th>
								<th>
									Data emissão
								</th>
								<th>
									Montante
								</th>
								<th>
									Valor recebido
								</th>
								<th>
									Último pgto.
								</th>
								<th>
									Dias de atraso
								</th>
								<th>
									Juros
								</th>
								<th>
									Valor restante
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(conta, index) in ContasReceber"
								:key="conta.cdDig"
								@click="handleCheckbox(conta, index)"
							>
								<td>
									<input
										type="checkbox"
										v-model="conta.checked"
									/>
								</td>
								<td>
									{{ conta.cdEmpresa }}
								</td>
								<td>
									{{ conta.cdLan }}
								</td>
								<td>
									{{ conta.cdDoc }}
								</td>
								<td>
									{{ (conta.valorTitulo || 0) | formatarParaReais  }}
								</td>
								<td>
									{{ dateFormatBR(conta.dataDoc) }}
								</td>
								<td>
									{{ (conta.contarc || 0) | formatarParaReais }}
								</td>
								<td>
									{{ conta.valor }}
								</td>
								<td>
									{{ conta.dataUltimoPagamento }}
								</td>
								<td>
									{{ conta.diasEmAtraso }}
								</td>
								<td>
									{{ 
										(conta.jurosCalculados + (conta.jurosAcumulado || 0)) | formatarParaReais
									}}
								</td>
								<td>
									{{
										Number(Math.trunc(conta.restante * 100) / 100) | formatarParaReais
									}}
								</td>
							</tr>
						</tbody>
					</table>
					<div
						v-else
						class="container-warning"
						id="warning"
					>
						<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
						<h1>
							Nenhum registro encontrado.
						</h1>
						<h2>
							Por favor verifique o cliente ou fornecedor
						</h2>
					</div>
				</div>
				<div class="container-button-contas">
					<button
						class="botao botao-cancelar"
						@click="cancelarSelecaoTitulo"
					>
						Cancelar
					</button>
					<button
						class="botao botao-acao"
						@click="selecionarTitulo"
					>
						Selecionar
					</button>
				</div>
			</div>
		</Modal>
		<Modal
			v-if="alterarTitulo"
			@fecharModal="fecharModal"
			:dimensoes="{
				width: '80%',
				height: '80%'
			}"
			:idModal="'modal-conciliacao'"
		>
			<AdicionarTitulo
				@salvouTitulo="salvouTitulo"
				:Titulo="TituloAlterar"
				:tipoTitulo="TituloAlterar.tipo"
				@fecharLancamentoTitulo="fecharModal"
				@removerLancamento="fecharModal"
			/>
		</Modal>
		<Modal
			v-if="listarConsultaClientes"
			@fecharModal="listarConsultaClientes = false"
		>
			<consulta-cliente
				:queryPorProps="queryPorProps"
				@selecionarPessoa="handleSelecionaCliente"
			>

			</consulta-cliente>
		</Modal>
	</container-page>
</template>
<script>
import ContainerPage from "./ContainerPage.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import ContentPage from "./ContentPage.vue";
import Modal from "./Modal.vue";
import AdicionarTitulo from "./AdicionarTitulo.vue";
import ConsultaCliente from "./ConsultaCliente.vue";

import { dateFormatBR } from "../utils/dateformat";

export default {
	name: "Conciliacao",
	components: {
		ContainerPage,
		ContentPage,
		Modal,
		AdicionarTitulo,
		ConsultaCliente
	},

	filters: {
		formatarParaReais
	},

	data() {
		return {
			indexTituloParaConciliar: -1,
			modalConsultarTitulos: false,
			arquivoSelecionado: null,
			alterarTitulo: false,
			TituloAlterar: {
				importacao: true
			},
			contaSelecionada: "SEL",
			fileImportType: "ofx",
			clienteSelecionadoConciliar: {},
			listarConsultaClientes: false,
			queryPorProps: "",
			dateFormatBR,
			condicaoFiltro: "A",
		};
	},

	computed: {
		Transactions () {
			return this.$store.state?.Financeiro?.Importacao?.Transactions || [];
		},
		Contas () {
			return this.$store.state.Contas;
		},

		ListaTransacoesConciliadas() {
			return this.$store.state.ListaTransacoesConciliadas || [];
		},

		ContasReceber() {
			return this.$store.state.Financeiro.contasReceber.contas || [];
		}
	},


	mounted() {
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
	},

	destroyed() {
		this.$store.state.Financeiro.Conciliacao.Transactions = [];
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
	},

	methods: {
		async handleChangeCondicao() {
			const idCliente = this.clienteSelecionadoConciliar.idCliente;
			await this.$store.dispatch("buscarContasCdCliente", {
				cdCliente: idCliente,
				condicao: this.condicaoFiltro,
				sort: "datavenc",
				sortOrder: "asc",
				size: 150,
			});
		},

		handleCancelarConcilaoTitulo(index) {
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja desvincular o título?",
				tipo: "polar",
				item: {index}
			});
		},

		confirmarPolar({index}) {
			this.ListaTransacoesConciliadas[index].titulo = {};
			this.$forceUpdate();
		},

		cancelarSelecaoTitulo() {
			this.modalConsultarTitulos = false;
		},

		handleCheckbox(conta, index) {
			if(conta){
				this.ContasReceber[index].checked = !this.ContasReceber[index].checked;
				this.$forceUpdate();
			}
		},
		async handleSelecionaCliente(cliente) {
			this.modalConsultarTitulos = true;
			await this.$store.dispatch("buscarContasCdCliente", {
				cdCliente: cliente.id,
				condicao: "A",
				sort: "datavenc",
				sortOrder: "asc",
				size: 150,
			});
			this.clienteSelecionadoConciliar = {
				idCliente: cliente.id,
				nome: cliente.nome
			};
			this.listarConsultaClientes = false;
		},

		selecionarTitulo() {
			const titulosSelecionado = [];			
			this.ContasReceber.forEach(conta => {
				if(conta.checked) {
					titulosSelecionado.push(conta);
					this.ListaTransacoesConciliadas[this.indexTituloParaConciliar].titulo = {
						...conta,
						valor: conta.contarc,
						cdPessoa: conta.cdPessoa,
						nome: conta.nome,
						pagamento: conta.pagamento
					};
				}
			});
			if(titulosSelecionado.length > 1) return this.$store.commit("relatarErro", {
				mensagem: "Não é possível selecionar mais que um título"
			});
			this.modalConsultarTitulos = false;
		},

		async consultarContas(transf, index) {
			try {
				if(transf && transf.cdPessoa) {
					this.indexTituloParaConciliar = index;
					await this.$store.dispatch("buscarContasCdCliente", {
						cdCliente: transf.cdPessoa,
						condicao: "A",
						sort: "datavenc",
						sortOrder: "asc",
						size: 150,
					});
					this.modalConsultarTitulos = true;
					this.clienteSelecionadoConciliar = {
						idCliente: transf.cdPessoa,
						nome: transf.nome
					};
				} else {
					this.indexTituloParaConciliar = index;
					this.listarConsultaClientes = true;
					this.queryPorProps = transf.remetente;
				}
			} catch (error) {
				console.error(error);
			}
		},

		adicionarTitulo(transf, index) {
			this.alterarTitulo = true;
			const dataAtual = new Date().toLocaleDateString("en-CA");
			const dtCompetencia = `${dataAtual.split()[0]}-${dataAtual.split()[1]}`;
			this.indexTituloParaConciliar = index;
			this.TituloAlterar = {
				valorParcela: (transf.valor || 0).toLocaleString("pt-BR", {
					style: "decimal",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}),
				valorTitulo: transf.valor,
				nome: transf.titulo.nome,
				observacao: "",
				cddoc: "",
				valorextenso: "",
				tipo: transf.tipo === "RECEBIDO" ? "receita" : "despesas",
				databaixa: transf.data,
				datadoc: transf.data,
				datavenc: transf.data,
				competencia: dtCompetencia,
				situacaoTitulo: "B",
				conta: this.contaSelecionada,
				cdPessoa: transf.titulo.cdPessoa,
				ehPix: true,
				importacaoPDF: true,
				titulo: transf.titulo,
			};
		},

		conciliarTitulo(transf, index) {
			if(!transf.titulo.conciliado) {
				this.adicionarTitulo(transf, index);
			} else {
				const titulo = transf.titulo;
				const payload = {
					cdagencia: titulo.cdAgencia,
					cdbanco: titulo.cdBanco,
					cdconta: titulo.cdConta,
					cddig: titulo.cdDig,
					tipo: titulo.tipo
				};
				this.handleEditTitle(payload);
			}
		},

		async editarTitulo() {
			// console.log(transf.titulo, index);
			// const payload = {
			// 	cdagencia: titulo.cdAgencia,
			// 	cdbanco: titulo.cdBanco,
			// 	cdconta: titulo.cdConta,
			// 	cddig: titulo.cdDig,
			// 	tipo: titulo.tipo
			// };
			// const res = await this.$store.dispatch("buscarContaUnica", transaction);
			// console.log(payload);
		},

		handleChangeFileType(fileType) {
			const buttons = document.querySelectorAll(".botao-import");
			this.fileImportType = fileType;

			if(buttons) {
				buttons.forEach(btn => {
					btn.classList.toggle("clicked");
				});
			}
		},

		async salvouTitulo(titulo) {
			if(titulo.importacaoOFX) {
				this.setSavedTransaction(titulo);
			} else {
				// BAIXAR CONTAS
				const cliente = {
					id: titulo.cdPessoa,
					nome: titulo.nome,
				};

				const parcelas = [];
				if(titulo?.titulo) {
					parcelas.push(titulo.titulo);
					const { cdAgencia, cdBanco, cdConta, cdDig, cdEmpresa, tipo } = titulo.titulo;
					const cc = {
						cdagencia: cdAgencia,
						cdbanco: cdBanco,
						cdconta: cdConta,
						cddig: cdDig,
						cdempresa: cdEmpresa,
						tipo: tipo,
					};
					await this.$store.dispatch("baixarContas", {
						parcelas: parcelas,
						cliente: cliente,
						observa: "",
						tipoPgto: {
							cdtipo: titulo.cdTipoPgto
						},
						valorBaixa: titulo.valorTitulo,
						valorEntreguePeloCliente: titulo.valorTitulo,
						aumdesc: 0,
						cheques: [],
						contaCorrente: cc,
						dataBaixa: titulo.databaixa?.split("T")[0],
					});
				}


				this.ListaTransacoesConciliadas[this.indexTituloParaConciliar].titulo.conciliado = true;

				this.$forceUpdate();
			}
		},


		async setSavedTransaction(title) {
			const cddoc = this.removerEspacos(title.cddoc.substring(0, 20));

			const moviEncontrada = this.Transactions?.find(
				transaction => {
					const nmrMovi = this.removerEspacos(transaction.nmrMovimentacao.substring(0, 20));
					
					return nmrMovi === cddoc;
				}
			);
			if(moviEncontrada) {
				const { conta } = title;
				moviEncontrada.cdconta = conta.cc;
				moviEncontrada.cdbanco = conta.banco;
				moviEncontrada.cdagencia = conta.agencia;

				moviEncontrada.cddig = title.cddig;
				moviEncontrada.tipo = title.tipo === "despesa" ? 3 : 1;
	
				moviEncontrada.cddoc = cddoc;
			}
		},

		fecharModal() {
			this.alterarTitulo = false;
			this.TituloAlterar = {};
		},

		// async isRegistredTransaction(transaction) {
		// 	try {
		// 		const data = await this.$store.dispatch("findTransactionByValorExtenso", {
		// 			cddoc: this.removerEspacos((transaction?.nmrMovimentacao || "").substring(0, 20))
		// 		});
		// 		if(data && !data.financeiroId) {
		// 			this.handleEditTitle(transaction);
		// 		} else {
		// 			this.$store.commit("relatarErro", {
		// 				mensagem: "Transação está cadastrada no sistema, por favor verifique!",
		// 				alerta: true,
		// 			});
		// 		}
		// 	} catch (error) {
		// 		console.error(error);
		// 	}
		// },

		async handleEditTitle(transaction) {
			if(transaction.cddig) {
				const res = await this.$store.dispatch("buscarContaUnica", transaction);
				this.alterarTitulo = true;
				this.TituloAlterar = {
					editando: true,
					valorParcela: res.valorTitulo,
					valorTitulo: res.valorTitulo,
					observacao: res.observacao,
					cddoc: res.cddoc,
					valorextenso: res.valorextenso,
					tipo: res.tipo == 3 ? "despesa" : "receita",
					databaixa: res.databaixa,
					datadoc: res.datadoc,
					datavenc: res.datavenc,
					competencia: res.competencia,
					situacaoTitulo: "B",
					conta: res.conta,
					planoContas: res.planoContas,
					cdTipoPgto: res.cdTipoPgto,
					cdPessoa: res.cdPessoa,
					nome: res.nome,
					origem: res.origem,
					cddig: res.cddig,
					cdEmpresa: res.cdempresa,
					cdlan: res.cdlan,
					importacaoOFX: true,
				};
				return;
			}

			const dataTransaction = transaction.data;
			const dtCompetencia = `${dataTransaction.split("-")[0]}-${dataTransaction.split("-")[1]}`;
			this.alterarTitulo = true;
			this.TituloAlterar = {
				valorParcela: (transaction.valor || 0).toLocaleString("pt-BR", {
					style: "decimal",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}),
				valorTitulo: transaction.valor,
				observacao: transaction.nmrMovimentacao,
				cddoc: this.removerEspacos(`${transaction?.nmrMovimentacao || ""}`?.trim().substring(0, 20)),
				valorextenso: this.removerEspacos((transaction?.nmrMovimentacao || "")?.substring(0, 20)),
				tipo: transaction.tipo === 3 ? "despesa" : "receita",
				databaixa: dataTransaction,
				datadoc: dataTransaction,
				datavenc: dataTransaction,
				competencia: dtCompetencia,
				situacaoTitulo: "B",
				conta: this.contaSelecionada,
				importacaoOFX: true,
			};
		},

		removerEspacos(str) {
			return str.replace(/\s/g, "");
		},

		handleSelectFile(e) {
			const file = e.target.files[0];
			if(!file?.name?.endsWith(".ofx") && this.fileImportType === "ofx") {
				return this.$store.commit("relatarErro", {
					mensagem: "Arquivo inválido, por favor selecione um arquivo com a extensão '.ofx'"
				});
			} else if(!file?.name?.endsWith(".pdf") && this.fileImportType === "pdf") {
				return this.$store.commit("relatarErro", {
					mensagem: "Arquivo inválido, por favor selecione um arquivo com a extensão '.pdf'"
				});
			} else {
				this.arquivoSelecionado = file;
			}
		},

		async realizarImportacao() {
			
			if(!this.arquivoSelecionado) {
				return;
			}

			if(this.fileImportType === "ofx") {
				const reader = new FileReader();
				reader.onloadend = async () => {
					const dataURL = reader.result;
					
					const base64Data = dataURL.split(",")[1];
					await this.$store.dispatch("realizarProcessamentoOFX", base64Data);
				};
				reader.readAsDataURL(this.arquivoSelecionado);
			} else {
				await this.$store.dispatch("conciliarTitulosPDF", this.arquivoSelecionado);
			}
		},
	}
};
</script>
<style scoped>
.container-warning{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.container-tabela-contas{
	width: 100%;
	height: 78vh;
}
.container-button-contas{
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 10px;
}

.container-tabela-contas table{
	width: 100%;
}
.container-tabela-contas table thead tr th{
	width: 150px;
}

.header-page{
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.container-type {
	width: 255px;
	background-color: #dfdfdf;
	border-radius: 10px;
	padding: 5px;

	display: flex;
	justify-content: space-around;
	align-items: center;
}

.container-type button{
	font-size: 14px;
	padding: 5px 10px;
	background-color: #fff;
	border: none;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	transition: all .2s ease-in-out;
}

.container-type button:hover {
	background-color: var(--primario);
	color: #FFF;
}
.container-type button.clicked {
	background-color: var(--primario);
	color: #FFF;
}

.btn-import {
	width: auto;
	border: 1px solid #cecece;
	background: #FFF;
	padding: 5px 20px;
	font-size: 16px;
	border-radius: 8px;
	transition: all .2s ease-in;
}

.btn-import:hover {
	background: var(--acao-background);
	color: #FFF;
	border: 1px solid var(--acao-borda);
}

#import-input{
	display: none;
}

.label-import {
	display: flex;
	align-items: center;
	border: 1px solid #cecece;
	padding: 5px 10px;
	border-radius: 8px;
}
.label-import i {
	color: var(--primario);
	font-size: 18px;
} 



.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 73vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.container-transactions{
	width: 89vw;
	overflow: auto;
	height: 73vh;
	padding: 25px 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.container-card{
	display: flex;
	align-items: center;
	width: 100%;
}

.card-transaction{
	width: 350px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: var(--primario) 0px 1px 3px 0px, var(--acao-background) 0px 0px 0px 1px;
}


.card-fornecedor h3 {
	display: flex;
	align-items: center;
}

.card-fornecedor .title-icon span,
.card-transaction h3{
	font-size: 14px;
	font-weight: bold;
}

.card-fornecedor .title-icon span {
	width: 99%;
}

.card-fornecedor .title-icon {
	display: flex;
	align-items: flex-start;
}

.title-icon i{
	color: var(--deletar);
	font-size: 16px;
}

.card-fornecedor{
	width: 350px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: var(--primario) 0px 1px 3px 0px, var(--acao-background) 0px 0px 0px 1px;
	background-color: #9dc8ff36;
}

.card-info-container {
	display: flex;
	align-items: center;
	gap: 25px;
	margin-top: 15px;
}
.card-info{
	display: flex;
	flex-direction: column;
	width: auto;
}

.card-info-title{
	font-size: 14px;
	color: #aaaaaa;
}

.card-info-value{
	font-size: 14px;
	font-weight: bold;
}

.line-card{
	width: 600px;
	background-color: var(--primario);
	height: 1px;
}

.card-icon{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: -20px;
}

.icon-container{
	width: 42px;
	height: 42px;
	background-color: var(--primario);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-maior {
	width: 325px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.campo-medio {
	width: 220px;
	margin-left: 10px;
}
.campo-data,
.campo-valor {
	width: 150px;
	text-align: center;
}
.campo-pequeno {
	width: 125px;
	text-align: center;
}

.campo-metodo{
	width: 150px;
	text-align: center;
}
.fa-edit {
	color: var(--primario);
}
.title-lancado{
	background-color:  #6170f779 !important;
	color: #FFF;
}
.fa-search{
	color: var(--primario);
}

.container-radios{
	display: flex;
	gap: 10px;
}

.container-radios div {
	display: flex;
	gap: 3px;
	align-items: center;
}

.container-inputs{
	display: flex;
	align-items: center;
	width: 100%;
}

.container-inputs .input-group{
	width: 65%;
}


</style>
