<template>
	<div class="div-principal-compra">
		<div class="aba-controle">
			<h3 class="aba abaSelecionada" @click="mudarAba(0)">
				Dados
			</h3>
			<h3 class="aba" @click="mudarAba(1)">
				Produtos
			</h3>
			<h3 class="aba" @click="mudarAba(2)">
				Financeiro
			</h3>
		</div>
		<div class="cabeca-compra" v-if="mostrarAba == 'Dados'">
			<div class="identificador-compra overflow">
				<fieldset>
					<legend>
						Identificação
					</legend>
					<div class="identificador-compra-item">
						<div class="row w-100">
							<div class="input-group">
								<input 
									v-model="compra" 
									type="text" 
									id="compra"
									@keydown.enter="proximoCampo('datacompra')" 
									@keydown="mascaraInteger" 
									:disabled="cdcompra || compraXML.tipo == 'I'"
								/>
								<label for="compra">
									Nº Compra
								</label>
							</div>
							<div class="input-group">
								<input 
									v-model="data" 
									type="date" 
									class="input-date" 
									id="datacompra" 
									@keydown.enter="proximoCampo('chegada')"
									:disabled="(cdcompra && data) || compraXML.tipo == 'I'"
									@blur="saiuData()"
								/>
								<label for="datacompra">
									Data
								</label>
							</div>
							<div class="input-group">
								<input 
									v-model="chegada" 
									type="date" 
									class="input-date"
									id="chegada" 
									@keydown.enter="proximoCampo('cfop')"
									:disabled="!podeAlterarEstoque"
									@blur="saiuData()"
								/>
								<label for="chegada">
									Chegada
								</label>
							</div>
						</div>
					</div>
					<div class="identificador-compra-item">
						<div class="row w-100 wrap">
							<div class="input-group">
								<input 
									v-model="cfop" 
									type="text" 
									id="cfop"
									@focus="(e) => e.target.select()" 
									maxlength="4" 
									@keydown="mascaraInteger"
									autocomplete="off"
									@input="alterarCfop" 
									@keydown.enter="proximoCampo('chave')" :disabled="!podeAlterarEstoque"
								/>
								<label for="cfop">
									CFOP
								</label>
							</div>
							<div class="input-group">
								<select
									id="confirmada"
									v-model="compraXML.confirmada"
									@beforeinput="alterarEntradaConfirmada"
									:disabled="!$store.state.Usuario.usuarioPrivilegiado || (compraXML.confirmada != 'C')"
								>
									<option 
										value="A" 
										v-if="compraXML.confirmada == 'A'"
									>
										Sem registro
									</option>
									<option 
										value="N" 
										v-if="compraXML.confirmada == 'N'"
									>
										Sem lançamentos
									</option>
									<option value="L">
										Em lançamento de produtos
									</option>
									<option value="F">
										Em lançamento de financeiro
									</option>
									<option value="C">
										Em conferência
									</option>
									<option value="S" v-if="compraXML.confirmada == 'S'">
										Confirmada
									</option>
								</select>
								<label for="confirmada">
									Situação
								</label>
							</div>
						</div>
					</div>
					<fieldset 
						v-if="compraXML.confirmada == 'A' || compraXML.tipo == 'I'"
					>
						<legend>
							NFe
						</legend>
						<div class="identificador-compra-item compra-item-input">
							<div class="input-group">
								<input 
									v-model="chave" 
									type="text" 
									id="chave" 
									autocomplete="off"
									@keydown.enter.prevent="iniciarSelecaoFornecedor"
									:disabled="cdcompra || compraXML.tipo == 'I'" 
									@keydown.tab.prevent="solicitarLancamentoXML"
								/>
								<label for="chave">
									Chave NFe
								</label>
							</div>
							<div class="row center" v-if="!nfeid && compraXML.confirmada == 'A'">
								<span 
									class="botao botao-acao botao-xml-tamanho" 
									@click="solicitarLancamentoXML" 
									id="importar-xml"
								>
									<i class="far fa-plus-square" style="color: var(--acao-texto);"></i>
									<span>Importar XML [Alt + I]</span>
								</span>
								<input 
									type="file" 
									id="js-xml" 
									style="display:none"
									@input="lerArquivoXML"
								>
							</div>
							<div 
								class="row center" 
								v-else-if="compraXML.confirmada == 'A'"
							>
								<button 
									class="botao botao-cancelar" 
									style="min-width:180px" 
									@click="limparDadosImportados"
								>
									<i class="fas fa-eraser" style="color: var(--cancelar-texto);"></i>
									<span>
										Limpar dados
									</span>
								</button>
							</div>
						</div>
						<div 
							class="identificador-compra-item compra-item-input" 
							v-if="compraXML.tipo == 'I'"
						>
							<div class="input-group">
								<input 
									:value="compraXML.natureza" 
									type="text" 
									id="natureza" 
									disabled
									autocomplete="off"
								>
								<label for="natureza" class="label-item">
									Natureza
								</label>
							</div>
						</div>
						<Modal 
							v-if="modalBuscaXML" 
							@fecharModal="modalBuscaXML = false, filtroAtivo = true"
						>
							<div class="selecionar-xml-container">
								<div class="headerXML">
									<div>
										<label class="novo-botao" for="js-xml">
											<i class="far fa-plus-square"></i>
											<span>Escolher arquivo no dispositivo</span>
										</label>
									</div>
									<div class="checked">
										<div class="selecionar">
											<input v-model="filtroAtivo" :value="true"  id="filtroAtivo" @click="mudarAtivo()" type="checkbox"/>
											<label for="filtroAtivo" class="check"></label>
											<span v-if="filtroAtivo">&nbsp;ATIVO</span>
											<span v-else>&nbsp;INATIVO</span>
										</div>
									</div>
								</div>
								<div class="tabela-xml-importados-container">
									<table class="tabela-xml-importados" v-if="xmlIntegracao.length">
										<thead>
											<tr>
												<td colspan="5" style="text-align: center">XML importados via integração e não lançados</td>
											</tr>
											<tr>
												<td>Fornecedor</td>
												<td>Emissão</td>
												<td>Chave</td>
												<td>Status</td>
												<td>Ação</td>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(xml, index) in xmlIntegracao" :key="index">
												<td class="text-left">{{ xml.nomeFornecedor }}</td>
												<td class="text-center">{{ xml.emissao ? new Date(`${xml.emissao}`.split("-")).toLocaleDateString("pt-BR") : "SEM CADASTRO"}}</td>
												<td class="text-center">{{ xml.chave }}</td>
												<td class="text-center">{{ xml.status }}</td>
												<td class="center">
													<i class="fas fa-cloud-download-alt" @click="selecionarXMLintegracao(xml)" style="color: var(--primario);"></i>
													<i class="far fa-times-circle" style="color: var(--deletar)" v-if="xml.ativo == 'A'" @click="desativarXML(xml)"></i>
													<i class="far fa-check-circle" style="color: var(--confirmar)" v-else></i>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</Modal>
					</fieldset>
				</fieldset>

				<fieldset>
					<legend>Fornecedor</legend>
					<div class="identificador-compra-item">
						<div class="row row-small" style="width:100%">
							<i
								v-if="compraXML.confirmada == 'A'"
								class="fas fa-address-book btn-icone-primario"
								style="color: var(--primario);" 
								@click="iniciarSelecaoFornecedor"
							></i>
							<div class="input-group" style="width:100%">							
								<input type="text" id="fornecedor" 
									:value="fornecedor ? `${fornecedor.nome ? fornecedor.id + ' - ' + fornecedor.nome : ''}` : ''"
									:style="`${fornecedor.nome ? fornecedor.id == 0 ? 'background: yellow;' : '' : ''}`"
									disabled>
								<label class="nocel" v-if="compraXML.confirmada == 'A'">Clique no livro de endereços para selecionar o fornecedor</label>
								<label class="nocel" v-else>Fornecedor</label>
								<label class="cel">Fornecedor</label>
							</div>
						</div>
						<div class="row center row-small" v-if="compraXML.confirmada == 'A'">
							<label for="checkfor" class="pointer">Cadastrado:&nbsp;</label>
							<input 
								v-model="checkboxFornecedor" 
								type="checkbox"
								id="checkfor"
								:value="true"
								class="pointer"
								@click="cadastrarFornecedor"
							>
						</div>
					</div>
					<div class="identificador-compra-item  compra-item-input" v-if="fornecedor.endereco">
						<div class="input-group">
							<input 
								type="text" 
								disabled 
								id="enderecoFornecedor"
								:value="`${fornecedor.endereco}${fornecedor.numero ? `, ${fornecedor.numero}` : ''}${fornecedor.nomecidade ? ` / ${fornecedor.nomecidade}` : ''}${fornecedor.uf ? ` - ${fornecedor.uf}` : ''}`"
							/>
							<label for="enderecoFornecedor">
								Endereço&nbsp;
							</label>
						</div>
					</div>
				</fieldset>

				<fieldset v-if="compraXML.confirmada == 'A' || compraXML.transporte">
					<legend>Transporte</legend>
					<div class="identificador-compra-item">
						<div class="row row-small" style="width:100%">
							<i
								v-if="compraXML.confirmada == 'A'"
								class="fas fa-address-book btn-icone-primario"
								style="color: var(--primario);"
								@click="iniciarSelecaoTransportadora"
							></i>
							<div class="input-group" style="width:100%">							
								<input
									type="text"
									id="transp"								
									:value="transp ? `${transp.nome ? transp.id + ' - ' + transp.nome : ''}` : ''" 
									:style="`${transp.nome ? transp.id == 0 ? 'background: yellow;' : '' : ''}`"
									disabled
								>
								<label class="nocel" v-if="compraXML.confirmada == 'A'">Clique no livro de endereços para selecionar a transportadora</label>
								<label class="nocel" v-else>Transportadora</label>
								<label class="cel">Transportadora</label>
							</div>
						</div>
						<div class="row center row-small" v-if="compraXML.confirmada == 'A'">
							<label for="checkTransportadora" class="pointer">Cadastrado:&nbsp;</label>
							<input 
								v-model="checkboxTransportadora"
								class="pointer"
								type="checkbox" 
								id="checkTransportadora"
								:value="true"
								@click="cadastrarTransportadora"
							>
						</div>
					</div>
					<div class="identificador-compra-item" v-if="transp.endereco">
						<div class="input-group">
							<input type="text" disabled id="enderecoTransporte"
							:value="`${transp.endereco}${transp.numero ? `, ${transp.numero}` : ''}${transp.nomecidade ? ` / ${transp.nomecidade}` : ''}${transp.uf ? ` - ${transp.uf}` : ''}`">
							<label for="enderecoTransporte">Endereço&nbsp;</label>
						</div>
					</div>
					<div class="identificador-compra-item compra-dados-transporte" v-if="transp.transporte">
						<div class="input-group">
							<input type="text" disabled id="qtdvolumetransporte" 
							:value="Number(transp.transporte.qtdvolumetransporte || 0).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})">
							<label for="qtdvolumetransporte">Volume&nbsp;</label>
						</div>
						<div class="input-group">
							<input type="text" disabled id="embalagemtransporte" :value="transp.transporte.embalagemtransporte">
							<label for="embalagemtransporte">Embalagem&nbsp;</label>
						</div>
						<div class="input-group">
							<input type="text" disabled id="pesobrutotransporte" 
							:value="Number(transp.transporte.pesobrutotransporte || 0).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})">
							<label for="pesobrutotransporte">Peso Bruto&nbsp;</label>
						</div>
						<div class="input-group" style="margin-top: 10px; width: 100%;">
							<input type="text" disabled id="pesoliquidotransporte" 
							:value="Number(transp.transporte.pesoliquidotransporte || 0).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})">
							<label for="pesoliquidotransporte">Peso líquido&nbsp;</label>
						</div>
					</div>
				</fieldset>

				<fieldset class="fieldset-totais" v-if="compraXML.tipo == 'I'">
					<legend>Totais</legend>
					<div class="identificador-compra-item wrap">
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.baseicms || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="baseicms" 
									readonly 
									placeholder="R$ 0,00" 
									@keydown.enter="proximoCampo('valorICMS')" 
								> 
								<label for="baseicms">
									Base de cálculo ICMS
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.valorICMS || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="valorICMS" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorFCP')"
								/>
								<label for="valorICMS">
									Valor do ICMS&nbsp;
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.basesubst || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape"
									type="text"
									id="basesubst"
									readonly
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorICMSst')"
								/>
								<label for="basesubst">
									Base de cálculo ICMS ST
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.valorICMSst || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text"
									id="valorICMSst" 
									readonly
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('vlrbrutocompra')"
								>
								<label for="valorICMSst">
									Valor ICMS ST&nbsp;
								</label>
							</div>
						</div>
					</div>
					<div class="identificador-compra-item wrap" v-if="compraXML.tipo == 'I'">
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.FCP || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text"
									id="valorFCP"
									readonly
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('basesubst')"
								/>
								<label for="valorFCP">
									Valor do FCP&nbsp;
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.vlrbrutocompra || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text"
									id="vlrbrutocompra"
									readonly
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorFrete')"
								/>
									
								<label for="vlrbrutocompra">
									Valor total produtos
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.frete || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="valorFrete" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorDesconto')"
								/>
								<label for="valorFrete">
									Valor frete
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.desconto || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="valorDesconto" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('vlripi')"
								/>
								<label for="valorDesconto">
									Valor desconto
								</label>
							</div>
						</div>
					</div>
					<div class="identificador-compra-item wrap" v-if="compraXML.tipo == 'I'">
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.outros || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="valorOutros" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorbrutopedido')"
								/>
								<label for="valorOutros">
									Valor total outras despesas
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.vlripi || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="vlripi" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('valorOutros')" 
								/>
								<label for="vlripi">
									Valor total IPI
								</label>
							</div>
						</div>
						<div class="row between">
							<div class="input-group">
								<input 
									:value="(compraXML.original.valorbrutopedido || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
									class="input-rodape" 
									type="text" 
									id="valorbrutopedido" 
									readonly 
									placeholder="R$ 0,00"
									@keydown.enter="proximoCampo('totalFrete')"
								/>
								<label for="valorbrutopedido">
									Valor total NFe&nbsp;
								</label>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="cabeca-compra" v-else-if="mostrarAba == 'Financeiro'">
			<div class="cabeca-financeiro">
				<div class="cabeca-financeiro-item" v-if="compraXML.tipo == 'I'">
					<input
						id="total-nfe-financeiro"
						type="text"
						readonly 
						:value="`Valor total NFe: ${(compraXML.original.valorbrutopedido || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`"
					>
					<button
						class="botao botao-acao"
						style="margin-left: 15px;"
						@click="handleVariacaoFinanceiro"
					>
						Variar produtos
					</button>
				</div>
				<div class="cabeca-financeiro-item" v-else>
					<div class="input-group">
						<input id="total-compra" type="text" readonly
							:value="`Total compra: ${(totalFinanceiroXML || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`">
					</div>
				</div>
				<div class="cabeca-financeiro-item" v-if="saldoFinanceiro > 0.005">
					<input id="restante-financeiro" type="text" readonly
						:value="`Saldo: ${(saldoFinanceiro || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`">
				</div>
				<div class="cabeca-financeiro-item" v-if="saldoFinanceiro > 0.005 && podeAlterarFinanceiro">
					<button class="botao botao-acao" @click="adicionarLinhaFinanceiro" style="min-width:200px">
						<i class="far fa-plus-square" style="color: var(--acao-texto);"></i>
						<span>Novo financeiro</span>
					</button>
				</div>
				<div class="cabeca-financeiro-item" v-if="alterarFinanceiroCompraConfirmada && !novoFinanceiroCompraConfirmada">
					<button
						class="botao botao-acao"
						@click="lancarNovoFinanceiro"
						style="min-width:200px"
					>
						<i class="far fa-plus-square" style="color: var(--acao-texto);"></i>
						<span>Novo financeiro</span>
					</button>
				</div>
				<div class="container-novo-financeiro" v-if="alterarFinanceiroCompraConfirmada && novoFinanceiroCompraConfirmada">
					<div class="input-group">
						<select id="formaPgto" v-model="novoFinanceiro.formaPgto">
							<option value="SEL" disabled>
								Selecione uma opção
							</option>
							<option
								v-for="fp in formasPagamento"
								:key="fp.cdtipo"
								:value="fp"
							>
								{{ fp.cdtipo }} - {{ fp.descricao }}
							</option>
						</select>
						<label for="formaPgto">
							Forma pagamento
						</label>
					</div>
					<div class="input-group">
						<input
							type="text"
							id="frequencia"
							placeholder=" "
							v-model="novoFinanceiro.frequencia"
							v-mascaraInteger
							@keydown.enter="proximoCampo('primeiroVenc')"
							autocomplete="off"
						/>
						<label for="frequencia">Frequência</label>
					</div>
					<div class="input-group">
						<input
							type="date"
							id="primeiroVenc"
							v-model="novoFinanceiro.primeiroVenc"
							@keydown.enter="proximoCampo('valorFinanceiro')" 
							autocomplete="off"
						/>
						<label for="primeiroVenc">1° Vencimento</label>
					</div>
					<div class="input-group">
						<input
							type="text"
							id="valorFinanceiro"
							v-model="novoFinanceiro.valor"
							v-mascaraFloat
							@keydown.enter="proximoCampo('qtdParcela')" 
							autocomplete="off"
						/>
						<label for="valorFinanceiro">Valor</label>
					</div>
					<div class="input-group">
						<input
							type="text"
							id="qtdParcela"
							v-model="novoFinanceiro.qtdParcela"
							v-mascaraInteger
							autocomplete="off"
							@keydown.enter="proximoCampo('confirmarNovoFinanceiro')"
						/>
						<label for="qtdParcela">Qtd parcela</label>
					</div>
					<button class="botao botao-adicionar" style="width: 10%;" id="confirmarNovoFinanceiro" @click="adicionarNovasParcelas">
						Confirmar
					</button>
				</div>
			</div>
			<div class="tabela-scroll" v-if="compraXML.financa.length">
				<table class="tabela-financeiro">
					<thead>
						<tr>
							<td class="text-center" v-if="podeAlterarFinanceiro">Remover</td>
							<td class="text-center">Parcela</td>
							<td class="text-center text-nowrap" v-if="podeAlterarFinanceiro">
								<label for="forma-pagamento">Forma de pagamento:</label>
								&nbsp;
								<select id="forma-pagamento" v-model="metodoPagamentoSelecionado"
									@change="escolherMetodoPagamento" class="my-5">
									<option :value="null">Selecione</option>
									<option v-for="metodo in formasPagamento" :key="metodo.cdtipo" :value="metodo">
										{{ metodo.descricao }}
									</option>
								</select>
							</td>
							<td class="text-center text-nowrap" v-else>Forma de pagamento</td>
							<td class="text-center">Vencimento</td>
							<td class="text-center">Baixa</td>
							<td class="text-center">Valor</td>
							<td style="min-width: 300px">
								Observação
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(fina, index) in compraXML.financa" :key="index" :style="`color: ${fina.databaixa?'green':'black'}`">
							<td class="text-center" v-if="podeAlterarFinanceiro">
								<i class="fas fa-trash-alt" style="color: var(--deletar)" @click="removerPagamento(index)"></i>
							</td>
							<td class="text-center">{{ fina.parcela }}</td>
							<td class="text-center" v-if="podeAlterarFinanceiro">
								<div class="col between">
									<select :id="`formaRecebimento-${index}`" v-model="fina.formaPagamento" class="p-2"
										@change="alterarPagamento(index)" @keydown.enter.prevent="validarCamposFinanceiro"
									>
										<option :value="null" selected>Selecione</option>
										<option :value="metodo" v-for="metodo in formasPagamento" :key="metodo.cdtipo">
											{{ metodo.descricao }}</option>
									</select>
								</div>
							</td>
							<td class="text-center text-nowrap" v-else>
								{{fina.cdtipo}} - {{fina.pagamento}}
							</td>
							<td style="text-align: right; width: max-content;" v-if="podeAlterarFinanceiro || alteraVencimento">
								<input
									v-model="fina.datavenc"
									:id="`datavenc-${index}`"
									class="p-2 w-100 input-date"
									type="date"
									@keydown="mascaraDateFinanceiro"
									@keydown.enter.prevent="validarCamposFinanceiro"
									@blur="saiuData(fina)"
								>
							</td>
							<td class="text-center text-nowrap" v-on:dblclick="alteraDataVencimento" v-else>
								{{`${fina.datavenc}`.includes("-") ? `${fina.datavenc}`.split("-").reverse().join("/") : fina.datavenc}}
							</td>
							<td class="text-center text-nowrap">
								{{fina.databaixa?fina.databaixa.split('T')[0].split('-').reverse().join('/'):"-"}}
							</td>
							<td 
								v-if="podeAlterarFinanceiro"
								class="text-right"
							>
								<input
									v-model="fina.contarcvlr"
									:id="`contarc-${index}`"
									class="input-tabela w-100"
									type="text"
									autocomplete="off"
									placeholder="R$ 0,00"
									@contextmenu.prevent=""
									v-mascaraFloat
									@input="capturarAlteracaoFinanceiro(index)"
									@keydown.enter.prevent="validarCamposFinanceiro"
								>
							</td>
							<td class="text-center" v-else>
								{{(fina.contarc || 0).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}}
							</td>
							<td
								v-if="podeAlterarFinanceiro || novoFinanceiroCompraConfirmada"
							>
								<input
									type="text"
									v-model="fina.observacao"
									style="width: 100%"
									:id="`observa-${index}`"
									autocomplete="off"
								/>
							</td>
							<td v-else>
								{{ fina.observacao }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="total-tabela-financeiro" v-if="compraXML.financa.length">
				<div class="input-group">
					<input
						type="text"
						id="total-parcelas"
						:value="totalFinanceiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" 
						readonly
						@contextmenu.prevent=""
					>
					<label for="total-parcelas">Total parcelas</label>
				</div>
			</div>
		</div>

		<div class="cabeca-compra" v-else-if="mostrarAba == 'Produtos'">
			<Modal
				v-if="variarProduto"
				v-on:fecharModal="cancelarVariacaoProduto({validaQtd: true})"
				:dimensoes="{width: 'auto', height: 'auto'}"
			>
				<div class="lancamento-produto w-100 w-90vw h-90vh" >
					<h2>VARIAÇÃO DE PRODUTO</h2>
					<div class="variacao-produto-compra w-100">
						<GeraVariacao :produtoPai="variarProduto" v-on:cancelarVariacao="cancelarVariacaoProduto({validaQtd: true})"
							v-on:gerarVariacoes="GerarVariacao" v-if="!variarProduto.filhos.length" class="w-100" 
							:geraVariacaoCompra="true" :produtosAgrupados="produtosAgrupados" 
						/>
						<div class="row around w-100" v-else-if="variarProduto.filhos.length">
							<div class="row around w-100" v-if="compraXML.confirmada != 'S' && !somenteConsulta">
								<button class="botao botao-cancelar w-40 my-5" @click="limparFilhos">
									<i class="fas fa-eraser" style="color: var(--cancelar-texto)"></i>
									Editar
								</button>
								<button class="botao botao-adicionar w-40 my-5" @click="salvarVariacao">
									<i class="fa fa-check" style="color: var(--salvar-texto)"></i>
									Confirmar
								</button>
							</div>
							<div class="row around w-100" v-else>
								<button class="botao botao-acao w-40 my-5" @click="cancelarConsultaVariacao">
									<i class="fas fa-check" style="color: var(--acao-texto)"></i>
									Fechar
								</button>
							</div>
						</div>
						<div class="produto-filhos" v-if="variarProduto.filhos.length ">
							<table v-if="variarProduto.filhos" class="w-100">
								<thead>
									<tr>
										<td style="color: blue; text-align: center;">Cód.</td>
										<td class="text-left">Produto</td>
										<td class="text-center text-nowrap">Qtd. Compra</td>
										<td class="text-center text-nowrap">$ Custo</td>
										<td class="text-center text-nowrap">$ Margem</td>
										<td class="text-center text-nowrap">$ Prazo</td>
										<td class="text-center text-nowrap">% Desc.</td>
										<td class="text-center text-nowrap">$ Vista</td>
										<td class="text-center text-nowrap">Depende</td>
										<td class="text-center text-nowrap" v-if="!somenteConsulta">Ação</td>
									</tr>
								</thead>
								<tbody>
									<tr class="itemProd" v-for="(profilho, index) in variarProduto.filhos"
										:key="index">
										<td class="text-center">{{ profilho.cdproduto }}</td>
										<td class="text-left text-nowrap">{{ profilho.descricao }}</td>
										<td class="text-right">
											<input class="input-tabela" type="text" v-model="profilho.quantidade"
												:id="`quantidade-${index}`" @focus="e => e.target.select()"
												@contextmenu.prevent="" autocomplete="off"
												@keydown.enter="proximoCampo(`markup-${index}`)">
										</td>
										<td class="text-right">
											<input class="input-tabela" type="text" :id="`custo-${index}`" disabled
												@contextmenu.prevent="" autocomplete="off"
												:value="(profilho.vlrunitario || 0).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })">
										</td>
										<td class="text-right">
											<input class="input-tabela" type="text" v-model="profilho.markup"
												:id="`markup-${index}`" @input="calcularValorVendaFilhos"
												@focus="e => e.target.select()" @contextmenu.prevent=""
												autocomplete="off"
												@keydown.enter="proximoCampo(`pontoequi-${index}`)">
										</td>
										<td class="text-right">
											<input class="input-tabela" type="text" v-model="profilho.pontoequi"
												:id="`pontoequi-${index}`" @input="calcularValorVendaFilhos"
												@focus="e => e.target.select()" @contextmenu.prevent=""
												autocomplete="off"
												@keydown.enter="proximoCampo(`descvista-${index}`)">
										</td>
										<td class="text-right">
											<input class="input-tabela" type="text" v-model="profilho.descvista"
												:id="`descvista-${index}`" @input="calcularValorVendaFilhos"
												@focus="e => e.target.select()" @contextmenu.prevent=""
												autocomplete="off"
												@keydown.enter="proximoCampo(`vlrvista-${index}`)">
										</td>
										<td class="text-right">
											<input class="input-tabela" type="text" v-model="profilho.vlrvista"
												:id="`vlrvista-${index}`" @input="calcularValorVendaFilhos"
												@focus="e => e.target.select()" @contextmenu.prevent=""
												autocomplete="off"
												@keydown.enter="proximoCampo(`quantidade-${index + 1 == variarProduto.filhos.length ? 0 : index + 1}`)">
										</td>
										<td class="text-center text-nowrap">
											<input 
												type="checkbox" 
												v-model="profilho.ecf" 
												:value="true" 
												@change="alterarDependenciaFilho(profilho, index)"
												:disabled="somenteConsulta"
											/>
										</td>
										<td class="text-center text-nowrap" v-if="!somenteConsulta">
											<i class="fas fa-trash-alt" style="color: var(--deletar)"
												@click="removerFilho(index)"></i>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<div class="rodape">
				<div class="rodape-linha">
					<div class="rodape-linha-item" v-if="compraXML.tipo == 'I' && compraXML.confirmada != 'A'">
						<div class="funcionalidade-atual">
							<div class="checked">
								<div class="selecionar">
									<label for="visaoEstoque" v-if="visaoEstoque">Visão do estoque</label>
									<label for="visaoEstoque" v-else>Visão da nota</label>
									&nbsp;
									<input v-model="visaoEstoque" :value="true" id="visaoEstoque" type="checkbox"/>
									<label for="visaoEstoque" class="check"></label>
								</div>
							</div>
						</div>
					</div>
					<div class="rodape-linha-item" v-if="!visaoEstoque && podeAlterarEstoque && compraXML.confirmada != 'N'">
						<button class="botao botao-acao" @click="conciliarEstoque" style="min-width:180px" v-if="!conciliouEstoque">
							Conciliar estoque
						</button>
					</div>
					<div class="rodape-linha-item" v-if="visaoEstoque">
						<i
							class="fas fa-cog icone-botao"
							style="color: var(--primario)"
							@click="alterarConfiguracaoProduto"
							v-if="compraXML.tipo == 'I' && podeAlterarEstoque"
						></i>
						<Modal v-if="configurarProdutos" v-on:fecharModal="configurarProdutos = false"
							:dimensoes="{ width: 'auto', height: 'auto' }">
							<div class="lancamento-produto">
								<h2>Configurar entrada de produtos</h2>
								<div class="row center">
									<input type="checkbox" class="mx-5 pointer" id="usar-codbarra-fornecedor"
										:value="true" v-model="usarCodBarraFornecedor">
									<label for="usar-codbarra-fornecedor" class="pointer">
										Usar código de barras do fornecedor
									</label>
								</div>
								<div class="botoes">
									<button class="botao botao-cancelar" @click="configurarProdutos = false" style="min-width:100px">Cancelar</button>
									<button class="botao botao-adicionar" @click="atualizarConfigProduto" style="min-width:100px">Confirmar</button>
								</div>
							</div>
						</Modal>
					</div>
					<div class="input-group input-acao-massa" v-if="visaoEstoque && podeAlterarEstoque" style="max-width: 250px">
						<select 
							v-model="acaoEmMassa" 
							@change="alterarAcaoEmMassa"
						>
							<option :value="null">Ação em massa</option>
							<option value="incluirGrupoEmMassa" v-if="compraXML.tipo == 'I'">Informar grupo</option>
							<option value="agruparProdutos" v-if="compraXML.tipo == 'I'">Agrupar</option>
							<option value="removerProdutos">Remover</option>
							<option value="informarResumoEmMassa">Informar resumo</option>
							<option value="formarPrecoEmMassa">Formar preços</option>
							<option value="ajustarPrecos">Ajustar preços</option>
							<option value="editarDescricaoEmMassa">Remover da descrição</option>
						</select>
					</div>
				
					<div class="rodape-linha-item input-group" v-if="acaoEmMassa === 'editarDescricaoEmMassa'">
						<input 
							type="text"
							v-model="textoParaRemover"
							id="textoParaRemover"
						/>
						<label for="textoParaRemover">
							Descrição para cortar
						</label>
					</div>
					<div class="rodape-linha-item" v-if="acaoEmMassa === 'incluirGrupoEmMassa' && visaoEstoque">
						<div 
							class="input-group" 
							v-if="grupoSelecionado"
						>
							<input		 
								:value="`${grupoSelecionado.descricao}${grupoSelecionado.subGrupos.length ? ` / ${grupoSelecionado.subGrupos[0].descricao}` : ''}`" 
								type="text" 
								placeholder=" " 
								readonly
								id="grupoSelecionado"
							/>
							<label for="grupoSelecionado">
								Grupo Selecionado
							</label>
						</div>
						&nbsp;
						<button class="botao botao-acao" @click="consultarGrupoParaAcaoEmMassa" style="min-width:210px">
							<i class="fas fa-search" style="color: var(--acao-texto)"></i>
							Consultar Grupos&nbsp;
						</button>
					</div>
					<div class="rodape-linha-item" v-if="acaoEmMassa === 'informarResumoEmMassa'">
						<div class="input-group">
							<input 
								v-model="resumoEmMassa" 
								type="text" 
								maxlength="60" 
								autocomplete="off"
								@keydown.enter.prevent="aplicarAcaoEmMassa"
							>
							<label class="nocel">Digite o resumo</label>
							<label class="cel">Resumo</label>
						</div>
					</div>
					<div class="rodape-linha-item" v-if="acaoEmMassa && visaoEstoque">
						<button 
							class="botao botao-cancelar botao-acao-tamanho" 
							@click="cancelarAcaoEmMassa" 
							v-if="acaoEmMassa === 'informarResumoEmMassa'"
						>
							Cancelar
						</button>
						&nbsp;
						<button class="botao botao-acao botao-acao-tamanho" @click="aplicarAcaoEmMassa">
							Aplicar
						</button>
					</div>
					<div 
						class="rodape-linha-item input-pesquisa-produto" 
						id="codPro" 
						v-if="!acaoEmMassa && visaoEstoque && podeAlterarEstoque"
					>
						<div class="input-group">
							<input 
								v-model="cdprodutoOuCodbarra"
								type="text"
								id="cdprodutoOuCodbarra"
								@contextmenu.prevent=""
								autocomplete="off"
								@keydown="capturarEntradaCdprodutoOuCodbarra"
							>
							<label for="cdprodutoOuCodbarra" class="nocel">(F4) / Código / Cód. barra Produto</label>							
							<label for="cdprodutoOuCodbarra" class="cel">Código</label>
						</div>
						<h4 class="mx-5" style="color: var(--primario)" v-if="produtoEncontrado">{{ produtoEncontrado.descricao }}</h4>
						<button class="botao botao-acao botao-consultatamanho" @click="indexListaProduto = 0" v-else>
							<i class="fas fa-search" style="color: var(--acao-texto)"></i>
							Consultar Produtos
						</button>
					</div>
				</div>

				<div class="rodape-linha">
					<div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="(totalCompra || 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })"
							class="input-rodape" 
							type="text" 
							id="totalCompra" 
							readonly 
							placeholder="R$ 0,00"
							autocomplete="off"
							@keydown.enter="proximoCampo('totalFrete')"
						/>
						<label for="totalCompra">
							$ Total
						</label>
					</div>
					<!-- <div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="(totalCompra || 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })"
							class="input-rodape" 
							type="text" 
							id="totalCompra" 
							readonly 
							placeholder="R$ 0,00"
							autocomplete="off"
							@keydown.enter="proximoCampo('totalFrete')"
						/>
						<label for="totalCompra">
							$ Total
						</label>
					</div> -->
					<div class="input-group" v-if="!visaoEstoque">
						<input
							class="input-rodape" 
							type="text" 
							id="totalFreteNota" 
							readonly
							autocomplete="off"
							placeholder=" " 
							:value="totalFreteNota | formatarParaReal"
						/>
						<label for="totalFreteNota">
							$ Frete
						</label>
					</div>
					<div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="totalOutrosNota | formatarParaReal"
							class="input-rodape" 
							type="text" 
							id="totalOutrosNota" 
							readonly
							placeholder=" " 
							@contextmenu.prevent=""
							autocomplete="off"
						>
						<label for="totalOutros">
							$ Outros
						</label>
					</div>
					<div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="totalIpiNota | formatarParaReal"
							class="input-rodape" 
							type="text" 
							id="totalIpiNota" 
							placeholder=" " 
							readonly 
							autocomplete="off"
						/> 
						<label for="totalIpiNota">
							$ IPI.
						</label>
					</div>
					<div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="totalDescontoNota | formatarParaReal"
							class="input-rodape"
							type="text"
							id="totalDescontoNota"
							readonly
						/>
						<label for="totalDescontoNota">
							$ Desconto
						</label>
					</div>
					<div class="input-group" v-if="!visaoEstoque">
						<input 
							:value="totalICMSstNota | formatarParaReal "
							class="input-rodape" 
							type="text" 
							id="totalICMSstNota" 
							readonly
							placeholder=" " 
							autocomplete="off"
						/>
						<label for="totalICMSstNota">
							$ ICMSst.
						</label>
					</div>
					<div class="input-group large-input-group" v-if="!visaoEstoque">
						<input
							:value="valorFinalCompraNota | formatarParaReal"
							class="input-rodape" 
							type="text" 
							id="valorFinalCompraNota" 
							readonly 
							placeholder=" " 
							autocomplete="off"

						/>
						<label for="valorFinalCompraNota">
							$ Compra
						</label>
					</div>
					<div class="input-group large-input-group" v-if="visaoEstoque">
						<input
							:value="valorFinalCompraEstoque | formatarParaReal"
							class="input-rodape" 
							type="text" 
							id="valorFinalCompraNota" 
							readonly 
							placeholder=" " 
							autocomplete="off"

						/>
						<label for="valorFinalCompraNota">
							$ Compra
						</label>
					</div>
					<div class="input-group large-input-group" v-if="compraXML.confirmada != 'A' && visaoEstoque">
						<input 
							v-model="totalMargem" 
							class="input-rodape" 
							type="text" 
							id="totalMargem"
							@input="calcularMargemRateio" 
							@focus="selectNoCampoEScrollTabela" 
							@keydown="mascaraFloat"
							@contextmenu.prevent="" autocomplete="off"
							@keydown.enter.prevent="proximoCampo('totalDescontoVista')" 
							:readonly="!podeAlterarEstoque"
						/>
						<label for="totalMargem">
							% Margem
						</label>
					</div>
					<div class="input-group large-input-group" v-if="compraXML.confirmada != 'A' && visaoEstoque">
						<input 
							v-model="totalDescontoVista" 
							class="input-rodape" 
							type="text" 
							id="totalDescontoVista"
							@input="calcularDescontoVistaRateio" 
							@focus="selectNoCampoEScrollTabela" 
							:readonly="!podeAlterarEstoque"
							@contextmenu.prevent="" autocomplete="off"
							@keydown="mascaraFloat" 
							@keydown.enter.prevent="proximoCampo(compraXML.estoque.length ? 'prazo-0' : 'totalCompra')"
						/>
						<label for="totalDescontoVista">
							% Desconto
						</label>
					</div>
				</div>
			</div>

			<div class="tabela-scroll">
				<TabelaNotaCompra 
					:Itens="compraXML.itens"
					v-if="!visaoEstoque && this.compraXML.itens.length"
					:totalFCPST="totalFCPST"
				/>
			
				<table class="tabela-produtos" v-if="visaoEstoque && compraXML.estoque.length">
					<thead>
						<tr>
							<td style="color: var(--primario)" class="text-nowrap" v-if="acaoEmMassa && visaoEstoque">
								<input type="checkbox" @change="selecionarTodosProdutos" class="pointer mx-5" id="selecionar-todos" v-model="selecionarTodos" :value="true">
								<label for="selecionar-todos" class="pointer">Selec.</label>
							</td>
							<td 
								style="color: var(--primario)"
								v-else-if="!agrupamentoProdutos && visaoEstoque && podeAlterarValorEstoque"
							>
								Ação
							</td>
							<td 
								style="color: var(--primario)"
								v-else-if="!podeAlterarValorEstoque"
							>
								Ação
							</td>
							<td style="color: var(--primario)">Seq.</td>
							<td style="color: var(--primario)">CFOP</td>
							<td
								style="color: var(--primario)"
								@click="atualizargrupo"
								v-if="visaoEstoque"
							>
								Grupo
							</td>
							<td style="color: var(--primario)" v-if="visaoEstoque">
								Sub.
							</td>
							<td style="color: var(--primario)">
								Codigo
							</td>
							<td style="color: var(--primario)">
								Referência
							</td>
							<td style="text-align: left;">Produtos</td>
							<td class="center">Qtd.</td>
							<td class="center text-nowrap">$ Unit.</td>
							<td class="center">SubTotal</td>
							<td class="center" v-if="visaoEstoque">Markup</td>
							<td class="center text-nowrap" v-if="visaoEstoque">$ Prazo</td>
							<td class="center" v-if="visaoEstoque">Desc.</td>
							<td class="center text-nowrap" v-if="visaoEstoque">$ Vista</td>
							<td class="center">Unid.</td>
							<td class="center">NCM</td>
							<td class="center">Barra</td>
							<td style="text-align: left;">Resumo</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(prod, index) in compraXML.estoque" :key="index" @contextmenu.prevent="(e)=>iniciarAlteracaoProduto(index, prod, e)"
						:style="prod.bgcolor ? `background-color: ${prod.bgcolor}` : ''">
							<td class="text-nowrap" v-if="acaoEmMassa && podeAlterarEstoque">
								<input type="checkbox" :value="true" v-model="prod.selecionado" :id="`${index}-selecionado`" class="checkbox-tabela-produto pointer"
								:disabled="prod.cdproduto || prod.cdgrupo" v-if="acaoEmMassa == 'incluirGrupoEmMassa'">
								<input 
									type="checkbox" 
									:value="true"
									v-model="prod.selecionado"
									:id="`${index}-selecionado`"
									class="checkbox-tabela-produto pointer"
									v-else-if="acaoEmMassa === 'editarDescricaoEmMassa'"
								/>
								<input
									type="checkbox"
									:value="true"
									v-model="prod.selecionado"
									:id="`${index}-selecionado`"
									class="checkbox-tabela-produto pointer"
									:disabled="produtoComCodigo(prod)"
									v-else-if="acaoEmMassa == 'agruparProdutos'"
									@change="verificarProdutosParaAgrupamento(index)"
									@click="handleClickCheckbox(index)"
									@keydown="handleShiftKeydown"
									@keydown.down.prevent="proximoCampo(index + 1 === compraXML.estoque ? '0-selecionado' : `${index + 1}-selecionado`)"
									@keydown.up.prevent="proximoCampo(index === 0 ? '0-selecionado' : `${index - 1}-selecionado`)"
								>

								<input type="checkbox" :value="true" v-model="prod.selecionado" :id="`${index}-selecionado`" class="checkbox-tabela-produto pointer"
								v-else-if="acaoEmMassa == 'removerProdutos' || acaoEmMassa == 'informarResumoEmMassa' || acaoEmMassa == 'formarPrecoEmMassa' || acaoEmMassa == 'ajustarPrecos'"   >

							</td>
							<td class="center text-nowrap" v-else-if="!agrupamentoProdutos && podeAlterarValorEstoque">
								
								<i 
									class="fas fa-eye"
									style="color: var(--primario)"
									v-show="prod.outrosProdutos ? prod.outrosProdutos.length > 0 : false"
									@click="visualizarOutrosProdutos(prod, index)"
								/>
								<i 
									class="fas fa-edit"
									style="color: var(--confirmar)"
									@click="(e) => iniciarAlteracaoProduto(index, prod, e)"
									v-if="podeAlterarValorEstoque"
								></i>
								<i
									class="far fa-object-group"
									style="color: var(--primario);"
									v-if="podeAlterarValorEstoque && (prod && prod.cdagrupamento)" 
									@click="visualizarProdutosAgrupados(prod)"
								></i>
								<i 
									class="fas fa-layer-group"
									style="color: var(--primario);"
									v-if="podeAlterarEstoque && !prod.filhos && (prod.cdproduto) && (prod.status != 'V')"
									@click="iniciarVariacaoProduto(index, prod)" 
								></i>

								<!-- ANTES ERA ASSIM -->
								<!-- <i 
									class="fas fa-layer-group"
									style="color: var(--confirmar);"
									v-else-if="podeAlterarValorEstoque && (prod.cdproduto) && (prod.status && prod.status == 'V')"
									@click="consultarVariacaoProduto(index, prod)"
								></i> -->
								<i 
									class="fas fa-layer-group"
									style="color: var(--confirmar);"
									v-else-if="podeAlterarValorEstoque && (prod.cdproduto)"
									@click="consultarVariacaoProduto(index, prod)"
								></i>
							</td>
							<td class="center text-nowrap" v-else>
								
								<i
									class="far fa-object-group"
									style="color: var(--primario);"
									v-if="!podeAlterarEstoque && prod.cdagrupamento"
									@click="visualizarProdutosAgrupados(prod)" 
								/>
								<i
									class="fas fa-layer-group"
									style="color: var(--primario);"
									v-if="!prod.filhos && (prod.cdproduto) && (prod.status != 'V') && podeVariarNovamente"
									@click="iniciarVariacaoProduto(index, prod)" 
								/>
								<i
									class="fas fa-layer-group"
									v-if="!podeAlterarEstoque && (prod.cdproduto) && (prod.status && prod.status == 'V')"
									@click="somenteConsultaVariacao(index, prod)"
									style="color: var(--confirmar);"
								/>
							</td>
							<td style="color: var(--primario)" class="center">{{ prod.cdseq }}</td>
							<td style="color: var(--primario)" class="center">{{ prod.cfop }}</td>
							<td 
								style="color: var(--primario)" 
								class="center" 
								@click="solicitaAlterarGrupo(index)" 
								v-if="visaoEstoque">
									{{ prod.cdgrupo }}
							</td>
							<td 
								style="color: var(--primario)" 
								class="center" 
								@click="solicitaAlterarSubGrupo(index)" 
								v-if="visaoEstoque"
							>
								{{ prod.cdsubgrupo }}
							</td>
							<td
								style="color: var(--primario)"
								class="center"
								@click="solicitaAlterarProduto(index)"
								v-if="!prod.cdproduto"
							>
								<!-- {{ prod.cdproduto || "Buscar" }} -->
								Buscar
							</td>
							<td 
								v-else
								style="color: var(--primario)"
								class="center"
								@dblclick="handleDesvincularProduto(index)"
							>
								{{ prod.cdproduto }}
							</td>
							<td style="color: var(--primario)" class="center text-nowrap">{{ prod.cdreferencia }}</td>
							<td class="text-left text-nowrap">{{ prod.descricao }}</td>
							<td class="text-right" v-if="compraXML.tipo == 'I' || !podeAlterarEstoque">{{ ((prod.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
							<td class="text-right" v-else>
								<input 
									class="input-tabela"
									type="text"
									v-model="prod.qtd"
									:id="`qtd-${index}`"
									@contextmenu.prevent=""
									@input="alterarQuantidade" 
									@focus="e => e.target.select()" 
									@keydown="mascaraFloat"
									autocomplete="off"
									@keydown.enter.prevent="proximoCampo(`vlrnovo-${index}`)"
								>
							</td>
							<td class="text-right" v-if="compraXML.tipo == 'I' || !podeAlterarEstoque">
								{{ 
									(prod.vlrunitario || 0) | formatarParaReal
								}} 
							</td>
							<td class="text-right" v-else>
								<input 
									class="input-tabela"
									type="text"
									v-model="prod.vlrnovo"
									:id="`vlrnovo-${index}`"
									@contextmenu.prevent=""
									@keydown="mascaraFloat"
									autocomplete="off"
									@keydown.enter.prevent="proximoCampo(`margemTabela-${index}`)"
									@input="alterarVlrunitario"
									@focus="(e) => e.target.select()"
								>
							</td>
							<td class="text-right">
								{{ ((prod.vlrunitario|| 0) * (prod.quantidade ||1)) | formatarParaReal}}
							</td>
							
							<td class="text-right" v-if="podeAlterarValorEstoque">
								<input class="input-tabela" type="text" v-model="prod.margemTabela" :id="`margemTabela-${index}`"
									@input="alterarMargem" @focus="e => e.target.select()"
									@contextmenu.prevent="" autocomplete="off"
									@keydown="mascaraFloat" @keydown.enter.prevent="proximoCampo(`prazo-${index}`)">
							</td>
							<td class="text-right" v-else>{{ (prod.margem || 0) | formatarParaReal }}</td>
							<td class="center" v-if="podeAlterarValorEstoque">
								<input class="input-tabela" type="text" v-model="prod.prazo" :id="`prazo-${index}`"
									@input="calcularMarkupEVistaTabela" @focus="e => e.target.select()"
									@contextmenu.prevent="" autocomplete="off"
									@keydown="mascaraFloat" @keydown.enter.prevent="proximoCampo(`descTabela-${index}`)">
							</td>
							<td class="text-right" v-else>{{(prod.prazo || 0)}}</td>
							<td class="text-right" v-if="podeAlterarValorEstoque">
								<input class="input-tabela" type="text" v-model="prod.descTabela" :id="`descTabela-${index}`"
									@input="alterarDescvista" @focus="e => e.target.select()"
									@contextmenu.prevent="" autocomplete="off"
									@keydown="mascaraFloat" @keydown.enter.prevent="proximoCampo(`vista-${index}`)">
							</td>
							<td class="center" v-else>
								{{ Number(prod.cdesc || 0) | formatarParaReal }}
							</td>
							<td class="center" v-if="podeAlterarValorEstoque">
								<input class="input-tabela" type="text" v-model="prod.vista" :id="`vista-${index}`"
									@input="calcularMarkupEVistaTabela" @focus="e => e.target.select()"
									@contextmenu.prevent="" autocomplete="off"
									@keydown="mascaraFloat" @keydown.enter.prevent="capturarEntradaValorVista(index)">
							</td>
							<td class="text-right" v-else>{{(prod.vista || 0)}}</td>
							<td class="center">{{ prod.unidade }}</td>
							<td class="center">{{ prod.ncm }}</td>
							<td class="center text-nowrap">{{ prod.codbarra }}</td>
							<td class="text-left text-nowrap">{{ prod.descresumido }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="botoes">
			<button 
				class="botao botao-cancelar" 
				@click="retornarParaTabela" 
				v-if="!acaoEmMassa && compraXML.confirmada != 'S'"
			>
				<i 
					class="fas fa-times" 
					style="color:var(--cancelar-texto)"
				></i>
				Cancelar
			</button>
			<button 
				class="botao botao-cancelar" 
				@click="cancelarAcaoEmMassa" 
				v-else-if="acaoEmMassa"
			>
				<i 
					class="fas fa-times" 
					style="color:var(--cancelar-texto)"
				></i>
				Cancelar
			</button>
			<button 
				class="botao botao-cancelar"
				@click="cancelarAlteracaoFinanceiro"
				v-else-if="alterarFinanceiroCompraConfirmada && novoFinanceiroCompraConfirmada"
			>
				<i
					class="fas fa-times" 
					style="color:var(--cancelar-texto)"
				></i>
				Cancelar alteração
			</button>
			<button 
				class="botao botao-cancelar" 
				@click="$emit('cancelarEdicao')" 
				v-else-if="compraXML.confirmada == 'S'"
			>
				<i 
					class="fas fa-times" 
					style="color:var(--cancelar-texto)"
				></i>
				Sair
			</button>
			<button 
				class="botao botao-adicionar w-100" 
				@click="salvarEntrada" 
				v-if="compraXML.confirmada == 'A' && compraXML.tipo == 'I'"
			>
				<i 
					class="fa fa-check"
					style="color: var(--adicionar-texto)"
				></i>
				Salvar NFe
			</button>
			<button 
				class="botao botao-adicionar"
				@click="salvarEntrada" 
				v-else-if="compraXML.confirmada == 'A' && compraXML.tipo != 'I'"
			>
				<i 
					class="fa fa-check" 
					style="color:var(--adicionar-texto)"
				></i>
				Salvar
			</button>
			<button 
				class="botao botao-adicionar" 
				@click="lancarEstoque" 
				v-else-if="((compraXML.confirmada == 'N' || compraXML.confirmada == 'L') && !acaoEmMassa)"
			>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				{{mostrarAba == "Produtos" ? "Salvar" : "Ver"}} produtos
			</button>
			<button 
				class="botao botao-adicionar" 
				@click="aplicarAcaoEmMassa" 
				v-else-if="acaoEmMassa"
			>
				<i 
					class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				Aplicar
			</button>
			<button
				class="botao botao-adicionar botao-lancar-tamanho"
				@click="lancarFinanceiro"
				v-else-if="compraXML.confirmada == 'F'"
			>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				{{mostrarAba == "Financeiro" ? "Lançar" : "Ver"}} financeiro
			</button>
			<button
				class="botao botao-adicionar"
				@click="solicitouConfirmarEntrada"
				v-else-if="compraXML.confirmada == 'C'"
			>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				Confirmar
			</button>
			<button
				class="botao botao-adicionar"
				@click="alterarFinanceiroConfirmada"
				v-else-if="alterarFinanceiroCompraConfirmada && novoFinanceiroCompraConfirmada"
			>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				Alterar Financeiro
			</button>
			<button class="botao botao-adicionar" @click="mostratelabarra" v-else>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				Emitir etiquetas
			</button>
			<button
				class="botao botao-adicionar"
				@click="salvaDataVencimento"
				v-if="alteraVencimento"
			>
				<i class="fa fa-check" style="color:var(--adicionar-texto)"></i>
				Salvar Vencimento
			</button>
		</div>
		<Modal v-if="indexListaProduto >= 0" v-on:fecharModal="cancelarCadastroProduto">
			<consulta-produto
				:Produtos="listadeproduto"
				@selecionarProduto="selecionarProduto"
				@adicionarNovo="selecionarProduto({novo: true})"
				:mostrarBotaoNovo="true"
				:desabilitarAcao="true"
				:height="'55vh'"
			/>
		</Modal>
		<Modal
			v-if="adicionarProduto"
			@fecharModal="cancelarCadastroProduto"
			:idModal="'cadastro-produto'"
			:dimensoes="{height: '95vh', width: '95vw'}"
		>
			<CadastroProduto 
				:Produto="adicionarProduto"
				:Fornecedor="this.fornecedor"
				@cancelarEdicao="cancelarCadastroProduto" 
				:inserindoCompra="true"
				@salvarProduto="cadastrouProduto"
				@duplicarProduto="duplicarEntradaProduto"
				:lancarVariacao="false"
				:CdCompra="compra"
			/>
		</Modal>
		<Modal
			v-if="listarFornecedores"
			@fecharModal="listarFornecedores = false"
		>
			<consulta-cliente
				@adicionarNovo="novoFornecedor = {novo: true} "
				v-if="!novoFornecedor"
				@selecionarPessoa="selecionarFornecedor"
				:mostrarBotaoNovo="true"
			/>
			<CadastroCliente 
				v-else 
				:cliente="novoFornecedor" 
				@cancelarEdicao="novoFornecedor = null" 
			/>
		</Modal>
		<Modal 
			v-if="listarTransp"
			@fecharModal="listarTransp = false"
		>
			<consulta-cliente
				@adicionarNovo="novaTransportadora = {novo: true}"
				v-if="!novaTransportadora"
				@selecionarPessoa="selecionarTransportadora"
				:mostrarBotaoNovo="true"
			/>
			<CadastroCliente 
				v-else
				:cliente="novaTransportadora"
				v-on:cancelarEdicao="novaTransportadora = null"
			/>
		</Modal>
		<Modal 
			v-if="listarCFOP"
			@fecharModal="listarCFOP = false"
		>
			<TabelaCfop @escolheucfop="CfopSelecioando" />
		</Modal>
		<Modal
			v-if="MostrarGrupos >= 0"
			@fecharModal="MostrarGrupos = -1"
		>
			<consulta-grupos 
				:habilitaFiltroGrupo="true"
				:mostrarBotaoNovo="true"
				:desabilitarAcao="false"
				@selecionarGrupos="incluirgrupo"
				:Produtos="listadeproduto"
				@editarGrupo="solicitaCadastroGrupo"
				v-if="!cadastrarGrupo"
			/>
			<CadastroGrupoProduto 
				@fecharModal="cadastrarGrupo = null"
				:grupoProduto="cadastrarGrupo"
				@cadastrouGrupo="cadastrouGrupo"
				v-else 
			/>
		</Modal>
		<Modal 
			v-if="telaBarra" 
			:dimensoes="{
				width: '80%',
				height: '80%'
			}" 
			@fecharModal="telaBarra = false"
		>
			<CodigoBarra 
				v-bind:codiBarra="CodiBarra" 
				@fechartela="fechatelabarra"
				@fecharCodigoBarra="telaBarra = false" 
			/>
		</Modal>
		<Modal 
			v-if="visualizarAgrupamento"
			@fecharModal="cancelarVisualizacaoAgrupamento"
			:dimensoes="{width: 'auto', height: 'auto'}"
			:idModal="'visualizar-agrupamento'"
		>
			<div class="container-agrupados">
				<h3>PRODUTOS AGRUPADOS</h3>
				<div class="container-tabela-agrupados" v-if="produtosAgrupados.length > 0">
					<table>
						<thead>
							<tr>

								<th>Seq. Nota</th>
								<th>Referência</th>
								<th>Descrição</th>
								<th>Quantidade</th>
								<th>Custo</th>
							</tr>
						</thead>
						<tbody>
							<tr 
								v-for="(prod, index) in produtosAgrupados" :key="index"
							>
								<td>
									{{ prod.cdseq }}
								</td>
								<td>
									{{ prod.cdreferencia }}
								</td>
								<td>
									{{ prod.descricao }}
								</td>
								<td>
									{{ (prod.quantidade || 0) | formatarParaDecimal }}
								</td>
								<td>
									{{ (prod.custo || 0) / (prod.quantidade || 1) | formatarParaReal }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="container-tabela-agrupados" v-else>
					<h3>NENHUM PRODUTO NESTE GRUPO!</h3>
				</div>
				&nbsp;
				<button 
					class="botao botao-cancelar"
					@click="cancelarVisualizacaoAgrupamento"
				>
					Fechar
				</button>
			</div>

		</Modal>
		<Modal 
			v-if="modalOutrosProdutos"
			@fecharModal="fecharModalOutrosProdutos"
			:dimensoes="{width: 'auto', height: 'auto'}"
			:idModal="'outros-produtos'"
		>
			<div class="container-modal-outros-produtos">
				<h2>Outros produtos encontrados</h2>
				<span class="container-info">
					<i class="fas fa-info-circle" />
					<span>Dica: Para selecionar um produto clique na linha do produto que deseja selecionar</span>
				</span>
				<div class="container-table-outros-produtos">
					<table>
						<thead>
							<tr>
								<td>Cód Produto</td>
								<td>Cód. referência</td>
								<td>Descrição</td>
								<td>Cód barra fornecedor</td>
							</tr>
						</thead>
						<tbody>
							<tr 
								v-for="(prod, index) in outrosProdutosParaSelecionar" 
								:key="index"
								@click="selecionarOutroProdutoEncontrado(prod)"
							>
								<td>
									{{ prod.produtoEncontrado.cdproduto }}
								</td>
								<td>
									{{ prod.produtoEncontrado.cdreferencia }}
								</td>
								<td>
									{{ prod.produtoEncontrado.descricao }}
								</td>
								<td>
									{{ prod.produtoEncontrado.codbarraFornecedor }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="botoes">
					<button class="botao botao-cancelar" @click="fecharModalOutrosProdutos">
						Cancelar
					</button>
					<button class="botao botao-acao" @click="removerProdutoSelecionadoNota">
						Remover produto selecionado
					</button>
				</div>
			</div>
		</Modal>
		<Confirmar 
			v-if="mensagemPergunta"
			:mensagemPergunta="mensagemPergunta"
			@confirmado="perguntaConfirmada"
			@cancelado="perguntaCancelada"
			:acaoCancelar="acaoCancelar"
			:acaoConfirmar="acaoConfirmar" 
		/>
	</div>
</template>
<script>
import Modal from "./Modal.vue";
import CadastroCliente from "../components/CadastroCliente.vue";
import TabelaCfop from "./TabelaCFOp.vue";
import CodigoBarra from "./CodigoBarra.vue";
import Confirmar from "./Confirmar.vue";
import GeraVariacao from "./GeraVariacao.vue";
import CadastroGrupoProduto from "./CadastroGrupoProduto.vue";
import CadastroProduto from "../components/CadastroProduto.vue";
import { FormacaoPrecoCompra } from "../models/Produto";
import { dateFormatISO } from "../utils/dateformat";
import { CompraXML } from "../models/Compra.js";
import TabelaNotaCompra from "../components/TabelaNotaCompra.vue";
import ConsultaProduto from "../components/ConsultaProdutos.vue";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaGrupos from "../components/ConsultaGrupos.vue";

class ItemProdutoManual {
	constructor(produto, index) {
		this.index = index;
		this.cdproduto = produto.cdproduto;
		this.id = produto.id || this.cdproduto;
		this.cdagrupamento = produto.cdagrupamento || null;
		this.cdreferencia = produto.cdreferencia;
		this.cdempresa = produto.cdempresa;
		this.descricao = produto.descricao;
		this.unidade = produto.unidade;
		this.cdfornecedor = produto.cdfornecedor;
		this.embalagem = produto.embalagem;
		this.codinter = produto.codinter;
		this.codbarra = produto.codbarra;
		this.codbarraFornecedor = produto.codbarraFornecedor;
		this.cfop = produto.cfop;
		this.cfopesta = produto.cfopesta;
		this.cfopinter = produto.cfopinter;
		this.custo = produto.custo;
		this.custoAnterior = produto.custo;
		this.quantidade = produto.quantidade;
		this.desconto = Number(produto.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.valordesconto = Number(produto.valordesconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		if (typeof(produto.descvista) == "number") {
			this.descvista = Number(produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.descVistaAnterior = Number(produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		} else {
			this.descvista = Number(`${produto.descvista || "0"}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.descVistaAnterior = Number(`${produto.descvista || "0"}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		}
		this.markupAnterior = produto.markup || produto.margem;
		if (produto.markup) {
			if (typeof(produto.markup) == "number") {
				this.novoMarkup = produto.markup.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			} else {
				this.novoMarkup = Number(`${produto.markup || 0}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
		} else if (produto.margem) {
			if (typeof(produto.margem) == "number") {
				this.novoMarkup = produto.margem.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			} else {
				this.novoMarkup = Number(`${produto.margem || 0}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
		} else {
			this.novoMarkup = "0,00";
		}
		this.markup = Number(produto.markup || produto.margem).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.valorVista = Number(`${produto.vista}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.vista = Number(`${produto.vista}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.valorPrazo = Number(`${produto.prazo}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.prazo = Number(`${produto.prazo}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.frete = produto.frete;
		this.valorfrete = Number((produto.valorfrete || 0) * (produto.quantidade || 1)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
		this.valorFreteAnterior = (produto.valorfrete || 0);
		this.ultsaldo = produto.ultsaldo;
		if (typeof(produto.ipi) == "number") {
			this.ipi = Number(produto.ipi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		} else {
			this.ipi = produto.ipi;
		}
		if (typeof(produto.icmsst) == "number") {
			this.icmsst = Number(produto.icmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
		} else {
			this.icmsst = Number(`${produto.icmsst || 0}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
		}
		if (typeof(produto.valoricmsst) == "number") {
			this.valoricmsst = Number(produto.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
		} else {
			this.valoricmsst = Number(`${produto.valoricmsst || 0}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
		}
		this.valoricmsstAnterior = Number(produto.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.valoripi = Number(produto.valoripi || 0);
		this.valoripiAnterior = Number(produto.valoripi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.vlrunitario = produto.vlrunitario;
		this.cdgrupo = produto.cdgrupo;
		this.cdsubgrupo = produto.cdsubgrupo;
		this.ncm = produto.ncm;
		this.icms = produto.icms;
		this.valoricms = produto.valoricms;
		this.pis = produto.pis;
		this.valorpis = produto.valorpis;
		this.cofins = produto.cofins;
		this.valorcofins = produto.valorcofins;
		this.outros = produto.outros || "0,00";
		this.valoroutros = produto.valoroutros || "0,00";
		this.ativo = "A";
		if (produto.filhos) {
			produto.filhos.forEach(item => {
				item.custo = this.custo;
				if (item.markup != this.markup) item.markup = this.markup;
				if (item.pontoequi != this.prazo) item.pontoequi = this.prazo;
				if (item.descvista != this.descvista) item.descvista = this.descvista;
				if (item.vlrvista != this.vista) item.vlrvista = this.vista;
				item.quantidade = item.quantidade || "1";
			});
		}
		this.filhos = produto.filhos || [];
	}
}

class CompraComponent {
	cdcompra = null;
	confirmada = "N";
	tipo = "C";
	original = {};
	itens = [];
	estoque = [];
	financa = [];
	pagamentos = [];
	constructor(compra) {
		if (compra) {
			this.cdcompra = compra.cdcompra || null;
			this.confirmada = compra.confirmada || "N";
			this.tipo = compra.tipo || "C";
			this.original = {...compra};
			this.itens = compra.itens || [];
			this.estoque = compra.estoque || [];
			this.financa = compra.financeiro || [];
			this.pagamentos = compra.pagamentos || [];
		}
	}
}

export default {
	name: "CadastroCompras",
	
	components: {
		Modal,
		CadastroCliente,
		TabelaCfop,
		CadastroGrupoProduto,
		CodigoBarra,
		Confirmar,
		GeraVariacao,
		CadastroProduto,
		TabelaNotaCompra,
		ConsultaCliente,
		ConsultaProduto,
		ConsultaGrupos
	},

	data(){
		return {
			alteraVencimento: false,
			backdoor: 0,
			adicionarProduto: null,
			mostrarAba: "Dados",
			timer: null,
			mensagemPergunta: null,
			acaoConfirmar: null,
			acaoCancelar: null,
			file: null,
			auxFile: null,
			nfeid: null,
			modalBuscaXML: false,
			conciliouEstoque: false,
			/* DADOS */
			cdcompra: null,
			compra: "",
			cfop: "",
			chave: "",
			fornecedor: {id:"",nome:""},
			novoFornecedor: false,
			listarFornecedores: false,
			checkboxFornecedor: false,
			transp: {id:"",nome:""},
			novaTransportadora: false,
			listarTransp: false,
			checkboxTransportadora: false,
			data: dateFormatISO({date: null, format: "ISOdate"}),
			chegada: dateFormatISO({date: null, format: "ISOdate"}),
			totalFrete: "0,00",
			totalDesconto: "0,00",
			totalMargem: "0,00",
			totalDescontoVista: "0,00",
			// Imposto
			totalIpi: "0,00",
			totalFCPST: "0,00",
			totalICMSst: "",
			totalOutros: "0,00",
			listarCFOPManual: false,
			listarCFOP: false,

			/* PRODUTOS */
			visaoEstoque: true,
			acaoEmMassa: null,
			selecionarTodos: false,
			resumoEmMassa: "",

			// Agrupamento
			indexCorAgrupamento: 0,
			coresAgrupamentos: [
				"#e3e6ff",
				"#fff4cc",
				"#c6c3e0",
				"#e0c9b6",
				"#d8f2e6",
				"#b6d3e3",
				"#b5afde",
				"#c5c5d6",
				"#dbd2ba",
				"#c9d8e4",
				"#ccccff",
				"#e6ffb3",
			],
			quantidadeAgrupamento: 0,

			// GRUPOS
			grupoSelecionado: null,

			configurarProdutos: false,
			usarCodBarraFornecedor: false,
			importarProdutos: true,
			produtoParaIncluir: null,
			qtdProdutoManual: "1",
			vlrUnitarioManual: "",
			cfopManual: "",
			cdprodutoOuCodbarra: "",
			produtoEncontrado: null,
			indexAlteracaoProduto: -1,
			indexListaProduto : -1,
			indexAlterarCodigo: -1,
			MostrarGrupos: -1,
			indexAlteraTodosGrupos: -1,
			cadastrarGrupo: false,
			variarProduto: null,
			agrupamentoProdutos: false,

			// VARIAÇÃO
			produtosVariados: [],

			// FINANCEIRO
			visaoPagamento: false,
			metodoPagamentoSelecionado: null,
			compraXML: {
				confirmada: "A",
				tipo: "C",
				original: {},
				itens: [],
				estoque: [],
				financa: [],
			},
			novoFinanceiro: {
				formaPgto: "SEL",
				frequencia: "",
				valor: "",
				qtdParcela: "",

			},

			CodiBarra : {},  
			telaBarra : false,                      
			filtroAtivo: true,
			novoFinanceiroCompraConfirmada: false,

			// VISUALIZACAO DE PRODUTO E VARIACAO
			somenteConsulta: false,
			visualizarAgrupamento: false,
			produtosAgrupados: [],

			textoParaRemover: "",

			shiftSelecionado: false,
			primeiroProdutoSelecionado: null,
			ultimoProdutoSelecionado: null,

			modalOutrosProdutos: false,
			outrosProdutosParaSelecionar: [],
			indexProdutoParaTrocar: -1,

			podeVariarNovamente: false,
		};
	},

	filters: {
		formatarParaReal: (value) => {
			return Number(value || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		},

		formatarParaDecimal: (value) => {
			return Number(value || 0).toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
		}
	},

	props : {
		alteracompra : Object,
	},

	computed:{
		alterarFinanceiroCompraConfirmada() {
			return this.compraXML.confirmada === "S" && (this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.supervisor);
		},

		produtoEntrada() {
			return this.$store.state.produtoEntrada;
		},

		podeAlterarEstoque() {
			return (["A", "N", "L"].includes(this.compraXML.confirmada));
		},
		podeAlterarValorEstoque() {
			return (["A", "N", "L", "C"].includes(this.compraXML.confirmada));
		},
		podeAlterarFinanceiro() {
			return (["F"].includes(this.compraXML.confirmada));
		},
		itensTabela() {
			return !this.visaoEstoque ? this.compraXML.itens : this.compraXML.estoque;
		},
		xmlIntegracao() {
			return this.$store.state.xmlIntegracao || [];
		},
		listadeproduto(){
			return this.$store.state.Produtos;
		},
		produtoFilhos() {
			return this.$store.state.produtoFilhos;
		},
		Grades() {
			return this.$store.state.Grades;
		},
		listadegrupos(){
			return this.$store.state.grupoProduto;
		},
		formasPagamento(){
			return this.$store.state.formasPagamento.filter(mt => (mt.mostracompra || "").toUpperCase() == "S");    
		},
		
		produtoSelecionado(){
			return this.$store.state.produtoSelecionado;
		},

		estoqueCompra: {
			cache: false,
			get: function () {
				return this.compraXML.estoque;
			},
		},

		totalCompra: {
			cache: false,
			get: function () {
				// let preco = 0;
				// this.estoqueCompra.forEach(item => {
				// 	preco += item.vlrunitario * item.quantidade;
				// });
				// return preco;
				return this.compraXML.original.vlrbrutocompra;
			}
		},

		// TOTAIS VISÃO NOTA

		totalFreteNota: {
			cache: false,
			get: function () {
				let preco = 0;
				this.compraXML.itens.forEach(item => {
					preco += Number(item.valorfrete || 0);
				});
				return Number(preco.toFixed(2));
			},
		},
		
		totalOutrosNota: {
			cache: false,
			get: function () {
				let preco = 0;
				this.compraXML.itens.forEach(item => {
					preco += Number(item.valoroutros || 0);
				});
				return Number(preco.toFixed(2));
			},
		},

		totalIpiNota: {
			cache: false,
			get: function () {
				let value = 0;
				this.compraXML.itens.forEach(item => {
					value += Number(item.valoripi || 0);
				});
				return value;
			}
		},

		totalDescontoNota: {
			cache: false,
			get: function () {
				let value = 0;
				this.compraXML.itens.forEach(item => {
					value += Number(item?.desconto);
				});
				return value;
			}
		},

		totalICMSstNota: {
			cache: false,
			get: function () {
				let value = 0;
				this.compraXML.itens.forEach(item => {
					value += Number(item.valoricmsst || 0);
				});
				return value;
			}
		},

		valorFinalCompraNota() {
			if(!this.compraXML?.nfeid) {
				let preco = 0;

				this.compraXML.estoque.forEach(p => {
					preco += p.quantidade * p.vlrunitario;
				});
				return Number(preco.toFixed(2));
			}
			return this.compraXML.original.valorbrutopedido;
		},

		// TOTAIS VISAO ESTOQUE
		valorFinalCompraEstoque() {
			let vlr = 0;
			if(this.compraXML?.estoque?.length > 0) {
				this.compraXML.estoque.forEach((item) => {
					vlr += (item?.vlrunitario * item?.quantidade);
				});
			}
			return Number(vlr);
		},

		totalFinanceiro() {
			let preco = 0;
			this.compraXML.financa.forEach(fin => {
				let vlr = Number(typeof (fin.contarcvlr) == "string" ? `${fin.contarcvlr || 0}`.split(".").join("").replace(",", ".") : fin.contarcvlr || 0) || 0;
				if (isNaN(vlr)) {
					vlr = fin.contarc;
				}
				preco += vlr;
			});
			return Number(preco.toFixed(2));
		},

		totalFinanceiroXML() {
			let preco = 0;
			if (this.valorFinalCompraNota > 0 && !this.compraXML?.nfeid) return this.valorFinalCompraNota;
			if(this.compraXML?.nfeid) {
				this.compraXML.financa.forEach(fin => {
					preco += Number(typeof (fin.contarc) == "string" ? `${fin.contarc}`.split(".").join("").replace(",", ".") : fin.contarc);
				});
			} else {
				this.compraXML.estoque?.forEach(p => {
					preco += p.vlrunitario * p.quantidade;
				});
			}
			return Number(preco?.toFixed(2));
		},

		saldoFinanceiro() {
			return (this.totalFinanceiroXML - this.totalFinanceiro) || 0;
		},

		compraState() {
			return this.$store.state.Entrada;
		}
	},

	methods: {
		handleVariacaoFinanceiro() {
			this.visaoEstoque = true;
			this.mudarAba(1, "Produtos");
			this.podeVariarNovamente = true;
		},

		produtoComCodigo(prod) {
			return prod.cdproduto ? true : false;
		},
		
		removerProdutoSelecionadoNota() {
			this.handleDesvincularProduto(this.indexProdutoParaTrocar);
			this.fecharModalOutrosProdutos();
		},

		fecharModalOutrosProdutos() {
			this.modalOutrosProdutos = false;
			this.outrosProdutosParaSelecionar = [];
			this.indexProdutoParaTrocar = -1;
		},

		selecionarOutroProdutoEncontrado({ produtoEncontrado }) {
			// VERIFICAR POR QUE A VARIACAO TA FICANDO PREENCHIDA
			this.compraXML.estoque[this.indexProdutoParaTrocar].descricao = produtoEncontrado.descricao;
			this.compraXML.estoque[this.indexProdutoParaTrocar].cdreferencia = produtoEncontrado.cdreferencia;
			this.compraXML.estoque[this.indexProdutoParaTrocar].codbarraFornecedor = produtoEncontrado.codbarraFornecedor;
			this.compraXML.estoque[this.indexProdutoParaTrocar].cdproduto = produtoEncontrado.cdproduto;
			this.fecharModalOutrosProdutos();
		},

		visualizarOutrosProdutos(produto, index) {
			this.modalOutrosProdutos = true;
			this.outrosProdutosParaSelecionar = [...produto.outrosProdutos];
			this.indexProdutoParaTrocar = index;
		},

		handleClickCheckbox(index) {
			if(this.shiftSelecionado) {
				if(this.primeiroProdutoSelecionado === null) {
					this.primeiroProdutoSelecionado = index;
				} else {
					this.ultimoProdutoSelecionado = index;
					this.selecionarIntervalo();
				}
			} else {
				this.primeiroProdutoSelecionado = index;
				this.ultimoProdutoSelecionado = null;
			}
		},

		handleShiftKeydown(e) {
			if(e.key === "Shift") {
				e.preventDefault();
				this.shiftSelecionado = true;
			}
		},

		selecionarIntervalo() {
			if(this.primeiroProdutoSelecionado !== null && this.ultimoProdutoSelecionado !== null) {
				for (let i = Math.min(this.primeiroProdutoSelecionado, this.ultimoProdutoSelecionado); i <= Math.max(this.primeiroProdutoSelecionado, this.ultimoProdutoSelecionado); i++) {
					this.compraXML.estoque[i].selecionado = true;
					this.$forceUpdate();
				}
				this.primeiroProdutoSelecionado = null;
				this.ultimoProdutoSelecionado = null;
				this.shiftSelecionado = null;
			}
		},

		handleDesvincularProduto(index) {
			if(!this.compraXML.nfeid) return;
			if(this.compraXML.confirmada === "N" || this.compraXML.confirmada === "L") {
				this.compraXML.estoque[index].cdproduto = "";
				this.compraXML.estoque[index].cdagrupamento = "";
				this.compraXML.estoque[index].codbarra = "";
				this.compraXML.estoque[index].cdgrupo = "";
				this.compraXML.estoque[index].cdsubgrupo = "";
				this.$forceUpdate();
			}
		},
		formatStringToNumber(value) {
			if(typeof value === "string") {
				return Number(`${value || ""}`.split(".").join("").replace(",", "."));
			} else {
				return Number(value || 0);
			}
		},

		desativarXML(xml){
			(xml);
			return this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja desativar esta nota?", 
				tipo: "polar",
				item: {
					...xml,
					desativarNota: true
				}
			});
		},
		async mudarAtivo(){
			this.filtroAtivo = !this.filtroAtivo;
			
			setTimeout(async () => {
				await this.buscarXMLintegracao(this.filtroAtivo == true ? "A" : "I");

			}, 50);
			this.$forceUpdate();
		},
		verificarProdutosParaAgrupamento (index) {
			try {
				const produtoAtual = this.compraXML.estoque[index];
				if (produtoAtual.selecionado) {
					this.compraXML.estoque.filter(item => item.selecionado).forEach(item => {
						if (Number(item.vlrunitario.toFixed(2)) !== Number(produtoAtual.vlrunitario.toFixed(2))) {
							document.getElementById(`${index}-selecionado`).click();
							this.compraXML.estoque[index].selecionado = false;
							this.$forceUpdate();
							throw new Error("Produto com custo diferente dos outros selecionados!");
						}
					});
				}
			} catch (error) {
				setTimeout(() => {
					this.$store.commit("relatarErro", { mensagem: error.message, alerta: true });
				}, 100);
			}
		},
		alterarEntradaConfirmada (e) {
			e.preventDefault();
		},

		selectNoCampoEScrollTabela(e) {
			if (e.target) e.target.select();
			const tabela = document.querySelector(".tabela-scroll");
			if (!tabela) return;
			tabela.scrollBy({
				left: tabela.scrollWidth
			});
		},

		// AÇÃO EM MASSA
		selecionarTodosProdutos() {
			this.compraXML.estoque.forEach(prod => {
				if (!prod.cdproduto && (
					this.acaoEmMassa === "agruparProdutos" 
					|| this.acaoEmMassa === "incluirGrupoEmMassa"
					|| this.acaoEmMassa === "editarDescricaoEmMassa"
				)) {
					if(!prod.cdgrupo){
						prod.selecionado = this.selecionarTodos;
					}
				} else if (this.acaoEmMassa == "removerProdutos" || this.acaoEmMassa == "informarResumoEmMassa" || this.acaoEmMassa == "formarPrecoEmMassa" || this.acaoEmMassa == "ajustarPrecos") {
					prod.selecionado = this.selecionarTodos;
				}
			});
		},
		aplicarAcaoEmMassa() {
			if (this.acaoEmMassa && this[this.acaoEmMassa]) {
				this[this.acaoEmMassa]();
			}
		},

		ajustarPrecos(){
			if(!this.existeProdutoSelecionado({
				mensagem: "Por favor selecione os produtos que deseja ajustar os preços.", 
				alerta: true
			})) return;
			
			for (let i = 0; i < this.compraXML.estoque.length; i++){
				const produto = this.compraXML.estoque[i];
				if(produto.selecionado){
					let prazoSeparado = produto.prazo.split(",");
					prazoSeparado[1] = "90";
					produto.prazo = prazoSeparado.join(",");
					if(typeof produto.descvista === "string" ? Number(produto?.descvista.replace(",", ".")) : produto.descvista === 0){
						produto.vista = produto.prazo;
					}
					this.alterarDescvista({index: i});
					let vistaSeparado = produto.vista.split(",");
					vistaSeparado[1] = "90";
					produto.vista = vistaSeparado.join(",");
					this.$forceUpdate();
					this.calcularMarkupEVistaTabela({campo: "prazo", index: i});
				}
			}
			this.cancelarAcaoEmMassa();
		},

		existeProdutoSelecionado({mensagem, alerta}) {
			let algumSelecionado = false;
			this.compraXML.estoque.forEach(produto => {
				if (produto.selecionado){
					algumSelecionado = true;
				}
			});
			if(!algumSelecionado) {
				this.$store.commit("relatarErro", {
					mensagem: mensagem,
					alerta: alerta
				});
				return false;	
			} 
			return true;
		},

		editarDescricaoEmMassa() {
			if(!this.existeProdutoSelecionado({
				mensagem: "Por favor selecione 1 ou mais produtos para alterar a descrição!",
				alerta: true,
			})) return;
			for(let i = 0; i < this.compraXML.estoque.length; i++) {
				const produto = this.compraXML.estoque[i];
				if(produto?.selecionado) {
					const remover = `${this.textoParaRemover}`.toUpperCase();
					if(produto?.descricao?.includes(remover)) {
						produto.descricao = produto.descricao.replace(`${remover}`, "");
						produto.selecionado = false;
					}
					if(produto?.descresumido?.includes(remover)) {
						produto.descresumido = produto.descresumido.replace(`${remover}`, "");
						produto.selecionado = false;
					} else {
						this.$store.commit("relatarErro", {
							mensagem: `Não foi possível remover a descrição do produto Seq.${produto.cdseq} - ${produto.descricao}, pois não possui o texo informado!`,
							alerta: true,
						});
						produto.selecionado = false;
					}
				}
			}
			this.textoParaRemover = "";
			this.cancelarAcaoEmMassa();
		},
		async informarResumoEmMassa () {
			if(!this.existeProdutoSelecionado({
				mensagem: "Favor selecionar os produtos que deseja informar o resumo!",
				alerta: true,
			})) return;

			this.$store.state.carregando = true;
			for (let i = 0; i < this.compraXML.estoque.length; i++) {
				const item = this.compraXML.estoque[i];
				if (item.selecionado) {
					item.descresumido = `${this.resumoEmMassa || ""}`.toUpperCase();
					item.selecionado = false;
				}
			}
			this.resumoEmMassa = "";
			this.$store.state.carregando = false;
		},
		formarPrecos (produto) {
			this.compraXML.estoque.forEach(item => {
				if (item.selecionado) {
					item.markup = produto.markup;
					item.margem = produto.margem;
					item.margemTabela = produto.margemTabela;
					item.prazo = produto.prazo;
					item.cdesc = produto.cdesc;
					item.descTabela = produto.descTabela;
					item.vista = produto.vista;
				}
			});
		},
		formarPrecoEmMassa () {
			this.cancelarAcaoEmMassa();
		},
		consultarGrupoParaAcaoEmMassa ()  {
			if(!this.existeProdutoSelecionado({
				mensagem: "Favor selecionar os produtos que deseja informar o grupo!",
				alerta: true,
			})) return;
			this.MostrarGrupos = 0;
		},
		alterarAcaoEmMassa() {
			this.selecionarTodos = false;
			this.compraXML.estoque.forEach(prod => {prod.selecionado = this.selecionarTodos;});
			const tabela = document.querySelector(".tabela-scroll");
			if (!tabela) return;
			tabela.scrollBy({
				left: -tabela.scrollWidth
			});
		},
		cancelarAcaoEmMassa() {
			this.acaoEmMassa = null;
			this.selecionarTodos = false;
			this.compraXML.estoque.forEach(item => item.selecionado = false);
		},
		removerProdutos() {
			this.compraXML.estoque = [...this.compraXML.estoque.filter(item => !item.selecionado)];
			this.compraXML.estoque.forEach((item, index) => item.cdseq = (index +1));
			this.atualizarTotaisCompra();
			this.cancelarAcaoEmMassa();
		},
		agruparProdutos() {
			const produtosSelecionados = this.compraXML.estoque.filter(item => item.selecionado);
			if(produtosSelecionados?.length <= 1) {
				return this.$store.commit("relatarErro", {
					mensagem: "Para realizar a ação de agrupamento, é necessário selecionar mais de 1 produto!",
					alerta: true,
				});
			}
			const custos = new Set(
				produtosSelecionados.map(item => Number(item?.vlrunitario.toFixed(2)))
			);
			if(custos.size !== 1) {
				return this.$store.commit("relatarErro", {
					mensagem: "Os produtos selecionados não possuem o mesmo custo, por favor verifique!",
					alerta: true,
				});
			}
			let quantidade = 0;
			let valorunitario = 0;
			if (produtosSelecionados.length) {
				this.compraXML.estoque.forEach(item => {
					if (item.selecionado) {
						quantidade += item.quantidade;
						valorunitario += (item.vlrunitario);
						item.agrupado = true;
						item.selecionado = false;
					}
				});
			}

			this.quantidadeAgrupamento = quantidade;
			this.agrupandoProdutos = true;
			valorunitario = valorunitario / quantidade;
			const produto = {
				...produtosSelecionados[0],
				cdreferencia: "AGRUPADO",
				custo: valorunitario,
			};
			this.iniciarAlteracaoProduto((produtosSelecionados[0].cdseq -1), produto);
			this.cancelarAcaoEmMassa();
		},

		incluirGrupoEmMassa() {
			if (!this.grupoSelecionado) {
				return this.$store.commit("relatarErro", {
					mensagem: "Favor selecionar o grupo para aplicar a ação em massa!"
				});
			}
			const cdgrupo = this.grupoSelecionado.cdGrupo;
			let cdsubgrupo = null;
			if (this.grupoSelecionado.subGrupos) {
				cdsubgrupo = this.grupoSelecionado.subGrupos.length 
					? this.grupoSelecionado.subGrupos[0].cdsubgrupo 
					: null;
			}
			this.compraXML.estoque.forEach((item, index) => {
				if (item.selecionado) {
					item.cdgrupo = cdgrupo;
					if (cdsubgrupo) {
						item.cdsubgrupo = cdsubgrupo;
					} else {
						item.cdsubgrupo = null;
					}
					item.selecionado = false;
					this.indexAlteraTodosGrupos = index;
				}
			});
			this.grupoSelecionado = null;
			this.cancelarAcaoEmMassa();
		},
		alterarCfop() {
			setTimeout(() => {
				this.compraXML.estoque.forEach(item => {
					item.cfop = this.cfop;
				});
			}, 50);
		},
		selecionarXMLintegracao(xml) {
			this.modalBuscaXML = false;
			this.lerXMLString(xml.docxml);
		},
		async buscarXMLintegracao(situacao) {
			await this.$store.dispatch("buscarXMLintegracao",
				situacao 
					? {situacao} : 
					{situacao: "A"}
			);
		},
		async solicitarLancamentoXML() {
			if (this.file) {
				this.mensagemPergunta = "Já existe um arquivo XML importado, Deseja limpar e importar um novo arquivo?";
				this.acaoConfirmar = "resetState";
				this.acaoCancelar = null;
				return;
			}
			await this.buscarXMLintegracao();
			if (this.xmlIntegracao.length) {
				this.modalBuscaXML = true;
			} else {
				const input = document.getElementById("js-xml");
				input.click();
			}
		},
		limparDadosImportados() {
			this.compraXML = new CompraComponent();
			this.file = null;
			this.auxFile = null;
			this.data = dateFormatISO({date: null, format: "ISOdate"});
			this.chegada = dateFormatISO({date: null, format: "ISOdate"});
			this.cdcompra = null;
			this.compra = null;
			this.chave = null;
			this.cfop = null;
			this.fornecedor = {id:"",nome:""};
			this.checkboxFornecedor = false;
			this.checkboxTransportadora = false;
			this.transp = {id:"",nome:""};
			this.nfeid = null;
		},
		calcularValorVendaFilhos(e) {
			const id = e.target.id;
			const campo = id.split("-")[0];
			const index = id.split("-")[1];
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this.variarProduto.filhos[index][campo] = this.variarProduto.filhos[index][campo].substring(0, this.variarProduto.filhos[index][campo].length -1);
			}
			if (campo == "markup") {
				let valorPrazo = this.variarProduto.filhos[index].custo + (this.variarProduto.filhos[index].custo * Number(`${this.variarProduto.filhos[index].markup}`.split(".").join("").replace(",", ".")) /100);
				this.variarProduto.filhos[index].pontoequi = valorPrazo.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
				let descontoVista = typeof(this.variarProduto.filhos[index].descvista) == "number" ? this.variarProduto.filhos[index].descvista : Number(`${this.variarProduto.filhos[index].descvista}`.split(".").join("").replace(",", ".") || 0);
				if (descontoVista > 0) {
					this.variarProduto.filhos[index].vlrvista = (valorPrazo - (valorPrazo * descontoVista /100)).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
				} else {
					this.variarProduto.filhos[index].vlrvista = this.variarProduto.filhos[index].pontoequi;
				}
			} else if (campo == "pontoequi") {
				let valorPrazo = Number(`${this.variarProduto.filhos[index].pontoequi || "0"}`.split(".").join("").replace(",", "."));
				this.variarProduto.filhos[index].markup = Number((valorPrazo - this.variarProduto.filhos[index].custo) / this.variarProduto.filhos[index].custo * 100).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
				let descontoVista = typeof(this.variarProduto.filhos[index].descvista) == "number" ? this.variarProduto.filhos[index].descvista : Number(`${this.variarProduto.filhos[index].descvista}`.split(".").join("").replace(",", ".") || 0);
				if (descontoVista > 0) {
					this.variarProduto.filhos[index].vlrvista = (valorPrazo - (valorPrazo * descontoVista /100)).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
				} else {
					this.variarProduto.filhos[index].vlrvista = this.variarProduto.filhos[index].pontoequi;
				}
			} else if (campo == "descvista") {
				let valorPrazo = Number(`${this.variarProduto.filhos[index].pontoequi || "0"}`.split(".").join("").replace(",", "."));
				let descontoVista = typeof(this.variarProduto.filhos[index].descvista) == "number" ? this.variarProduto.filhos[index].descvista : Number(`${this.variarProduto.filhos[index].descvista}`.split(".").join("").replace(",", ".") || 0);
				if (descontoVista > 0) {
					this.variarProduto.filhos[index].vlrvista = (valorPrazo - (valorPrazo * descontoVista /100)).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
				} else {
					this.variarProduto.filhos[index].vlrvista = this.variarProduto.filhos[index].pontoequi;
				}
			} else if (campo == "vlrvista") {
				let valorPrazo = Number(`${this.variarProduto.filhos[index].pontoequi || "0"}`.split(".").join("").replace(",", "."));
				let valorVista = Number(`${this.variarProduto.filhos[index].vlrvista || "0"}`.split(".").join("").replace(",", "."));
				this.variarProduto.filhos[index].descvista = Number((valorPrazo - valorVista) / valorPrazo * 100).toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if (this.variarProduto.filhos[index].ecf) {
				this.atualizarFilhosDependentes(this.variarProduto, this.variarProduto.filhos[index]);
			}
			this.$forceUpdate();
		},

		async verificarFilhosProduto(index, produto) {
			this.vlrUnitarioManual = Number(produto.vlrunitario || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.cfopManual = produto.cfop;
			this.qtdProdutoManual = (produto.quantidade ? produto.quantidade.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : null) || "1";
			this.cdprodutoOuCodbarra = "";
			this.produtoEncontrado = null;
			if (produto.cdproduto) {
				if (produto.filhos?.length) {
					this.$store.state.produtoFilhos = produto.filhos;
				} else {
					await this.$store.dispatch("buscarFilhos", {
						pai: produto.cdproduto,
					});
				}
			} else {
				this.$store.state.produtoFilhos = [];
			}
			this.indexAlteracaoProduto = index;
			this.produtoParaIncluir = new ItemProdutoManual(produto, index);
			this.variarProduto = this.produtoParaIncluir;
		},
		
		removerVariacao(index) {
			this.produtoParaIncluir.filhos = null;
			this.compraXML.estoque[index].filhos = null;
		},

		async limparFilhos() {
			this.variarProduto.filhos = [];
			// await this.$store.dispatch("buscarFilhos", {
			// 	pai: this.variarProduto.cdproduto,
			// });
			if(this.variarProduto?.cdagrupamento) {
				this.produtosAgrupados = await this.buscarPreencherProdutosAgrupados(this.variarProduto.cdagrupamento);
			}
			this.$forceUpdate();
		},

		cancelarAgrupamento() {
			this.agruparTodos = false;
			this.compraXML.estoque.forEach(prod => {
				prod.agrupar = false;
			});
			this.agrupamentoProdutos = false;
		},

		iniciarAgrupamento() {
			const produto = this.compraXML.estoque.find(item => item.agrupar);
			if (!produto) return;
			this.indexAlteracaoProduto = (produto.cdseq -1);
			this.produtoParaIncluir = new ItemProdutoManual(produto, (produto.cdseq -1));
			this.vlrUnitarioManual = Number(produto.vlrunitario || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.cfopManual = produto.cfop;
			this.qtdProdutoManual = 0;
			this.compraXML.estoque.filter(item => item.agrupar).forEach(item => {
				this.qtdProdutoManual += item.quantidade;
			});
			this.qtdProdutoManual = Number(this.qtdProdutoManual).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.cdprodutoOuCodbarra = "";
			this.produtoEncontrado = null;
			this.proximoCampo("qtdProdutoManual");
		},
		solicitaAgruparProdutos() {
			this.agrupamentoProdutos = true;
		},
		solicitaAlterarSubGrupo(index) {
			if (!this.podeAlterarEstoque) return;
			if (this.compraXML.estoque[index].cdproduto) return;
			this.MostrarGrupos = index;
			this.$store.state.carregando = true;
			setTimeout(() => {
				this.$store.dispatch("filtrarGrupos", {cdGrupo: this.compraXML.estoque[index].cdgrupo});
				this.$store.state.carregando = false;
			}, 500);
		},
		cadastrouGrupo(grupo) {
			if (this.acaoEmMassa) {
				this.grupoSelecionado = {...grupo};
			} else if (this.MostrarGrupos >= 0) {
				this.compraXML.estoque[this.MostrarGrupos].cdgrupo = grupo.cdGrupo;
			}
			this.MostrarGrupos = -1;
		},
		// Variação
		removerFilho(index) {
			if (this.indexAlteracaoProduto >= 0 ? this.compraXML.estoque[this.indexAlteracaoProduto].filhos?.length : false) {
				this.compraXML.estoque[this.indexAlteracaoProduto].filhos.splice(index, 1);
			} else {
				this.variarProduto.filhos.splice(index, 1);
			}
			if (!this.variarProduto.filhos.length) {
				this.limparFilhos();
			}
		},
		cancelarConsultaVariacao() {
			this.compraXML.estoque[this.indexAlteracaoProduto].filhos.forEach(item => {
				item.quantidade = Number(`${item.quantidade || 0}`.split(".").join("").replace(",", ".") || 0);
				item.markup = Number(`${item.markup || 0}`.split(".").join("").replace(",", ".") || 0);
			});
			this.variarProduto = null;
			this.indexAlteracaoProduto = -1;
			this.somenteConsulta = false;
		},
		consultarVariacaoProduto(index, produto) {
			produto.filhos?.forEach(item => {
				item.custo = (item.custo || 0);
				item.descvista = Number(item.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.quantidade = Number(item.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.pontoequi = typeof item.prazo == "string" ? item.prazo : Number(item.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.vlrvista = typeof item.vista == "string" ? item.vista : Number(item.vista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.descvista = Number(item.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				item.markup = Number(item.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			});
			this.variarProduto = {
				...produto,
				id: produto.cdproduto,
				markup: (produto?.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				pontoequi: produto.prazo,
				descvista: (produto?.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3}),
				vlrvista: produto.vista,
			};
			this.$store.state.produtoFilhos = produto.filhos;
			this.indexAlteracaoProduto = index;
		},

		somenteConsultaVariacao(index, produto) {
			this.consultarVariacaoProduto(index, produto);
			this.somenteConsulta = true;
		},
		
		async iniciarVariacaoProduto(index, produto) {
			let produtosVariados = [];
			if (!produto.custo) {
				return setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: "É necessário informar o valor unitário do produto primeiro!",
					});
				});
			}
			this.$store.state.carregando = true;
			const produtoVariar = await this.$store.dispatch("buscarProdutoPorId",produto.cdproduto);
			if (produto.filhos ? !produto.filhos?.length : true) {
				await this.$store.dispatch("buscarFilhos", {
					pai: produto.cdproduto,
					carregando: true
				});
				this.$store.state.carregando = false;
				if(produto?.quantidade === 1 && this.produtoFilhos?.length === 0) {
					return this.$store.commit("relatarErro", {
						mensagem: `
							Não é possível variar somente 1 (um) produto, por favor verifique!
						`,
						alerta: true,
					});
				}
				if(produto.cdagrupamento) {
					this.produtosAgrupados = this.compraXML.itens.filter(item => item.cdagrupamento === produto.cdagrupamento);
				}

			} else {
				produtosVariados = [...produto.filhos];
			}
			if(!this.produtoFilhos?.length > 0 && produtoVariar.saldo > 0){
				return setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: "Produto com saldo não pode ser variado na compra!",
					});
				});
			}
			this.variarProduto = {
				...produto,
				id: produto.cdproduto,
				filhos: [...produtosVariados],
				markup: (produto.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				pontoequi: produto.prazo,
				descvista: (produto.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3}),
				vlrvista: produto.vista,
			};
			this.$forceUpdate();
			this.indexAlteracaoProduto = index;
			this.$store.state.carregando = false;
		},
		async GerarVariacao(produtosVariados) {
			const produtoPai = this.compraXML.estoque[this.indexAlteracaoProduto];
			produtosVariados.forEach((item) => {
				item.vlrunitario = produtoPai.vlrunitario;
				item.cfop = produtoPai.cfop;
				item.cdgrupo = produtoPai.cdgrupo;
				item.cdsubgrupo = produtoPai.cdsubgrupo;
				item.cdreferencia = produtoPai.cdreferencia;
				item.frete = produtoPai.frete;
				item.valorfrete = (produtoPai.frete * produtoPai.vlrunitario / 100);
				item.markup = ((item.markup || item.margem) || (produtoPai.margem || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.pontoequi = (item.pontoequi).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.descvista = (item.descvista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.vlrvista = (item.vlrvista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.ecf = item.ecf || true;
				item.quantidade = (item.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.grade_varia_manual = {
					grade: item.grade,
					quantidade: item.quantidade
				};
			});
			if (!produtosVariados?.length) {
				this.variarProduto.filhos = [];
			} else {
				this.variarProduto.filhos = [];
				this.variarProduto.filhos = [...produtosVariados];
				this.$store.state.produtoFilhos = [...produtosVariados];
			}
			this.$forceUpdate();
			const produto = this.variarProduto;
			this.variarProduto = null;
			this.variarProduto = produto;
			this.produtosAgrupados = [];
		},
		alterarDependenciaFilho(filho, index) {
			if (filho.ecf) {
				this.variarProduto.filhos[index].markup = (this.variarProduto.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.variarProduto.filhos[index].pontoequi = this.variarProduto.prazo;
				this.variarProduto.filhos[index].descvista = (this.variarProduto.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				this.variarProduto.filhos[index].vlrvista = this.variarProduto.vista;
			}
		},
		cancelarVariacaoProduto( { validaQtd } ) {
			const produtoPai = this.compraXML.estoque[this.indexAlteracaoProduto];
			if(validaQtd && this.variarProduto?.filhos?.length > 0) {
				if(!this.validarQuantidadesVariacao(produtoPai)) return;
			}
			if (this.variarProduto?.status == "V") {
				return this.cancelarConsultaVariacao();
			}
			this.variarProduto = null;
			this.$store.state.produtoFilhos = [];
			this.indexAlteracaoProduto = -1;
			this.produtosAgrupados = [];
		},

		validarQuantidadesVariacao(produtoPai) {
			let qtdTotal = 0;
			this.variarProduto.filhos.forEach(item => {
				
				item.quantidade = typeof item.quantidade === "string" ? Number(item.quantidade.replace(",", ".")) : item.quantidade;
				qtdTotal += item.quantidade;
			});
			try {
				if(qtdTotal < produtoPai.quantidade) {
					throw new Error("Para confirmar a variação é necessário distribuir a quantidade total do produto pai entre as variações, por favor verifique!");
				}
				if (qtdTotal > produtoPai.quantidade) {
					this.variarProduto.filhos.forEach(item => {
						item.quantidade = (item.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						item.descvista = (item.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
					});
					throw new Error("Você está tentando cadastrar uma quantidade maior de variações do que o produto pai!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
					alerta: true,
				});
				return false;
			}
		},

		async salvarVariacao(){
			const produtoPai = this.compraXML.estoque[this.indexAlteracaoProduto];
			this.variarProduto.filhos.forEach(item => {
				const quantidade = typeof item.quantidade == "number" ? item.quantidade : Number(`${item.quantidade || 0}`.split(".").join("").replace(",","."));
				item.quantidade = quantidade;
				item.pai = produtoPai.cdproduto;
				item.cdpai = produtoPai.cdproduto;
				item.bgcolor = produtoPai.bgcolor;
				const valorOutros = typeof produtoPai.valoroutros == "number" ? produtoPai.valoroutros : Number(`${produtoPai.valoroutros || 0}`.split(".").join("").replace(",","."));
				const outros = typeof produtoPai.outros == "number" ? produtoPai.outros : Number(`${produtoPai.outros || 0}`.split(".").join("").replace(",","."));
				item.valoroutros = Number(valorOutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				item.outros = Number(outros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				item.prazo = item.pontoequi;
				const desconto = typeof item.descvista == "number" ? item.descvista : Number(`${item.descvista || 0}`.split(".").join("").replace(",","."));
				item.cdesc = desconto;
				item.vista = typeof item.vlrvista == "number" ? item.vlrvista : Number(`${item.vlrvista || 0}`.split(".").join("").replace(",","."));
				item.embalagem = produtoPai.embalagem || produtoPai.unidade;
				this.calcularCustoUnitario(item);
				this.atualizarFilhosOuAgrupados(item);
				if (item.ecf) {
					produtoPai.prazo = item.prazo;
					produtoPai.margem = item.margem;
					produtoPai.vista = item.vista;
				}
			});
			
			if(!this.validarQuantidadesVariacao(produtoPai)) return;
			this.compraXML.estoque[this.indexAlteracaoProduto].filhos = [...this.variarProduto.filhos];
			try {
				for (const prod of this.compraXML.estoque[this.indexAlteracaoProduto].filhos) {
					prod.margem = prod.markup;
					prod.descvista = prod.cdesc;
					if(prod.descresumida?.length > 60) {
						prod.descresumida = prod?.descresumida.substring(0, 60);
					}
					if (!prod.cdproduto) {
						const produtoCadastrado = await this.$store.dispatch("cadastrarProduto", {
							...prod,
							formacaoPreco: {
								[this.$store.state.Empresa.id]: new FormacaoPrecoCompra({...prod, saldo: 0, ultsaldo: 0})
							},
						});
						if (produtoCadastrado ? !produtoCadastrado.id : true) {
							throw new Error(`Erro ao cadastrar a variação ${prod.descricao}.`+"\n"+produtoCadastrado.message);
						}
						prod.cdproduto = produtoCadastrado.id;
						prod.variacao = produtoCadastrado.variacao;
						prod.grade = produtoCadastrado.grade;
						prod.ecf = produtoCadastrado.ecf;
					}
				}
			} catch (error) {
				return this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
			}
			const filhos = await this.$store.dispatch("variarEstoqueEntrada", {
				cdcompra: this.cdcompra || this.compra,
				cdempresa: this.$store.state.Empresa.id,
				cdfornecedor: this.fornecedor.id,
				tipo: this.compraXML.tipo,
				confirmada: "L",
				cdproduto: this.compraXML.estoque[this.indexAlteracaoProduto].cdproduto,
				cdreferencia: this.compraXML.estoque[this.indexAlteracaoProduto].cdreferencia,
				cdseq: this.compraXML.estoque[this.indexAlteracaoProduto].cdseq,
				estoque: [
					{...this.compraXML.estoque[this.indexAlteracaoProduto]},
					...this.compraXML.estoque[this.indexAlteracaoProduto].filhos,
				],
			});
			if (!filhos?.length) return;
			this.compraXML.estoque[this.indexAlteracaoProduto].filhos = filhos;
			this.compraXML.estoque[this.indexAlteracaoProduto].status = "V";
			filhos.forEach(filho => {
				if (filho.ecf) {
					this.compraXML.estoque[this.indexAlteracaoProduto].margem = filho.markup;
					this.compraXML.estoque[this.indexAlteracaoProduto].margemTabela = (filho.markup || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
					this.compraXML.estoque[this.indexAlteracaoProduto].prazo = (filho.prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
					this.compraXML.estoque[this.indexAlteracaoProduto].cdesc = filho.cdesc;
					this.compraXML.estoque[this.indexAlteracaoProduto].descTabela = (filho.cdesc || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3 });
					this.compraXML.estoque[this.indexAlteracaoProduto].vista = (filho.vista || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				}
			});
			await this.salvarItemEstoqueEntrada(this.compraXML.estoque[this.indexAlteracaoProduto]);
			this.indexAlteracaoProduto = -1;
			this.variarProduto = null;
			this.$store.state.produtoFilhos = [];
			// await this.informarCadastroCompra(compraCadastrada);
			this.$forceUpdate();
		},

		// CONFIRMAR
		perguntaConfirmada(acao) {
			if (acao) {
				this[acao]();
			}
			this.mensagemPergunta = null;
			this.acaoConfirmar = null;
			this.acaoCancelar = null;
		},
		perguntaCancelada(acao) {
			this.mensagemPergunta = null;
			this.acaoConfirmar = null;
			if (acao) {
				this[acao]();
			}
		},
		/* CONTROLE DE ABAS*/
		mudarAba(index, mostrarAba){
			this.alteraVencimento = false;
			const aba = document.getElementsByClassName("aba")[index];
			const abaSelecionada = document.getElementsByClassName("abaSelecionada")[0];
			if (!aba || !abaSelecionada) return;
			if (!this.cdcompra && !aba.innerHTML.includes("Dados")) {
				if (!this.fornecedor?.id && !this.fornecedor?.nome) return this.$store.commit("relatarErro", {mensagem: "Favor informar o fornecedor!"});
				this.salvarEntrada();
			}
			if (aba.innerHTML.includes("Financeiro")) {
				if (this.compraXML.confirmada == "N" && !this.compraXML.estoque.length) {
					return this.$store.commit("relatarErro", {
						mensagem: "Não é possível lançar uma compra sem produtos!"
					});
				} else if (this.compraXML.confirmada == "L") {
					return this.lancarEstoque();
				} else if (this.compraXML.confirmada == "A") {
					return;
				}
			}
			if (aba != abaSelecionada) {
				aba.classList.add(["abaSelecionada"]);
				abaSelecionada.classList.remove(["abaSelecionada"]);
				setTimeout(() => {
					this.mostrarAba = mostrarAba || aba.innerText;
					this.cancelarAcaoEmMassa();
				}, 30);
			}
		},

		/* SELEÇÃO DE CAMPOS */
		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
						if (input.select) {
							input.select();
						}
					}
					this.backdoor = 0;
					this.$forceUpdate();
				},20);
			} catch (error) {
				console.log(error);
			}
		},

		/* MASCARAS */
		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraIntegerLancamento(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraFloat(e) {
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ","){
				e.preventDefault();
			}
		},
		capturarAlteracaoFinanceiro(index, time){
			clearTimeout(this.timer);
			const timer = time || 5000;
			// if (this.saldoFinanceiro < 0) {
			// 	return this.$store.commit("relatarErro", {
			// 		mensagem: "Valor informado maior que o saldo a receber, favor conferir!",
			// 		alerta: true,
			// 	});
			// }
			let contarc = Number(`${this.compraXML.financa[index].contarcvlr || 0}`.split(".").join("").replace(",", ".") || 0);
			this.compraXML.financa[index].contarc = contarc;
			this.timer = setTimeout(() => {
				if (isNaN(contarc)) {
					this.compraXML.financa[index].contarc = 0;
					return this.$store.commit("relatarErro", {
						mensagem: "Valor informado está inválido, favor conferir!",
						alerta: true,
					});
				}
				if (contarc == 0) {
					this.compraXML.financa[index].contarc = 0;
					return this.$store.commit("relatarErro", {
						mensagem: "Valor informado está inválido, favor conferir!",
						alerta: true,
					});
				}
			}, timer);
		},
		validarCamposFinanceiro(e) {
			try {
				const id = `${e.target.id}`.split("-")[0];
				const index = `${e.target.id}`.split("-")[1];
				if (id == "formaRecebimento") {
					if (!this.compraXML.financa[index].formaPagamento) {
						throw new Error("Favor informar a forma de pagamento!");
					}
					this.proximoCampo(`datavenc-${index}`);
				} else if (id == "datavenc") {
					if (!this.compraXML.financa[index].formaPagamento) {
						throw new Error("Favor informar a forma de pagamento!");
					}
					if (!this.compraXML.financa[index].datavenc) {
						throw new Error("Favor informar a data de vencimento da parcela!");
					}
					this.proximoCampo(`contarc-${index}`);
				} else if (id == "contarc") {
					if (!this.compraXML.financa[index].formaPagamento) {
						throw new Error("Favor informar a forma de pagamento!");
					}
					if (!this.compraXML.financa[index].datavenc) {
						throw new Error("Favor informar a data de vencimento da parcela!");
					}
					this.proximoCampo(`observa-${index}`);

				} else if (id === "observa") {
					this.capturarAlteracaoFinanceiro(index, 100);
					if (this.saldoFinanceiro >= 0.01 && this.compraXML.financa[index].contarc > 0) {
						this.adicionarLinhaFinanceiro();
					}	
				}
				
			} catch (error) {
				setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error)});
				}, 100);
			}
		},
		mascaraDateFinanceiro(e) {
			if (e.type == "focusout" || (e.type == "keydown" && (e.key == "Enter" || e.key == "Tab"))) {
				setTimeout(() => {
					const inputContarc = document.getElementById(`contarc-${e.target.id.split("-")[1]}`);
					if (inputContarc) {
						inputContarc.focus();
						if (inputContarc.select) {
							inputContarc.select();
						}
					}
				}, 100);
			}
		},
		mascaraUpperCase(evento) {
			const id = evento.target.id;
			if (!id) return console.log("Id não encontrado");
			this[id] = this[id].toUpperCase();
		},
		mascaraUpperCaseLancamento(e) {
			this.produtoParaIncluir[e.target.id] = this.produtoParaIncluir[e.target.id].toUpperCase();
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				if (e.target.id == "descricao" && this.produtoParaIncluir.filhos?.length) {
					this.produtoParaIncluir.filhos.forEach(filho => {
						filho.descricao = `${this.produtoParaIncluir[e.target.id]}; ${filho.variacao} [${filho.grade}]`;
					});
				}
				this.timer = null;
			},420);
		},

		/* DADOS */
		iniciarSelecao(e) {
			const id = e.target.id;
			if (id == "fornecedor" && !e.target.disabled) {
				this.listarFornecedores = true;
			} else if (id == "transp" && !e.target.disabled) {
				this.listarTransp = true;
			} else if (id == "cfop" && !e.target.disabled) {
				this.listarCFOP = true;
			}
		},
		// TRANSPORTADORA
		iniciarSelecaoTransportadora() {
			this.listarTransp = true;
		},
		selecionarTransportadora(transporte){
			if (this.listarTransp) {
				this.questionarAdicaoProdutos();
			}
			this.listarTransp = false;
			this.transp = transporte;
			this.transp.nomecidade = transporte?.nomecidade || transporte?.cidade?.nomecidade;
			this.transp.uf = transporte?.uf || transporte?.cidade?.uf;
			this.transp.endereco = transporte?.endereco;
			this.transp.numero = transporte?.numero;
			this.transp.transporte = transporte?.transporte;
			this.checkboxTransportadora = true;
			this.compraXML.transporte = {...transporte};
		},
		async cadastrarTransportadora() {
			try {
				if (this.transp.id == 0 && !this.transp.nome) return setTimeout(() => {this.checkboxTransportadora = false;},10);
				if (this.transp.id == 0) {
					await this.validarTransporteXML();
				}
				if (this.transp.id != 0) return;
				if (this.compraXML.transporte.cnpj == this.compraXML.fornecedor.cnpj) return setTimeout(() => {this.checkboxTransportadora = false;},10);
				this.$store.state.carregando = true;
				const transportadoraCadastrado = await this.$store.dispatch("adicionarCliente", {
					cidade: {
						cdcidade: 0,
						codibge: null,
						nomecidade: this.compraXML.transporte.nomecidade,
						uf: this.compraXML.transporte.uf,
					},
					bairro: this.compraXML.transporte.bairro,
					celular: null,
					cep: this.compraXML.transporte.cep,
					classificacao: {cdclassi : 3},
					cnpj: this.compraXML.transporte.cnpj,
					dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					email: null,
					endereco: this.compraXML.transporte.logradouro,
					fantasia: this.compraXML.transporte.nome,
					fone: null,
					ierg: this.compraXML.transporte.ierg,
					nome: this.compraXML.transporte.nome,
					tipoPessoa: this.compraXML.transporte.cnpj.length > 15 ? "J" : "F",
				});
				if (transportadoraCadastrado) {
					if (transportadoraCadastrado.id) {
						this.selecionarTransportadora({...transportadoraCadastrado, transporte: this.compraXML.transporte.transporte});
					}
				}
				this.$store.state.carregando = false;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error)});
			}
		},
		// FORNECEDOR
		iniciarSelecaoFornecedor() {
			setTimeout(() => {
				this.listarFornecedores = true;
			}, 50);
		},

		questionarCFOPEntrada() {
			if (this.cfop) return;
			setTimeout(() => {
				this.$store.commit("relatarErro", {
					mensagem: "Favor preencher o CFOP",
					alerta: true,
					item: {
						acao: setTimeout(() => {
							this.proximoCampo("cfop");
						}, 100),
					}
				});
			}, 100);
		},

		questionarAdicaoProdutos() {
			setTimeout(() => {
				this.$store.commit("relatarErro", {
					mensagem: "Deseja adicionar produtos?",
					tipo: "polar",
					item: {
						acao: async () => {
							await this.salvarEntrada();
							this.mudarAba(1);
							setTimeout(() => {
								this.proximoCampo("cdprodutoOuCodbarra");
							}, 100);
						},
					}
				});
			}, 100);
		},

		selecionarFornecedor(fornecedor){
			this.listarFornecedores = false;
			this.fornecedor = fornecedor;
			this.fornecedor.id = fornecedor.id;
			this.fornecedor.uf = fornecedor.cidade?.uf;
			if (this.compraXML.tipo != "I") {
				if ((this.fornecedor.uf == this.$store.state.Empresa.ufCidade)) {
					this.cfop = this.$store.state.Empresa.cfopestacompra || "";
				} else {
					this.cfop = this.$store.state.Empresa.cfopintercompra || "";
				}
			}
			this.fornecedor.nomecidade = fornecedor.cidade?.nomecidade;
			this.fornecedor.numero = fornecedor.numero;
			this.compraXML.itens.forEach(item => {
				item.cdfornecedor = this.fornecedor.id;
			});
			this.compraXML.estoque.forEach(item => {
				item.cdfornecedor = this.fornecedor.id;
			});
			this.checkboxFornecedor = true;
			if ((fornecedor.cnpj == this.transp.cnpj) || (fornecedor.cnpj == (this.compraXML.transporte?.cnpj))) {
				const transporte = {
					qtdvolumetransporte: this.compraXML.qtdvolumetransporte,
					embalagemtransporte: this.compraXML.embalagemtransporte,
					pesobrutotransporte: this.compraXML.pesobrutotransporte,
					pesoliquidotransporte: this.compraXML.pesoliquidotransporte
				};
				this.selecionarTransportadora({...fornecedor, transporte});
				if (!this.cfop) {
					setTimeout(() => {
						this.questionarCFOPEntrada();
					}, 100);
				}
			} else if (!this.transp.id && !this.cdcompra && this.compraXML.tipo != "I") {
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: "Deseja selecionar o transporte?",
						tipo: "polar",
						item: {
							acao: () => {this.listarTransp = true;},
							acaoCancelar: this.questionarAdicaoProdutos,
						}
					});
				}, 100);
			}
		},

		async cadastrarFornecedor() {
			try {
				if (this.fornecedor.id == 0 && !this.fornecedor.nome) return setTimeout(() => {this.checkboxFornecedor = false;},10);
				if (this.fornecedor.id == 0) {
					await this.validarFornecedorXML();
				}
				if (this.fornecedor.id != 0) return;
				this.$store.state.carregando = true;
				const cidade = await this.$store.dispatch("buscarIbge", this.compraXML.fornecedor.ibge);
				const fornecedorCadastrado = await this.$store.dispatch("adicionarCliente", {
					cidade: {
						cdcidade: cidade ? cidade.cdcidade : 0,
						codibge: this.compraXML.fornecedor.ibge,
						nomecidade: this.compraXML.fornecedor.cidade,
						uf: this.compraXML.fornecedor.uf,
					},
					bairro: this.compraXML.fornecedor.bairro,
					celular: null,
					cep: this.compraXML.fornecedor.cep ? `${this.compraXML.fornecedor.cep.substring(0,2)}.${this.compraXML.fornecedor.cep.substring(2,5)}-${this.compraXML.fornecedor.cep.substring(5)}` : null,
					classificacao: {cdclassi : 2},
					cnpj: this.compraXML.fornecedor.cnpj,
					dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					email: null,
					endereco: this.compraXML.fornecedor.endereco,
					fantasia: this.compraXML.fornecedor.fantasia,
					fone: this.compraXML.fornecedor.fone ? this.compraXML.fornecedor.fone.length == 10 ? 
						`(${this.compraXML.fornecedor.fone.substring(0,2)})${this.compraXML.fornecedor.fone.substring(2,6)}-${this.compraXML.fornecedor.fone.substring(6)}` : 
						`(${this.compraXML.fornecedor.fone.substring(0,2)})${this.compraXML.fornecedor.fone.substring(2,7)}-${this.compraXML.fornecedor.fone.substring(7)}` : null,
					ierg: this.compraXML.fornecedor.ie,
					nome: this.compraXML.fornecedor.nome,
					tipoPessoa: "J",
					numero: this.compraXML.fornecedor.nro
				});
				if (fornecedorCadastrado) {
					if (fornecedorCadastrado.id) {
						this.selecionarFornecedor(fornecedorCadastrado);
					}
				}
				this.$store.state.carregando = false;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error)});
			}
		},
		
		/* PRODUTOS */
		selecionouProdutoParaAgrupar(index, produto) {
			try {
				if (!produto.agrupar) return;
				const produtosParaAgrupar = this.compraXML.estoque.filter(item => (item.agrupar && (item.cdseq != produto.cdseq)))[0];
				if (produtosParaAgrupar) {
					if (produto.vlrunitario != produtosParaAgrupar.vlrunitario) {
						throw new Error("Valor do produto selecionado é diferente dos outros produtos, é permitido apenas agrupar produtos de mesmo valor!");
					}
				}
			} catch (error) {
				this.compraXML.estoque[index].agrupar = false;
				produto.agrupar = false;
				document.getElementById(`${index}-agrupamento`).checked = false;
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error)});
			}
		},
		async cancelarCadastroProduto() {
			this.quantidadeAgrupamento = 0;
			this.agrupandoProdutos = false;
			this.adicionarProduto = null;
			this.indexAlteracaoProduto = -1;
			this.indexAlterarCodigo = -1;
			this.indexListaProduto = -1;
			this.compraXML.estoque.forEach(item => item.agrupado = false);
			this.cdprodutoOuCodbarra = "";
			this.produtoEncontrado = null;
			this.cancelarAcaoEmMassa();
		},
		async atualizarAgrupamento(produto) {
			let agrupamento = [];
			this.compraXML.estoque.filter(item => item.cdproduto == produto.id).forEach(item => {
				agrupamento.push({
					cdfornecedor: item.cdfornecedor,
					cdreferencia: item.cdreferencia,
					codbarraFornecedor: item.codbarraFornecedor
				});
			});
			if (!this.acaoEmMassa) {
				const produtoAgrupado = this.compraXML.estoque[this.indexAlterarCodigo];
				let itemAgrupado = this.compraXML.itens.find(it => (it.cdreferencia == produtoAgrupado.cdreferencia && it.codbarraFornecedor == produtoAgrupado.codbarraFornecedor));
				if (itemAgrupado) {
					itemAgrupado.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
					itemAgrupado.cdproduto = produto.id || produto.cdproduto;
					agrupamento.push({
						cdfornecedor: itemAgrupado.cdfornecedor,
						cdreferencia: itemAgrupado.cdreferencia,
						codbarraFornecedor: itemAgrupado.codbarraFornecedor
					});
				}
				produtoAgrupado.cdproduto = produto.id || produto.cdproduto;
				produtoAgrupado.cdgrupo = produto.cdgrupo;
				produtoAgrupado.cdsubgrupo = produto.cdsubgrupo;
				produtoAgrupado.codbarra = produto.codBarra || produto.codbarra;
				produtoAgrupado.codinter = produto.codinter;
				produtoAgrupado.descricao = produto.descricao;
				produtoAgrupado.ncm = produto.ncm;
				produtoAgrupado.cdesc = produto.descvista;
				produtoAgrupado.descvista = produto.descvista;
				produtoAgrupado.descTabela = Number(produto.descvista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				produtoAgrupado.descVistaAnterior = produto.descvista;
				produtoAgrupado.markupAnterior = produto.markup;
				produtoAgrupado.novoMarkup = produto.markup;
				produtoAgrupado.markup = produto.markup;
				produtoAgrupado.margem = produto.markup;
				produtoAgrupado.margemTabela = Number(produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produtoAgrupado.vlrunitario = produto.custo || produto.custofixo;
				produtoAgrupado.vlrnovo = Number(produto.custo || produto.custofixo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produtoAgrupado.vista = Number(produto.valorVista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produtoAgrupado.prazo = Number(produto.valorPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produtoAgrupado.outros = Number((produto.outros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits:4, maximumFractionDigits:4});
				produtoAgrupado.valoroutros = Number((produto.valoroutros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				produtoAgrupado.valorfrete = produto.valorfrete;
				produtoAgrupado.ultsaldo = produto.saldo;
				produtoAgrupado.ipi = produto.ipi;
				produtoAgrupado.icmsst = produto.icmsst;
				produtoAgrupado.valoricmsst = produto.valoricmsst;
				produtoAgrupado.valoricmsstAnterior = produto.valoricmsst;
				produtoAgrupado.valoripi = produto.valoripi,
				produtoAgrupado.valoripiAnterior = produto.valoripi;
				produtoAgrupado.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
				this.calcularCustoUnitario(produtoAgrupado);
			} else {
				const bgcolor = this.coresAgrupamentos[this.indexCorAgrupamento];
				if (this.indexCorAgrupamento == this.coresAgrupamentos.length-1) {
					this.indexCorAgrupamento = 0;
				} else {
					this.indexCorAgrupamento++;
				}
				// let valorTotal = 0;
				// let totalQuantidade = 0;
				const produtosAgrupando = this.compraXML.estoque.filter(item => (item.selecionado || (item.cdproduto == (produto.id || produto.cdproduto))));
				if(produtosAgrupando?.length > 0) {
					produtosAgrupando.forEach(item => {
						// valorTotal += item.custo;
						// totalQuantidade += item.quantidade;
						item.cdproduto = produto.id || produto.cdproduto;
						item.cdgrupo = produto.cdgrupo;
						item.cdsubgrupo = produto.cdsubgrupo;
						item.codbarra = produto.codBarra || produto.codbarra;
						item.codinter = produto.codinter;
						item.descricao = produto.descricao;
						item.descvista = produto.descvista;
						item.descVistaAnterior = produto.descvista;
						item.markupAnterior = produto.markup;
						item.novoMarkup = produto.markup;
						item.markup = produto.markup;
						item.margem = produto.markup;
						// item.vlrunitario = valorTotal / totalQuantidade;
						// this.compraXML.estoque[this.indexAlteracaoProduto].vlrunitario = item.vlrunitario;
						item.margemTabela = (item.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						item.prazo = Number(produto.valorPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						item.vista = Number(produto.valorVista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						item.cdesc = (produto.valorPrazo - produto.valorVista) / produto.valorPrazo * 100;
						item.descTabela = (item.cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
						if (this.indexAlterarCodigo >= 0 && (this.indexAlteracaoProduto != this.indexAlterarCodigo)) {
							item.ncm = produto.ncm;
							// item.vlrunitario = valorTotal / totalQuantidade;
							item.outros = (Number((produto.outros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}));
							item.valoroutros = Number((produto.valoroutros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
							item.valorfrete = produto.valorfrete;
							item.ipi = produto.ipi;
							item.icmsst = produto.icmsst;
							item.valoricmsst = produto.valoricmsst;
							item.valoricmsstAnterior = produto.valoricmsst;
							item.valoripi = produto.valoripi,
							item.valoripiAnterior = produto.valoripi;
							item.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
						} else {
							item.ncm = this.compraXML.estoque[this.indexAlteracaoProduto].ncm;
							item.outros = this.compraXML.estoque[this.indexAlteracaoProduto].outros;
							item.valoroutros = this.compraXML.estoque[this.indexAlteracaoProduto].valoroutros;
							item.valorfrete = this.compraXML.estoque[this.indexAlteracaoProduto].valorfrete;
							item.ipi = this.compraXML.estoque[this.indexAlteracaoProduto].ipi;
							item.icmsst = this.compraXML.estoque[this.indexAlteracaoProduto].icmsst;
							item.valoricmsst = this.compraXML.estoque[this.indexAlteracaoProduto].valoricmsst;
							item.valoricmsstAnterior = this.compraXML.estoque[this.indexAlteracaoProduto].valoricmsst;
							item.valoripi = this.compraXML.estoque[this.indexAlteracaoProduto].valoripi,
							item.valoripiAnterior = this.compraXML.estoque[this.indexAlteracaoProduto].valoripi;
							item.bgcolor = bgcolor;
							// item.vlrunitario = valorTotal / totalQuantidade;
							// this.compraXML.estoque[this.indexAlteracaoProduto].vlrunitario = item.vlrunitario;
						}
						
						let itensAgrupados = this.compraXML.itens.filter(it => (it.cdreferencia == item.cdreferencia && it.codbarraFornecedor == item.codbarraFornecedor));
						
						if (itensAgrupados) {
							itensAgrupados?.forEach(prod => {
								prod.bgcolor = bgcolor;
								// prod.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
								prod.cdproduto = produto.id || produto.cdproduto;
								agrupamento.push({
									cdfornecedor: prod.cdfornecedor,
									cdreferencia:  prod.cdreferencia,
									codbarraFornecedor: prod.codbarraFornecedor	
								});
							});
							// itemAgrupado.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
							// itemAgrupado.cdproduto = produto.id || produto.cdproduto;
							// agrupamento.push({
							// 	cdfornecedor: itemAgrupado.cdfornecedor,
							// 	cdreferencia:  itemAgrupado.cdreferencia,
							// 	codbarraFornecedor: itemAgrupado.codbarraFornecedor
							// });
						}
					});
				}
			}
			if (agrupamento.length) {
				const prod = await this.$store.dispatch("cadastrarAgrupamentoProduto", {
					cdproduto: produto.id,
					cdempresa: this.$store.state.Empresa.id,
					cdfornecedor: this.fornecedor.id,
					agrupamento
				});
				if(!prod) {
					const produtosAgrupando = this.compraXML.estoque.filter(item => (item.selecionado || (item.cdproduto == (produto.id || produto.cdproduto))));
					if(produtosAgrupando) {
						produtosAgrupando.forEach(item => {
							item.bgcolor = "";
							item.cdagrupamento = "";
							item.cdproduto = null;
							item.cdgrupo = null;
							item.cdsubgrupo = null;
						});
					}
					agrupamento = [];
					this.cancelarAcaoEmMassa();
					this.cancelarCadastroProduto();
					this.atualizarTotaisCompra();
				}
				this.compraXML.estoque.forEach(item => {
					if (item.cdproduto == produto.id) {
						item.cdagrupamento = prod.cdagrupamento;
					}
				});
				this.removerAgrupamentoDuplicado(prod.cdagrupamento);
				this.indexAlteracaoProduto = this.compraXML.estoque.map(item => item.cdagrupamento).indexOf(prod.cdagrupamento);
			}
			await this.salvarItemEstoqueEntrada(this.compraXML.estoque[this.indexAlteracaoProduto]);
			this.cancelarCadastroProduto();
			this.atualizarTotaisCompra();
		},

		removerAgrupamentoDuplicado (cdagrupamento) {
			let quantidade = 0;
			let total = 0;
			this.compraXML.estoque.filter(item => (`${item.cdagrupamento}` == `${cdagrupamento}`)).forEach(item => {
				quantidade += (item.quantidade || 0);
				total += item.vlrunitario * item.quantidade;
				item.agrupado = true;
			});
			const index = this.compraXML.estoque.map(item => `${item.cdagrupamento}`).indexOf(`${cdagrupamento}`);
			if (index >= 0) {
				this.compraXML.estoque[index].quantidade = quantidade;
				this.compraXML.estoque[index].qtd = Number(quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				this.compraXML.estoque[index].agrupado = false;
				this.compraXML.estoque[index].vlrunitario = total / quantidade;
			}
			this.compraXML.estoque = this.compraXML.estoque.filter(item => !item.agrupado);
			this.compraXML.estoque.forEach((item, index) => item.cdseq = (index + 1));
			this.$forceUpdate();
		},

		async cadastrarAgrupamento(produto) {
			let bgcolor = this.coresAgrupamentos[this.indexCorAgrupamento];
			if (this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor) {
				if (this.indexCorAgrupamento == this.coresAgrupamentos.length-1) {
					this.indexCorAgrupamento = 0;
				} else {
					this.indexCorAgrupamento++;
				}
			}
			const agrupamento = [];
			this.compraXML.estoque.filter(item => item.agrupado).forEach(item => {
				item.cdproduto = produto.id || produto.cdproduto;
				item.cdgrupo = produto.cdgrupo;
				item.cdsubgrupo = produto.cdsubgrupo;
				item.codbarra = produto.codBarra || produto.codbarra;
				item.codinter = produto.codinter;
				item.descricao = produto.descricao;
				item.ncm = produto.ncm;
				item.cdesc = produto.descvista;
				item.descvista = produto.descvista;
				item.descTabela = (produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				item.descVistaAnterior = produto.descvista;
				item.markupAnterior = produto.markup;
				item.novoMarkup = produto.markup;
				item.markup = produto.markup;
				item.margem = produto.markup;
				item.margemTabela = Number(produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.vlrunitario = (produto.custo || (produto.custofixo || 0));
				item.vlrnovo = (produto.custo || (produto.custofixo || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.vista = Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.prazo = Number(produto.valorPrazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				item.outros = Number((produto.outros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				item.valoroutros = Number((produto.valoroutros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				item.valorfrete = produto.valorfrete;
				item.ultsaldo = produto.saldo;
				item.ipi = produto.ipi;
				item.icmsst = produto.icmsst;
				item.valoricmsst = produto.valoricmsst;
				item.valoricmsstAnterior = produto.valoricmsst;
				item.valoripi = produto.valoripi,
				item.valoripiAnterior = produto.valoripi;
				item.bgcolor = bgcolor;

				// AQUI
				// let itemAgrupado = this.compraXML.itens.find(it => (
				// 	it.cdreferencia == item.cdreferencia && it.codbarraFornecedor == item.codbarraFornecedor
				// ));

				// if (itemAgrupado) {
				// 	itemAgrupado.bgcolor = bgcolor;
				// 	itemAgrupado.cdproduto = produto.id || produto.cdproduto;
				// 	agrupamento.push({
				// 		cdfornecedor: itemAgrupado.cdfornecedor,
				// 		cdreferencia: itemAgrupado.cdreferencia,
				// 		codbarraFornecedor: itemAgrupado.codbarraFornecedor
				// 	});
				// }
				const produtosAgrupados =  this.compraXML.itens.filter(it => (it.cdreferencia === item.cdreferencia && it.codbarraFornecedor === item.codbarraFornecedor));
				if(produtosAgrupados?.length > 0) {
					produtosAgrupados.forEach(prod => {
						prod.bgcolor = bgcolor;
						prod.cdproduto = produto.id || produto.cdproduto;
						agrupamento.push({
							cdfornecedor: prod.cdfornecedor,
							cdreferencia: prod.cdreferencia,
							codbarraFornecedor: prod.codbarraFornecedor
						});
					});
				}
			});
			if (agrupamento.length) {
				const agrupamentoCadastrado = await this.$store.dispatch("cadastrarAgrupamentoProduto", {
					cdproduto: produto.id,
					cdempresa: this.$store.state.Empresa.id,
					cdfornecedor: this.fornecedor.id,
					agrupamento
				});
				if (!agrupamentoCadastrado) return;
				let quantidade = 0;
				this.compraXML.estoque.filter(item => item.agrupado).forEach(item => {
					item.cdagrupamento = agrupamentoCadastrado.cdagrupamento;
					quantidade += item.quantidade;
				});

				const index = this.compraXML.estoque.map(item => item.agrupado).indexOf(true);
				const item = this.compraXML.estoque[index];
				
				this.compraXML.estoque[index].quantidade = quantidade;
				this.compraXML.estoque[index].agrupado = false;
				this.compraXML.estoque = this.compraXML.estoque.filter(item => !item.agrupado);
				this.indexAlteracaoProduto = this.compraXML.estoque.map(item => item.cdproduto).indexOf(item.cdproduto);
				this.compraXML.estoque.forEach((item, index) => item.cdseq = (index +1));
				this.$forceUpdate();
			}
			this.compraXML.estoque[this.indexAlteracaoProduto].markup = produto.markup;
			this.compraXML.estoque[this.indexAlteracaoProduto].margem = produto.markup;
			this.compraXML.estoque[this.indexAlteracaoProduto].margemTabela = Number(produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.compraXML.estoque[this.indexAlteracaoProduto].prazo = Number(produto.valorPrazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.compraXML.estoque[this.indexAlteracaoProduto].cdesc = produto.descvista;
			this.compraXML.estoque[this.indexAlteracaoProduto].descvista = produto.descvista;
			this.compraXML.estoque[this.indexAlteracaoProduto].descTabela = (produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
			this.compraXML.estoque[this.indexAlteracaoProduto].vista = Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		alterarProdutoUnitario(produto, cadastro) {
			
			return new Promise((res) => {
				this.compraXML.estoque[this.indexAlteracaoProduto].cdproduto = produto.id;
				this.compraXML.estoque[this.indexAlteracaoProduto].codbarra = produto.codBarra;
				this.compraXML.estoque[this.indexAlteracaoProduto].codinter = produto.codinter;
				this.compraXML.estoque[this.indexAlteracaoProduto].cdgrupo = produto.cdgrupo;
				this.compraXML.estoque[this.indexAlteracaoProduto].cdsubgrupo = produto.cdsubgrupo;
				this.compraXML.estoque[this.indexAlteracaoProduto].descricao = produto.descricao;
				this.compraXML.estoque[this.indexAlteracaoProduto].descvista = produto.descvista;
				this.compraXML.estoque[this.indexAlteracaoProduto].descTabela = (produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				this.compraXML.estoque[this.indexAlteracaoProduto].descVistaAnterior = produto.descvista;
				this.compraXML.estoque[this.indexAlteracaoProduto].markupAnterior = produto.markup;
				this.compraXML.estoque[this.indexAlteracaoProduto].novoMarkup = produto.markup;
				this.compraXML.estoque[this.indexAlteracaoProduto].markup = produto.markup;
				this.compraXML.estoque[this.indexAlteracaoProduto].qtd = Number(this.compraXML.estoque[this.indexAlteracaoProduto].quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				this.compraXML.estoque[this.indexAlteracaoProduto].margem = (produto?.markup || 0);
				this.compraXML.estoque[this.indexAlteracaoProduto].margemTabela = (this.compraXML.estoque[this.indexAlteracaoProduto].margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.compraXML.estoque[this.indexAlteracaoProduto].vista = Number(produto.valorVista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.compraXML.estoque[this.indexAlteracaoProduto].prazo = Number(produto.valorPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.compraXML.estoque[this.indexAlteracaoProduto].cdesc = (((produto.valorPrazo || 0) - (produto.valorVista || 0)) / (((produto.valorPrazo || 0) * 100) || 1)) || 0;
				this.compraXML.estoque[this.indexAlteracaoProduto].outros = Number((produto.outros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				this.compraXML.estoque[this.indexAlteracaoProduto].valoroutros = Number((produto.valoroutros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				this.compraXML.estoque[this.indexAlteracaoProduto].valorfrete = produto.valorfrete;
				this.compraXML.estoque[this.indexAlteracaoProduto].ipi = produto.ipi;
				this.compraXML.estoque[this.indexAlteracaoProduto].icmsst = produto.icmsst;
				this.compraXML.estoque[this.indexAlteracaoProduto].valoricmsst = produto.valoricmsst;
				this.compraXML.estoque[this.indexAlteracaoProduto].valoricmsstAnterior = produto.valoricmsst;
				this.compraXML.estoque[this.indexAlteracaoProduto].valoripi = produto.valoripi,
				this.compraXML.estoque[this.indexAlteracaoProduto].valoripiAnterior = produto.valoripi;
				this.compraXML.estoque[this.indexAlteracaoProduto].pis = produto.pis,
				this.compraXML.estoque[this.indexAlteracaoProduto].valorpis = produto.valorpis;
				this.compraXML.estoque[this.indexAlteracaoProduto].cofins = produto.cofins,
				this.compraXML.estoque[this.indexAlteracaoProduto].valorcofins = produto.valorcofins;
				this.compraXML.estoque[this.indexAlteracaoProduto].descresumido = produto.descresumido;
				this.compraXML.estoque[this.indexAlteracaoProduto].cdFornecedor = produto.cdFornecedor;
				this.compraXML.estoque[this.indexAlteracaoProduto].cdlote = produto.cdlote;
				if (cadastro) {
					this.compraXML.estoque[this.indexAlteracaoProduto].vlrunitario = (produto.custo || produto.custofixo);
					this.compraXML.estoque[this.indexAlteracaoProduto].vlrnovo = (this.compraXML.estoque[this.indexAlteracaoProduto].vlrunitario || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					this.compraXML.estoque[this.indexAlteracaoProduto].ncm = produto.ncm;
					this.compraXML.estoque[this.indexAlteracaoProduto].cdreferencia = produto.cdReferencia;
					this.compraXML.estoque[this.indexAlteracaoProduto].margemTabela = (this.compraXML.estoque[this.indexAlteracaoProduto].markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					this.compraXML.estoque[this.indexAlteracaoProduto].margem = (this.compraXML.estoque[this.indexAlteracaoProduto].markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					this.compraXML.estoque[this.indexAlteracaoProduto].descTabela = (this.compraXML.estoque[this.indexAlteracaoProduto].descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				}
				this.atualizarFilhosOuAgrupados(this.compraXML.estoque[this.indexAlteracaoProduto]);
				res(true);
			});
		},
		incluirProdutoTabela(produto) {
			const totalMargem = Number(this.totalMargem.split(".").join("").replace(",","."));
			return new Promise((res) => {
				this.compraXML.estoque.push({
					...produto,
					cdseq: (this.compraXML.estoque.length +1),
					cfop: this.cfop,
					cdorigem: produto.cdorigem,
					cdproduto: produto.id,
					codbarra: produto.codBarra,
					descresumido: produto.descresumido || produto.descricao,
					quantidade: 1,
					qtd: "1,00",
					ncm: produto.ncm,
					cdgrupo: produto.cdgrupo,
					cdsubgrupo: produto.cdsubgrupo,
					cdreferencia: produto.cdReferencia || produto.cdreferencia,
					cdesc: produto.descvista || 0,
					descTabela: Number(produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3}),
					descvista: Number(produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					descVistaAnterior: Number(produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					markupAnterior: Number(produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					novoMarkup: Number(totalMargem > 0 ? totalMargem : produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					markup: Number(totalMargem > 0 ? totalMargem : produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					margemTabela: Number(totalMargem > 0 ? totalMargem : produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					margem: Number(totalMargem > 0 ? totalMargem : produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					vista: Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					prazo: Number(produto.valorPrazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					outros: Number((produto.outros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}),
					valoroutros: Number((produto.valoroutros || 0) + (produto.valormarkupseg || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}),
					valorfrete: Number((produto.valorfrete || 0) * (produto.quantidade || 1)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}),
					valorFreteAnterior: (produto.valorfrete || 0),
					vlrunitario: (produto.custo || (produto.custofixo || 0)),
					vlrnovo: (produto.custo || (produto.custofixo || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					ultsaldo: produto.saldo,
					ipi: Number(produto.ipi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					icmsst: Number(produto.icmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
					valoricmsst: Number(produto.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}),
					valoricmsstAnterior: Number(produto.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}),
					valoripi: produto.valoripi || 0,
					valoripiAnterior: Number(produto.valoripi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				});
				this.calcularCustoUnitario(this.compraXML.estoque[this.compraXML.estoque.length -1]);
				setTimeout(() => {
					this.proximoCampo(`qtd-${this.compraXML.estoque.length -1}`);
				}, 50);
				this.indexAlteracaoProduto = -1;
				this.indexAlterarCodigo = -1;
				this.indexListaProduto = -1;
				this.cdprodutoOuCodbarra = "";
				this.produtoEncontrado = null;
				return res(true);
			});
		},
		duplicarEntradaProduto() {
			if (!this.podeAlterarEstoque) return;
			this.indexAlteracaoProduto = -1;
			this.adicionarProduto = {
				...this.adicionarProduto,
				codBarra: null,
				codinter: null,
				cdproduto: null,
				id: null,
				novo: true,
				duplicando: true
			};
		},

		// SALVAR ITEM ESTOQUE NA ENTRADA 

		// PROCURAR ONDE FAZ O PUT DO CADASTRO DE PRODUTO
		async salvarItemEstoqueEntrada(produto) {
			if(produto?.cdagrupamento && this.compraXML?.nfeid) {	
				produto.cdreferencia = "AGRUPADO";
			}
			const status = `${produto.filhos ? "V" : "N"}`;
			produto.cdempresa = this.$store.state.Empresa.id;
			const entradaItem = await this.$store.dispatch("salvarItemEstoqueEntrada", {
				cdcompra: this.cdcompra,
				cdfornecedor:  this.fornecedor.id,
				cdempresa: this.$store.state.Empresa.id,
				tipo: this.compraXML.tipo,
				produto,
			});
			if (entradaItem.cdcompra) {
				entradaItem.cdproduto = produto.cdproduto;
				entradaItem.descresumido = produto.descresumido;
				entradaItem.status = status;
				entradaItem.qtd = Number(entradaItem.quantidade || 1).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.bgcolor = this.compraXML.estoque[this.indexAlteracaoProduto].bgcolor;
				entradaItem.descTabela = (entradaItem.descvista || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3 });
				entradaItem.margem = (entradaItem.markup || 0);
				entradaItem.margemTabela = Number(entradaItem.markup || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.outros = Number(entradaItem.outros || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.valoroutros = Number(entradaItem.valoroutros || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.prazo = Number(entradaItem.prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.vista = Number(entradaItem.vista || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.vlrnovo = Number(entradaItem.vlrunitario || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				entradaItem.saldo = Number(produto.saldo || 0); // adicionado essa linha para salvar o saldo quando o produto não é variado
				if (status == "V") {
					entradaItem.filhos = produto.filhos;
					// produto.filhos.forEach(filho => {
					// 	entradaItem.quantidade += filho.quantidade;
					// });
					// entradaItem.qtd = Number(entradaItem.quantidade || 1).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				}
				this.compraXML.estoque[this.indexAlteracaoProduto] = entradaItem;
				this.calcularCustoUnitario(this.compraXML.estoque[this.indexAlteracaoProduto]);
				this.$forceUpdate();
			}
		},
		async cadastrouProduto(produto, podeFinalizar) {
			if (!this.podeAlterarEstoque || !this.podeAlterarValorEstoque) {
				this.adicionarProduto = {...produto};
				if (podeFinalizar) {
					this.cancelarCadastroProduto();
				}
				return;
			}
			this.adicionarProduto = {...produto};
			if (this.agrupandoProdutos) {
				await this.cadastrarAgrupamento(produto);
				if(this.indexAlteracaoProduto >= 0) {
					await this.alterarProdutoUnitario(produto, true);	
				}
			} else {
				if (this.indexAlteracaoProduto >= 0) {
					await this.alterarProdutoUnitario(produto, true);
				} else {
					await this.incluirProdutoTabela(produto);
					this.indexAlteracaoProduto = this.compraXML.estoque.length -1;
				}
			}

			this.calcularCustoEstoque();

			// Cadastrar home0032
			this.compraXML.estoque[this.indexAlteracaoProduto].cdproduto = produto?.id;
			await this.salvarItemEstoqueEntrada(this.compraXML.estoque[this.indexAlteracaoProduto], this.indexAlteracaoProduto);
			this.atualizaProdutoTabela(this.indexAlteracaoProduto);
			if (podeFinalizar) {
				if (this.compraXML.tipo == "C") {
					this.proximoCampo(`qtd-${this.indexAlteracaoProduto}`);
				}
				this.cancelarCadastroProduto();
			}
		},

		atualizaProdutoTabela(index) {
			const itemRemovido = this.compraXML.estoque.splice(index, 1)[0];

			this.compraXML.estoque.splice(index, 0, itemRemovido);
		},

		capturarEntradaCdprodutoOuCodbarra(e) {
			if (e.key == "F4" || e.key == "Tab") {
				e.preventDefault();
				this.mascaraInteger(e);
				return this.indexListaProduto = 0;
			}
			if (e.key == "Enter") {
				if (`${this.cdprodutoOuCodbarra || ""}` == "0") {
					return setTimeout(() => {
						this.lancarEstoque();
					}, 50);
				}
				if (`${this.cdprodutoOuCodbarra || ""}` == "") {
					return setTimeout(() => {
						this.proximoCampo("totalCompra");
					}, 50);
				}
				return setTimeout(() => {
					this.selecionarProduto(this.produtoEncontrado);
				}, 300);
			}
			if (e.key == "." && `${e.target.value}`.includes(".")) this.mascaraInteger(e);
			clearTimeout(this.timer);
			this.timer = setTimeout(this.selecionarItemPorId, 120);
		},
		async selecionarItemPorId(){
			try {
				if (!this.cdprodutoOuCodbarra) return;
				let produto = null;
				if (this.cdprodutoOuCodbarra.length >= 12) {
					produto = await this.$store.dispatch("buscarProdutoPorCodigoBarras", `${this.cdprodutoOuCodbarra}`.substring(0,12));
				} else {
					produto = await this.$store.dispatch("buscarProdutoPorId", this.cdprodutoOuCodbarra);
				}
				if (!produto) {
					return this.produtoEncontrado = null;
				}
				this.produtoEncontrado = produto;
			} catch (error) {
				console.log(error);
			}
		},
		solicitaAlterarProduto(index) {
			if (!this.visaoEstoque) return;
			if (!this.podeAlterarEstoque) return;
			// const produtosSelecionados = this.compraXML.estoque.filter(item => item.selecionado);
			// if(produtosSelecionados?.length <= 1 && this.acaoEmMassa === "agruparProdutos") {
			// 	return this.$store.commit("relatarErro", {
			// 		mensagem: "Para realizar a ação de agrupamento, é necessário selecionar mais de 1 produto!",
			// 		alerta: true,
			// 	});
			// }
			this.indexListaProduto = index;
			this.indexAlteracaoProduto = index;
			this.indexAlterarCodigo = index;
		},
		atualizarTotaisCompra() {
			this.totalICMSst = "";
			this.totalFCPST = "";
			this.totalIpi = 0;
			this.totalFrete = "0,00";
			this.totalOutros = "0,00";
			this.totalDesconto = "0,00";
			this.compraXML.estoque.forEach(item => {
				if (item.valoricmsst) {
					if (typeof(item.valoricmsst) == "number") {
						this.totalICMSst = 
							(Number(`${this.totalICMSst || "0"}`.split(".").join("").replace(",", ".") || 0) 
							+ (item.valoricmsst)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						this.totalICMSst = 
						(Number(`${this.totalICMSst || "0"}`.split(".").join("").replace(",", ".") || 0) + ((Number(`${item.valoricmsst}`.split(".").join("").replace(",", ".") || 0)))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
				}

				// verificar o valoripi
				if (item.valoripi) {
					if (typeof(item.valoripi) == "number") {
						this.totalIpi = (this.totalIpi + (item.valoripi * item.quantidade));
					} else {
						this.totalIpi = (this.totalIpi + ((Number(`${item.valoripi}`.split(".").join("").replace(",", ".") || 0)) * item.quantidade));
					}
				}

				if (item.valorfrete)  {
					if (typeof(item.valorfrete) == "number") {
						this.totalFrete = (Number(`${this.totalFrete}`.split(".").join("").replace(",", ".") || 0) + (item.valorfrete * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						this.totalFrete = (Number(`${this.totalFrete}`.split(".").join("").replace(",", ".") || 0) + ((Number(`${item.valorfrete}`.split(".").join("").replace(",", ".") || 0)) * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
				}

				if (item.valoroutros) {
					if (typeof(item.valoroutros) == "number") {
						this.totalOutros = (Number(`${this.totalOutros || "0"}`.split(".").join("").replace(",", ".") || 0) + (item.valoroutros * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						this.totalOutros = (Number(`${this.totalOutros || "0"}`.split(".").join("").replace(",", ".") || 0) + ((Number(`${item.valoroutros}`.split(".").join("").replace(",", ".") || 0)) * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
				}
				if (item.valorFCPST) {
					if (typeof(item.valorFCPST) == "number") {
						this.totalFCPST = (Number(`${this.totalFCPST || "0"}`.split(".").join("").replace(",", ".") || 0) + (item.valorFCPST * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						this.totalFCPST = (Number(`${this.totalFCPST || "0"}`.split(".").join("").replace(",", ".") || 0) + ((Number(`${item.valorFCPST}`.split(".").join("").replace(",", ".") || 0)) * item.quantidade)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
				}
			});
			this.totalIpi = this.totalIpi.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.totalICMSst = Number(`${this.totalICMSst || "0"}`.split(".").join("").replace(",", ".")).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.totalOutros = Number(`${this.totalOutros || "0"}`.split(".").join("").replace(",", ".")).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.totalFrete = Number(`${this.totalFrete || "0"}`.split(".").join("").replace(",", ".")).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.totalDesconto = Number(`${this.totalDesconto || "0"}`.split(".").join("").replace(",", ".") || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		resetProdutoManual() {
			this.qtdProdutoManual = "1";
			this.vlrUnitarioManual = "";
			this.cfopManual = "";
			if (!this.produtoParaIncluir.filhos.length) {
				this.produtoParaIncluir.filhos = null;
			}
			this.produtoParaIncluir = null;
			this.variarProduto = null;
			this.indexAlteracaoProduto = -1;
			this.indexAlterarCodigo = -1;
		},
		async selecionarProduto(produto){
			if (!produto) return this.$store.commit("relatarErro", {mensagem: "Produto não encontrado!"});
			if (produto.pai) {
				produto = await this.$store.dispatch("selecionarProdutoPorId", {id: produto.pai});
			}
			const itemExiste = this.compraXML.estoque.filter(item => item.cdproduto || item.id).map(item => `${item.cdproduto}`).indexOf(`${produto.cdproduto || produto.id}`);
			this.agrupandoProdutos = this.acaoEmMassa == "agruparProdutos";
			if (this.agrupandoProdutos && !produto?.novo) {
				await this.atualizarAgrupamento(produto);
			} else if (itemExiste >= 0 && (this.indexAlteracaoProduto != itemExiste) && !this.compraXML.nfeid) {
				if (this.compraXML.tipo == "I") return this.cancelarCadastroProduto();
				this.indexAlterarCodigo = this.indexAlteracaoProduto;
				this.indexAlteracaoProduto = itemExiste;
				this.compraXML.estoque[itemExiste].quantidade += 1;
				this.compraXML.estoque[itemExiste].qtd = Number(this.compraXML.estoque[itemExiste].quantidade).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.atualizarFilhosOuAgrupados(produto);
				if (this.compraXML.tipo == "C") {
					this.proximoCampo(`qtd-${this.indexAlteracaoProduto}`);
				} else {
					this.proximoCampo(`outros-${this.indexAlteracaoProduto}`);
				}
			} else {
				if(produto.novo && this.agrupandoProdutos) {
					this.agruparProdutos();
					return;
				} 
				if (produto.novo) {
					this.adicionarProduto = {
						novo: true,
						cdFornecedor: produto.cdfornecedor || this.fornecedor.id,
						nomeFornecedor: this.fornecedor.nome
					};
					if (this.indexAlterarCodigo >= 0) {
						this.indexListaProduto = -1;
						return this.iniciarAlteracaoProduto(this.indexAlterarCodigo, this.compraXML.estoque[this.indexAlterarCodigo]);
					}
				} else {
					if (this.indexAlterarCodigo >= 0) {
						await this.alterarProdutoUnitario(produto);
						await this.salvarItemEstoqueEntrada(this.compraXML.estoque[this.indexAlterarCodigo]);
					} else {
						await this.incluirProdutoTabela(produto);
						this.indexAlteracaoProduto = this.compraXML.estoque.length -1;
						await this.salvarItemEstoqueEntrada(this.compraXML.estoque[this.compraXML.estoque.length -1]);
						return this.indexAlteracaoProduto = -1;
					}
				}
			}
			this.indexAlteracaoProduto = -1;
			this.indexAlterarCodigo = -1;
			this.indexListaProduto = -1;
		},
		converterStringParaNumber(string) {
			return string ? Number(`${string}`.split(".").join("").replace(",", ".")) : 0;
		},
		iniciarAlteracaoProduto(index, produto, e) {
			if(e ? (e.target.id.substring(0,4) !== "qtd-") : true ){
				if (!this.visaoEstoque) return;
				this.adicionarProduto = {
					id: produto.cdproduto,
					cdproduto: produto.cdproduto,
					descricao: `${produto.descricao}`.substring(0,100).toUpperCase(),
					pai: produto.cdpai,
					cfop: produto.cfop,
					pontoequi: Number((this.converterStringParaNumber(produto.prazo) || this.converterStringParaNumber(produto.vista) || this.converterStringParaNumber(produto.vlrunitario)).toFixed(2)),
					descvista: produto.cdesc,
					vlrvista: Number((this.converterStringParaNumber(produto.vista) || this.converterStringParaNumber(produto.prazo) || this.converterStringParaNumber(produto.vlrunitario)).toFixed(2)),
					codBarra: produto.codbarra,
					codbarraFornecedor: produto.codbarraFornecedor,
					cdReferencia: this.agrupandoProdutos ? "AGRUPADO" : produto.cdreferencia,
					ativo: produto.ativo || "A",
					unidade: produto.unidade,
					embalagem: produto.embalagem || produto.unidade,
					qteEmbalagem: produto.qteEmbalagem || "1",
					cdEmpresa: produto.cdempresa || this.$store.state.Empresa.id,
					cdgrupo: produto.cdgrupo || null,
					cdsubgrupo: produto.cdsubgrupo || null,
					saldo: produto.saldo || 0,
					ultsaldo: produto.ultsaldo,
					qtd: Number(produto.quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
					vlrnovo: Number(produto.vlrunitario || ((produto.vlrcompra) / (produto.quantidade || 1)) || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
					custo: produto.vlrunitario || ((produto.vlrcompra) / (produto.quantidade || 1)),
					custoMedio: produto.customedio || produto.vlrunitario || ((produto.vlrcompra) / (produto.quantidade || 1)),
					ultcusto: produto.ultcusto || (produto.custo || produto.vlrunitario),
					custofixo: produto.custofixo || (produto.vlrunitario || ((produto.vlrcompra) / (produto.quantidade || 1))),
					valormarkupseg: produto.valormarkupseg || 0,
					markupseg: produto.markupseg || 0,
					markup: produto.margem || produto.markup,
					descontoentrada: produto.valordesconto || 0,
					fator: produto.fator || 0,
					dataultalteracao: produto.dataultalteracao || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					valoripi: produto.valoripi || 0,
					ipi: produto.ipi || 0,
					valorpis: produto.valorpis || 0,
					pis: produto.pis || 0,
					valorcofins: produto.valorcofins || 0,
					cofins: produto.cofins || 0,
					valoricmsst: produto.valoricmsst || 0,
					icmsst: produto.icmsst || 0,
					valorfrete: produto.valorfrete || 0,
					frete: this.converterStringParaNumber(produto.frete),
					valoroutros: this.converterStringParaNumber(produto.valoroutros),
					outros: this.converterStringParaNumber(produto.outros),
					ncm: produto.ncm,
					cfopesta: produto.cfopesta || this.$store.state.Empresa.cfopesta,
					cfopinter: produto.cfopinter || this.$store.state.Empresa.cfopinter,
					icmsint: produto.icmsint,
					icmsest: produto.icmsest,
					pissittrib: produto.pissittrib || this.$store.state.Empresa.pissittrib,
					pistributacao: produto.pistributacao || this.$store.state.Empresa.pis,
					icmsintsittrib: produto.icmsintsittrib || this.$store.state.Empresa.icmsintsittrib,
					icmsestsittrib: produto.icmsestsittrib || this.$store.state.Empresa.icmsestsittrib,
					cofinssittrib: produto.cofinssittrib || this.$store.state.Empresa.cofinssittrib,
					cofinstributacao: produto.cofinstributacao || this.$store.state.Empresa.cofins,
					ipisittrib: produto.ipisittrib || this.$store.state.Empresa.ipisittrib,
					ipitributacao: produto.ipitributacao || this.$store.state.Empresa.ipi,
					curvaabc: produto.curvaabc,
					cdorigem: produto.cdorigem,
					grade: produto.grade,
					variacao: produto.variacao,
					estoqueMaximo: produto.estoqueMaximo,
					estoqueMinimo: produto.estoqueMinimo,
					pesoBruto: produto.pesoBruto,
					pesoLiquido: produto.pesoLiquido,
					geraComissao: produto.geraComissao,
					ecf: produto.ecf,
					cdFornecedor: produto.cdfornecedor || this.fornecedor.id,
					nomeFornecedor: this.fornecedor.nome,
					multiplo: produto.multiplo,
					codinter: produto.codinter,
					quantidade: produto.quantidade,
					cdagrupamento: produto.cdagrupamento,
					descresumido: produto.descresumido,
					cdlote: produto.cdlote,
				};
				this.indexAlteracaoProduto = index;
				this.cdprodutoOuCodbarra = "";
				this.produtoEncontrado = null;
			}
		},
		iniciarRemoverProduto(index, produto) {
			this.$store.commit("relatarErro",{
				mensagem: `Tem certeza que deseja remover o produto ${produto.cdproduto || produto.cdreferencia} - ${produto.descricao}?`,
				tipo: "polar",
				item: {
					acao: "removerProduto",
					index
				}
			});
		},
		removerProduto(index) {
			this.compraXML.estoque.splice(index ,1);
			this.compraXML.estoque.forEach((item, index) => {
				item.cdseq = index +1;
			});
			this.atualizarTotaisCompra();
			this.$forceUpdate();
		},
		alterarConfiguracaoProduto() {
			this.configurarProdutos = true;
		},
		atualizarConfigProduto() {
			if (!this.usarCodBarraFornecedor) {
				this.compraXML.estoque.forEach(item => {
					if (item.cdproduto && item.codbarra) return;
					item.codbarra = null;
				});
			} else {
				this.compraXML.estoque.forEach(item => {
					if (item.cdproduto && item.codbarra) return;
					if (`${item.codbarraFornecedor}`.includes("SEM GTIN")) return;
					item.codbarra = item.codbarraFornecedor || "";
				});
			}
			this.configurarProdutos = false;
		},

		atualizarFilhosDependentes(pai, produto) {
			if (pai.filhos ? pai.filhos.length : false) {
				pai.filhos.filter(filho => filho.descricao != produto.descricao).forEach(filho => {
					if (filho.ecf) {
						filho.outros = produto.outros;
						filho.valoroutros = produto.valoroutros;
						filho.frete = produto.frete;
						filho.valorfrete = produto.valorfrete;
						filho.icms = produto.icms;
						filho.valoricms = produto.valoricms;
						filho.icmsst = produto.icmsst;
						filho.valoricmsst = produto.valoricmsst;
						filho.ipi = produto.ipi;
						filho.valoripi = produto.valoripi;
						filho.custo = produto.custo;
						filho.prazo = produto.prazo;
						filho.pontoequi = produto.pontoequi;
						filho.margem = produto.margem;
						filho.markup = produto.markup;
						filho.cdesc = produto.cdesc;
						filho.descvista = produto.descvista;
						filho.vista = produto.vista;
						filho.vlrvista = produto.vlrvista;
					}
				});
			}
		},

		atualizarProdutosAgrupados(produto) {
			this.compraXML.estoque
				.filter((agrup, index) => (agrup.cdagrupamento == produto.cdagrupamento) && (index != this.indexAlteracaoProduto))
				.forEach(agrup => {
					agrup.outros = produto.outros;
					agrup.valoroutros = produto.valoroutros;
					agrup.frete = produto.frete;
					agrup.valorfrete = produto.valorfrete;
					agrup.icms = produto.icms;
					agrup.valoricms = produto.valoricms;
					agrup.icmsst = produto.icmsst;
					agrup.valoricmsst = produto.valoricmsst;
					agrup.ipi = produto.ipi;
					agrup.valoripi = produto.valoripi;
					agrup.custo = produto.custo;
					agrup.prazo = produto.prazo;
					agrup.margem = produto.margem;
					agrup.markup = produto.margem;
					agrup.cdesc = produto.cdesc;
					agrup.descvista = produto.descvista;
					agrup.vista = produto.vista;
					agrup.vlrnovo = Number(produto.vlrunitario).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					agrup.margemTabela = Number(produto.margem).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					agrup.descTabela = Number(produto.cdesc).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				});
		},

		atualizarProdutosFilhos(produto) {
			if (!produto.filhos) return;
			produto.filhos.forEach(filho => {
				if (filho.ecf) {
					filho.outros = produto.outros;
					filho.valoroutros = produto.valoroutros;
					filho.frete = produto.frete;
					filho.valorfrete = produto.valorfrete;
					filho.icms = produto.icms;
					filho.valoricms = produto.valoricms;
					filho.icmsst = produto.icmsst;
					filho.valoricmsst = produto.valoricmsst;
					filho.ipi = produto.ipi;
					filho.valoripi = produto.valoripi;
					filho.custo = produto.custo;
					filho.prazo = produto.prazo;
					filho.margem = produto.margem;
					filho.markup = produto.margem;
					filho.cdesc = produto.cdesc;
					filho.descvista = produto.descvista;
					filho.vista = produto.vista;
				}
			});
		},

		atualizarFilhosOuAgrupados(item) {
			if (item.cdagrupamento) {
				this.atualizarProdutosAgrupados(item);
			}
			if (item.filhos?.length) {
				this.atualizarProdutosFilhos(item);
			}
		},

		//CALCULA VALOR UNITARIO (VISAO ESTOQUE)
		calcularValorVendaUnitario(item) {
			const custo = (item.vlrunitario) || 0; 
			if (!item.prazo) {
				item.prazo = custo;
			} else if (typeof item.prazo == "string") {
				item.prazo = Number(`${item.prazo}`.split(".").join("").replace(",", "."));
			}
			const vista = typeof item.vista == "string" ? Number(`${item.vista}`.split(".").join("").replace(",", ".")) : item.vista;
			if (!vista) {
				item.vista = (item.prazo || 0);
			}
			if (item.cdesc > 0) {
				if (item.cdesc > 0) {
					item.vista = (item.prazo - (item.prazo * item.cdesc / 100));
				} else {
					item.cdesc = 0;
					item.vista = (item.prazo);
				}
			} else {
				item.vista = (item.prazo || 0);
			}
			item.vista = (item.vista || 0).toLocaleString("pt-BR", {
				style: "decimal", 
				minimumFractionDigits: 2, 
				maximumFractionDigits: 2
			});
			
			item.descvista = (item.cdesc || 0).toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
			if (item.cdesc != Number(`${item.descTabela || 0}`.split(".").join("").replace(",", "."))) {
				item.descTabela = (item.cdesc || 0).toLocaleString("pt-BR", {
					style: "decimal", 
					minimumFractionDigits: 3, 
					maximumFractionDigits: 3
				});
			}
			item.prazo = (item.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			
		},
		calcularMargemUnitario(item) {
			const custo = (item.vlrunitario || 0);  //((item.custo) || item.vlrunitario) || 0
			const valorPrazo = typeof item.prazo == "string" ? Number(`${item.prazo || 0}`.split(".").join("").replace(",", ".")) : (item.prazo || 0);
			const margem = ((valorPrazo || 0) - custo) / custo * 100;
			if (margem <= 0) {
				item.margem = 0;
				item.prazo = custo;
				this.calcularValorVendaUnitario(item);
			} else if (!isFinite(margem)) {
				item.margem = 0;
			} else {
				item.margem = margem;
			}	
			item.margemTabela = (item.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		calcularValorVendaEstoque() {
			this.compraXML.estoque.forEach(produto => {
				this.calcularValorVendaUnitario(produto);
				this.calcularMargemUnitario(produto);
				this.atualizarFilhosOuAgrupados(produto);
			});
		},
		//CALCULA CUSTO
		calcularCustoUnitario(item) {
			const valorFrete = typeof item.valorfrete == "number" ? item.valorfrete : Number(`${item.valorfrete || 0}`.split(".").join("").replace(",","."));
			const valorIpi = typeof item.valoripi == "number" ? item.valoripi : Number(`${item.valoripi || 0}`.split(".").join("").replace(",","."));
			const valorIcmsSt = typeof item.valoricmsst == "number" ? item.valoricmsst : Number(`${item.valoricmsst || 0}`.split(".").join("").replace(",","."));
			const valorOutros = typeof item.valoroutros == "number" ? item.valoroutros : Number(`${item.valoroutros || 0}`.split(".").join("").replace(",","."));
			item.custo = (item.vlrcompra || 0) + (valorFrete || 0) + (valorIpi || 0) + (valorIcmsSt || 0) + (valorOutros || 0) - (item.desconto || 0);
		},
		calcularCustoNfe() {
			this.compraXML.itens.forEach(item => {
				this.calcularCustoUnitario(item);
			});
		},


		// CALCULA CUSTO - VISAO ESTOQUE
		calcularCustoEstoque() {
			let freteAux = (this.compraXML.frete || 0);
			const percentFrete = Number(((freteAux || 0) / (this.totalCompra || 1)) * 100 || 0);

			let descontoPercentual = 0;
			if (this.compraXML.desconto > 0){
				descontoPercentual = (this.compraXML?.percDesc || 0);
			}

			let custoOutros = 0;
			let outrosGeral = (this.compraXML.outros || 0);
			if (outrosGeral > 0){
				custoOutros = (outrosGeral*100) / this.totalCompra;
			}

			this.compraXML.estoque.forEach(item => {
				item.qtd = (item.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

				if (this.compraXML.tipo == "I") {
					item.outros = (custoOutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					let valorOutros = Number((item.vlrunitario*(custoOutros/100)).toFixed(4));
					if (outrosGeral - (valorOutros * item.quantidade) < 0) {
						item.valoroutros = ((outrosGeral || 0) / (item.quantidade || 1)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						item.valoroutros = (valorOutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
					outrosGeral -= ((valorOutros || 0) * (item.quantidade || 0));
				} else {
					item.outros = (item.outros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					item.valoroutros = (item.valoroutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				}

				if (this.compraXML.desconto > 0) {
					// item.desconto = descontoPercentual;
					item.valordesconto = (item.vlrunitario * (descontoPercentual / 100));
				}

				item.valordesconto = (item.vlrunitario*(descontoPercentual/100));

				item.frete = (percentFrete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				let valorFrete = Number((item.vlrunitario * (percentFrete || 0) / 100).toFixed(4));
				if (freteAux - (valorFrete * item.quantidade) < 0) {
					item.valorfrete = ((freteAux || 0) / (item.quantidade || 1));
				} else {
					item.valorfrete = (valorFrete || 0);
				}
				freteAux -= ((valorFrete || 0) * (item.quantidade || 0));

				if (!item.margemTabela) item.margemTabela = "0,00";
				if (!item.descTabela) item.descTabela = (item.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				if (!item.vlrnovo) item.vlrnovo = (item.vlrunitario || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				this.calcularCustoUnitario(item);
			});
			this.atualizarTotaisCompra();
		},

		// Calcular Rateio totais
		calcularFreteRateio() {
			const freteGeral = Number(this.totalFrete.split(".").join("").replace(",", ".") || 0);
			let freteAux = freteGeral;
			const percentFrete = Number((freteGeral / (this.totalCompra || 1)) * 100);
			this.compraXML.estoque.forEach(produto => {
				produto.frete = percentFrete.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				let valorFrete = Number((produto.vlrunitario * percentFrete / 100).toFixed(4));
				if (freteAux - (valorFrete * produto.quantidade) < 0) {
					produto.valorfrete = (freteAux / produto.quantidade);
				} else {
					produto.valorfrete = (valorFrete);
				}
				freteAux -= (valorFrete * produto.quantidade);
				this.calcularCustoUnitario(produto);
			});
			this.calcularValorVendaEstoque();
		},
		calcularIPIRateio () {
			let custoIPI = 0;
			let ipiGeral = Number(this.totalIpi.split(".").join("").replace(",", ".") || 0);
			if (ipiGeral > 0){
				custoIPI = (Number(this.totalIpi.split(".").join("").replace(",", "."))*100) / this.totalCompra;
			}
			this.compraXML.estoque.forEach(produto => {
				if (produto.valoripi) {
					produto.ipi = (custoIPI).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					let valorIpi = Number((produto.vlrunitario*(custoIPI/100)).toFixed(4));
					if (ipiGeral - (valorIpi * produto.quantidade) < 0) {
						produto.valoripi = (ipiGeral / produto.quantidade).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						produto.valoripi = (valorIpi).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
					ipiGeral -= (valorIpi * produto.quantidade);
					this.calcularCustoUnitario(produto);
				}
			});
			this.calcularValorVendaEstoque();
		},
		calcularICMSstRateio () {
			let custoST = 0;
			let stGeral = Number(this.totalICMSst.split(".").join("").replace(",", ".") || 0);
			if (stGeral > 0){
				custoST = (Number(this.totalICMSst.split(".").join("").replace(",", "."))*100) / this.totalCompra;
			}
			this.compraXML.estoque.forEach(produto => {
				if (produto.valoricmsst) {
					produto.icmsst = (custoST).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					let valorst = Number((produto.vlrunitario*(custoST/100)).toFixed(4));
					if (stGeral - (valorst * produto.quantidade) < 0) {
						produto.valoricmsst = (stGeral / produto.quantidade).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					} else {
						produto.valoricmsst = (valorst).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
					}
					stGeral -= (valorst * produto.quantidade);
					this.calcularCustoUnitario(produto);
				}
			});
			this.calcularValorVendaEstoque();
		},
		calcularOutrosRateio() {
			let custoOutros = 0;
			let outrosGeral = Number(this.totalOutros.split(".").join("").replace(",", ".") || 0);
			if (outrosGeral > 0){
				custoOutros = (Number(this.totalOutros.split(".").join("").replace(",", "."))*100) / this.totalCompra;
			}
			this.compraXML.estoque.forEach(produto => {
				produto.outros = (custoOutros).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				let valorOutros = Number((produto.vlrunitario*(custoOutros/100)).toFixed(4));
				if (outrosGeral - (valorOutros * produto.quantidade) < 0) {
					produto.valoroutros = (outrosGeral / produto.quantidade).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				} else {
					produto.valoroutros = (valorOutros).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				}
				outrosGeral -= (valorOutros * produto.quantidade);
				this.calcularCustoUnitario(produto);
			});
			this.calcularValorVendaEstoque();
		},
		capturarEntradavalorFinalCompra() {
			if (!this.visaoEstoque) return;
			if (this.compraXML.confirmada != "A") {
				this.proximoCampo("totalMargem");
			} else if (this.compraXML.estoque.length) {
				if (this.compraXML.tipo == "I") {
					this.proximoCampo("outros-0");
				} else {
					this.proximoCampo("qtd-0");
				}
			} else {
				this.proximoCampo("cdprodutoOuCodbarra");
			}
		},
		calcularDescontoRateio() {
			let totalDesconto = Number(`${this.totalDesconto || 0}`.split(".").join("").replace(",","."));
			if (totalDesconto > this.totalCompra) {
				totalDesconto = this.totalCompra;
				this.$store.commit("relatarErro", {
					mensagem: `Desconto alterado para ${(this.totalCompra || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}, não é possível incluir desconto da entrada maior que o valor total da compra!`,
					alerta: true,
				});
				this.totalDesconto = (totalDesconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			let descontoPercentual = 0;
			if (totalDesconto > 0){
				descontoPercentual = (totalDesconto * 100) / this.totalCompra;
			}
			this.compraXML.estoque.forEach(produto => {
				produto.valordesconto = (produto.vlrunitario*(descontoPercentual/100));
				this.calcularCustoUnitario(produto);
			});
			this.calcularValorVendaEstoque();
		},
		calcularMargemRateio() {
			// MUDEI O produto.custo para produto.vlrunitario
			const totalMargem = Number(this.totalMargem.split(".").join("").replace(",","."));
			this.compraXML.estoque.forEach(produto => {
				produto.margem = (totalMargem || 0);
				produto.prazo = ((produto.vlrunitario + (produto.vlrunitario * produto.margem / 100)) || 0);
				produto.margemTabela = (produto.margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				if ((produto.prazo > produto.vlrunitario)) {
					if (produto.cdesc > 0) {
						produto.vista = (produto.prazo - (produto.prazo * produto.cdesc / 100)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					}
					if (produto.vista < produto.prazo) {
						produto.cdesc = 0;
						produto.descTabela = "0,00";
						produto.vista = (produto.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					}
				} else {
					produto.cdesc = 0;
					produto.descTabela = "0,00";
					produto.vista = (produto.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				}
				produto.prazo = (produto.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.calcularValorVendaUnitario(produto);
				this.atualizarFilhosOuAgrupados(produto);
			});
			this.$forceUpdate();
		},
		calcularDescontoVistaRateio() {
			let totalDescontoVista = Number(`${this.totalDescontoVista || 0}`.split(".").join("").replace(",",".") || 0);
			if (totalDescontoVista > 100) {
				totalDescontoVista = 100;
				this.$store.commit("relatarErro", {
					mensagem: "Desconto alterado para 100%, não é possível incluir desconto no valor a vista maior de que 100%!",
					alerta: true,
				});
				this.totalDescontoVista = (totalDescontoVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			this.compraXML.estoque.forEach(produto => {
				produto.cdesc = (totalDescontoVista || 0);
				produto.descTabela = (totalDescontoVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3});
				const vlrprazo = Number(`${produto.prazo || 0}`.split(".").join("").replace(",",".") || 0);
				produto.vista = (vlrprazo - (vlrprazo * produto.cdesc / 100)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.atualizarFilhosOuAgrupados(produto);
			});
			this.$forceUpdate();
		},

		// Calcular unitário tabela
		mascaraTabela(e) {
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				e.preventDefault();
			}
		},
		alterarVlrunitario(e) {
			const index = e.target.id.split("-")[1];
			const unitarioNovo = Number(`${this.compraXML.estoque[index].vlrnovo || 0}`.split(".").join("").replace(",", ".") || 0);
			const itemRemovido = this.compraXML.estoque.splice(index, 1)[0];

			itemRemovido.vlrunitario = Number(unitarioNovo || 0);

			this.compraXML.estoque.splice(index, 0, itemRemovido);
			
			
			this.calcularFreteRateio();
			this.calcularOutrosRateio();
			this.calcularDescontoRateio();
			this.calcularCustoUnitario(this.compraXML.estoque[index]);
			this.calcularValorVendaUnitario(this.compraXML.estoque[index]);
			this.calcularMargemUnitario(this.compraXML.estoque[index]);
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(this.compraXML.estoque[index]);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(this.compraXML.estoque[index]);
			}
			// this.indexAlteracaoProduto = -1;
			// this.backdoor = 0;
			// this.$forceUpdate();
		},
		alterarQuantidade(e) {
			clearTimeout(this.timer);
			const index = e.target.id.split("-")[1];
			let quantidade = Number(`${this.compraXML.estoque[index].qtd || 0}`.split(".").join("").replace(",", ".") || 0);
			if (quantidade <= 0 || isNaN(quantidade)) {
				quantidade = 1;
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: "Você informou uma quantidade inválida, verifique!",
						alerta: true
					});
					this.compraXML.estoque[index].qtd = quantidade.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				}, 50);
			}

			const itemRemovido = this.compraXML.estoque.splice(index, 1)[0];
			itemRemovido.quantidade = Number(quantidade || 0);

			this.compraXML.estoque.splice(index, 0, itemRemovido);
			this.backdoor = 0;
			this.timer = setTimeout(() => {
				this.calcularFreteRateio();
				this.calcularOutrosRateio();
				this.calcularDescontoRateio();
				this.$forceUpdate();
			}, 300);
		},
		alterarFrete(e) {
			this.mascaraTabela(e);
			const index = e.target.id.split("-")[1];
			const campo = e.target.id.split("-")[0];
			if (campo == "freteTabela") {
				const frete = Number(`${this.compraXML.estoque[index].freteTabela || 0}`.split(".").join("").replace(",",".") || 0);
				this.compraXML.estoque[index].frete = frete;
				this.compraXML.estoque[index].valorfrete = (this.compraXML.estoque[index].vlrunitario * ( frete / 100));
				this.compraXML.estoque[index].valorfreteTabela = (this.compraXML.estoque[index].valorfrete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			} else {
				const valorfrete = Number(`${this.compraXML.estoque[index].valorfreteTabela || 0}`.split(".").join("").replace(",",".") || 0);
				this.compraXML.estoque[index].valorfrete = valorfrete;
				this.compraXML.estoque[index].frete = ((valorfrete || 0) / this.compraXML.estoque[index].vlrunitario * 100);
				this.compraXML.estoque[index].freteTabela = (this.compraXML.estoque[index].frete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			}
			this.calcularCustoUnitario(this.compraXML.estoque[index]);
			this.calcularValorVendaUnitario(this.compraXML.estoque[index]);
			this.calcularMargemUnitario(this.compraXML.estoque[index]);
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(this.compraXML.estoque[index]);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(this.compraXML.estoque[index]);
			}
			this.indexAlteracaoProduto = -1;
			this.atualizarTotaisCompra();
			this.$forceUpdate();
		},
		alterarOutros(e) {
			this.mascaraTabela(e);
			const index = e.target.id.split("-")[1];
			const campo = e.target.id.split("-")[0];
			if (campo == "outros") {
				this.compraXML.estoque[index].valoroutros = (this.compraXML.estoque[index].vlrunitario * (Number(this.compraXML.estoque[index].outros.split(".").join("").replace(",",".") || 0)/100)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			} else {
				this.compraXML.estoque[index].outros = (Number(this.compraXML.estoque[index].valoroutros.split(".").join("").replace(",",".") || 0) /  this.compraXML.estoque[index].vlrunitario * 100).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			}
			this.calcularCustoUnitario(this.compraXML.estoque[index]);
			this.calcularValorVendaUnitario(this.compraXML.estoque[index]);
			this.calcularMargemUnitario(this.compraXML.estoque[index]);
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(this.compraXML.estoque[index]);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(this.compraXML.estoque[index]);
			}
			this.indexAlteracaoProduto = -1;
			this.atualizarTotaisCompra();
			this.$forceUpdate();
		},
		alterarIpi(e) {
			this.mascaraTabela(e);
			const index = e.target.id.split("-")[1];
			const margem = Number(`${this.compraXML.estoque[index].margemTabela || 0}`.split(".").join("").replace(",", ".") || 0);
			this.compraXML.estoque[index].margem = margem;
			const valorPrazo = this.compraXML.estoque[index].custo + (this.compraXML.estoque[index].custo * margem / 100);
			this.compraXML.estoque[index].prazo = (valorPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.compraXML.estoque[index].vista = (valorPrazo - (valorPrazo * this.compraXML.estoque[index].cdesc / 100)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.$forceUpdate();
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(this.compraXML.estoque[index]);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(this.compraXML.estoque[index]);
			}
			this.indexAlteracaoProduto = -1;
		},
		alterarMargem(e) {
			this.mascaraTabela(e);
			const index = e.target.id.split("-")[1];
			const produto = this.compraXML.estoque[index];
			const margem = Number(`${produto.margemTabela || 0}`.split(".").join("").replace(",", ".") || 0);
			produto.margem = margem;
			const valorPrazo = produto.vlrunitario + (produto.vlrunitario * margem / 100);
			produto.prazo = (valorPrazo).toLocaleString("pt-BR", {
				style: "decimal", 
				minimumFractionDigits: 4, 
				maximumFractionDigits: 4
			});
			produto.vista = (valorPrazo - (valorPrazo * produto.cdesc / 100)).toLocaleString("pt-BR", {
				style: "decimal", 
				minimumFractionDigits: 4, 
				maximumFractionDigits: 4
			});
			this.$forceUpdate();
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(produto);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(produto);
			}
			this.indexAlteracaoProduto = -1;
		},
		capturarEntradaValorVista(index) {
			if ((index + 1) == this.compraXML.estoque.length) {
				this.proximoCampo("cdprodutoOuCodbarra");
			} else {
				this.proximoCampo(`margemTabela-${index + 1}`);
			}
		},
		calcularMarkupEVistaTabela(e) {
			const campo = e.target ? e.target.id.split("-")[0] : e.campo;
			const index = e.target ? e.target.id.split("-")[1]: e.index;
			this.mascaraTabela(e);
			if (campo == "prazo") {
				if (Number(this.compraXML.estoque[index].custo) > 0) {
					const prazo = this.formatStringToNumber(this.compraXML.estoque[index].prazo);
					const unitario = this.compraXML.estoque[index].vlrunitario;
					this.compraXML.estoque[index].margem = Number((prazo * 100 / unitario) - 100);
				} else {
					this.compraXML.estoque[index].margem = Number((100).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}));
				}
				this.compraXML.estoque[index].margemTabela = (this.compraXML.estoque[index].margem || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				if(this.acaoEmMassa == null || !this.acaoEmMassa){
					this.compraXML.estoque[index].vista = (Number(this.compraXML.estoque[index].prazo.split(".").join("").replace(",", ".")) - (Number(this.compraXML.estoque[index].prazo.split(".").join("").replace(",", ".")) * Number(this.compraXML.estoque[index].cdesc || 0) /100)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				}
			}
			this.compraXML.estoque[index].cdesc = ((Number(this.compraXML.estoque[index].prazo.split(".").join("").replace(",", ".")) - Number(this.compraXML.estoque[index].vista.split(".").join("").replace(",", ".")))  / Number(this.compraXML.estoque[index].prazo.split(".").join("").replace(",", ".")) *100);
			this.compraXML.estoque[index].descTabela = (this.compraXML.estoque[index].cdesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3}); 
			this.$forceUpdate();
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(this.compraXML.estoque[index]);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(this.compraXML.estoque[index]);
			}
			this.indexAlteracaoProduto = -1;
		},
		alterarDescvista(e) {
			this.mascaraTabela(e);
			const index = e.target ? e.target.id.split("-")[1] : e.index;
			const produto = this.compraXML.estoque[index];
			let desconto = Number(`${produto?.descTabela || 0}`.split(".").join("").replace(",", ".") || 0);
			if (desconto > 100) {
				desconto = 100;
				this.$store.commit("relatarErro", {
					mensagem: "Desconto alterado para 100%, não é possível incluir desconto no valor a vista maior de que 100%!",
					alerta: true,
				});
				produto.descTabela = (desconto || 0).toLocaleString("pt-BR", {
					style: "decimal", 
					minimumFractionDigits: 3, 
					maximumFractionDigits: 3
				});
			}
			produto.cdesc = desconto;
			const vlrprazo = Number(`${produto?.prazo || 0}`.split(".").join("").replace(",",".") || 0);
			produto.vista = (vlrprazo - (vlrprazo * produto?.cdesc / 100)).toLocaleString("pt-BR", {
				style: "decimal", 
				minimumFractionDigits: 2, 
				maximumFractionDigits: 2
			});
			this.$forceUpdate();
			this.indexAlteracaoProduto = index;
			this.atualizarFilhosOuAgrupados(produto);
			if (this.acaoEmMassa == "formarPrecoEmMassa") {
				this.formarPrecos(produto);
			}
			this.indexAlteracaoProduto = -1;
		},
		
		/* ARQUIVO XML */
		async validarEntradaXML(xmlString) {
			this.$store.state.carregando = true;
			let compraAux = null;
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(xmlString, "application/xml");
			const compraXML = new CompraXML(xmlDoc,this.$store.state.Empresa.id);
			try {
				if (compraXML.error) {
					throw new Error(compraXML.error);
				}
				// if ((compraXML.empresa.cnpj != this.$store.state.Empresa.cnpj) && this.$store.state.Usuario.cargo != "ADMINISTRADOR") {
				// 	throw new Error("Destinatário da NFE diferente da empresa cadastrada no sistema, verifique!");
				// }
				if (this.cdcompra && (this.cdcompra != compraXML.cdcompra) && this.compraXML.confirmada != "N") {
					throw new Error("Arquivo informado diferente da compra cadastrada!");
				}
				compraAux = compraXML;
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", {mensagem: error.message});
			}
			const compra = await this.$store.dispatch("buscarCompraPorNfeChave", compraAux.nfechave);
			try {
				if (compra ? compra.cdcompra : false) {
					if (compra.confirmada == "S") {
						this.file = null;
						throw new Error(`NFe já está finalizada, não é possivel alteração! Compra código ${compra.cdcompra}.`);
					} else if (compra.confirmada == "N") {
						this.file = xmlDoc;
						await this.informarCadastroCompra(compra);
						throw new Error("NFe lançada no sistema, porém falta o lançamento no estoque e financeiro!");
					} else if (compra.confirmada == "L") {
						this.file = xmlDoc;
						await this.informarCadastroCompra(compra);
						throw new Error("NFe com estoque lançado no sistema, porém falta o lançamento do financeiro!");
					} else if (compra.confirmada == "F") {
						this.file = xmlDoc;
						await this.informarCadastroCompra(compra);
						throw new Error("NFe com estoque e financeiro lançado no sistema, falta conferir e finlizar a compra!");
					}
				}
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", { 
					mensagem: error.message,
					alerta: true
				});
			}
			try {
				this.chave = compraAux.nfechave;
				this.file = xmlDoc;
				this.compraXML = {...compraAux};
				this.nfeid = (new XMLSerializer()).serializeToString(xmlDoc);
				this.compraXML.nfeid = this.nfeid;
				this.compra = this.compraXML.cdcompra;
				this.data = dateFormatISO({date: this.compraXML.datacompra, format: "ISOdate"});
				if (this.compraXML.estoque[0].cfop) {
					this.cfop = this.compraXML.estoque[0].cfop;
				}
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				this.$store.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				throw new Error(error.message || JSON.stringify(error));
			}
		},

		async validarFornecedorXML() {
			// Verificar fornecedor
			try {
				const existeFornecedor = await this.$store.dispatch("filtrarcnpj",{
					cnpj: this.compraXML?.fornecedor?.cnpj
				});
				if (existeFornecedor != false){
					this.selecionarFornecedor(existeFornecedor.content[0]);
				} else {
					this.fornecedor = {...this.compraXML.fornecedor};
					this.fornecedor.id = 0;
					this.checkboxFornecedor = false;
				}
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				this.$store.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
			}
		},

		async validarTransporteXML() {
			// Verificar transportadora
			const transportadora = this.compraXML?.transporte;
			const fornecedor = this.compraXML?.fornecedor;
			
			if (transportadora?.cnpj) {
				if (transportadora?.cnpj == fornecedor?.cnpj) {
					if (this.fornecedor?.id) {
						fornecedor.id = this.fornecedor?.id;
					} else {
						fornecedor.id = 0;
					}
					return this.selecionarTransportadora({
						...fornecedor, 
						transporte: transportadora?.transporte
					});
				}
				const existeTransportadora = await this.$store.dispatch("filtrarcnpj",{
					cnpj: transportadora?.cnpj
				});
				if (existeTransportadora != false){
					return this.selecionarTransportadora({
						...existeTransportadora.content[0], 
						transporte: transportadora?.transporte
					});
				}
				this.transp = {
					...transportadora
				};
				this.transp.id = 0;
				this.checkboxTransportadora = false;
			} else if (transportadora?.nome && (transportadora?.nome == fornecedor?.nome)) {
				this.selecionarTransportadora({
					...this.fornecedor, 
					transporte: transportadora?.transporte
				});
			}
		},

		async validarEntradaAgrupamento() {
			const agrupamentos = {};
			if(!this.compraXML?.nfeid) return;
			for (const produto of this.compraXML.estoque) {
				if (produto.cdagrupamento >= 0 && produto.cdagrupamento != null) {
					const agrupamento = await this.$store.dispatch("buscarAgrupamento", {
						cdagrupamento: produto.cdagrupamento,
						carregando: true
					});
					if (agrupamento) {
						const bgcolor = this.coresAgrupamentos[this.indexCorAgrupamento];
						if (!agrupamentos[produto.cdagrupamento]) {
							if (this.indexCorAgrupamento == this.coresAgrupamentos.length-1) {
								this.indexCorAgrupamento = 0;
							} else {
								this.indexCorAgrupamento++;
							}
							agrupamentos[produto.cdagrupamento] = bgcolor;
						}
						agrupamento.forEach(agrupado => {
							const produtosFiltrados = this.compraXML.itens.filter(item => 
								(item.cdreferencia == agrupado.cdreferencia) &&
								(item.codbarraFornecedor == agrupado.codbarrafornecedor) || 
								(item.cdproduto == agrupado.cdproduto)
							);

							produtosFiltrados.forEach(item => {
								item.cdproduto = agrupado.cdproduto;
								item.cdagrupamento = agrupado.cdagrupamento;
								item.bgcolor = agrupamentos[produto.cdagrupamento];
							});
							if (agrupado.cdreferencia == produto.cdreferencia && agrupado.cdproduto == produto.cdproduto) {
								produto.codbarraFornecedor = agrupado.codbarrafornecedor;
							}
						});
						produto.bgcolor = agrupamentos[produto.cdagrupamento];
					}
				}
			}
			Object.keys(agrupamentos).forEach(key => {
				this.removerAgrupamentoDuplicado(key);
			});
		},

		// VISUALIZAR AGRUPAMENTO
		async buscarPreencherProdutosAgrupados(cdagrupamento) {
			const agrupamento = await this.$store.dispatch("buscarAgrupamento", {
				cdagrupamento: cdagrupamento,
			});

			let produtosAgrupados = [];
			if(agrupamento) {
				agrupamento?.forEach(agrupado => {
					const produtosNotaEncontrado = this.compraXML.itens.filter(item => (
						item.cdreferencia === agrupado.cdreferencia 
						&& item.codbarraFornecedor === agrupado.codbarrafornecedor
					));
					
					if(produtosNotaEncontrado?.length > 0) {
						produtosNotaEncontrado.forEach(prod => {
							const existe = produtosAgrupados.find(p => p.cdseq === prod.cdseq);

							if(!existe) {
								produtosAgrupados.push(prod);
							}
						});
					}
					// const produtoNotaEncontrado = this.compraXML.itens.find(
					// 	item => item.cdreferencia === agrupado.cdreferencia && 
					// 	item.codbarraFornecedor === agrupado.codbarrafornecedor
					// );

					// if(produtoNotaEncontrado) {
					// 	produtosAgrupados.push(produtoNotaEncontrado);
					// }
				});
			}
			// produtosAgrupados = produtosAgrupados.sort()
			produtosAgrupados = [...produtosAgrupados.sort((a, b) => a.cdseq - b.cdseq).map(item => ({...item}))];
			return produtosAgrupados;
		},

		async visualizarProdutosAgrupados(prod) {


			const produtosNotaAgrupado = this.compraXML.itens.filter(item => item.cdagrupamento === prod.cdagrupamento);

			if(!prod.cdagrupamento) return;
			if(produtosNotaAgrupado?.length === 0) {
				return this.$store.commit("relatarErro", {
					mensagem: "Não existe produtos agrupados"
				});
			}
			this.produtosAgrupados = produtosNotaAgrupado;
			this.visualizarAgrupamento = true;

			// this.produtosAgrupados = await this.buscarPreencherProdutosAgrupados(prod.cdagrupamento);
			// if(!this.produtosAgrupados) return this.$store.commit("relatarErro", {
			// 	mensagem: "Não tem produtos agrupados!",
			// });
			// this.visualizarAgrupamento = true;

		},

		cancelarVisualizacaoAgrupamento() {
			this.visualizarAgrupamento = false;
			this.produtosAgrupados = [];
			
		},

		async validarEntradaProdutos({ conciliarEstoque }) {
			// Verificar produtos
			try {
				this.$store.state.carregando = true;
				if((this.compraXML.estoque.length > 0 && this.compraXML.confirmada !== "L") || conciliarEstoque) {
					const promises = this.compraXML.estoque.map(async (produto) => {
						const { produtoEncontrado } = await this.$store.dispatch("filtrarProdutosParaEntrada", {
							Cdreferencia: produto?.cdreferencia, 
							cdfornecedor: this.fornecedor.id,
							codbarraFornecedor: produto?.codbarraFornecedor || "",
							carregando: true,
						});
						if (produtoEncontrado) {
							if(produtoEncontrado?.outrosProdutos?.length > 0) {
								produto.outrosProdutos = produtoEncontrado?.outrosProdutos;
							} else {
								produto.outrosProdutos = [];
							}
							produto.cdproduto  = produtoEncontrado?.id;
							produto.codinter = produtoEncontrado?.codinter;
							produto.cdreferencia = produtoEncontrado?.cdReferencia;
							produto.codbarra = produtoEncontrado?.codBarra;
							produto.cdgrupo = produtoEncontrado?.cdgrupo;
							produto.cdsubgrupo = produtoEncontrado?.cdsubgrupo;
							produto.descricao = produtoEncontrado?.descricao;
							produto.descresumido = produtoEncontrado?.descresumido || produtoEncontrado?.descricao;
							if (this.compraXML.tipo == "C") {
								produto.frete = (produtoEncontrado?.frete || 0);
								produto.valorfrete = (produtoEncontrado?.valorfrete || 0);
								produto.outros = (produtoEncontrado?.outros || 0);
								produto.valoroutros = (produtoEncontrado?.valoroutros || 0);
								produto.ipi = (produtoEncontrado?.ipi || 0);
								produto.valoripi = (produtoEncontrado?.valoripi || 0);
								produto.icmsst = (produtoEncontrado?.icmsst || 0);
								produto.valoricmsst = (produtoEncontrado?.valoricmsst || 0);
								produto.descontoentrada = (produtoEncontrado?.descontoentrada || 0);
								produto.pis = (produtoEncontrado?.pis || 0);
								produto.valorpis = (produtoEncontrado?.valorpis || 0);
								produto.cofins = (produtoEncontrado?.cofins || 0);
								produto.valorcofins = (produtoEncontrado?.valorcofins || 0);
								produto.margem = (produtoEncontrado?.markup || 0);
								produto.prazo = (produtoEncontrado?.valorPrazo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
								produto.cdesc = ((produtoEncontrado?.valorPrazo || 0) - produtoEncontrado?.valorVista) / (produtoEncontrado?.valorPrazo || 0) *100;
								produto.vista = (produtoEncontrado?.valorVista || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
								produto.custoAnterior = produtoEncontrado?.custo;
							} else {
								if (!produto.status) {
									if ((produtoEncontrado?.valorPrazo || 0 ) > 0 && !produto.prazo) {
										produto.prazo = (produtoEncontrado?.valorPrazo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
										produto.margem = ((produtoEncontrado?.valorPrazo || 0 ) - (produto.custo)) / produto.custo;
										produto.cdesc = ((produtoEncontrado?.valorPrazo || 0) - produtoEncontrado?.valorVista) / (produtoEncontrado?.valorPrazo || 0) *100;
										produto.vista = (produtoEncontrado?.valorVista || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
									} else {
										produto.margem = 0;
										produto.prazo = (produto.custo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
										produto.cdesc = 0;
										produto.vista = (produto.custo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
									}
								}
							}
							produto.cfopesta = produtoEncontrado?.cfopesta || this.$store.state.Empresa.cfopesta;
							produto.cfopinter = produtoEncontrado?.cfopinter || this.$store.state.Empresa.cfopinter;
							
							if (produtoEncontrado?.cdagrupamento != null && produtoEncontrado?.cdagrupamento >= 0) {
								produto.cdagrupamento = produtoEncontrado?.cdagrupamento;
								produto.descricao = produtoEncontrado?.descricao;
								produto.unidade = produtoEncontrado?.unidade;
							} else {
								this.compraXML.itens.forEach(item => {
									if (
										item.cdreferencia == produto.cdreferencia 
											&& item.codbarraFornecedor == produto.codbarraFornecedor
									) {
										item.cdproduto = produto.cdproduto;
									}
								});
							}
						} else {
							produto.outrosProdutos = [];
							produto.vista = produto.vlrunitario;
							produto.prazo = produto.vlrunitario;
							produto.cfopesta = this.$store.state.Empresa.cfopesta;
							produto.cfopinter = this.$store.state.Empresa.cfopinter;
							produto.descresumido = (produto.descricao || "").substring(0, 60);
						}
						if (this.fornecedor.id > 0) {
							produto.cdfornecedor = this.fornecedor.id;
						}
					});
					
					await Promise.all(promises);
				}

				this.$forceUpdate();
			} catch (error) {
				console.log(error);
			}
		},
		async lerXMLString(docXML) {
			try {
				this.$store.state.carregando = true;
				if (!docXML) throw new Error("Não contém a string XML em docXML!");
				await this.validarEntradaXML(docXML);
				await this.validarFornecedorXML();
				await this.validarTransporteXML();
				this.totalICMSst = Number(this.compraXML.valorICMSst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.totalIpi = Number(this.compraXML.vlripi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.totalFrete = Number(this.compraXML.frete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.totalDesconto = Number(this.compraXML.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.calcularICMSstRateio();
				this.calcularIPIRateio();
				this.calcularDescontoRateio();
				this.calcularCustoNfe();
				this.calcularCustoEstoque();
				this.calcularValorVendaEstoque();
				this.$forceUpdate();
				this.$store.state.carregando = false;
				this.visaoEstoque = false;
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				return this.$store.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
			}
		},
		lerArquivoXML(event) {
			try {
				this.modalBuscaXML = false;
				if (!event.target.files) {
					throw new Error("Nenhum arquivo selecionado!");
				}
				const file = event.target.files[0];
				if (!file.type.match("text/xml")) {
					throw new Error("Arquivo não suportado!");
				}
				const reader = new FileReader();
				this.$store.state.carregando = true;
				reader.onload = () => {
					this.lerXMLString(reader.result);
				};
				reader.readAsText(file);
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				return setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem: error.message || (error.details || JSON.stringify(error))});
				}, 20);
			}
		},

		

		/* FINANCEIRO */
		cancelarAlteracaoFinanceiro() {
			this.novoFinanceiroCompraConfirmada = false;
			this.compraXML.financa = this.compraXML.financaOriginal;
		},

		camposFinanceiroValidado() {
			let mensagemErro = "";

			if(this.novoFinanceiro.formaPgto === "SEL") {
				mensagemErro = "Por favor escolha um método de pagamento válido";
			}
			if(this.novoFinanceiro.frequencia === "" || this.novoFinanceiro.frequencia === "0") {
				mensagemErro = "Por favor insira uma frequência";
			}
			if(this.novoFinanceiro.qtdParcela === "" || this.novoFinanceiro.qtdParcela === "0") {
				mensagemErro = "Por favor insira uma quantidade de parcelas válida";
			}

			if(mensagemErro) {
				this.$store.commit("relatarErro", {
					mensagem: mensagemErro
				});
				return false;
			}
			return true;
		},

		adicionarNovasParcelas() {
			if(!this.camposFinanceiroValidado()) return;
			const frequencia = Number(this.novoFinanceiro.frequencia);
			const qtdParcela = Number(this.novoFinanceiro.qtdParcela);
			let dia = Number(this.novoFinanceiro.primeiroVenc.split("-")[2]);
			let mes = Number(this.novoFinanceiro.primeiroVenc.split("-")[1]);
			const metodoVista = this.novoFinanceiro.formaPgto.valorVista || this.novoFinanceiro.formaPgto?.cartao == "S";
			const dataBaixaMetodoVista = `${new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), (new Date().getHours() - 3), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() ).toISOString()}`;
			let nmrParcela = 1;

			for (let i= 0; i < qtdParcela; i++) {
				const vencimento = new Date(
					new Date().getFullYear(),
					mes,
					dia + (i - 1) * frequencia,
				).toLocaleDateString("en-CA");
				const valor = this.convertNumber(this.novoFinanceiro.valor) / Number(qtdParcela);
				this.compraXML.financa.push({
					cddoc: this.cdcompra,
					parcela: "00" + nmrParcela,
					formaPagamento: this.novoFinanceiro.formaPgto || null,
					datavenc: vencimento,
					alterarDataVenc: true,
					contarc: (valor),
					contarcvlr: (valor).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) || "",
					alterarContarc: true,
					databaixa:  metodoVista ? dataBaixaMetodoVista : null,
					cdtipopgto: this.novoFinanceiro.formaPgto?.cdtipo || null,
					cdtipo: this.novoFinanceiro.formaPgto?.cdtipo || null,
					pagamento: this.novoFinanceiro.formaPgto?.descricao || null,
					datadoc: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					debito: 0,
					observacao: "",
					observa: null
				});
				nmrParcela++;
			}

		},

		convertNumber(str) {
			const numeroFormatado = typeof str === "string" ? str?.replace(".", "")?.replace(",", ".") : str;

			const number = parseFloat(numeroFormatado);
			return number;
		},

		alteraDataVencimento(){
			this.alteraVencimento = true;
		},

		lancarNovoFinanceiro() {
			if(this.parcelasPossuiBaixa()) return this.$store.commit("relatarErro", {
				mensagem: "O financeiro possui parcelas baixadas, não é possível altera-lo, por favor verifique!"
			});
			this.novoFinanceiroCompraConfirmada = true;
			this.novoFinanceiro.primeiroVenc = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()).toLocaleDateString("en-ca");
			this.compraXML.financaOriginal = this.compraXML.financa;
			this.novoFinanceiro.valor = this.totalFinanceiroXML;
			this.compraXML.financa = [];
		},

		parcelasPossuiBaixa() {
			if(this.compraXML?.financa?.length > 0) {
				let possuiBaixa = false;
				this.compraXML.financa.forEach(parcela => {
					if(parcela.databaixa !== null) {
						possuiBaixa = true;
					}
				});
				return possuiBaixa;
			}	
		},

		alterarFinanceiroConfirmada() {
			this.novoFinanceiroCompraConfirmada = false;
			const aumdesc = this.convertNumber(this.compraXML.original.valorbrutopedido) - this.convertNumber(this.novoFinanceiro.valor);
			if(aumdesc > 0) {
				this.compraXML.desconto = aumdesc;
			}
			if(aumdesc < 0) {
				this.compraXML.aumento = aumdesc;
			}
			// this.limparCamposNovoFinanceiro();
			this.concluirLancamentoFinanceiro();
		},

		async salvaDataVencimento(){
			this.alteraVencimento = false;
			this.compraXML.financa.forEach(async (parcela)=>{
				const payload = {
					cdUsuario: this.$store.state.Usuario.id,
					cddig: parcela.cddig,
					cdagencia: parcela.cdagencia,
					cdbanco: parcela.cdbanco,
					cdconta: parcela.cdconta,
					justificativa: "Alteração de venciemnto da compra",
					novoVencimento: parcela.datavenc,
					tipo: parcela.tipo
				};
				const compra = await this.$store.dispatch("alteraVencimento", payload);
				if (compra.erro) {
					return this.$store.commit("relatarErro", {mensagem: compra.message});
				}
			});			
			this.$store.commit("relatarErro", {
				mensagem: "Parabéns, o lançamento foi atualizado com sucesso!",
				sucess: true,
			});
		},
		limparFinanceiro() {
			this.compraXML.financa = [];
		},
		limparPagamento(event) {
			const id = event.target.id;
			if (!id) return;
			setTimeout(() => {
				event.target.select();
			}, 30);
		},
		gerarParcelas(cdtipo) {
			this.compraXML.financa = this.compraXML.financa.filter(pgto => pgto.cdtipo != cdtipo);
			const formaPagamento = this.formasPagamento.find(metodo => metodo.cdtipo == cdtipo);
			if (Number(`${formaPagamento.valor || 0}`.split(".").join("").replace(",", ".")) <= 0) {
				return this.compraXML.financa.forEach((fin, index) => {
					fin.parcela = "00" + (index +1);
				});
			}
			if (formaPagamento.valorVista) {
				this.compraXML.financa.push({
					parcela: "",
					formaPagamento: formaPagamento,
					pagamento: formaPagamento.descricao,
					dataBaixa: new Date().toISOString(),
					datavenc: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					alterarDataVenc: false,
					contarc: Number(`${formaPagamento.valor}`.split(".").join("").replace(",", ".") || 0),
					alterarContarc: false,
					cdtipo: formaPagamento.cdtipo,
				});
			} else {
				let valorTotal = Number(`${formaPagamento.valor}`.split(".").join("").replace(",", ".") || 0);
				let valorParcela = valorTotal / (formaPagamento.numeroParcelas || 1);
				for (let i = 0; i < (formaPagamento.numeroParcelas || 1); i++) {
					this.compraXML.financa.push({
						parcela: "",
						formaPagamento: formaPagamento,
						pagamento: formaPagamento.descricao,
						dataBaixa: formaPagamento.cartao ? new Date().toISOString() : null,
						datavenc: new Date(
							new Date().getFullYear(),
							new Date().getMonth() + i+1,
							new Date().getDate(),
						).toLocaleDateString("en-CA"),
						alterarDataVenc: false,
						contarc: i == formaPagamento.numeroParcelas -1 ? valorTotal : valorParcela,
						alterarContarc: false,
						cdtipo: formaPagamento.cdtipo,
					});
					valorTotal -= valorParcela;
				}
			}
			this.ordenarFinanceiro();
		},
		ordenarFinanceiro() {
			this.compraXML.financa.sort((a,b) => new Date(a.datavenc).getTime() - new Date(b.datavenc).getTime()).forEach((fin, index) => {fin.parcela = "00" + (index +1);});
		},
		escolherMetodoPagamento() {
			this.compraXML.financa.forEach(fin => {
				if (!this.metodoPagamentoSelecionado) {
					fin.formaPagamento = null;
					fin.cdtipopgto = null;
					fin.pagamento = null;
					fin.databaixa = null;
				} else {
					fin.formaPagamento = this.metodoPagamentoSelecionado;
					fin.cdtipopgto = this.metodoPagamentoSelecionado.cdtipo;
					fin.pagamento = this.metodoPagamentoSelecionado.descricao;
					if (this.metodoPagamentoSelecionado.vistaprazo == 1 || `${this.metodoPagamentoSelecionado.cartao}`.toUpperCase() == "S") {
						fin.databaixa = `${new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), (new Date().getHours() - 3), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() ).toISOString()}`;
					}else{
						fin.databaixa = null;
					}
				}
				fin.cddoc = this.cdcompra;
			});
		},
		removerPagamento(index) {
			if (!this.podeAlterarFinanceiro) return;
			this.compraXML.financa.splice(index, 1);
			this.compraXML.financa.forEach((item, index) => {
				let parcela = "";
				for (let i = `${index}`.length; i < 3; i++) {
					parcela += "0";
				}
				parcela += `${index+1}`;
				item.parcela = parcela;
			});
		},
		alterarPagamento(index) {
			if (!this.podeAlterarFinanceiro) return;
			if (!this.compraXML.financa[index].formaPagamento) {
				this.compraXML.financa[index].cdtipopgto = null;
				this.compraXML.financa[index].pagamento = null;
				this.compraXML.financa[index].databaixa = null;
			} else {
				this.compraXML.financa[index].cdtipopgto = this.compraXML.financa[index].formaPagamento.cdtipo;
				this.compraXML.financa[index].pagamento = this.compraXML.financa[index].formaPagamento.descricao;
				if (this.compraXML.financa[index].formaPagamento.vistaprazo == 1 || `${this.compraXML.financa[index].formaPagamento.cartao}`.toUpperCase() == "S") {
					this.compraXML.financa[index].databaixa = `${new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), (new Date().getHours() - 3), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() ).toISOString()}`;
				}
			}
			this.compraXML.financa[index].cddoc = this.cdcompra;
		},
		adicionarLinhaFinanceiro() {
			try {
				if (this.compraXML.financa.length) {
					if (!this.compraXML.financa[this.compraXML.financa.length-1].contarc) return;
					if (!this.compraXML.financa[this.compraXML.financa.length-1].formaPagamento) {
						throw new Error("Favor informar a forma de pagamento!");
					}
					if (!this.compraXML.financa[this.compraXML.financa.length-1].datavenc) {
						throw new Error("Favor informar a data de vencimento da parcela!");
					}
				}
				this.compraXML.financa.push({
					cddoc: this.cdcompra,
					parcela: "00" + (this.compraXML.financa.length +1),
					formaPagamento: this.metodoPagamentoSelecionado || null,
					datavenc: this.metodoPagamentoSelecionado?.valorVista ? new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") : null,
					alterarDataVenc: true,
					contarc: (this.totalFinanceiroXML - this.totalFinanceiro),
					contarcvlr: (this.totalFinanceiroXML - this.totalFinanceiro).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) || "",
					alterarContarc: true,
					databaixa: (this.metodoPagamentoSelecionado?.valorVista || this.metodoPagamentoSelecionado?.cartao == "S") ? `${new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), (new Date().getHours() - 3), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() ).toISOString()}` : null,
					cdtipopgto: this.metodoPagamentoSelecionado?.cdtipo || null,
					pagamento: this.metodoPagamentoSelecionado?.descricao || null,
					datadoc: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
					debito: 0,
					observacao: "",
					observa: null
				});
				this.proximoCampo(`formaRecebimento-${this.compraXML.financa.length -1}`);
			} catch (erro) {
				this.$store.commit("relatarErro", {mensagem: erro.message});
			}
		},
		alterarFinanceiro(index, campoControle) {
			if (!this.podeAlterarFinanceiro) return;
			this.compraXML.financa[index][campoControle] = true;
			if (campoControle == "alterarContarc") {
				this.compraXML.financa[index].contarcvlr = (this.compraXML.financa[index].contarc || 0).toLocaleString("pt-BR", {styçe: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			setTimeout(() => {
				const id = `${campoControle == "alterarContarc" ? "contarc" : "datavenc"}-${index}`;
				this.proximoCampo(id);
			},50);
		},

		async validarLancamentoFinanceiro() {
			try {
				this.compraXML.financa.forEach(pgto => {
					if (!pgto.formaPagamento) throw new Error(`Favor informar o método de pagamento para a parcela nº ${pgto.parcela}!`);
					if (!pgto.datavenc) throw new Error(`Favor informar a data de vencimento para a parcela nº ${pgto.parcela}!`);
					if (!pgto.contarc) throw new Error(`Favor informar o valor da parcela nº ${pgto.parcela}!`);
					if (isNaN(pgto.contarc)) throw new Error(`Favor conferir o valor da parcela nº ${pgto.parcela}!`);
				});
				if (Number(this.totalFinanceiro.toFixed(2)) > Number(this.valorFinalCompraNota.toFixed(2))) {
					throw new Error("Parece que o valor das parcelas está maior que o total da compra, favor verifique!");
				}
				return true;
			} catch(error) {
				console.log(error);
				setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem: error.message});
				}, 100);
				return false;
			}
		},
		async informarCadastroItens(compra) {
			try {
				if (compra.itens?.length) {
					this.compraXML.itens = [...compra.itens.map(item => ({...item}))];
					this.compraXML.itens = [...this.compraXML.itens.sort((a, b) => a.cdseq - b.cdseq).map(item => ({...item}))];
				} else {
					this.compraXML.itens = [];
				}
				this.calcularCustoNfe();
			
			} catch (error) {
				this.$store.state.carregando = false;
				throw new Error(error.message);
			}
		},

		async conciliarEstoque () {
			if(this.conciliouEstoque) return;
			this.compraXML.itens.forEach(item => {
				if (!item.cdproduto) {
					const existe = this.compraXML.estoque.find(est => est.cdreferencia == item.cdreferencia && est.codbarraFornecedor == item.codbarraFornecedor);
					if (!existe) {
						this.compraXML.estoque.push({
							...item,
							cdseq: this.compraXML.estoque.length+1,
							vlrunitario: item.custo / item.quantidade
						});
					} else {
						// const totalProdutoUm = item.quantidade * item.custo;
						// const totalProdutoDois = existe.quantidade * existe.custo;
						// const totalProdutos = totalProdutoUm + totalProdutoDois;
						// const totalQuantidade = item.quantidade + existe.quantidade;


						const totalProdutos = item.custo + existe.custo;
						const totalQuantidade = item.quantidade + existe.quantidade;


						existe.quantidade = totalQuantidade;
						existe.vlrunitario = totalProdutos / totalQuantidade;
						existe.custo = totalProdutos;
						existe.vlrcompra = totalProdutos;

						// existe.quantidade = totalQuantidade;
						// existe.vlrunitario = totalProdutos / totalQuantidade;
						// existe.custo = totalProdutos;
						// existe.vlrcompra = totalProdutos;

					}
				}
			});
			await this.validarEntradaProdutos({conciliarEstoque: true});

			await this.validarEntradaAgrupamento();
			this.calcularCustoEstoque();
			this.calcularValorVendaEstoque();
			this.$store.state.carregando = false;
			this.conciliouEstoque = true;
		},
		async informarCadastroEstoque(compra) {
			try {
				if (!compra.estoque?.length) {
					this.compraXML.estoque = [...compra.itens.sort((a, b) => a.cdseq - b.cdseq).map((item, index) => {
						item.cdseq = (index + 1);
						return {...item};
					})];
					await this.validarEntradaProdutos({conciliarEstoque: false});
					await this.validarEntradaAgrupamento();
					return;
				}
				if(compra.confirmada === "N") {
					const produtosAgrupados = [];
					this.compraXML.itens.forEach((item) => {
						const chave = `${item.cdreferencia}_${item.codbarraFornecedor}`;
						if(produtosAgrupados[chave]) {
							// const totalProdutoUm = produtosAgrupados[chave].quantidade * produtosAgrupados[chave].custo;
							// const totalProdutoDois = item.quantidade * item.custo;
							// const totalProdutos = totalProdutoUm + totalProdutoDois;							
							// const totalQuantidade = item.quantidade + produtosAgrupados[chave].quantidade;
							

							const totalValorProdutos = produtosAgrupados[chave].custo + item.custo;
							const totalQuantidadeProdutos = produtosAgrupados[chave].quantidade + item.quantidade;
							produtosAgrupados[chave] = {
								...produtosAgrupados[chave],
								custo: totalValorProdutos,
								vlrcompra: totalValorProdutos,
								vlrunitario: totalValorProdutos / totalQuantidadeProdutos,
								quantidade: totalQuantidadeProdutos,
							};
							
						} else {
							produtosAgrupados[chave] = {
								...item,
								cdreferencia: item.cdreferencia,
								codbarrafornecedor: item.codbarrafornecedor,
								quantidade: item.quantidade,
								vlrunitario: item.custo / item.quantidade,
							};
						}

					});
					compra.estoque = Object.values(produtosAgrupados);
				}
				this.compraXML.estoque = [...compra.estoque.sort((a, b) => a.cdseq - b.cdseq)];
				if (compra.confirmada == "N" || (compra.confirmada == "L" && compra.tipo == "I")) {
					await this.validarEntradaProdutos({conciliarEstoque: false});
					await this.validarEntradaAgrupamento();
				} else if (compra.confirmada == "L" || compra.confirmada == "F" || compra.confirmada == "S") {
					await this.validarEntradaAgrupamento();
				}

				this.calcularCustoEstoque();
				this.calcularValorVendaEstoque();
			} catch (error) {
				this.$store.state.carregando = false;
				throw new Error(error.message);
			}
		},
		
		async informarCadastroFinanceiro(compra) {
			try {
				this.compraXML.financa = [];
				if (compra.financeiro?.length) {
					for (let index = 0; index < compra.financeiro.length; index++) {
						const pagamento = compra.financeiro[index];
						let parcela = "";
						for (let j = `${pagamento.cdlan}`.length; j < 3; j++) {
							parcela += "0";
						}
						parcela += `${pagamento.cdlan}`;
						pagamento.parcela = parcela || `00${(index+1)}`;
						pagamento.contarc = pagamento.contarc > 0 ? pagamento.contarc : (pagamento.debito || 0);
						pagamento.contarcvlr = (pagamento.contarc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						let formaPagamento = null;
						if (pagamento.cdtipo) {
							formaPagamento = await this.$store.dispatch("buscarFormasPagamentoPorId", pagamento.cdtipo);
						}
						if (formaPagamento) {
							pagamento.formaPagamento = {...formaPagamento};
						} else {
							pagamento.formaPagamento = null;
						}
						this.compraXML.financa.push(pagamento);
					}
				}
				this.$forceUpdate();
				this.compraXML.financa.forEach(fin => {
					let vlr = Number(typeof (fin.contarcvlr) == "string" ? `${fin.contarcvlr || 0}`.split(".").join("").replace(",", ".") : fin.contarcvlr) || 0;
					if (isNaN(vlr)) {
						vlr = 0;
					}
				});
				this.$store.state.carregando = false;
				return true;
			} catch (error) {
				this.$store.state.carregando = false;
				throw new Error(error.message);
			}

		},

		async informarCadastroCompra(compra) {
			try {
				this.$store.state.carregando = true;
				this.compra = compra.cdcompra;
				this.cdcompra = compra.cdcompra;
				this.data = compra.datacompra;
				this.chave = compra.nfechave;
				this.nfeid = compra.nfeid;
				this.chegada = compra.datapedcompra;
				this.cfop = compra.cfop;
				this.compraXML.tipo = compra.tipo;
				this.compraXML.confirmada = compra.confirmada;
				this.compraXML.nfeid = compra.nfeid;
				this.compraXML.vlrbrutocompra = compra.valorbrutocompra;
				this.compraXML.valorbrutopedido = compra.valorbrutopedido;
				this.compraXML.desconto = compra.desconto;
				this.compraXML.frete = compra.frete;
				this.compraXML.valorFCPST = compra.valorFCPST;
				this.compraXML.aumento = compra.aumento;
				this.compraXML.basesubst = compra.basesubst;
				this.compraXML.outros = compra.outros;
				this.compraXML.baseicms = compra.baseicms;
				this.compraXML.valorICMS = compra.icms;
				this.compraXML.valorICMSst = compra.vlrsubst;
				this.compraXML.seguro = compra.seguro;
				this.compraXML.vlripi = compra.vlripi;
				this.compraXML.qtdvolumetransporte = compra.qtdvolumetransporte;
				this.compraXML.embalagemtransporte = compra.embalagemtransporte;
				this.compraXML.pesobrutotransporte = compra.pesobrutotransporte;
				this.compraXML.pesoliquidotransporte = compra.pesoliquidotransporte;
				this.compraXML.fornecedor = compra.fornecedor;
				this.compraXML.transporte = compra.transporte;
				
				if (compra.fornecedor) {
					this.selecionarFornecedor({...compra.fornecedor});
				}
				if (compra.transporte) {
					const transporte = {
						qtdvolumetransporte: compra.qtdvolumetransporte,
						embalagemtransporte: compra.embalagemtransporte,
						pesobrutotransporte: compra.pesobrutotransporte,
						pesoliquidotransporte: compra.pesoliquidotransporte
					};
					this.selecionarTransportadora({...compra.transporte, transporte});
				}
				
				await this.informarCadastroItens({...compra});
				await this.informarCadastroEstoque({...compra});
				await this.informarCadastroFinanceiro({...compra});
				this.compraXML.original = {...this.compraXML};
				this.$forceUpdate();
				this.$store.state.carregando = false;
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async solicitouConfirmarEntrada() {
			setTimeout(() => {
				this.$store.commit("relatarErro", {
					mensagem: "Tem certeza que deseja finalizar o lançamento da compra? Depois não será mais possível alterar nada!",
					tipo: "polar",
					item: {
						acao: this.confirmarEntrada
					}
				});
			}, 100);
		},

		async confirmarEntrada() {
			if (this.compraXML.confirmada == "S") {
				return this.$store.commit("relatarErro", {
					mensagem: "Entrada já está finalizada, verifique!"
				});
			}
			const compraCadastrada = await this.$store.dispatch("confirmarEntrada", {
				cdcompra: this.cdcompra || this.compra,
				cdfornecedor: this.fornecedor.id,
				cdempresa: this.$store.state.Empresa.id,
				confirmada: "S",
				tipo: this.compraXML.tipo,
				datacompra: this.data,
				datapedcompra: this.chegada,
				nfechave: this.chave,
				nfeid: this.compraXML.nfeid,
				cfop: this.cfop,
				basesubst: this.compraXML.basesubst,
				vlrsubst: this.compraXML.valorICMSst || this.totalICMSst,
				baseicms: this.compraXML.baseicms,
				valorICMS: this.compraXML.valorICMS,
				outros: (this.compraXML.outros + this.compraXML.valorFCPST),
				seguro: this.compraXML.seguro || 0,
				frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
				vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
				aumento: this.compraXML.aumento,
				transporte: this.transp.id ? {
					...this.transp
				} : {...this.compraXML.transporte},
				fornecedor: this.fornecedor.id ? {
					...this.fornecedor
				} : {...this.compraXML.fornecedor},
				vlrbrutocompra: this.totalCompra,
				valorbrutopedido: this.valorFinalCompraNota,
				desconto: Number(this.compraXML.original.desconto),
				itens: this.compraXML.itens || [],
				estoque: this.compraXML.estoque || [],
				financeiro: this.compraXML.financa || []
			});
			if (compraCadastrada ? compraCadastrada.cdcompra : false) {
				await this.informarCadastroCompra(compraCadastrada);
				this.mudarAba(1,"Produtos");
				this.mostratelabarra();
			}
		},
		
		async salvarEntrada() {
			try {
				let compraCadastrada = {cdcompra: null};
				if (!await this.validarPreSave()) return;
				if (this.compraXML.confirmada != "A") return;
				compraCadastrada = await this.$store.dispatch("lancarEntrada", {
					cdcompra: this.cdcompra || this.compra,
					cdempresa: this.$store.state.Empresa.id,
					confirmada: "N",
					tipo: this.compraXML.tipo,
					desconto: this.compraXML.original.desconto,
					datacompra: this.data,
					datapedcompra: this.chegada,
					nfechave: this.chave,
					nfeid: this.compraXML.nfeid,
					cfop: this.cfop,
					basesubst: this.compraXML.basesubst,
					valorICMSst: this.totalICMSst,
					baseicms: this.compraXML.baseicms,
					valorICMS: this.compraXML.valorICMS,
					outros: (this.compraXML.outros + this.compraXML.valorFCPST),
					seguro: this.compraXML.seguro || 0,
					frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
					vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
					aumento: this.compraXML.aumento,
					transporte: this.transp.id ? {
						...this.transp
					} : {...this.compraXML.transporte},
					qtdvolumetransporte: this.transp.transporte?.qtdvolumetransporte,
					embalagemtransporte: this.transp.transporte?.embalagemtransporte,
					pesobrutotransporte: this.transp.transporte?.pesobrutotransporte,
					pesoliquidotransporte: this.transp.transporte?.pesoliquidotransporte,
					naturezaoperacao: this.compraXML.natureza,
					fornecedor: this.fornecedor.id ? {
						...this.fornecedor
					} : {...this.compraXML.fornecedor},
					vlrbrutocompra: this.totalCompra,
					valorbrutopedido: this.valorFinalCompraNota,
					// desconto: Number(this.totalDesconto.split(".").join("").replace(",", ".")),
					itens: this.compraXML.itens || [],
					estoque: this.compraXML.tipo == "I" ? [] : this.compraXML.estoque,
					financeiro: this.compraXML.financa || []
				});
				if (!compraCadastrada.cdcompra) {
					this.$store.commit("relatarErro", {
						mensagem: compraCadastrada.mensagem || (compraCadastrada.message || JSON.stringify(compraCadastrada))
					});
				} else {
					await this.informarCadastroCompra(compraCadastrada);
					if (compraCadastrada.tipo == "I") {
						await this.lancarEstoque();
					}
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},
		async validarPreSave() {
			try {
				if (this.compraXML.confirmada == "S") {
					throw new Error("NFe em situação confirmada, não é possível alteração!");
				}
				if (!this.fornecedor.id && !this.fornecedor.nome) {
					throw new Error("Favor selecionar o fornecedor!");
				}
				if (!this.cfop) {
					throw new Error("Favor selecionar o CFOP!");
				}
				if (!this.data) {
					throw new Error("Favor informar a data da compra!");
				}
				if (this.fornecedor.id == 0 && this.fornecedor.nome != "") {
					await this.cadastrarFornecedor();
				}
				if (this.transp.id == 0 && this.transp.nome != "" && (this.transp.cnpj != this.fornecedor.cnpj)) {
					await this.cadastrarTransportadora();
				}
				if (this.compraXML.confirmada == "N" || this.compraXML.confirmada == "L") {
					if (!await this.validarLancamentoEstoque()) return false;
				} else if (this.compraXML.confirmada == "F") {
					if (!await this.validarLancamentoFinanceiro()) return false;
				}
				return true;
			} catch (error) {
				console.log(error);
				setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
				}, 100);
				return false;
			}
		},

		async validarLancamentoEstoque() {
			try {
				if (!this.compraXML.estoque.length) {
					throw new Error("Favor adicionar ao menos 1 (um) produto!");
				}
				this.compraXML.estoque.forEach(item => {
					if (!item.cdgrupo && item.cdgrupo != 0) throw new Error(`Favor informar o grupo para o produto ${item.descricao}. Seq. ${item.cdseq}.`);
					if (!item.cfop) throw new Error(`Favor informar o CFOP para o produto ${item.descricao}. Seq. ${item.cdseq}.`);
				});
				return true;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
				return false;
			}
		},

		iniciarLancamentoEstoque() {
			this.compraXML.confirmada = "L";
		},

		async iniciarLancamentoFinanceiro () {
			const compra = await this.$store.dispatch("atualizarEntrada", {
				cdcompra: this.cdcompra || this.compra,
				cdfornecedor: this.fornecedor.id,
				cdempresa: this.$store.state.Empresa.id,
				confirmada: "F",
				tipo: this.compraXML.tipo,
				datacompra: this.data,
				datapedcompra: this.chegada,
				nfechave: this.chave,
				nfeid: this.compraXML.nfeid,
				cfop: this.cfop,
				basesubst: this.compraXML.basesubst,
				vlrsubst: this.compraXML.valorICMSst || this.totalICMSst,
				baseicms: this.compraXML.baseicms,
				valorICMS: this.compraXML.valorICMS,
				outros: (this.compraXML.outros + this.compraXML.valorFCPST),
				seguro: this.compraXML.seguro || 0,
				frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
				vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
				aumento: this.compraXML.aumento,
				transporte: this.transp.id ? {
					...this.transp
				} : {...this.compraXML.transporte},
				fornecedor: this.fornecedor.id ? {
					...this.fornecedor
				} : {...this.compraXML.fornecedor},
				vlrbrutocompra: this.totalCompra,
				valorbrutopedido:  this.valorFinalCompraNota,
				desconto: Number(this.compraXML.original.desconto),
				itens: this.compraXML.itens || [],
				estoque: this.compraXML.estoque || [],
				financeiro: this.compraXML.financa || []
			});
			if (compra.erro) {
				return this.$store.commit("relatarErro", {mensagem: compra.message});
			}
			await this.informarCadastroCompra(compra);
			setTimeout(() => {
				this.mudarAba(2, "Financeiro");
			}, 100);
		},

		async iniciarConferencia () {
			const compra = await this.$store.dispatch("atualizarEntrada", {
				cdcompra: this.cdcompra || this.compra,
				cdfornecedor: this.fornecedor.id,
				cdempresa: this.$store.state.Empresa.id,
				confirmada: "C",
				tipo: this.compraXML.tipo,
				datacompra: this.data,
				datapedcompra: this.chegada,
				nfechave: this.chave,
				nfeid: this.compraXML.nfeid,
				cfop: this.cfop,
				basesubst: this.compraXML.basesubst,
				vlrsubst: this.compraXML.valorICMSst || this.totalICMSst,
				baseicms: this.compraXML.baseicms,
				valorICMS: this.compraXML.valorICMS,
				outros: (this.compraXML.outros + this.compraXML.valorFCPST),
				seguro: this.compraXML.seguro || 0,
				frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
				vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
				aumento: this.compraXML.aumento,
				transporte: this.transp.id ? {
					...this.transp
				} : {...this.compraXML.transporte},
				fornecedor: this.fornecedor.id ? {
					...this.fornecedor
				} : {...this.compraXML.fornecedor},
				vlrbrutocompra: this.totalCompra,
				valorbrutopedido: this.valorFinalCompraNota, // this.valorFinalCompra,
				desconto: Number(this.compraXML.original.desconto),
				itens: this.compraXML.itens || [],
				estoque: this.compraXML.estoque || [],
				financeiro: this.compraXML.financa || []
			});
			if (compra.erro) {
				return this.$store.commit("relatarErro", {mensagem: compra.message});
			}
			this.informarCadastroCompra(compra);
			this.$store.commit("relatarErro", {
				mensagem: "Parabéns, o lançamento foi finalizado com sucesso, favor conferir os dados e depois clicar em confirmar para que os produtos estejam disponíveis no sistema!",
				sucess: true,
			});
			this.mudarAba(0, "Dados");
		},

		async concluirLancamentoFinanceiro() {
			try {
				const compraCadastrada = await this.$store.dispatch("lancarFinanceiroEntrada", {
					cdcompra: this.cdcompra || this.compra,
					cdempresa: this.$store.state.Empresa.id,
					confirmada: "F",
					tipo: this.compraXML.tipo,
					datacompra: this.data,
					datapedcompra: this.chegada,
					nfechave: this.chave,
					nfeid: this.compraXML.nfeid,
					cfop: this.cfop,
					basesubst: this.compraXML.basesubst,
					vlrsubst: this.compraXML.valorICMSst || this.totalICMSst,
					baseicms: this.compraXML.baseicms,
					valorICMS: this.compraXML.valorICMS,
					outros: (this.compraXML.outros + this.compraXML.valorFCPST),
					seguro: this.compraXML.seguro || 0,
					frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
					vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
					aumento: this.compraXML.aumento,
					transporte: this.transp.id ? {
						...this.transp
					} : {...this.compraXML.transporte},
					fornecedor: this.fornecedor.id ? {
						...this.fornecedor
					} : {...this.compraXML.fornecedor},
					vlrbrutocompra: this.totalCompra,
					valorbrutopedido: this.valorFinalCompraNota,
					desconto: Number(this.compraXML.original.desconto),
					itens: this.compraXML.itens || [],
					estoque: this.compraXML.estoque || [],
					financeiro: this.compraXML.financa || []
				});
				if (!compraCadastrada.cdcompra) {
					this.$store.commit("relatarErro", {mensagem: compraCadastrada.mensagem || (compraCadastrada.message || JSON.stringify(compraCadastrada))});
				} else {
					await this.informarCadastroCompra(compraCadastrada);
					this.$store.commit("relatarErro", {
						mensagem: "Financeiro da compra cadastrado com sucesso, deseja iniciar a conferência agora?",
						tipo: "polar",
						item: {
							acao: this.iniciarConferencia,
						}
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async lancarFinanceiro(confirmado) {
			if (this.mostrarAba != "Financeiro") {
				return this.mudarAba(2, "Financeiro");
			}
			
			if (!await this.validarPreSave()) return;
			if (this.saldoFinanceiro < 0) {
				return this.$store.commit("relatarErro", {
					mensagem: "Valor informado maior que o saldo a receber, favor conferir!",
					alerta: true,
				});
			}
			if (confirmado !== true) {
				if (!this.compraXML.financa?.length && this.totalFinanceiroXML > 0) {
					return setTimeout(() => {
						this.$store.commit("relatarErro", {
							mensagem: "Nenhum pagamento encontrado, deseja finalizar o lançamento da compra sem financeiro?",
							tipo: "polar",
							item: {
								acao: () => {
									this.concluirLancamentoFinanceiro(true);
								},
							}
						});
					}, 100);
				}
				if (this.saldoFinanceiro >= 0.01) {
					return setTimeout(() => {
						this.$store.commit("relatarErro", {
							mensagem: "Ainda possui saldo no financeiro que não foi informado um pagamento, deseja finalizar o lançamento da compra sem informar pagamento do saldo?",
							tipo: "polar",
							item: {
								acao: () => {
									this.concluirLancamentoFinanceiro();
								},
							}
						});
					}, 100);
				}
			}
			await this.concluirLancamentoFinanceiro();
		},

		async concluirLancarEstoque() {
			try {
				this.compraXML.estoque.forEach(prod => {
					if(prod.codbarraFornecedor === "SEM GTIN") {
						prod.codbarraFornecedor = "";
					}
				});		
				this.$store.state.carregando = true;
				const compraCadastrada = await this.$store.dispatch("lancarEstoqueEntrada", {
					cdcompra: this.cdcompra || this.compra,
					cdempresa: this.$store.state.Empresa.id,
					confirmada: "L",
					tipo: this.compraXML.tipo,
					datacompra: this.data,
					datapedcompra: this.chegada,
					nfechave: this.chave,
					nfeid: this.compraXML.nfeid,
					cfop: this.cfop,
					basesubst: this.compraXML.basesubst,
					vlrsubst: this.compraXML.valorICMSst || this.totalICMSst,
					baseicms: this.compraXML.baseicms,
					valorICMS: this.compraXML.valorICMS,
					outros: (this.compraXML.outros + this.compraXML.valorFCPST),
					seguro: this.compraXML.seguro || 0,
					frete: this.compraXML.frete || Number(this.totalFrete.split(".").join("").replace(",", ".")),
					vlripi: this.compraXML.vlripi || Number(this.totalIpi.split(".").join("").replace(",", ".")),
					aumento: this.compraXML.aumento,
					transporte: this.transp.id ? {
						...this.transp
					} : {...this.compraXML.transporte},
					fornecedor: this.fornecedor.id ? {
						...this.fornecedor
					} : {...this.compraXML.fornecedor},
					vlrbrutocompra: this.totalCompra,
					valorbrutopedido: this.valorFinalCompraNota, //this.valorFinalCompraNota,
					desconto: Number(this.compraXML.original.desconto),
					itens: !this.compraXML.nfeid ? this.compraXML.estoque : this.compraXML.itens || [],
					estoque: this.compraXML.estoque || [],
					financeiro: this.compraXML.financa || []
				});
				if (!await this.validarPreSave()) return;
				if (!compraCadastrada.cdcompra) {
					this.$store.commit("relatarErro", {mensagem: compraCadastrada.mensagem || (compraCadastrada.message || JSON.stringify(compraCadastrada))});
				} else {
					await this.informarCadastroCompra(compraCadastrada);
					this.$store.commit("relatarErro", {
						mensagem: "Tem certeza que deseja finalizar o lançamento dos produtos?\n Depois não será mais possível alterar quantidade e custo!",
						tipo: "polar",
						success: true,
						item: {
							acao: () => {
								this.iniciarLancamentoFinanceiro();
							},
						}
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async lancarEstoque() {
			if(!this.compraXML.nfeid) {
				const produtosParaVariar = [];
				this.compraXML?.estoque?.forEach(
					produto => {
						if(produto.tipo === "V" && !produto?.filhos) {
							produtosParaVariar.push(`${produto.cdproduto}`);
						}
					}
				);
				if(produtosParaVariar?.length > 0) {
					this.$store.commit("relatarErro", {
						mensagem: `Para salvar os produtos, é necessário realizar a variação dos seguintes produto(s) ${produtosParaVariar}, por favor verifique`
					});
					return;
				}
			}
			if (!this.visaoEstoque || this.mostrarAba != "Produtos") {
				this.mudarAba(1, "Produtos");
				return this.visaoEstoque = true;
			}
			try {
				if (!await this.validarPreSave()) return;
				await this.concluirLancarEstoque();
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},
		resetState() {
			this.mostrarAba = "Dados";
			this.timer = null;
			this.mensagemPergunta = null;
			this.acaoConfirmar = null;
			this.acaoCancelar = null;
			this.file = null;

			/* DADOS */
			this.cdcompra = null;
			this.compra = "";
			this.cfop = "";
			this.chave = "";
			this.fornecedor = {id:"", nome: ""};
			this.listarFornecedores = false;
			this.checkboxFornecedor = false;
			this.transp = {id:"", nome: ""};
			this.listarTransp = false;
			this.checkboxTransportadora = false;
			this.data = dateFormatISO({date: null, format: "ISOdate"}),
			this.chegada = dateFormatISO({date: null, format: "ISOdate"}),
			this.totalFrete = "";
			this.totalDesconto = "";
			this.totalMargem = "";
			this.totalDescontoVista = "";
			
			//  Imposto
			this.totalIpi = "";
			this.totalICMSst = "";
			this.listarCFOPManual = false;
			this.listarCFOP = false;

			/* PRODUTOS */
			this.configurarProdutos = false;
			this.usarCodBarraFornecedor = false;
			this.importarProdutos = true;
			this.produtoParaIncluir = null;
			this.qtdProdutoManual = "1";
			this.vlrUnitarioManual = "";
			this.cfopManual = "";
			this.cdprodutoOuCodbarra = "";
			this.produtoEncontrado = null;
			this.indexAlteracaoProduto = -1;
			this.indexListaProduto = -1;
			this.MostrarGrupos = -1;

			// FINANCEIRO
			this.metodoPagamentoSelecionado = null;
			this.visaoPagamento = false;
			
			this.compraXML = {
				itens : [],
				financa: []
			};
			if (this.auxFile) {
				this.lerArquivoXML(this.auxFile);
			}
			this.auxFile = null;
		},
		retornarParaTabela(){
			if (this.mostrarAba != "Dados") {
				if (this.mostrarAba == "Financeiro") {
					this.mudarAba(1, "Produtos");
				} else {
					this.mudarAba(0, "Dados");
				}
				return;
			}
			setTimeout(() => {
				try {
					if (this.compraXML.confirmada == "A") {
						if (this.compra || (this.fornecedor.id != 0) || (this.transp.id != 0) || this.cfop) {
							throw new Error("Tem certeza que deseja abandonar o lançamento da compra? Você perderá todos os dados informados!");
						}
					} else if (this.compraXML.confirmada == "N" && this.compraXML.estoque.length) {
						throw new Error("Tem certeza que deseja abandonar o lançamento do estoque? Você perderá todos os dados que não salvou!");
					} else if (this.compraXML.confirmada == "L" && this.compraXML.financa.length) {
						throw new Error("Tem certeza que deseja abandonar o lançamento do financeiro? Você perderá todos os dados que não salvou!");
					}
					this.$emit("cancelarEdicao");
				} catch (error) {
					return this.$store.commit("relatarErro", {
						mensagem: error.message,
						tipo: "polar",
						item: {
							acao: () => this.$emit("cancelarEdicao"),
						}
					});
				}
			}, 50);
		},

		// Atualizando compra
		async filtrarcompra(){
			try {
				// TESTAR SE CAI AQUI DEPOIS
				let compra;
				if(this.$route?.params?.compra) {
					const {
						cdcompra,
						cdempresa,
						fornecedor,
						tipo
					} = this.$route.params.compra;
					compra = await this.$store.dispatch("buscarComprasUnica", {
						cdcompra,
						cdempresa,
						cdfornecedor: fornecedor.id,
						tipo
					});
				} else {
					compra = await this.$store.dispatch("buscarComprasUnica", {
						cdcompra: this.alteracompra.cdcompra,
						cdempresa: this.alteracompra.cdempresa,
						cdfornecedor: this.alteracompra.fornecedor?.id || this.alteracompra.cdfornecedor,
						tipo: this.alteracompra.tipo,
					});
				}

				if (compra.cdcompra) {
					await this.informarCadastroCompra(compra);
				}
			} catch (error) {
				console.log(error);
			}
		},

		// Grupo
		async solicitaCadastroGrupo(cdgrupo) {
			const grupo = await this.$store.dispatch("buscarUnicoGrupo", cdgrupo);
			this.cadastrarGrupo = grupo;
		},
		solicitaAlterarGrupo(index) {
			if (!this.podeAlterarEstoque) return;
			if (this.compraXML.estoque[index].cdproduto) return;
			this.MostrarGrupos = index;
		},
		incluirgrupo({grupo, subgrupo}){
			if (this.MostrarGrupos < 0) return;
			if (this.acaoEmMassa) {
				const index = this.$store.state.grupoProduto.map(grupo => `${grupo.cdGrupo}`).indexOf(`${grupo}`);
				if (index >= 0) {
					this.grupoSelecionado = this.$store.state.grupoProduto[index];
					if (subgrupo > 0) {
						this.grupoSelecionado.subGrupos = [...this.grupoSelecionado.subGrupos].filter(subg => subg.cdsubgrupo == subgrupo);
					}
				}
				return this.MostrarGrupos = -1;
			}
			this.compraXML.estoque[this.MostrarGrupos].cdgrupo = grupo;
			this.compraXML.estoque[this.MostrarGrupos].cdsubgrupo = subgrupo;
			if (!this.compraXML.estoque[this.MostrarGrupos].ncm) {
				let grupoSelecionado = this.listadegrupos.find(gp => gp.cdGrupo == grupo);
				let subgrupoSelecionado = null;
				if (grupoSelecionado) {
					if (subgrupo && grupoSelecionado.subGrupos.length) {
						subgrupoSelecionado = grupoSelecionado.subGrupos.find(sub => sub.cdsubgrupo == subgrupo);
					}
				}
				if (subgrupoSelecionado?.ncm) {
					this.compraXML.estoque[this.MostrarGrupos].ncm = subgrupoSelecionado.ncm;
				} else if (grupoSelecionado?.ncm) {
					this.compraXML.estoque[this.MostrarGrupos].ncm = grupoSelecionado.ncm;
				}
			}
			this.indexAlteraTodosGrupos = this.MostrarGrupos;
			this.MostrarGrupos = -1;
			if (this.indexAlteracaoProduto >= 0) {
				this.iniciarAlteracaoProduto(this.indexAlteracaoProduto, this.compraXML.estoque[this.indexAlteracaoProduto]);
				if (this.agrupamentoProdutos) {
					this.qtdProdutoManual = 0;
					this.compraXML.estoque.filter(item => item.agrupar).forEach(item => {
						this.qtdProdutoManual += item.quantidade;
					});
					this.qtdProdutoManual = Number(this.qtdProdutoManual).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
				}
			}
		},
		atualizargrupo(){
			if (this.indexAlteraTodosGrupos < 0) return;
			this.$store.state.carregando = true;
			let grupo = this.compraXML.estoque[this.indexAlteraTodosGrupos || 0].cdgrupo;
			let subgrupo = this.compraXML.estoque[this.indexAlteraTodosGrupos || 0].cdsubgrupo;
			let grupoSelecionado = this.listadegrupos.find(gp => gp.cdGrupo == grupo);
			let subgrupoSelecionado = null;
			if (grupoSelecionado) {
				if (subgrupo && grupoSelecionado.subGrupos.length) {
					subgrupoSelecionado = grupoSelecionado.subGrupos.find(sub => sub.cdsubgrupo == subgrupo);
				}
			}
			this.compraXML.estoque.forEach(item => {
				if (item.cdproduto) return;
				if (item.cdgrupo) return;
				item.cdgrupo = grupo;
				if (subgrupo) {
					item.cdsubgrupo = subgrupo;
				} else {
					item.cdsubgrupo = null;
				}
				if (!item.ncm) {
					if (subgrupoSelecionado?.ncm) {
						item.ncm = subgrupoSelecionado.ncm;
					} else if (grupoSelecionado?.ncm) {
						item.ncm = grupoSelecionado.ncm;
					}
				}
			});
			this.$store.state.carregando = false;
			this.$forceUpdate();
		},

		// Codigo de barras
		fechatelabarra(){
			this.telaBarra = false;
		},
		mostratelabarra(){
			this.CodiBarra = {
				barracompra: this.compraXML.estoque,
				cdcompra: this.cdcompra
			};
			this.telaBarra = true;
		},

		async polarConfirmado(item) {
			if(item.desativarNota){
				await this.$store.dispatch("desativarXML", item.chave);
			}
			if (item.acao == "removerProduto") {
				this.removerProduto(item.index);
			} else if (item.acao == "concluirLancarEstoque") {
				this.concluirLancarEstoque();
			} else if (typeof item.acao == "function") {
				item.acao();
			}
		},

		polarCancelado(item) {
			if (item.acaoCancelar) {
				item.acaoCancelar();
			}
		},
		
		async buscarDadosIniciais() {
			try {
				this.$store.state.carregando = true;
				await this.$store.dispatch("buscarGrades");
				await this.$store.dispatch("buscarFormasPagamento", {carregando: !this.alteracompra.novo});
				if (!this.formasPagamento.length) {
					throw new Error("Nenhuma forma de pagamento encontrado para mostrar na compra, favor configurar formas de pagamento!");
				}
				if (!this.alteracompra.novo){
					this.filtrarcompra();
				} else {
					this.$store.state.carregando = false;
					this.proximoCampo("compra");
				}
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
					alerta: true
				});
			}
		},

		validarTeclaPressionada(e) {
			if (e.altKey && `${e.key}`.toUpperCase() == "I") {
				e.preventDefault();
				if (this.compraXML.confirmada != "A") return;
				this.solicitarLancamentoXML();
			}
		},
		//validação data
		saiuData(target){
			if(target){
				if(target.datavenc == ""){
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					target.datavenc = " ";
				}
			}
			else{
				if(this.data == ""){
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.data = dateFormatISO({date: null, format: "ISOdate"});
				}
				if(this.chegada == ""){
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.chegada = dateFormatISO({date: null, format: "ISOdate"});
				}
			}
		},
	},

	beforeMount() {
		this.buscarDadosIniciais();
	},

	mounted(){
		window.addEventListener("keydown", this.validarTeclaPressionada);
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
	},

	destroyed(){
		this.produtosAgrupados = [];
		this.conciliouEstoque = false;
		window.removeEventListener("keydown", this.validarTeclaPressionada);
		this.$bus.$off("confirmarPolar",this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	},
};

</script>
<style scoped>
.botoes .botao{
	width:45%;
}
.total-tabela-financeiro .input-group{
	width:auto;
}
.cel{
	display:none;
}
.botao-consultatamanho{
	min-width: 250px;
}
.botao-xml-tamanho{
	min-width: 250px;
}
.botao-lancar-tamanho{
	min-width: 200px;
}
.botao-acao-tamanho{
	min-width: 100px;
}
#codPro{
	width: 100%;
}
.row .input-group{
	width: 33%;
}
.compra-dados-transporte .input-group{
	width: 30%;
}

.input-group .input-date{
	margin-top:10px;
}
.div-principal-compra {
	display: flex;
	flex-direction: column;
	width: calc(100vw - 120px);
	height: 100%;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	margin: 0px;
	padding: 0px;
}

.cabeca-compra {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--bg-app);
	flex-grow: 1;
	margin: 0px 5px;
	margin-bottom: 5px;
	overflow: hidden;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	flex-grow: 1;
	/* box-shadow: 3px 3px 1px #292929; */
}
.funcionalidade-atual {
	box-shadow: none;
	margin: 0px;
	padding: 0px;
}
/*
	CSS - IDENTIFICADOR COMPRA
*/
.identificador-compra {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 5px 2px;
	flex-grow: 1;
}
.identificador-compra.overflow {
	overflow: auto;
}

.identificador-compra-item {
	display: flex;
	flex-direction: row;
	margin: 5px;
	width: 100%;
	justify-content: space-between;
}

.wrap {
	flex-wrap: wrap;
}
.grow {
	flex-grow: 1;
}
.compra-dados-transporte {
	flex-wrap: wrap;
	width: 100%;
}
.compra-dados-transporte div {
	white-space: nowrap;
}
.identificador-compra-item:nth-child(6),
.identificador-compra-item:nth-child(7),
.identificador-compra-item:nth-child(8) {
	justify-content: flex-start;
	padding: 0px 5px;
}
.identificador-compra-item-total {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	flex-grow: 1;
}

.identificador-compra-item-total input {
	width: 100%;
	height: max-content;
}

.fieldset-totais .identificador-compra-item div {
	flex-grow: 1;
}

.fieldset-totais .identificador-compra-item div label {
	text-align: right ;
}

.label-item {
	white-space: nowrap;
	width: 100px;
	text-align: right;
}

.identificador-compra-item div {
	margin: 0px 2px;
}

.identificador-compra-item input {
	flex-grow: 1;
}

.identificador-compra-item .input-date {
	width: 125px;
	padding: 0px 2px;
}

.tabela-produtos {
	overflow: auto;
	width: 100%;
}

.tabela-financeiro {
	overflow: auto;
	width: 100%;
}

.tabela-scroll {
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	overflow-y: auto;
	align-items: flex-start;
	flex-grow: 1;
}

.rodape {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.rodape-linha {
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: auto;
	width: 100%;
	padding: 3px 2px;
	position: relative;
}

.rodape-linha-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px 1px;
}

.rodape-linha-item label {
	white-space: nowrap;
}

.botoes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	justify-self: flex-end;
	padding: 5px 0px;
}

.botoes button {
	margin: 0px 5px;
}

/* PRODUTO */
.lancamento-produto {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	overflow-x: hidden;
}
.variacao-produto-compra {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-grow: 1;
}
.lancamento-produto-item {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 2px;
	align-items: center;
}

.produto-filhos {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: auto;
	padding: 0;
	margin: 0;
}

.lancamento-produto-item label {
	width: 120px;
	text-align: right;
}

.lancamento-produto-item input[type="text"] {
	flex-grow: 1;
}

/* INPUTS */

.input-tabela {
	background-color: transparent;
	max-width: 80px;
	width: 80px;
	height: 100%;
	border: none;
	text-align: right;
	font-size: 11pt;
}
.input-tabela.w-100 {
	width: 100% !important;
	max-width: unset;
}

/* FINANCEIRO */
.cabeca-financeiro {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: max-content;
	overflow-y: hidden;
	max-width: 100vw;
	flex-wrap: wrap;
}

.cabeca-financeiro-item {
	margin: 5px;
	display: flex;
	flex-direction: row;
}
.cabeca-financeiro-item input {
	font-size: 12pt;
	font-weight: bold;
	text-align: right;
}
#total-compra,
#total-financeiro {
	font-size: 12pt;
	font-weight: bold;
	text-align: right;
}

.total-tabela-financeiro {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	text-align: right;
	width: 100%;
}

.total-tabela-financeiro label {
	white-space: nowrap;
}

.total-tabela-financeiro input {
	font-size: 12pt;
	font-weight: bold;
	text-align: right;
}

/* FINANCEIRO */
.container-novo-financeiro{
	display: flex;
	padding: 10px 0px;
}

.metodos-pagamento {
	overflow-y: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	margin: 5px;
	width: 100%;
	max-width: 360px;
}

.metodos-pagamento-header {
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: var(--bg-secundario);
	font-size: 14pt;
	font-weight: bold;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.metodos-pagamento-lista {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.metodos-pagamento-footer {
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: var(--bg-secundario);
	font-size: 14pt;
	font-weight: bold;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.metodos-pagamento-item {
	border: 1px solid #292929;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.metodos-pagamento-header label,
.metodos-pagamento-item label {
	margin: 0px 2px;
}

.label-pagamento {
	width: 100%;
	min-width: 160px;
	text-align: right;
}

.label-valor,
.label-parcela {
	width: 100px;
	text-align: center;
}

.metodos-pagamento-footer label {
	width: 150px;
}

.metodos-pagamento-footer input {
	font-size: 14pt;
	font-weight: bold;
	text-align: right;
	max-width: 180px;
}

.cabeca-parcelamento {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.controle-pagamento {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow: hidden;
	flex-wrap: wrap;
}

.parcelamento {
	display: flex;
	flex-direction: column;
	margin: 5px;
}

.parcelamento-item {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.tabela-parcelas {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	height: 100%;
}

.novo-botao {
	width: auto;
	text-align: right;
	white-space: nowrap;
}

.novo-botao.btn-deletar {
	border-color: var(--deletar);
	box-shadow: 1px 1px 2px var(--deletar);
}

.icone-botao {
	font-size: 12pt;
	align-self: center;
	padding: 2px;
	border: 1px solid black;
	border-radius: 50%;
	box-shadow: 1px 1px 3px 1px black;
}

.icone-botao:active {
	transform: translateY(1px);
}

.checkbox-agrupamento {
	display: flex;
	margin: auto;
	justify-self: center;
	align-self: center;
}

.checkbox-tabela-produto {
	display: flex;
	margin: auto;
	justify-self: center;
	align-self: center;
}

.selecionar-xml-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 10px;
	width: 100%;
}

.input-rodape{
	text-align: right;
}
.tabela-xml-importados-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
}

.tabela-xml-importados {
	margin-top: 10px;
	width: 100%;
}

fieldset  {
	display: flex;
	flex-direction: column;
	padding: 3px 5px;
}

fieldset legend {
	margin-left: 1rem;
	padding: 0 .5rem;
	cursor: default
}

.titulo {
	background-color: var(--bg-secundario);
	width: 95%;
	margin: 5px auto;
	padding: 5px;
	border-radius: 10px;
	text-align: center;
	font-size: 16pt;
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.selecionar input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}

.compra-item-input .input-group{
	width: 100%;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
@media screen and (max-width: 786px) {
	.div-principal-compra {
		width: 100%;
	}
}

@media screen and (max-width: 510px) {
	.identificador-compra-item {
		flex-wrap: wrap;
		margin: 0px;
	}

	.identificador-compra-item div {
		margin: 2px;
	}

	.identificador-compra-item:nth-child(4) div:nth-child(2) {
		width: 100%;
		justify-content: flex-start;
	}

	span.novo-botao {
		margin: 0px auto;
	}

	.label-pagamento {
		font-size: 12pt;
		min-width: unset;
	}

	.label-valor,
	.label-parcela {
		font-size: 12pt;
	}

	.metodos-pagamento-footer input {
		font-size: 12pt;
		font-weight: bold;
		text-align: right;
		max-width: 160px;
	}

	.btn i {
		display: none;
	}

	.total-tabela-financeiro {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		text-align: right;
	}

	.total-tabela-financeiro input {
		width: 160px;
	}
}

@media screen and (max-width: 425px){
	#compra{
		max-width: 130px;
	}
	#datacompra{
		max-width: 110px;
	}
	.row .input-group{
		width: auto;
	}
	.identificador-compra-item .input-group{
		margin:5px;
	}
	.cel{
		display: inline-block;

	}
	.nocel{
		display:none;
	}
	.botao-consultatamanho{
		min-width: 100px;
	}
	.rodape-linha:nth-child(1){
		flex-wrap: wrap;
	}
	.rodape-linha .input-group{
		width: auto;
	}
	.rodape-linha:nth-child(2) .input-group{
		min-width: 100px;
	}
	.rodape-linha:nth-child(2) .large-input-group{
		min-width: 130px;
	}
}
@media screen and (max-width:400px){
	.botao i{
		display:none;
	}
}
@media screen and (max-width:375px){
	.row{
		flex-direction:column;
	}	
	.row-small{
		flex-direction: row;
	}
}
.w-90vw {
	width: 90vw;
}
.h-90vh {
	height: 90vh;
}
.headerXML{
	display: flex !important;
	justify-content: space-between;
	width: 100%;
	padding: 0px 10px;
}
.input-pesquisa-produto .input-group{
	width: 100%;
}
.input-acao-massa{
	width: 350px !important;
}

.container-agrupados{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.container-modal-outros-produtos {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-info i{
	color: #00b4d8;
}
.container-info{
	width: 100%;
	height: 7vh;
	padding: 10px;
	margin-top: 5px;
	background-color: #caf0f8;
	border-radius: 10px;
	border: 2px solid #00b4d8;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.container-info span {
	color: #00b4d8;
}
.container-modal-outros-produtos table{
	width: 100%;
	margin: 25px 0px;
}

.container-table-outros-produtos {
	height: 45vh;
	overflow-y: auto;
}
.container-tabela-agrupados {
	max-height: 40vh;
	overflow-y: auto;

}
</style>