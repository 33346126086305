<template>
	<div id="gridPrincipal">
	
		<IdentificadorVendedorCliente id="identificadorVenda" v-on:trocarCliente="trocarCliente"
		v-on:trocarVendedor="listarVendedores = true" v-on:trocarCaixa="listarCaixas = true"
		v-on:salvarInformacoes="salvouInformacoes" v-on:consultarContas="consultarContas" v-on:cancelarInformacoes="cancelouInformacoes" />

		<InserirProdutos id="painelControle" :produtos="produtos" :itens="itensVenda" v-on:adicionarItem="adicionarItem"
		v-on:atualizarItem="atualizarItem" v-on:consultarProdutos="solicitaListagemProdutos" v-on:buscarItemPorId="selecionarItem" v-on:buscarProdutoCodBarra="selecionarItem"
		v-on:cancelarVenda="cancelarVenda" v-on:alterarTipoVenda="alterarTipoVenda" v-on:trocarVendedor="listarVendedores = {finalizar:true}"
		v-on:adicionarTroca="eventosTeclado({code: 'KeyT', altKey: true})" v-on:trocarCliente="listarClientes = true"
		v-on:iniciarConsorcio="iniciarLancamentoConsorcio" v-on:finalizarVenda="finalizarVenda" v-on:podeVenderCondicional="alterarTipoVenda" 
		v-on:verificaLotes="verificaLotes"/>

		<TabelaProdutos id="tabelaProdutos" :itens="itensVenda" v-on:editarItem="editarItemVenda"
		v-on:removerItem="removerItemVenda" v-on:selecionarItem="selecionarItem" v-on:adicionarItem="adicionarItem"
		v-on:limparItem="item = null" v-on:alterarTipoVenda="alterarTipoVenda" v-on:finalizarVenda="finalizarVenda" />

		<Modal 
			v-if="listarProdutos" 
			@fecharModal="fecharModal"
		>
			<consulta-produtos 
				:Produtos="produtos"
				:mostrarBotaoNovo="false"
				@selecionarProduto="selecionarItem"
				:trocaListagem="$route.query.trocarProdutos ? true : false"
				:height="'65vh'"
				@fecharModal="fecharModal"
			/>
		</Modal>

		<Modal 
			v-if="listarClientes"
			@fecharModal="fecharModal"
			:dimensoes="{width: '95%', height: '90vh'}"
			:idModal="'consulta-cliente'"
		>
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				v-on:consultarContas="consultarContas"
				:mostrarAcao="true"
				v-on:adicionarNovo="cadastrandoCliente = {novo: true}"
				:mostrarBotaoNovo="true"
				:height="'60vh'"
				v-if="!cadastrandoCliente"
			/>
			<CadastroCliente
				v-else-if="cadastrandoCliente != null"
				:cliente="cadastrandoCliente"
				@cancelarEdicao="cancelarEdicao"
				@clienteCadastrado="alterarClienteVenda"
			/>
		</Modal>

		<Modal 
			v-if="listarVendedores"
			@fecharModal="fecharModal"
		>
			<consulta-vendedores 
				:mostrarBotaoNovo="false"
				@selecionarPessoa="selecionarVendedor"
				:height="'65vh'"
			/>
		</Modal>

		<SelecionaCaixa
			v-if="listarCaixas"
			@fecharModal="fecharModal"
		/>

		<Modal 
			v-if="listarVendas"
			@fecharModal="fecharModal"
			:dimensoes="{width: '95%', height: '95%'}"
			:idModal="'listar-vendas'"
		>
			<div v-if="!vendaSelecionada" style="align-items: right;">	
				<consulta-vendas-troca
					:VendasTroca="VendasTrocas" 
					v-on:selecionarVenda="selecionarVenda" 
					:height="'55vh'"
					v-if="trocaBuscaVenda"
				/>
				<consulta-produtos 
					:Produtos="produtos"
					:troca="true"
					:trocaBuscaVenda="trocaBuscaVenda"
					v-on:selecionarProduto="preTrocaProduto"
					:height="'55vh'"
					v-else
				/>
				<div 
					id="switch-controle"
					v-if="$store.state.Usuario.multiTroca"
					style="width: 100px; margin-left: auto; margin-right: 10px"
				>
					<span 
						@click="trocaBuscaVenda = !trocaBuscaVenda; vendaSelecionada = null"
					>
						{{trocaBuscaVenda ? "Vendas" : "Produtos"}}
					</span>
					<i 
						class="fas fa-toggle-on switch-icon"
						style="color: var(--confirmar)"
						v-if="trocaBuscaVenda" 
						@click="trocaBuscaVenda = !trocaBuscaVenda; vendaSelecionada = null"
					></i>
					<i 
						class="fas fa-toggle-off switch-icon" 
						style="color: rgba(0,0,0,0.2)"
						v-else
						@click="trocaBuscaVenda = !trocaBuscaVenda; vendaSelecionada = null"
					></i>
				</div>
			</div>
			
			<ListagemProdutos
				:Produtos="vendaSelecionada.itens"
				:troca="true"
				:trocaBuscaVenda="trocaBuscaVenda"
				:trocaPorVenda="true"
				v-else
				@trocarProdutos="trocarProdutos"
				@cancelarTroca="vendaSelecionada = null"
			/>
		</Modal>
		<Modal v-if="modalVistaPrazoTroca" v-on:fecharModal="fecharModalVistaPrazoTroca" 
			:idModal="'modalVistaPrazoTroca'" :dimensoes="{ width: '450px', height: '100px'}">
			Qual valor deseja utilizar?
			<div class="botoes" style="justify-content: space-between;">
				<button class="botao botao-acao" @click="tipoValorTroca('p')">prazo: {{ this.produtoModalVistaPrazoTroca.valorPrazo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</button>
				<button class="botao botao-acao" @click="tipoValorTroca('v')">vista: {{ this.produtoModalVistaPrazoTroca.valorVista.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</button>
			</div>
		</Modal>

		<Modal
			v-if="lancarConsorcio"
			@fecharModal="lancarConsorcio = false"
		>
			<CadastroConsorio
				:Consorcio="{novo:true}"
				:Lancamento=true
				@cancelouCadastro="lancarConsorcio = false"
			/>
		</Modal>

		<Modal 
			v-if="receberConta" 
			@fecharModal="fecharConsultaContas"
		>
			<ContasReceber 
				@fecharContasReceber="fecharConsultaContas"
				:clienteProps="consultarPessoa"
			/>
		</Modal>
		<Modal 
			v-if="servico"
			@fecharModal="servico = false"
			:dimensoes="{width: '60%', height: 'auto'}"
			:idModal="'adicionar-servico'"
		>
			<AdicionarServico
				@mascarar="mascaraInteger"
				@mascaraFloat="mascaraFloat"
				:produto="produtoServico"
				@atualizarProduto="atualizarProduto"
				@incluirItemVenda="incluirItemVenda"
				@cancelarServico="cancelarServico"
				@atualizarItemVenda="atualizarItemVenda"
				@fecharModal="servico = false"
			/>
		</Modal>
		<Modal
			v-if="transferirEstoque"
			:dimensoes="{width: '45%', height: 'auto'}"
			@fecharModal="transferirEstoque = false"
			:idModal="'transferencia-estoque'"
		>
			<div class="modalTransferenciaContainer">
				<div class="containerTransferente">
					<div>
						<table>
							<thead>
								<tr>
									<th>Cód</th>
									<th>Empresa</th>
									<th>Saldo</th>
									<th>Ação</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{ EmpresaTransferente.id }}</td>
									<td>{{ EmpresaTransferente.nome }}</td>
									<td>{{ empresaComSaldo.saldo }}</td>
									<td>
										<i 
											class="fas fa-edit"
											style="color: var(--primario); font-size: 20px"
											@click="alteraEmpresa = true"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="transferir">
						<div>
							<label for="qtdTransferir">
								Qtd. Transferir
							</label>
						</div>
						<div>
							<i 
								class="fas fa-minus icone"
								@click="adicionarDiminuir('menos')"
								style="user-select: none; color: var(--primario)"
							></i>
							<input
								type="text"
								v-model="qtdTransferir"
								id="qtdTransferir"
							/>
							<i 
								class="fas fa-plus icone"
								@click="adicionarDiminuir('mais')"
								style="user-select: none; color: var(--primario)"
							></i>
						</div>
					</div>
				</div>
				<div>
					<i class="fas fa-exchange-alt" style="color: var(--primario)"></i>
				</div>
				<div>
					<table>
						<thead>
							<tr>
								<th>Cód</th>
								<th>Empresa</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{ Empresa.id }}</td>
								<td>{{ Empresa.nome }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="buttons">
					<button class="botao botao-adicionar" @click="realizarTransferenciaEstoque">
						Realizar Transferência
					</button>
				</div>
			</div>
		</Modal>
		<Modal
			v-if="alteraEmpresa"
			:dimensoes="{width: 'auto', height: 'auto'}"
			@fecharModal="fecharModalAlteraEmpresa()"
			:idModal="'alterar-empresa'"
		>
			<table class="containerTableSelecionar">
				<thead>
					<tr>
						<th>Cód</th>
						<th>Empresa</th>
						<th>Saldo</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(empresa, index) in empresasTransferente" :key="index" @click="alterarEmpresa(empresa)">
						
						<td>
							{{ empresa.cdempresa }}
						</td>
						<td>
							{{ $store.state.Empresas.find(emp => emp.id == empresa.cdempresa).nome }}
						</td>
						<td>
							{{ empresa.saldo  }}
						</td>

					</tr>
				</tbody>
			</table>
		</Modal>
		<Modal v-if="modalLotes" v-on:fecharModal="cancelarSelecaoLote" :dimensoes="{width: 'auto', height: 'auto'}" :idModal="'lotes'">
			<div class="containerMensagem">
				<p>Escolha o lote</p>			
			</div>	
			<div class="containerTabelaFilhos" >
				<table class="tabelaFilhos" id="tabelaFilhos">
					<thead>
						<tr>
							<th>Cód Lote</th>
							<th>Lote</th>
							<th>Fabricação</th>
							<th>Vencimento</th>
							<th>Saldo</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(lote, index) in  Lotes" :key="index" @click="SelecionarLote(lote)">							
							<td>
								{{ lote.cdlote }}
							</td>
							<td>
								{{ lote.lote }}
							</td>	
							<td>
								{{ lote.fabricacao.split("-").reverse().join("/") }}
							</td>
							<td>
								{{ lote.dataVenc.split("-").reverse().join("/") }}
							</td>
							<td>
								{{ lote.quantidade-lote.saida }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>		
		</Modal>
		<Autenticacao
			v-if="solicitouAutorizacao"
			:mensagem="autenticacaoConfig.mensagem"
			:autorizar="autenticacaoConfig.autorizar"
			:negar="autenticacaoConfig.negar"
			:autorizacao="true"
		/>
	</div>
</template>

<script>
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendasTroca from "../components/ConsultaVendasTroca.vue";
import { dateFormatISO } from "../utils/dateformat";
import Modal from "../components/Modal.vue";
import TabelaProdutos from "../components/TabelaProdutos.vue";
import InserirProdutos from "../components/InserirProdutos.vue";
import IdentificadorVendedorCliente from "../components/IdentificadorVendedorCliente.vue";
import ListagemProdutos from "../components/ListagemProdutos.vue";
import SelecionaCaixa from "../components/SelecionaCaixa.vue";
import CadastroConsorio from "../components/CadastroConsorcio.vue";
import CadastroCliente from "../components/CadastroCliente.vue";
import ContasReceber from "../pages/ContasReceber.vue";
import Autenticacao from "../components/Autenticacao.vue";
import AdicionarServico from "../components/AdicionarServico.vue";
import { FormacaoPreco } from "../models/Produto.js";

export default {
	name: "Lancamento",

	components: {
		ConsultaProdutos,
		ConsultaVendedores,
		ConsultaCliente,
		ConsultaVendasTroca, 
		Modal,
		InserirProdutos,
		IdentificadorVendedorCliente,
		TabelaProdutos,
		ListagemProdutos,
		SelecionaCaixa,
		CadastroConsorio,
		CadastroCliente,
		ContasReceber,
		Autenticacao,
		AdicionarServico
	},
	
	data() {
		return {
			solicitouAutorizacao: false,
			autenticacaoConfig: {
				mensagem: "",
				autorizar() {},
				negar() {},
			},
			seq: 0,
			listarProdutos: false,
			listarClientes: false,
			cadastrandoCliente: null,
			listarVendedores: false,
			listarCaixas: false,
			listarVendas: false,
			vendaSelecionada: null,
			trocaBuscaVenda: true,
			inserirIdentificador: false,
			lancarConsorcio: false,
			receberConta: false,
			consultarPessoa: null,
			editarProduto: false,
			transferirEstoque: false,
			// Serviço
			servico: false,
			

			//Transferencia
			empresasTransferente: [],
			empresaComSaldo: {},
			qtdTransferir: 1,

			alteraEmpresa: false,
			produtoServico: {},
			modalLotes: false,
			quantidadeLote: 1,

			modalVistaPrazoTroca: false,
			produtoModalVistaPrazoTroca: {},
		};
	},

	computed: {
		Empresa(){
			return this.$store.state.Empresa;
		},

		EmpresaTransferente(){
			return this.$store.state.Empresas.find(emp => emp.id === this.empresaComSaldo.cdempresa);
		},
		
		produtos(){
			return this.$store.state.Produtos;
		},

		itensVenda(){
			return this.$store.state.itensVenda;
		},

		Usuario(){
			return this.$store.state.Usuario;
		},

		produtoSelecionado(){
			return this.$store.state.produtoSelecionado;
		},

		VendasFiltradas(){
			return this.$store.state.VendasFiltradas;
		},

		VendasTrocas(){
			return this.$store.state.VendasTroca;
		},
		
		totalGeral() {
			let preco = 0;
			this.itensVenda.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					preco += Number(Number(item.valorUnitario * item.quantidade).toFixed(2));
				} else if (item.cdTipo == "T") {
					preco -= Number(Number(item.valorUnitario * item.quantidade).toFixed(2));
				}
			});
			return preco;
		},

		totalBruto() {
			let preco = 0;
			this.itensVenda.forEach(item => {
				if (item.cdpromocao && item.cdTipo != "T") {
					preco += Number(Number(item.produto.valorPrazo * item.quantidade).toFixed(2));
				} else {
					preco += Number(Number(item.valorUnitario * item.quantidade).toFixed(2));
				}
			});
			return preco;
		},

		existePromocao() {
			return this.itensVenda.find(item => item.cdpromocao || item.promocaoAntiga) != null;
		},

		Venda() {
			return this.$store.state.Venda;
		},

		ClientePadrao() {
			return this.$store.state.ClientePadrao;
		},

		Lotes(){
			return this.$store.state.Lotes;
		}
	},

	methods: {
		verificaLotes(produto){
			this.quantidadeLote = produto.quantidade;
			if(this.Lotes.length>0){
				this.modalLotes = true;
			}
		},
		cancelarSelecaoLote(){
			this.modalLotes = false;
			this.$bus.$emit("cancelarEdicao");
			this.$store.commit("selecionarProduto", null);
		},
		async SelecionarLote(lote){
			this.modalLotes = false;
			this.$store.state.Lotes = [];
			let produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: lote.cdproduto, cdempresa: lote.cdempresa});
			produto.cdCor = lote.cdlote;
			this.adicionarItem({
				...produto, 
				seq: this.seqProdutoLote,
				quantidade: Number(this.quantidadeLote) || 1,
				valorUnitario: this.$store.state.Venda?.valorVista ?
					Number(!Number(this.valorUnitario) ? Number((produto.promocaoVista && !this.$route.query.trocarProdutos) ? (produto?.valorPromocaoVista) : produto.valorVista) : Number(this.valorUnitario)) :
					Number(!Number(this.valorUnitario) ? Number((produto.promocaoPrazo && this.$route.query.tipoVenda != "C" && !this.$route.query.trocarProdutos) ? (produto.valorPromocaoPrazo) : produto.valorPrazo) : Number(this.valorUnitario)),});
		},
		fecharModalAlteraEmpresa(){
			this.alteraEmpresa = false;
		},

		alterarEmpresa(empresa){
			if(empresa.cdempresa === this.empresaComSaldo.cdempresa){
				this.alteraEmpresa = false;
				return this.$store.commit("relatarErro", {
					mensagem: "Está empresa ja está selecionada."
				});
			} 
			else {
				this.empresaComSaldo = empresa;
				this.alteraEmpresa = false;
			}
		},

		adicionarDiminuir(button){
			let saldo =  Number(`${this.qtdTransferir || 0}`.split(".").join("").replace(",", ".")) || 0;
			if(button === "menos"){
				if (saldo - 1 < 0) return;
				saldo--;
			} else {
				saldo++;
			}
			this.qtdTransferir = saldo.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},

		cancelarEdicao () {
			this.cadastrandoCliente = null;
			this.$store.state.situacaoCliente = {};
		},

		consultarContas(pessoa) {
			this.receberConta = true;
			this.consultarPessoa = pessoa;
		},

		fecharConsultaContas() {
			this.receberConta = false;
			this.consultarPessoa = null;
		},

		solicitaListagemProdutos() {
			if (this.$route.query.baixarCondicional && !this.$store.state.inserirProduto) return;
			this.listarProdutos = true;
		},

		temItensVenda(itensVenda){
			if(
				itensVenda.length < 1 || (itensVenda.length == itensVenda.filter(item => (item.situacao == "D" || item.situacao == "B")).length)
			){
				return true;
			} else {
				return false;
			}
		},

		async finalizarVenda(itensVenda){
			const vendasOrigem = this.$store.state.Venda.vendasOrigem;
			try {
				if (this.temItensVenda(itensVenda)){
					if (this.$route.query.baixarCondicional) {
						Object.keys(vendasOrigem).forEach(key => {
							vendasOrigem[key].baixarCondicional = true;
						});
						return this.$store.dispatch("alterarEmLote", {...vendasOrigem, });
					}
					return setTimeout(() => {
						return this.$store.commit("relatarErro", {mensagem: "Não é possível finalizar venda sem itens, verifique!"});
					}, 10);
				}
				if (this.totalGeral.toFixed(3) < 0.000) {
					return setTimeout(() => {
						return this.$store.commit("relatarErro", {
							mensagem: `O valor a ${this.$store.state.Venda.valorVista ? "vista" : "prazo"} está negativo, o cliente tem ${Number(this.totalGeral *(-1)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})} para levar em produtos!`
						});
					}, 10);
				}
				//parametro nao validar dados do consumidor final
				// if(!this.$store.state.Usuario.solicitaNomeConsumidorFinal){
				// 	if ((this.$store.state.Venda.cliente.id === this.$store.state.ClientePadrao?.id) && (!this.$store.state.Venda.nomeCliente)) {
				// 		if (!this.inserirIdentificador) {
				// 			this.inserirIdentificador = true;
				// 			return this.$bus.$emit("incluirObservacao");
				// 		}
				// 	}
				// }
				if ((this.$store.state.Venda.cliente.id === this.$store.state.ClientePadrao?.id) && (!this.$store.state.Venda.nomeCliente)) {
					if (!this.inserirIdentificador) {
						this.inserirIdentificador = true;
						return this.$bus.$emit("incluirObservacao");
					}
				}
				this.$store.state.Venda = {
					...this.$store.state.Venda,
					itens: [...this.itensVenda],
					valorTroca: this.itensVenda.map(item => item.cdTipo == "T" ? (item.valorUnitario * item.quantidade) : 0).reduce((total, vlr) => total += vlr),
					valorBruto: this.totalBruto,
					cdEmpresa: this.$store.state.Empresa.id,
					identificador: this.$store.state.Venda.identificador || `Realizado por ${this.$store.state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`,
					financeiro: this.$store.state.Venda.financeiro,
					cdUsuarioCaixa: this.$store.state.Usuario.id
				};
				let condicional = false;
				this.itensVenda.forEach( item => {
					if (item.cdTipo == "C") condicional = true;
				});
				if (
					this.$store.state.Usuario.vendedorRecebePrazo 
					&& !condicional && ((!this.$store.state.Venda.valorVista) || this.$store.state.Usuario.somenteVendaSemCaixa)
				) {
					this.$store.state.Venda.fechamento = true;
					return this.$router.push({name: "Fechamento", query: this.$route.query});
				} else {
					if ((this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa) && !condicional) {
						this.$store.state.Venda.fechamento = true;
						return this.$router.push({name: "Fechamento", query: this.$route.query});
					}
					if (this.$route.query.baixarCondicional && this.$route.query.alteracao && this.$store.state.Venda?.vendasOrigem) {
						Object.keys(vendasOrigem).forEach(key => {
							vendasOrigem[key].cdUsuarioCaixa = this.$store.state?.Usuario?.id;
							vendasOrigem[key].alteracao = this.$route.query.alteracao;
						});
						return this.$store.dispatch("alterarEmLote", {...this.$store.state.Venda.vendasOrigem});
					}
					if (this.$route.query.baixarCondicional && this.$route.query.alteracao) {
						return this.$store.dispatch("atualizarVenda", {...this.$store.state.Venda});
					}
					if (
						(!this.$store.state.Usuario.supervisor || !this.$store.state.Usuario.caixa) 
						&& !condicional
						&& this.$store.state.Venda.valorVista 
						&& !this.$store.state.Usuario.somenteVendaSemCaixa
					) {
						return this.$store.commit("relatarErro", {
							mensagem: "A venda está marcada como a vista, será feito em cartão?",
							tipo: "polar",
							item: {...this.$store.state.Venda, cdIdentificador: 0, tipoVenda: this.$route.query.tipoVenda}
						});
					}
					if (this.$route.query.baixarCondicional) {
						if (this.$store.state.Venda.vendasOrigem) {
							for (const key of Object.keys(this.$store.state.Venda.vendasOrigem)) {
								await this.$store.dispatch("cancelarCondicional", {
									vendaOrigem: key,
								});
							}
						}
					}
					this.$store.dispatch("inserirVendaSemPagamento", {
						...this.$store.state.Venda,
						tipoVenda: this.$route.query.tipoVenda,

						cdIdentificador: 0,
					});
				}
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		iniciarLancamentoConsorcio() {
			this.lancarConsorcio = true;
		},

		async verificarDevolucao(item) {
			let itensVenda = this.$store.state.itensVenda;
			let index = itensVenda.map(prod => `${prod.produto.id}`).indexOf(`${item.id}`);
			if (index < 0) {
				index = itensVenda.map(prod => `${prod.produto.codBarra}`).indexOf(`${item.id}`);
				if(index < 0) {
					index = itensVenda.map(prod => `${prod.produto.codbarraFornecedor}`).indexOf(`${item.id}`);
				}
			}
			if (index >= 0) {
				let produto = itensVenda[index];
				let qtd = item?.quantidade?.includes(",") ? Number(`${item.quantidade}`.replace(",", ".")) : Number(item.quantidade);
				if(qtd > produto.quantidade ){
					this.$store.commit("relatarErro", {
						mensagem: "Você esta tentando remover uma quantidade maior que a quantidade do produto!"
					});
				} else if(qtd === produto.quantidade){
					this.removerItemVenda(index);
				} else {
					produto.quantidade = Number(produto.quantidade - qtd);
				} 
				if (!item.focar) {
					this.$store.commit("selecionarProduto", {...produto});
				}
			} else {
				if (item.focar) {
					this.$store.commit("relatarErro", {mensagem: "Produto não encontrado!", proximoFoco: "js-id-produto"});
				}
			}
		},

		proximoCampo(id) {
			const input = document.getElementById(id);
			if (input) {
				input.focus();
				if (input.select) {
					input.select();
				}
			}
		},
		
		verificarServico (produto) {
			this.servico = true;
			this.produtoServico = produto;
			this.$forceUpdate();
		},

		async selecionarItem(item){
			this.$store.commit("handleConsultandoProduto", false);
			if(item.servprod === "S" || item.produto?.servprod === "S"){
				this.$store.state.carregando = false;
				return this.verificarServico(item);
			}
			if (!item.id) return this.$store.commit("selecionarProduto", null);
			if (item.devolucao) {
				return this.verificarDevolucao(item);
			}
			if (item.seq){
				this.$store.commit("selecionarProduto", {...item, descricao: item.produto.descricao, saldo: item.produto.saldo, id: item.produto.id});
				this.seq = item.seq -1;
				document.getElementById("js-id-produto").readOnly = true;
				document.getElementById("js-id-produto").disabled = true;
				document.getElementById("js-quant").value = item.quantidade;
				const btnCancelar = document.getElementById("js-btn-cancelar");
				btnCancelar.style.display = "block";
				const tipoVenda = document.getElementById("tipoVenda");
				document.getElementById("btn-baixa") ? document.getElementById("btn-baixa").style.display = "none" : null;
				if (tipoVenda) tipoVenda.style.display = "none";
				return this.$store.state.editarProduto = true;
			} else {
				if (item.focar) {
					if (!item.id) return;
					if (item.valorVista <= 0 || item.valorPrazo <= 0 || !item.descricao) {
						return this.$store.commit("relatarErro", {mensagem: "Produto com cadastro incorreto!"});
					}
					await this.$store.dispatch("buscarLotes", {cdProduto: item.id, empresa: this.$store.state.Empresa.id, saldo:"S"});
					this.verificaLotes({...item, quantidade: this.quantidade, ehPeso: this.produtoBalanca});
					if(this.Lotes.length>0){
						return;
					}
					return this.adicionarItem({
						...item,
						cdTipo: this.$route.query.tipoVenda,
						quantidade: 1,
						valorUnitario: this.$store.state.Venda.valorVista ?
							Number(!Number(item.valorUnitario) ? Number((item.promocaoVista && !this.$route.query.trocarProdutos)? (item.valorPromocaoVista) : (item.valorVista)) : Number(item.valorUnitario)) :
							Number(!Number(item.valorUnitario) ? Number((item.promocaoPrazo && this.$route.query.tipoVenda != "C" && !this.$route.query.trocarProdutos) ? (item.valorPromocaoPrazo) : (item.valorPrazo)) : Number(item.valorUnitario)),
					});
				}
				if (item.id == null || item.id == ""){
					document.getElementById("js-id-produto").focus();
					return this.$store.commit("selecionarProduto", null);
				}
				let produto = null;
				this.$store.commit("handleConsultandoProduto", true);
				produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: item.id});
				// if(!produto) {
				// 	produto = await this.$store.dispatch("buscarProdutoPorCodigoBarras", item.id);
				// }
				this.$store.commit("handleConsultandoProduto", false);
				if(this.produtoSelecionado && this.produtoSelecionado !== "naoencontrado" && this.produtoSelecionado !== "cadastroincorreto") {
					if((produto?.cdEmpresa !== this.Empresa.id) && this.Empresa.transfereProdutoAutomatico) {
						try {
							const { transferencia } = await this.transferirProdutoAutomatico(produto);
							if(!transferencia?.id) {
								throw new Error("Produto sem cadastro na empresa conectada, e não foi possível transferi-lo automaticamente, por favor contate o suporte!");
							}
							this.$store.commit("handleConsultandoProduto", true);
							await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: item.id});
							this.$store.commit("handleConsultandoProduto", false);
							if(item.todasEmpresas) {
								this.adicionarItem({...item,
									cdTipo: this.$route.query.tipoVenda,
									quantidade: 1,
									valorUnitario: this.$store.state.Venda.valorVista ?
										Number(!Number(item.valorUnitario) ? Number((item.promocaoVista && !this.$route.query.trocarProdutos)? (item.valorPromocaoVista) : (item.valorVista)) : Number(item.valorUnitario)) :
										Number(!Number(item.valorUnitario) ? Number((item.promocaoPrazo && this.$route.query.tipoVenda != "C" && !this.$route.query.trocarProdutos) ? (item.valorPromocaoPrazo) : (item.valorPrazo)) : Number(item.valorUnitario)),
								});
								return;
							}
						} catch (error) {
							console.error("Erro ao transferir o produto automaticamente:", error.message);
							return this.$store.commit("relatarErro", {
								mensagem: error.message
							});
						}
					}
					else  if((produto?.cdEmpresa !== this.Empresa.id) && !this.Empresa.transfereProdutoAutomatico){
						this.$store.commit("selecionarProduto", "naoencontrado");
					}
				}
				
				this.$store.commit("handleConsultandoProduto", false);
			}
		},
		trocarCliente() {
			if (this.$store.state.Venda.cliente.id != this.$store.state.ClientePadrao?.id) {
				this.cadastrandoCliente = this.$store.state.Venda.cliente;
			}
			this.listarClientes = true;
		},
		
		async alterarClienteVenda(cliente) {
			let liberacao = this.Venda?.liberacao;
			if (cliente?.id != liberacao?.cliente?.id){
				if (!liberacao) {
					liberacao = {};
				}
				liberacao.cliente = {};
			}

			if (this.$route.query.tipoVenda == "C" && (!this.verificaClientePadrao(cliente))) {
				if (!await this.autorizaVenderCondicional(null, cliente)) return;
				if(!this.podeAdicionarItem(null, cliente)) return;
				this.$bus.$emit("recalcularValores", "prazo", "altera-cliente-venda-lancamento");
			}
			if(!this.verificaClientePadrao(cliente)){
				if(await this.clienteDevendo(cliente)) {
					this.solicitarLiberacaoCliente({
						clienteDevedor: cliente,
						situacaoCliente: this.$store.state.situacaoCliente,
						usuario: this.$store.state.Usuario,
					});
				}
				this.$bus.$emit("recalcularValores", "prazo", "altera-cliente-venda-lancamento-2");
			}
			if (!this.verificaClientePadrao(cliente)) {
				await this.$store.dispatch("buscarClientePorId", {id: cliente?.id, venda: true});
			} else {
				this.$store.commit("atualizarConsumidorVenda", {...cliente});
			}
			document.getElementById("js-id-produto").focus();
			if (this.listarClientes != true && typeof this.listarClientes == "object") {
				if (this.listarClientes.selecionarCliente) {
					this.cadastrandoCliente = cliente;
					this.listarClientes = false;
					if(!await this.autorizaVenderCondicional(null, cliente)) return;
					if(!this.podeAdicionarItem(null, cliente)) return;
				}
			}
			this.$store.commit("atualizarConsumidorVenda", {...cliente});
			this.listarClientes = false;
			this.$bus.$emit("atualizadoConsumidorOuVendedor");
			if (cliente.mensagemVenda) {
				this.$store.commit("relatarErro", { mensagem: cliente.mensagemVenda, alerta: true });
			}
		},


		//METODOS CLIENTE

		verificaClientePadrao(cliente){
			if(cliente?.id === this.ClientePadrao?.id) return true;
			return false;
		},

		async selecionarCliente(cliente) {
			if(cliente.ativo != "A"){
				return this.$store.commit(
					"relatarErro", 
					{
						mensagem: "O cliente seleiconado esta inativo. Verifique!"
					}
				);
			}
			if (
				((this.verificaClientePadrao(cliente)) 
				&& (this.$route.query.tipoVenda == "C" || (!this.listarClientes ? this.listarClientes.selecionarCliente : false))) 
				&& !this.Usuario.condicionalConsumidorFinal
			){
				return this.$store.commit("relatarErro", {mensagem: "Não é possível vender condicional para consumidor final."});
			}
			if (!cliente.autorizado && (!this.verificaClientePadrao(cliente))) {
				if (
					!this.$route.query.autorizacao 
					&& (this.$route.params.clienteDevedor?.cliente.id != cliente.id)
				) {
					if ((!this.verificaClientePadrao(cliente)) && (cliente.id != this.Venda.cliente.id)) {
						if (this.listarClientes != true && typeof this.listarClientes == "object") {
							cliente.alterarTipoVenda = true;
						}
					}
				}
			}
			if (this.verificaClientePadrao(cliente)) {
				this.cadastrandoCliente = cliente;
				if (typeof this.listarClientes == "object") return;
				this.listarClientes = true;
			} else {
				if (this.Usuario.condicionalConsumidorFinal) {
					await this.alterarClienteVenda(cliente);
				} else if(this.$route.query.tipoVenda == "C" && this.verificaClientePadrao(cliente)) {
					return this.$store.commit("relatarErro", {mensagem: "Não é possível vender condicional para consumidor final."});
				} else {
					await this.alterarClienteVenda(cliente);
				}
			}
			// const liberado = await this.verificarSituacaoCliente(cliente);
			this.alterarClienteVenda(cliente);

		},

		selecionarVendedor(vendedor){
			this.$store.commit("atualizarVendedorVenda", vendedor);
			if (this.listarVendedores != true) {
				if (this.listarVendedores.finalizar) {
					this.listarVendedores = false;
					return this.$bus.$emit("finalizarVenda", this.itensVenda);
				}
			}
			this.listarVendedores = false;
			document.getElementById("js-id-produto").focus();
			this.$bus.$emit("atualizadoConsumidorOuVendedor");
		},

		fecharModal(){
			if (this.$route.query.tipoVenda == "C" && ((this.Venda.cliente.id === this.ClientePadrao?.id) && !this.$store.state.Usuario.condicionalConsumidorFinal)){
				return this.$router.go(-1);
			}
			if((this.$store.state.Venda.cliente.id === this.ClientePadrao?.id) && !this.Usuario.condicionalConsumidorFinal && !this.$route.query.trocarProdutos && this.$route.query.tipoVenda != "S"){
				this.alterarTipoVenda("S");
			}
			this.listarProdutos = false;
			this.listarClientes = false;
			this.listarVendedores = false;
			this.listarCaixas = false;
			this.listarVendas = false;
		},

		async realizarTransferenciaEstoque(){
			const empresa = this.empresaComSaldo;
			const qtdTransferente = Number(this.qtdTransferir.replace(",", "."));
			const body = {
				cdempresadestino: this.Empresa.id,
				cdempresaorigem: empresa.cdempresa,
				cdusuario: this.$store.state.Usuario.id,
				data: dateFormatISO({date: null, format: "ISOdate"}),
				produtos: [
					{
						cdseq: 1,
						cdProduto: empresa.cdProduto,
						custoProduto: empresa.custo || 0,
						custoFixoProduto: empresa.custofixo || 0,
						descontoProduto: empresa.descvista || 0,
						margemProduto: empresa.markup || 0,
						qtdTransferir: qtdTransferente,
						valorVista: empresa.vlrvista || 0,
						valorPrazo: empresa.pontoequi || 0
					}
				],
				tipo: "N",
				valorcusto: false,
				valorvenda: false,
			};
			if(qtdTransferente === 0) {
				return this.$store.commit("relatarErro", {
					mensagem: "Não é possivel transferir uma quantidade igual a zero"
				});
			}
			if(qtdTransferente > empresa.saldo){
				return this.$store.commit("relatarErro", {mensagem: "Não é possivél transferir uma quantidade maior que o saldo em estoque."});
			}
			
			const { transferencia, data } = await this.realizarTransferenciaProdutos(body);		
			const produto = await this.$store.dispatch("selecionarProdutoPorId", {id: empresa.cdProduto});
			if(transferencia && !transferencia?.error){
				produto.quantidade = Number(1.0);
				this.adicionarItem(produto);
			}
			if(data && data.mensagem && !data.error){
				this.transferirEstoque = false;
				this.$store.commit("relatarErro", {mensagem: `Transferência ${transferencia?.id ? `cód. ${transferencia?.id}` : ""} cadastrada com sucesso!`, sucess: true,});
				return;
			}
		},

		async realizarTransferenciaProdutos(body) {
			try {
				const transferencia = await this.$store.dispatch("cadastrarTransferencia", body);
				const data = await this.$store.dispatch("finalizarTransferencia", {...transferencia, cdtransferencia: transferencia.id, produtos: transferencia.itens});

				return {
					transferencia,
					data
				};
			} catch (error) {
				console.error("Erro Na transferencia: ", error);
			}
		},

		verificarTransferencia(item){
			try {
				if(!item) return;
				if(this.Empresa.transfereProdutoAutomatico){
					if(item?.saldo <= 0){
						if(item.saldoOutrasEmpresas?.length === 0){
							this.$store.commit("selecionarProduto", null);
							throw new Error( "O produto está sem estoque, favor verificar!");
						}  else {
							item.saldoOutrasEmpresas?.forEach(empresa => {
								if(empresa.saldo > 0){
									this.empresasTransferente.push(empresa);
									this.empresaComSaldo = empresa; 
								}
							});
							if(this.empresasTransferente?.length === 0 && !this.$store.state.Usuario.permiteVenderSaldoNegativo){	
								throw new Error("O produto está sem estoque em todas empresas, favor verificar!");
							}
							if(typeof this.qtdTransferir != Number){
								this.qtdTransferir = 1;
							}
							this.qtdTransferir = Number(this.qtdTransferir || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						}
						if(item.tipo === "V") {
							const empresaComSaldo = item.saldoOutrasEmpresas.find(p => p.saldo > 0).cdempresa;
							this.$store.state.produtoSelecionado.cdEmpresa = empresaComSaldo;
							return false;
						}
						this.$store.commit("relatarErro", {
							mensagem: "O produto está sem saldo, deseja transferir de outra empresa?", 
							tipo: "polar",
							item: {transferirProduto: true}
						});
						return false;
					}
				}
				return true;
			
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
				return false;
			}
		},

		podeVenderComSaldoNegativo(novoItem) {
			if (!this.$store.state.Usuario.permiteVenderSaldoNegativo) {
				if(!this.verificarTransferencia(novoItem)) return false;
				let qtdExistente = 0;
				this.itensVenda.forEach(produto => {
					if ((produto.produto?.id == novoItem?.id) && produto.cdTipo != "T"){
						qtdExistente += Number(produto.quantidade);
					}
				});
				if (((novoItem?.quantidade > novoItem?.saldo) || (Number(novoItem.quantidade + qtdExistente) > novoItem.saldo ))){
					setTimeout(() => {
						this.$store.commit("relatarErro", {mensagem: "A quantidade informada é maior que a que está em estoque, favor verificar!"});
					}, 50);
					this.$bus.$emit("lancamento/cancelarEdicao");
					this.seq = -1;
					return false;
				}
			}
			return true;
		},			
		//novoItem
		autorizouItemCondicional () {
			let liberacao = this.Venda.Liberacao;
			if (!liberacao) liberacao = {};
			liberacao.produto = {
				liberado: true,
			};
			this.solicitouAutorizacao = false;
			this.$store.state.Venda.Liberacao = liberacao;
		},

		autorizouTipoVenda (cdTipo) {
			let liberacao = this.Venda.Liberacao; 
			if (!liberacao) liberacao = {};
			if (!liberacao.cliente) liberacao.cliente = {};
			liberacao.cliente = {
				naoLibera: false,
				liberado: true,
			};
			this.alterarTipoVenda(cdTipo);
		},

		async clienteDevendo(cliente){
			let situacaoCliente = await this.verificarSituacaoCliente(cliente);
			const clienteDevedor = cliente || this.$store.state.Venda.cliente;
			let controle = false;
			if (clienteDevedor?.classificacao?.limitecondicional == -1){
				controle = false;
			}
			if (((situacaoCliente?.diasvencido || 0) > (this.$store.state.Empresa.carenciaCliente || 30) 
				&& !cliente?.liberado)){
				controle = true;
			}

			return controle;
		},

		async clienteTemLimiteCondicional(novoItem){
			const situacaoCliente = this.$store.state.situacaoCliente;
			let limiteCondicionalClassificacao = situacaoCliente?.cliente?.classificacao?.limitecondicional;
			let limiteCondicional = situacaoCliente?.limiteCondicional || null;
			// if(this.$route.query?.alteracao){
			// 	limiteCondicional = this.$store.state.Venda.cliente?.classificacao.limitecondicional;
			// }

			let quantidadeItens = 0;
			this.itensVenda.forEach((item, index) => {
				if (this.seq !== index) {
					if(item.situacao == "A"){
						quantidadeItens += item.quantidade;
					}
				}
			});
			
			quantidadeItens += (novoItem?.quantidade || 0);
			if(limiteCondicional != -1){
				if(quantidadeItens > limiteCondicional){
					return false;
				}
			} 
			if(limiteCondicionalClassificacao === -1){
				return true;
			} 
			if(limiteCondicional < 1){
				return false;
			}
			return true;
		},
		//Verificar clientae
		async verificarSituacaoCliente(cliente){
			const liberado = await this.$store.dispatch("verificaSituacaoCliente", (cliente || this.$store.state.Venda.cliente));
			if(liberado) return liberado;
			return false;
		},

		async autorizaVenderCondicional(novoItem, cliente){
			const usuarioSistema = this.Usuario;

			const clienteDevedor = cliente || this.Venda.cliente;

			const liberado = await this.verificarSituacaoCliente(clienteDevedor);
			
			const mudouCliente = clienteDevedor?.id && (!this.verificaClientePadrao(clienteDevedor));
			
			const liberaCliente = this.Usuario.liberaCliente;

			let liberacao = this.$store.state.Venda.liberacao;
			if (liberacao && (!mudouCliente)) {
				if (liberacao?.produto?.liberado) return true;
				if (liberacao?.cliente?.liberado) return true;
			}
			if (mudouCliente) {
				const liberado = await this.verificarSituacaoCliente(cliente);
				if (!liberado) return false;
			}
			let situacaoCliente = this.$store.state?.situacaoCliente;
			if(!liberado) return false;

			if(!usuarioSistema.liberaCliente){
				if(this.clienteDevendo(clienteDevedor)){
					return this.solicitarLiberacaoCliente({
						novoItem: novoItem,
						situacaoCliente: situacaoCliente,
						usuario: usuarioSistema,
						clienteDevedor: clienteDevedor
					});
				}
			}

			if(!this.verificaClientePadrao(cliente || clienteDevedor)){
				if(((!await this.clienteTemLimiteCondicional(novoItem) || !liberaCliente))){
					this.podeAdicionarItem(novoItem, cliente);
				}
			}
			// if(cliente?.alterarTipoVenda){
			// 	this.alterarTipoVenda(cliente?.cdTipo ? cliente?.cdTipo : "C");
			// }
			// if ((cliente?.alterarTipoVenda && (this.$store.state.Venda.cliente?.id != cliente?.id)) && !liberaCliente) {
			// 	this.alterarTipoVenda("C");
			// }
			return true;
		},
		//Autorizar Cliente se nao tiver liberacaoCliente
		async solicitarLiberacaoCliente({novoItem, situacaoCliente, usuario, clienteDevedor, cupomDados}){
			let liberacao = this.Venda?.Liberacao;
			if(!liberacao?.cliente?.liberado || !clienteDevedor?.autorizado){
				if( (situacaoCliente?.diasvencido? situacaoCliente.diasvencido : 0) > 0){
					setTimeout(() => {
						this.$store.commit("relatarErro", {
							mensagem: `Cliente com ${situacaoCliente?.diasvencido} dias em atraso.
							${!usuario.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
							tipo: !usuario.liberaCliente ? "polar" : "error",
							item: {
								cupomDados,
								liberarCliente: this.clienteDevendo(clienteDevedor) ? true : false,
								liberarTipoVenda: !novoItem ? true : false,
								solicitarAutorizacao: usuario.liberaCliente ? false : true,
								clienteDevedor,
								...situacaoCliente,
								produto: novoItem,
								mensagem: `${usuario.nome} deseja autorização para liberar condicional para o cliente ${clienteDevedor.nome}. Cliente possui ${this.$store?.state?.situacaoCliente?.diasvencido} dias em atraso!`,
								acao: (usuario) =>  {
									this.solicitouAutorizacao = false;
									if (!usuario.liberaCliente) {
										return this.$store.commit("relatarErro", {
											mensagem: "Usuário sem permissão para liberar cliente!",
										});
									}
									if(novoItem){
										this.autorizouItemCondicional(novoItem);
									}
									if(!this.verificaClientePadrao(clienteDevedor)){
										this.autorizouCliente(usuario);
										// this.alterarClienteVenda(clienteDevedor);
										this.cadastrandoCliente = false;
										this.listarClientes = false;
									}
								},
								acaoCancelar: () => {
									this.solicitouAutorizacao = false;
									if(this.$route.query.tipoVenda != "S"){
										this.alterarTipoVenda("S");
										this.$bus.$emit("lancamento/cancelarEdicao");
										this.seq = -1;
									}
									if(clienteDevedor.id != this.Venda.cliente.id){
										this.negouCliente(clienteDevedor);
									}
								}
							}
						});
					}, 50);
				}
			}
			return true;
		},
		incluirItemVenda (item) {
			if(item?.produto?.servprod == "S" ){
				return this.$store.commit("inserirItemVenda", {
					...item,
					descricao: item.descricao,
					servprod: item?.produto?.servprod,
					vendaOrigem: this.$route.query.baixarCondicional && this.$route.query.alteracao ? this.$store.state.indexVendaOrigem : null
				});
			}
			this.$store.commit("inserirItemVenda", {
				...item,
				vendaOrigem: this.$route.query.baixarCondicional && this.$route.query.alteracao ? this.$store.state.indexVendaOrigem : null
			});
			setTimeout(() => {
				document.getElementById("tabela-produto-scroll").scrollTop = (document.getElementById("tabela-produto-scroll").scrollHeight);
				this.$store.state.produtoSelecionado = null;
			},50);
			this.$bus.$emit("lancamento/cancelarEdicao");
			this.seq = -1;
		},

		async atualizarItemVenda (item) {
			if (!item.valorUnitario){
				item.valorUnitario = this.itensVenda[this.seq].valorUnitario;
			}
			this.itensVenda[this.seq].descricao = item.descricao;
			this.itensVenda[this.seq].produto.descricao = item.descricao;
			this.itensVenda[this.seq].quantidade = item.quantidade;
			this.itensVenda[this.seq].valorUnitario = item.valorUnitario;
			this.$bus.$emit("lancamento/cancelarEdicao");
			this.seq = -1;
		},
		cancelarServico(){
			this.$bus.$emit("lancamento/cancelarEdicao");
			this.seq = -1;
			this.$store.state.produtoSelecionado = null;
			this.servico = false;
			this.ncmSelecionado = {};
		},
		async atualizarProduto(produto){
			let formacaoPreco = {};
			formacaoPreco[this.$store.state.Empresa.id] = new FormacaoPreco({
				...produto,
			});
			await this.$store.dispatch("formarPreco", {formacaoPreco: formacaoPreco, id: produto.id});
			this.$store.state.produtoSelecionado = null;
		},
		
		async adicionarItem(novoItem){
			if(novoItem.servprod == "S"){
				return this.verificarServico(novoItem);
			}
			this.itemAdicionado = novoItem;
			if (novoItem.ativo != "A") {
				return setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: `O produto ${novoItem.id} ${novoItem.descricao} está inativo, favor verifique o cadastro do produto!`
					});
				}, 50);
			}
			
			if (!this.podeVenderComSaldoNegativo(novoItem)) return;
			if (this.$route.query?.tipoVenda == "C" && !this.verificaClientePadrao(this.Venda.cliente)){

				if(!this.podeAdicionarItem(novoItem)) return;
				return this.incluirItemVenda(novoItem);
			}
			this.incluirItemVenda(novoItem);	
		},
		async podeAdicionarItem(novoItem, cliente){
			const liberaCliente = this.$store.state.Usuario.liberaCliente;
			const clienteDevedor = cliente || this.Venda.cliente;
			const usuarioSistema = this.$store.state.Usuario;
			let liberacao = this.Venda.Liberacao;
			if (!liberacao) liberacao = {};
			if(((!await this.clienteTemLimiteCondicional(novoItem ? novoItem : null)) && this.$route.query.tipoVenda == "C" && !this.$route.query.baixarCondicional) && (!liberacao?.produto?.liberado) ){
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem:`Cliente atingiu o limite de produtos em condicional.
						${!liberaCliente ? "Deseja solicitar autorização?" : "Deseja Continuar?"}`,
						tipo: "polar",
						item: {
							produto: novoItem,
							liberarProduto: novoItem ? true : false,
							solicitarAutorizacao: !liberaCliente ? true : false,
							liberarTipoVenda: true,
							clienteDevedor,
							...this.$store.state?.situacaoCliente,
							mensagem: `${usuarioSistema.nome} deseja autorização para lançar produtos no condicional para o cliente ${clienteDevedor.nome}. Cliente possui limite de ${this.$store.state?.situacaoCliente?.limiteCondicional} peças para condicional!`,
							acao: (usuario, liberaProduto, cliente) =>  {
								this.solicitouAutorizacao = false;							
								if (!usuario.liberaCliente) {
									return this.$store.commit("relatarErro", {
										mensagem: "Usuário sem permissão para liberar cliente!",
									});
								}
								if(novoItem || liberaProduto){
									this.autorizouItemCondicional(novoItem);
								} else {
									this.autorizouTipoVenda("C");
								}
								if(clienteDevedor.id != this.$store.state.Venda.cliente.id){
									this.autorizouCliente(usuario);
									this.alterarClienteVenda(cliente);
									this.cadastrandoCliente = false;
									this.listarClientes = false;
								}
							},
							acaoCancelar: () => {
								this.solicitouAutorizacao = false;
								if(this.$route.query.tipoVenda != "S"){
									this.alterarTipoVenda("S");
									this.$bus.$emit("lancamento/cancelarEdicao");
									this.seq = -1;
								}
								if(clienteDevedor.id != this.Venda.cliente.id){
									this.negouCliente(clienteDevedor);
								}
							}
						}
					});
				}, 50);
				return true;
			}
		},

		async transferirProdutoAutomatico(item) {
			try {
				const body = {
					cdempresadestino: this.Empresa.id,
					cdempresaorigem: item.cdEmpresa,
					cdusuario: this.$store.state.Usuario.id,
					data: dateFormatISO({date: null, format: "ISOdate"}),
					produtos: [
						{
							cdseq: 1,
							cdProduto: item?.id ? item.id : item.codinter,
							custoProduto: item.custo || 0,
							custoFixoProduto: item.custofixo || 0,
							descontoProduto: item.descvista || 0,
							margemProduto: item.markup || 0,
							qtdTransferir: 0,
							valorVista: item.vlrvista || 0,
							valorPrazo: item.pontoequi || 0
						}
					],
					tipo: "N",
					valorcusto: false,
					valorvenda: false,
				};
				return await this.realizarTransferenciaProdutos(body);
			} catch (error) {
				console.error("Erro ao Invocar transferencia do produto:", error);
			}
		},

		async atualizarItem({novoItem}){
			if (!this.podeVenderComSaldoNegativo(novoItem)) return;
			if (this.$route.query.tipoVenda == "C") {
				if(!this.podeAdicionarItem(novoItem)) return;
			}
			this.atualizarItemVenda(novoItem);
		},

		editarItemVenda(item){
			this.$store.state.editarProduto = true;
			this.seq = item.seq - 1;
			this.$bus.$emit("selecionarItem", {...item});
		},

		removerItemVenda(index){
			this.$store.commit("selecionarProduto", null);
			this.$bus.$emit("lancamento/cancelarEdicao");
			this.seq = -1;
			this.itensVenda.splice(index, 1);
			this.itensVenda.forEach((item, idx) => {
				if (idx >= index){
					item.seq--;
				}
			});
			this.$bus.$emit("selecionarItem", {id: ""});
		},
		cancelarVenda(){
			this.$store.state.Venda = null;
			this.$store.state.itensVenda = [];
			this.$store.state.produtoSelecionado = null;
			this.$store.state.situacaoCliente = null;
			this.$store.commit("selecionarProduto", null);
			if ((!this.$store.state.Usuario.supervisor && !this.$store.state.Usuario.caixa) && !this.$store.state.Usuario.permiteBaixarCondicional){
				return this.$store.dispatch("logOut");
			}
			this.$router.replace({name: "Principal"});
		},

		async eventosTeclado(e){
			if(e.key === "Tab"){
				e.preventDefault();
			}
			if (e.key === "F4"){	
				e.preventDefault();
				if (this.$route?.query?.baixarCondicional && !this.$store.state.inserirProduto) return;
				if(this.listarProdutos) return this.fecharModal();
				return this.listarProdutos = true;
			}
			if (e.key === "F2") {
				e.preventDefault();
				this.fecharModal();
				if (this.$route.query.baixarCondicional || this.$route.query.trocarProdutos) {
					return;
				}
				return this.listarClientes = true;
			}
			if (e.code == "KeyT" && e.altKey && this.$route.query.trocarProdutos){
				this.listarVendas = !this.listarVendas;
				if (!this.listarVendas) {
					this.vendaSelecionada = null;
				}
			}
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				
				if (this.listarClientes || this.cadastrandoCliente || this.autenticacaoConfig) return;
				this.inserirIdentificador = false;
				return this.finalizarVenda(this.itensVenda);
			}
			if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				if(this.servico) return this.cancelarServico();
				if (this.listarClientes || this.cadastrandoCliente || this.autenticacaoConfig) return;
				return this.cancelarVenda();
			}
			
		},
		
		alterarTipoVenda(cdTipo) {
			if (this.$route.query.baixarCondicional) return;
			const btn = document.getElementById("tipoVenda");
			if (cdTipo == "C"){
				if(!this.verificaClientePadrao(this.Venda.cliente)){
					this.$store.commit("alterarTipoVenda", cdTipo);
					if(!this.autorizaVenderCondicional(null, this.Venda.cliente)) return;
					if(!this.podeAdicionarItem()) return;
				}
				if (!this.$route.query.autorizacao) {
					if (!this.verificaClientePadrao(this.Venda.cliente)) {
						if (this.$store.state.situacaoCliente?.limiteCondicional == 0 || this.Venda?.cliente.limitecredito == 0) {
							if (this.$route.query.tipoVenda == "C") {
								this.$store.state.Venda.vistaPrazo = true;
								btn.innerText = "Condicional";
								this.$store.commit("alterarTipoVenda", "S");
							}
						}
					}
				}
				this.$store.state.Venda.vistaPrazo = false;
				btn.innerText = "Venda";
			} else {
				this.$store.state.Venda.vistaPrazo = true;
				btn.innerText = "Condicional";
			}
			this.$store.state.carregando = true;
			this.$store.commit("alterarTipoVenda", cdTipo);
			this.$bus.$emit("recalcularValores", (cdTipo == "C" || this.$store.state.Venda?.cliente.classificacao?.bloqueiavendaprazo) ? "prazo" : "vista", "altera-tipo-venda-lancamento");
			document.getElementById("js-id-produto").focus();
		},

		async selecionarVenda(venda){
			try {
				const data = await this.$store.dispatch("buscarVendaPorId", {
					cdVenda: venda.cdVenda, 
					cdEmpresa: this.$store.state.Empresa.id
				});
				const existePromocao = data.itens.filter(item => item.cdpromocao).length > 0;
				
				for (let index = 0; index < data.itens.length; index++) {
					if (data.itens[index].cdpromocao) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.itens[index].percpromocao / 100));
					} else if (data.itens[index].percdesc) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.itens[index].percdesc / 100));
					} else if (!existePromocao) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.descontoPercentual / 100));
					}
				}
				this.vendaSelecionada = data;
				this.vendaSelecionada.itens = data.itens.filter(item => item.cdTipo == "S");
				setTimeout(() => {
					document.getElementsByClassName("troca")[0].focus();
				}, 10);
			} catch (error) {
				console.log(error);
			}
			// const existePromocao = venda.itens.filter(item => item.cdpromocao).length > 0;
			// for (let index = 0; index < venda.itens.length; index++) {
			// 	let item = venda.itens[index];
			// 	if (item.cdpromocao) {
			// 		item.valorUnitario = (item.valorUnitario - (item.valorUnitario * item.percpromocao /100));
			// 	} else if (item.percdesc) {
			// 		item.valorUnitario = (item.valorUnitario - (item.valorUnitario * item.percdesc /100));
			// 	} else if (!existePromocao){
			// 		item.valorUnitario = (item.valorUnitario - (item.valorUnitario * venda.descontoPercentual /100));
			// 	}
			// }
			// this.vendaSelecionada = venda;
		},
		preTrocaProduto(produto){
			this.modalVistaPrazoTroca = true;
			this.produtoModalVistaPrazoTroca = produto;
		},
		fecharModalVistaPrazoTroca(){
			this.modalVistaPrazoTroca = false;
		},
		tipoValorTroca(tipo){
			this.produtoModalVistaPrazoTroca.tipoValorTroca = tipo;
			this.modalVistaPrazoTroca = false;
			this.trocarProduto(this.produtoModalVistaPrazoTroca);
		},
		trocarProduto(produto) {
			produto.cdTipo = "T";
			produto.situacao = null;
			produto.valorUnitario = produto.tipoValorTroca && produto.tipoValorTroca == "p"? produto.valorPrazo:produto.valorVista;
			produto.quantidade = 1;
			if (!produto["produto"]) {
				produto["produto"] = {...produto};
			}
			produto.seq = (this.$store.state.itensVenda.length +1);
			this.$store.state.itensVenda.push(produto);
			this.listarVendas = false;
			this.vendaSelecionada = null;
		},

		trocarProdutos(produtos) {
			let seq = this.$store.state.itensVenda.length;
			let produtosTroca = [];
			const itensVenda = this.$store.state.itensVenda;
			produtos.forEach( item => {
				item.cdTipo = "T";
				item.situacao = null;
				item.seq = (seq +1);
				item.cdVenda = this.vendaSelecionada.cdVenda;
				seq++;
				produtosTroca.push({...item});
			});
			let qtdItensIguaisVenda = 0;
			let qtdItensAdicionadoTroca = 0;
			let idProdutoJaAdicionado = [];
			produtosTroca.forEach(prod => {
				const itensAdicionadoTrocaIguais = itensVenda.filter(
					item => item.id === prod.id && item.cdVenda === this.vendaSelecionada.cdVenda
				);
				const itensVendaIguais = this.vendaSelecionada.itens.filter(
					item => item.id === prod.id
				);
				if(itensVendaIguais?.forEach) {
					itensVendaIguais.forEach(item => {
						qtdItensIguaisVenda += item?.quantidade;
					});
				}
				if(itensAdicionadoTrocaIguais?.forEach) {
					itensAdicionadoTrocaIguais.forEach(item => {
						qtdItensAdicionadoTroca += item.quantidade;
					});
					const produtoAdicionandoTroca = itensVenda.find(item => item.id === prod.id);
					if(produtoAdicionandoTroca) {
						qtdItensAdicionadoTroca += produtoAdicionandoTroca.quantidade;
						idProdutoJaAdicionado.push(produtoAdicionandoTroca.id);
					}
				}
			});
			if(qtdItensAdicionadoTroca > qtdItensIguaisVenda) {
				this.$store.commit("relatarErro", {
					mensagem: `Os produto(s) ${idProdutoJaAdicionado} ja foram adicionados à troca por favor verifique!`
				});
				document.querySelectorAll(".troca").forEach((input) => {
					if(input.checked) {
						input.checked = false;
					}
				});
				return;
			}
			itensVenda.push(...produtosTroca);
			this.listarVendas = false;
			this.vendaSelecionada = null;
		},

		// Autorização Cliente
		autorizouCliente(usuario) {
			let liberacao = this.Venda.Liberacao;
			const cliente = this?.autenticacaoConfig?.cliente;
			try {
				this.solicitouAutorizacao = false;
				if (!usuario) {
					this.negouCliente(cliente);
				} else {
					if (!usuario.liberaCliente) throw new Error("Usuário sem permissão para liberar cliente!");
					if (!liberacao) {
						liberacao = {};
					}
					liberacao.cliente = {
						...cliente,
						liberado: true,
						cdusuarioliberacao: usuario.id
					};
					this.solicitouAutorizacao = false;
					cliente.autorizado = true;
					this.$store.state.Venda.Liberacao = liberacao;
					this.selecionarCliente(cliente);
					this.Venda.somenteVista = false;
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message});
				this.negouCliente(cliente);
			}
		},
		negouCliente(cliente) {
			this.solicitouAutorizacao = false;
			let liberacao = this.Venda.Liberacao;
			if (!liberacao) liberacao = {};
			liberacao.cliente = {};
			this.Venda.somenteVista = true;
			this.alterarClienteVenda({...cliente, autorizado: false, bloqueio: true});
			setTimeout(() => {
				this.$store.commit("relatarErro", {mensagem: "Não será possível selecionar uma condição de pagamento a prazo!", alerta: true});
			}, 100);
		},
		async polarConfirmado(item) {
			if(item.transferirProduto){
				this.transferirEstoque = true;
			}
			if(item.liberarCliente){
				if(item.solicitarAutorizacao){
					this.solicitouAutorizacao = true;
					this.autenticacaoConfig.mensagem = item.mensagem;
					this.autenticacaoConfig.cliente = item.cliente;
					this.autenticacaoConfig.autorizar = (usuario) => {
						item.acao(usuario, {liberarProduto: false}, item.cliente || item.clienteDevedor);
					};
					this.autenticacaoConfig.negar = () => {item.acaoCancelar();};
				}
			}
			if(item.liberarProduto && item.produto){
				if(!item.solicitarAutorizacao && this.Usuario.liberaCliente){
					let liberacao = this.Venda.Liberacao;
					if (!liberacao) liberacao = {};
					liberacao.produto = {
						liberado: true,
					};

				}
				if(item.solicitarAutorizacao && item.liberarProduto){
					this.solicitouAutorizacao = true;
					this.autenticacaoConfig.produto = item.produto;
					this.autenticacaoConfig.mensagem = item.mensagem;
					this.autenticacaoConfig.cliente = item.cliente;
					this.autenticacaoConfig.autorizar = (usuario) => {
						item.acao(usuario, {liberarProduto: true}, item.cliente);
					};
					this.autenticacaoConfig.negar = () => {item.acaoCancelar();};
				}
			}
			if(item.liberarTipoVenda && !this.Usuario.liberaCliente) {
				if(item.solicitarAutorizacao && item.liberarTipoVenda){
					this.solicitouAutorizacao = true;
					this.autenticacaoConfig.produto = item.produto;
					this.autenticacaoConfig.mensagem = item.mensagem;
					this.autenticacaoConfig.cliente = item.cliente;
					this.autenticacaoConfig.autorizar = (usuario) => {
						item.acao(usuario, {liberarProduto: true}, item.cliente);
					};
					this.autenticacaoConfig.negar = () => {item.acaoCancelar();};
				}
			}
			
			if(this.Usuario.liberaCliente){
				if(item.liberarTipoVenda || item.liberarProduto){
					item.clienteDevedor.liberado = true;

				}
			}

			if (item.condicional) {
				if (item.selecionarCliente) {
					this.alterarTipoVenda(item.cdTipo);
					return this.listarClientes = {selecionarCliente: true};
				}
				if (this.$store.state.Venda.somenteVista && !this.$route.query.autorizacao && (!this.$store.state.situacaoCliente?.limiteCondicional && !this.$store.state.situacaoCliente?.limiteCondicional != -1 && !this.$store.state.situacaoCliente?.limiteCondicional != 0)) {
					setTimeout(() => {
						this.$store.commit("relatarErro", {
							mensagem: `Cliente com pendências${(!this.$store.state.Usuario.liberaCliente) ?
								", permitido vender somente a vista. Deseja solicitar autorização para vender a prazo?" : "! Verifique!"}`,
							tipo: (!this.$store.state.Usuario.liberaCliente) ? "polar" : null,
							item: {
								...this.$store.state.situacaoCliente,
								liberarCondicional: true,
								liberaCliente: item.liberaCliente,
								clienteDevedor: {...this.$store.state.Venda.cliente},
								mensagem: `${this.$store.state.Usuario.nome} deseja autorização para vender a prazo para o cliente ${this.$store.state.Venda.cliente.nome}. Cliente com pendências!`,
							}
						});
					}, 50);
					if ((!this.$store.state.Usuario.liberaCliente) && (!this.$store.state.Usuario.condicionalConsumidorFinal && (this.$store.state.ClientePadrao.id != this.$store.state.Venda.cliente.id))) return;
				}
				if(item.cdTipo == "C"){
					if(this?.$store?.state?.Venda?.liberacao){
						if(this.$store.state.Venda.liberacao.cliente?.liberado == true){
							return this.alterarTipoVenda("C");
						}
					}
					if (
						!await this.autorizaVenderCondicional(null, {
							...this.$store.state.Venda.cliente,
							alterarTipoVenda: true,
							cdTipo: item.cdTipo
						})
					) return;
				}
				return this.alterarTipoVenda(item.cdTipo);
			}			
			if (item.liberaCliente || item.liberarCondicional) {
				this.listarClientes = false;
				this.solicitouAutorizacao = true;
				this.autenticacaoConfig.cliente = item.clienteDevedor;
				this.autenticacaoConfig.mensagem = item.mensagem;
				this.autenticacaoConfig.autorizar = this.autorizouCliente;
				this.autenticacaoConfig.negar = () => {this.negouCliente(item.clienteDevedor);};
			}
		},
		polarCancelado(item) {
			if (item.liberarProduto) {
				this.$bus.$emit("lancamento/cancelarEdicao");
				this.seq = -1;
			}
			this.$store.state.produtoSelecionado = null;
			if(item.liberarCliente && item.cupomDados) {
				this.$store.commit("atualizarConsumidorVenda", this.$store.state.ClientePadrao);
				this.$forceUpdate();
				this.$bus.$emit("recalcularValores", "vista", "polar-cancelado-lancamento");
				return;
			}
			if (item?.liberarCondicional || item.condicional) return;
			if (item?.liberarTipoVenda) {
				if(typeof item.acaoCancelar == "function"){
					item.acaoCancelar();
				}
				return;
			}
			
			if (item.liberaCliente) {
				
				return this.negouCliente(item.clienteDevedor);
			}
		},

		mascaraFloat(e) {
			const regex = /[0-9,.]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascara(evento) {
			const id = evento.target.id;
			const input = document.getElementById(id);
			if(id == "nome") {
				return this.nome = this.nome.toUpperCase();
			}
			const v = input.value;
			var er = /[^-()0-9.]/;
			er.lastIndex = 0;
			if (er.test(v)) {
				this[id] = this[id].split(evento.data)[0];
			}
			if(isNaN(v[v.length-1])) { 
				this[id] = this[id].substring(0, v.length-1);
				return;
			}

			if (evento.data === null){
				if (v.length == 13 && v[8] != "-") {
					this[id] = `${this[id].slice(0, 8) + "-" + v[10] + v.slice(10) }`;
				}
				return;
			}
			if(id == "fone") {
				if (v.length == 1) {
					this[id] = "(" + evento.data;
				}
				if (v.length == 3) {
					this[id] += ")";
				}
				if (v.length == 4 && v.length -1 != ")") {
					this[id] = this[id].slice(0, 3) + ")" + evento.data;
				}
				if (v.length == 8) {
					if (v.length-1 == "-"){
						return;
					}
					this[id] += "-";
				}
				if (v.length == 9 && evento.data!= "-") {
					this[id] = this[id].slice(0, 8) + "-" + evento.data;
				}
				if (v.length == 14 && v[8] == "-") {
					this[id] = `${this[id].slice(0, 8)+ v[9] + "-" + v.slice(10)}`;
				}
			}
		},
		async salvouInformacoes(informacoes) {
			Object.keys(informacoes).forEach(key => {
				this.$store.state.Venda[key] = informacoes[key];
			});

			// NAO VALIDA DADOS DO CONSUMIDOR FINAL
			if(this.Usuario.solicitaNomeConsumidorFinal){
				this.$store.state.Venda.cliente.nome = informacoes?.nomeCliente; 
				// this.$store.state.Venda.cliente.id = informacoes?.cdCliente;
				// this.$bus.$emit("atualizadoConsumidorOuVendedor");
				// if(!this.verificaClientePadrao(this.Venda.cliente)) {
				// 	if(await this.clienteDevendo(this.$store.state.Venda.cliente)) {
				// 		this.solicitarLiberacaoCliente({
				// 			cupomDados: true,
				// 			clienteDevedor: this.$store.state.Venda.cliente,
				// 			situacaoCliente: this.$store.state.situacaoCliente,
				// 			usuario: this.$store.state.Usuario,
				// 		});
				// 		return;
				// 	}
				if (this.inserirIdentificador) {
					return this.finalizarVenda(this.itensVenda);
				}
				// }
			}
			if (informacoes.cdCliente) {
				const cliente = await this.$store.dispatch("buscarClientePorId", {id: informacoes.cdCliente, venda: true});
				this.$store.state.carregando = true;
				const form = this.montarPayload(informacoes, cliente.classificacao, cliente); 
				await this.$store.dispatch("atualizarCliente", form);
				this.$store.state.carregando = true;
				setTimeout(() => {
					this.$bus.$emit("atualizadoConsumidorOuVendedor");
					this.$store.state.carregando = false;
				},50);
			} else {
				this.$store.state.carregando = true;
				const classificacao = await this.$store.dispatch("buscarClassificacaoPadrao");
				if (classificacao?.erro || classificacao?.mensagem) {
					this.$store.commit("relatarErro", {
						mensagem: "Classificação pardão não definida, entre em contato com o suporte."
					});
					this.$store.state.carregando = false;
					return;
				}
				const form = this.montarPayload(informacoes, classificacao, {}); 
				const cliente = await this.$store.dispatch("adicionarCliente", form);
				if(cliente.erro){
					this.$store.state.carregando = false;
					return;
				}
				await this.$store.dispatch("buscarClientePorId", {id: cliente.id, venda: true});
				setTimeout(() => {
					this.$bus.$emit("atualizadoConsumidorOuVendedor");
					this.$store.state.carregando = false;
				},50);
			}
			if (this.inserirIdentificador) {
				this.finalizarVenda(this.itensVenda);
			}
		},
		montarPayload(informacoes, classificacao, cliente){
			const cadastro =  {
				id: informacoes.cdCliente ? informacoes.cdCliente : "",
				bairro: informacoes.bairroCliente ? informacoes.bairroCliente : "",
				cep: informacoes.cepCliente ? informacoes.cepCliente : "",
				cidade: {
					nomecidade: informacoes.cidadeCliente ? informacoes.cidadeCliente : "",
					uf: informacoes.ufCliente ? informacoes.ufCliente : "",
					cdcidade: informacoes.cdcidade,
				},
				classificacao: classificacao ? {...classificacao} : "",
				tipoPessoa: informacoes.tipoPessoa ? informacoes.tipoPessoa : "",
				fone: informacoes.foneCliente ? informacoes.foneCliente : "",
				cnpj: informacoes.cnpjCliente ? informacoes.cnpjCliente : "",
				endereco: informacoes.enderecoCliente ? informacoes.enderecoCliente : "",
				numero: informacoes.numeroEndereco ? informacoes.numeroEndereco : "",
				ierg: informacoes.ierg ? informacoes.ierg : "",
				nome: informacoes.nomeCliente ? informacoes.nomeCliente : "",
				dataNascimento: informacoes.dataNascimento,
				ativo: "A"
			};
			return {...cliente,...cadastro};
		},
		
		cancelouInformacoes(finalizar) {
			if(finalizar){
				this.finalizarVenda(this.itensVenda);
			}
			this.inserirIdentificador = false;
			this.$forceUpdate();
		},
		verificarAutorizacao() {
			if (this.$route.query.autorizacao) {
				if (this.$route.params.clienteDevedor && this.$route.params.liberaCliente) {
					this.selecionarCliente(this.$route.params.clienteDevedor.cliente);
				}
				if (this.$route.params.clienteDevedor?.liberarCondicional) {
					this.alterarTipoVenda("C");
					if (this.$route.params.clienteDevedor?.produto) {
						this.adicionarItem(this.$route.params.clienteDevedor.produto);
					}
				}
				this.$store.state.Venda.somenteVista = false;
			} else if (!this.$route.query.autorizacao && (this.$route.params.clienteDevedor)) {
				this.$store.state.Venda.somenteVista = true;
				delete this.$route.params.clienteDevedor;
			}
		},
		async preencherVendaState(){
			await this.alterarClienteVenda(this.clienteStorage);
			await this.selecionarVendedor(this.vendedorStorage);

			Object.keys(this.itensVendaStorage).forEach(pos => {
				this.adicionarItem({
					...this.itensVendaStorage[pos],
					...this.itensVendaStorage[pos].produto
				});
			});
		}
	},

	created(){
		if (!this.$store.state.Venda) {
			this.$store.commit("iniciarVenda");
		}
		this.$store.state.inserirProduto = !this.$route.query.baixarCondicional;
		this.$store.state.editarProduto = false;
		this.$store.state.LancarCusto = false;
		this.$store.commit("selecionarProduto", null);

	},

	mounted() {
		this.$store.dispatch("buscarContas");
		if(this.$store.state.Venda.cliente?.id != this.$store.state.ClientePadrao?.id){
			this.alterarClienteVenda(this.Venda.cliente);
		} else {
			if(this.$store.state.Empresa.tiposistema === "L") {
				this.$store.commit("atualizarConsumidorVenda", {
					...this.Venda.cliente,
					nome: this.Venda.nomeCliente,
				});
			}
		}
		if (!this.$route.query.tipoVenda) {
			this.alterarTipoVenda("S");
		}
		document.addEventListener("keydown", this.eventosTeclado);
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		
		if (this.$route.query.tipoVenda == "C" && this.$store.state.Venda.cliente.id == this.$store.state.ClientePadrao.id) {
			this.listarClientes = true;
		}
		this.verificarAutorizacao();
		if (!this.listarClientes && !this.listarVendedores) {
			document.getElementById("js-id-produto").focus();
		}
		if (this.existePromocao) {
			this.itensVenda.forEach(item => {
				if (item.promocaoAntiga) {
					item.cdpromocao = item.promocaoAntiga;
				}
				item.promocaoAntiga = null;
			});
		}
	},

	destroyed(){
		document.removeEventListener("keydown", this.eventosTeclado);
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	}
};
</script>

<style scoped>
#routerView {
	overflow: hidden;
}

#gridPrincipal {
	display: grid;
	grid-template-columns: 40vw 60vw;
	grid-template-rows: 25vh 65vh;
	margin: 5px 0px;
	flex-grow: 1;
}

#tabelaProdutos {
	grid-column: 2/3;
	grid-row: 1/3;
}

#identificadorVenda {
	grid-row: 1/2;
	grid-column: 1/2;
}

#painelControle {
	grid-row: 2/3;
	grid-column: 1/2;
}

i {
	font-size: 18pt;
	cursor: pointer;
	align-self: center;
	margin: auto;
}

.scrollable {
	height: 100%;
	overflow: auto;
}
.grow {
	flex-grow: 1;
}
.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 10px;
	gap: 10px;
}
.buttons .btn{
	width: 50%;
}
.modalTransferenciaContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 25px;
	justify-content: space-between;
	padding: 15px 10px;
}
.containerTransferente{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.transferir{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
}
.transferir div{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.transferir label{
	font-size: 14px;
}
.transferir input{
	width: 55px;
	height: 35px;
	text-align: center;
	border-top: none;
	border-left: none;
	border-right: none;
	outline: none;
}

.containerTableSelecionar{
	padding: 10px 10px;
}
.buttons{
	width: 100%;
}
.buttons .btn{
	width: 100%;
}

@media screen and (max-width: 930px) {

	#gridPrincipal {
		display: grid;
		position: relative;
		grid-template-rows: auto auto 160px;
		grid-template-columns: 1fr 1fr;
		margin: 0px auto;
		height: 100%;
		max-height: 92vh;
		overflow-y: auto;
	}

	#identificadorVenda {
		grid-row: 3/4;
		grid-column: 1/3;
		display: flex;
	}

	#painelControle {
		grid-row: 1/2;
		grid-column: 1/3;
		display: flex;
	}

	#tabelaProdutos {
		grid-row: 2/3;
		grid-column: 1/3;
		display: flex;
	}
}

@media screen and (max-width: 768px){
	.camposServicos{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}
@media screen and (max-width: 420px){
	.servicoItem input{
		width: 220px;
	}
	.buttons span{
		display: none;
	}
}

</style>
