import { Produto } from "./Produto";
import { dateFormatISO, dateFormatBR } from "../utils/dateformat";
const situacaoItem = {
	"devolvido": "D",
	"aberto": "A",
	"saida": "S",
	"baixado": "B"
};
const tipoItem = {
	"saida": "S",
	"condicional": "C",
	"troca": "T",
	"brinde": "B"
};
const condicaoPedido = {
	"devolvido": "D",
	"aberto": "A",
	"normal": "N",
	"baixado": "B"
};

export class ItemVenda {
	constructor(item) {
		try {
			this.situacao = item.situacao;
			this.cdCor = item.cdcor;
			this.cdGrade = item.cdgrade;
			this.seq = item.cdseq;
			this.cdTipo = item.cdtipo;
			this.descor = item.descor;
			this.valorUnitario = item.valorunit;
			this.quantidade = item.quantidade;
			this.cdpromocao = item.cdpromocao;
			this.percpromocao = item.percpromocao;
			this.percdesc = item.percdesc;
			this.custo = item.custo;
			this.descricao = item.descricao;
			this.id = item.cdproduto;
			this.qtdentregue = item?.qtdentregue || 0;
			if (item.produto) {
				this.produto = new Produto(item.produto);
				
				this.valorVista = this.produto.valorVista;
				this.valorPrazo = this.produto.valorPrazo;

				if (!this.custo) {
					this.custo = this.produto.custofixo || this.produto.custo;
				}
			} else {
				this.produto = new Produto({});
			}
			
			this.composicaoKit = item?.vendaProdutoKits?.length > 0 ? item.vendaProdutoKits.map(sugestao => new ComposicaoKit(sugestao)) : [];
		
		} catch (error) {
			console.error(error);
		}
	}
}

export class VendaTroca {
	cdVenda;
	nomeCliente;
	nfce;
	cdProduto;
	descricaoProduto;
	qtdProdutos;
	dataEmissao;
	valorUnitario;
	valorPago;

	constructor(venda){
		this.cdVenda =  venda.cdvenda;
		this.nfce = venda.nfce;
		this.nomeCliente =  venda.cliente;
		this.cdProduto =  venda.cdproduto;
		this.descricaoProduto =  venda.descricao;
		this.qtdProdutos =  venda.quantidade;
		this.dataEmissao =  dateFormatBR(venda.dataemissao.split("T")[0]);
		this.valorUnitario =  venda.valorUnitario;
		this.valorPago =  venda.valorPago;
	}
}

export class ItemVendaAPI {
	constructor(item, venda) {
		try {
			this.situacao = !item.situacao ? (item.cdTipo == "C" ? "A" : "S") : item.situacao;
			this.cdtipo = item.cdTipo || item.cdtipo || "";
			this.cdcor = item.cdCor || 0;
			this.cdgrade = item.cdGrade || 0;
			this.descor = item.descor || 0;
			this.cdseq = item.seq === 0 ? 0 : item.seq ? item.seq : item.cdseq;
			this.qtdentregue = item.qtdentregue || 0;
			if(item.produto){
				this.produto = {
					ativo: item.produto.ativo,
					cdfornecedor: item.produto.cdfornecedor,
					cdgrupo: item.produto.cdgrupo,
					cdlinha: item.produto.cdlinha,
					cdpai: item.produto.cdpai,
					cdproduto: item.produto?.id || item.produto.cdproduto,
					cdreferencia: item.produto.cdReferencia,
					codbarra: item.produto.codBarra,
					descricao: item.produto.descricao,
					unidade: item.produto.unidade,
					custo: item.produto.custo,
					servprod: item.produto.servprod,
					
					// ativo: payload.ativo,
					// id: payload.id,
					// cdfornecedor: payload.cdFornecedor,
					// cdgrupo: payload.cdsubgrupo,
					// cdlinha: payload.cdgrupo,
					// cdpai: payload.cdpai,
					// cdReferencia: payload.cdReferencia,
					// codBarra: payload.codBarra,
					// descricao: payload.descricao,
					// unidade: payload.unidade,
					// saldo: payload.saldo,
					// valorVista: Number(payload.valorVista),
					// valorPrazo: Number(payload.valorPrazo),
					// promocaoPrazo: payload.promocaoPrazo,
					// promocaoVista: payload.promocaoVista,
					// nomePromocao: payload.nomePromocao,
					// cdpromocao: payload.cdpromocao,
					// custo: payload.custo,
					// custofixo: payload.custofixo,
					// servprod: payload.servprod,
					// valorVistaAnterior: payload.valorVistaAnterior,
					// valorPrazoAnterior: payload.valorPrazoAnterior,
				};
			}
			this.observacao = `${item?.observacao || ""}`.toUpperCase() || "";
			this.composicao = item?.sugestoes?.map(sugestao => new ComposicaoKit(sugestao)) || item?.composicaoKit?.map(comp => new ComposicaoKit(comp));
			this.custo = item.custofixo || item.custo || 0;
			this.quantidade = item.quantidade || 0;
			this.valorunit = item.valorUnitario || item.valorunit || 0;
			this.cdpromocao = item.cdpromocao || 0;
			this.percpromocao = `${venda.beneficio}`.includes("Promocional") ? venda.valorVista ? item.produto.promocaoVista : item.produto.promocaoPrazo : null;
			this.percdesc = item.cdpromocao ? null : `${venda.beneficio}`.includes("Promocional") ? null : venda.descontoPercentual;
			this.desconto = item.cdpromocao ? null : `${venda.beneficio}`.includes("Promocional") ? null : Number((item.valorUnitario * venda.descontoPercentual / 100).toFixed(3) || 0);
		} catch (error) {
			console.error(error);
		}
	}
}

export class ComposicaoKit {
	constructor(comp) {
		this.cdprodutokit = comp?.id || comp?.cdprodutokit;
		this.custo = comp?.custo;
		this.cdseq = comp?.cdseq;
		this.descricao = comp?.descricao;
		this.quantidade = comp?.quantidade;
		this.unidade = comp.unidade;
		this.valorunit = comp?.valorunit || comp?.custo;
		this.multiplo = comp?.multiplo || 0;
	}
}

export class VendaFinanceiroChequeAPI {
	banco;
	agencia;
	conta;
	numerocheque;
	vencimento;
	nominal;
	valor;
	vlrcheque;
	situacao;
	cdlan;

	constructor(cheque, cdlan) {
		this.banco = cheque.banco;
		this.agencia = cheque.agencia;
		this.conta = cheque.conta;
		this.numerocheque = cheque.numeroCheque;
		this.vencimento = dateFormatISO({date: cheque.vencimentoCheque, format: "ISOdate"});
		this.nominal = cheque.nominal;
		this.valor = 0;
		this.vlrcheque = cheque.valorCheque;
		this.situacao = "A";
		this.cdlan = cdlan;
	}
}

export class VendaFinanceiroAPI {
	cddig = 0;
	cddoc;
	cdtipopgto;
	pagamento;
	datadoc = dateFormatISO({date: null, format: "ISOdate"});
	datavenc;
	databaixa = null;
	credito = 0;
	contarc = 0;
	observa;
	nsucartao;
	doccartao;
	cdbandeira;
	cheques = [];
	txid = null
	codprodutoconciliadora;
	vistaprazo;
	valorVista;
	cheque;
	cartao;
	qtdeParcelaCartao;
	contaCorrente;

	descricao;

	constructor(venda, financeiro) {
		this.cddoc = venda.cdVenda || "";
		this.datadoc = dateFormatISO({date: venda?.dataEmissao.split("T")[0] || this.datadoc, format: "ISOdate"}); 
		this.observa = financeiro.observacao ? financeiro.observacao : financeiro.observa || `${venda.cdVenda ? `Ref. Venda: ${venda.cdVenda}` : venda.observacao ? `Obs.: ${venda.observacao}` : `Feito em ${new Date().toLocaleString("pt-BR")}`}`,
		this.nsucartao = financeiro.nsucartao;
		this.doccartao = financeiro.doccartao;
		this.cdbandeira = financeiro.cdbandeira;
		this.cdtipopgto = financeiro.cdTipo || financeiro.cdTipoPgto || financeiro.cdtipo || financeiro.cdtipopgto;
		this.pagamento = financeiro.metodoParcela || financeiro.pagamento;
		this.txid = financeiro.txid;
		this.codprodutoconciliadora = financeiro.codprodutoconciliadora;
		this.valorVista = financeiro.valorVista;
		this.vistaprazo = financeiro.vistaprazo;
		this.cheque = financeiro.cheque;
		this.cartao = financeiro.cartao;
		this.qtdeParcelaCartao = Number(financeiro.qtdeParcelaCartao || 0);
		this.descricao = financeiro.descricao;
		this.cddig = financeiro.cddig || financeiro.cdDig || 0;
		this.contaCorrente = financeiro.contaCorrente;
		if ((financeiro.vistaprazo == 1 || financeiro.valorVista) || (financeiro.cheque == "S")) {
			this.credito = financeiro?.valorParcela || financeiro.credito;
			this.datavenc = this.datadoc;
			this.databaixa = venda?.dataEmissao.split("Z") [0] || this.datadoc; 
		} else {
			this.contarc = Number(`${financeiro?.valorParcela || financeiro?.contarc || ""}`.replace(",", "."));
			this.datavenc =  financeiro?.dataVenc ? financeiro?.dataVenc : dateFormatISO({date: financeiro.dataVencimento && financeiro.dataVencimento != null && financeiro.dataVencimento != undefined ? financeiro.dataVencimento.split("/").reverse().join("-") : null, format: "ISOdate"});
			this.databaixa = dateFormatISO({date: venda?.dataEmissao, format: "ISOhour"});
		}
		if (financeiro.cheque == "S") {
			this.cheques = financeiro?.cheques?.map((cheque, index) => (new VendaFinanceiroChequeAPI(cheque, (index+1))));
		}
	}
}
export class VendaAPI {
	cdcliente;
	cdempresa;
	cdvenda;
	cdusuariocaixa;
	cdusuarioliberacao;
	condicao;
	dataemissao = dateFormatISO({date: null, format: "ISOhour"}) //testar;
	dataentsaida = dateFormatISO({date: null, format: "ISOhour"}) //testar;
	desmoeda;
	desperc;
	identificador;
	cdIdentificador;
	emitirNota;
	cnpjCliente;
	ieCliente;
	nomeCliente;
	foneCliente;
	enderecoCliente;
	bairroCliente;
	cepCliente;
	cidadeCliente;
	ufCliente;
	nascimentoCliente;
	numeroCliente;
	via;
	valorComissao;
	itens;
	observ1;
	vendedor;
	vendaOrigem;
	troca;
	valorbruto;
	vlrcondicional;
	valorpago;
	baixarCondicional;
	financeiro = [];
	consorcio = [];
	impressaoRemota;

	mesa;
	contato;

	valorfrete;
	fretegratis;
	acrescimo;

	constructor(venda = new Venda({}), state) {
		// Identificação
		this.cdempresa = venda.cdEmpresa;
		this.cdvenda = venda.cdVenda;
		this.contato = venda.contato;
		this.cdusuariocaixa = venda.cdUsuarioCaixa;
		this.cdusuarioliberacao = venda.cdUsuarioLiberacao;
		this.cdIdentificador = venda.cdIdentificador;
		this.identificador = venda.identificador;
		this.dataemissao = venda.dataEmissao != null || venda.dataEmissao != undefined ? venda.dataEmissao && venda.dataEmissao.includes("Z") ? venda.dataEmissao.split("Z")[0] : venda.dataEmissao : this.dataemissao || this.dataemissao;
		this.dataentsaida = venda.dataEntradaSaida || this.dataentsaida;
		this.baixarCondicional = venda.baixarCondicional || false;
		if (venda.condicao) {
			this.condicao = venda.condicao;
		} else {
			if (venda.itens.filter(item => (item.cdTipo == tipoItem["condicional"] && item.situacao == situacaoItem["devolvido"])).length == venda.itens.length) {
				this.condicao = condicaoPedido["devolvido"];
			} else if (venda.itens.filter(item => (item.cdTipo == "C" && (item.situacao == "A" || item.situacao == "S"))).length) {
				this.condicao = condicaoPedido["aberto"];
			} else {
				this.condicao = condicaoPedido["normal"];
			}
		}
		this.emitirNota = venda.emitirNota;
		this.via = venda.via || 0;
		this.observ1 = venda.observacao;
		this.vendaOrigem = venda.vendaOrigem || 0;

		// Vendedor
		this.vendedor = venda.vendedor?.id;

		// Cliente
		this.cdcliente = venda.cliente.id ? venda.cliente.id : venda.cdcliente;
		this.cnpjCliente = venda.cliente.id == state.ClientePadrao.id ? (venda.cnpjCliente || venda.cliente.cnpj) : venda.cliente.cnpj;
		this.ieCliente = venda.cliente.id == state.ClientePadrao.id ?  (venda.ieCliente || venda.ierg || venda.cliente.ierg) : (venda.cliente.ierg);
		this.nomeCliente = venda.cliente.id == state.ClientePadrao.id ?  (venda.nomeCliente || venda.cliente.nome) : venda.cliente.nome;
		this.foneCliente = venda.cliente.id == state.ClientePadrao.id ? (venda.foneCliente || (venda.cliente.fone ? venda.cliente.fone : (venda.cliente.celular ? venda.cliente.celular : (venda.cliente.fax ? venda.cliente.fax : "")))) : (venda.foneCliente || (venda.cliente.fone ? venda.cliente.fone : (venda.cliente.celular ? venda.cliente.celular : (venda.cliente.fax ? venda.cliente.fax : ""))));
		this.enderecoCliente = venda.cliente.id == state.ClientePadrao.id ? venda.enderecoCliente : venda.cliente.endereco;
		this.bairroCliente = venda.cliente.id == state.ClientePadrao.id ? venda.bairroCliente : venda.cliente.bairro;
		this.cepCliente = venda.cliente.id == state.ClientePadrao.id ? venda.cepCliente : venda.cliente.cep;
		this.cidadeCliente = venda.cliente.id == state.ClientePadrao.id ? venda.cidadeCliente : venda.cliente.cidade?.nomecidade;
		this.ufCliente = venda.cliente.id == state.ClientePadrao.id ? venda.ufCliente : venda.cliente.cidade?.uf;
		this.nascimentoCliente = venda.cliente.id == state.ClientePadrao.id ? venda.nascimentoCliente : venda.cliente.dataNascimento;
		this.numeroCliente = venda.cliente.id == state.ClientePadrao.id ? venda.numeroCliente: venda.cliente.numero;
		// Itens e Valores
		this.desmoeda = (typeof venda.descontoMoeda === "string"? Number(venda.descontoMoeda.replace(",",".")) : venda.descontoMoeda ) || 0;
		this.desperc = venda.descontoPercentual || 0;
		this.itens = venda.itens.filter(item => !(item.cdTipo != tipoItem["condicional"] && item.situacao == situacaoItem["devolvido"]))?.map(item => new ItemVendaAPI(item, venda));
		this.valorComissao = venda.valorComissao || 0;
		this.troca = 0;
		this.vlrcondicional = 0;
		this.valorbruto = 0;
		this.itens.forEach(item => {
			if (item.cdtipo == tipoItem["troca"]) {
				this.troca += ((item.valorunit || 0) * (item.quantidade || 1));
			} else if (item.cdtipo ==  tipoItem["condicional"] && (item.situacao == situacaoItem["aberto"] || item.situacao == situacaoItem["baixado"])) {
				this.vlrcondicional += ((item.valorunit || 0) * (item.quantidade || 1));
			}
			if (item.cdtipo != tipoItem["troca"] && (item.situacao != situacaoItem["devolvido"])) {
				this.valorbruto += ((item.valorunit || 0) * (item.quantidade || 1));
			}
		});

		// FRETE
		this.valorfrete = venda?.valorfrete || 0;
		this.fretegratis = venda?.fretegratis ? "S" : "N";
		
		this.valorbruto += this.valorfrete || 0;
		if(venda.cancelaDelivery) {
			this.valorbruto -= this.valorfrete || 0;
		}

		// Financeiro
		this.financeiro = venda.financeiro ? venda?.financeiro?.map(financeiro => (new VendaFinanceiroAPI(venda, financeiro))) : [];
		this.consorcio = venda.consorcio ? venda.consorcio : [];
		this.valorpago = 0;
		if (!this.vlrcondicional) {
			this.financeiro.forEach(financeiro => {
				this.valorpago += (financeiro.credito || financeiro?.contarc) || 0;
			});
		}

		
		//IMPRESSAO
		this.impressaoRemota = venda.impressaoRemota || null;

		this.mesa = venda.mesa ? "S" : "N";
		this.acrescimo = venda?.acrescimo || 0;
	}
}

export class VendaFinanceiro {
	cdDig;
	cdLan;
	cdDoc;
	cdTipoPgto;
	pagamento;
	dataDoc;
	dataVenc;
	dataBaixa;
	credito;
	contarc;
	observa;
	nsucartao;
	doccartao;
	bandeira;
	cheques = [];
	txid;
	vistaprazo;
	valorVista;
	cheque;
	cartao;
	qtdeParcelaCartao;

	valorParcela;
	constructor(financeiro) {
		this.cdDig = financeiro.cddig,
		this.cdLan = financeiro.cdlan;
		this.cdDoc = financeiro.cddoc;
		this.cdTipoPgto = financeiro.cdtipopgto;
		this.pagamento = financeiro.pagamento;
		this.dataDoc = financeiro.datadoc;
		this.dataVenc = financeiro.datavenc;
		this.dataBaixa = financeiro.databaixa;
		this.credito = financeiro.credito;
		this.contarc = financeiro.contarc; 

		if (((financeiro.vistaprazo == 1 || financeiro.valorVista || financeiro.cheque === "S"))) {
			this.valorParcela = this.credito;
		}  else {
			this.valorParcela = this.contarc;
		}
		
		this.observa = financeiro.observa;
		this.nsucartao = financeiro.nsucartao;
		this.doccartao = financeiro.doccartao;
		this.bandeira = financeiro.bandeira;
		this.cheques = financeiro.cheques ? financeiro.cheques : [];
		this.cheque = financeiro.cheque != null ? financeiro.cheque : "N";
		this.cartao = financeiro.cartao;
		this.valorVista = financeiro.valorVista;
		this.vistaprazo = financeiro.vistaprazo;
		this.txid = financeiro.txid;
		this.qtdeParcelaCartao = financeiro.qtdeParcelaCartao;
	}
}

export class Venda {
	cdEmpresa;
	cdVenda;
	identificador;
	cdIdentificador;
	tipo;
	cdUsuarioCaixa;
	cdUsuarioLiberacao;
	condicao;
	cancelada;
	dataEmissao;
	dataEntradaSaida;
	dataCancelada;
	descontoMoeda;
	descontoPercentual;
	emitirNota;
	cnpjCliente;
	ieCliente;
	nomeCliente;
	foneCliente;
	enderecoCliente;
	bairroCliente;
	cepCliente;
	cidadeCliente;
	ufCliente;
	nascimentoCliente;
	numeroCliente;
	via;
	valorComissao;
	itens = [];
	cliente;
	vendedor;
	valorBruto = 0;
	valorTroca = 0;
	valorPago = 0;
	valorCondicional = 0;
	valorConsorcio = 0;
	vendaOrigem;
	nfepdf;
	observacao;
	observ3;
	arquivoxml;
	financeiro = [];
	consorcio = [];
	usuarioCaixa;
	
	mesa;
	impressaoRemota;
	contato;

	valorfrete;
	fretegratis;
	acrescimo;
	nfeid;
	constructor(venda) {
		this.mesa = venda.mesa;
		this.contato = venda.contato;
		this.impressaoRemota = venda.impressaoRemota;
		this.cdEmpresa = venda.cdempresa;
		this.cdVenda = venda.cdvenda;
		this.identificador = venda.identificador;
		this.cdIdentificador = venda.cdIdentificador;
		this.tipo = venda.tipo;
		this.cdUsuarioCaixa = venda.cdusuariocaixa;
		this.cdUsuarioLiberacao = venda.cdusuarioliberacao;
		this.condicao = venda.condicao;
		this.cancelada = venda.cancelada;
		this.dataEmissao = venda.dataemissao;
		this.dataEntradaSaida = venda.dataentsaida;
		this.dataCancelada = venda.datacancela;
		this.descontoMoeda = venda.desmoeda;
		this.descontoPercentual = venda.desperc;
		this.emitirNota = venda.emitirNota;
		this.cnpjCliente = venda.cnpjCliente;
		this.ieCliente = venda.ieCliente;
		this.nomeCliente = venda.nomeCliente;
		this.foneCliente = venda.foneCliente;
		this.enderecoCliente = venda.enderecoCliente;
		this.bairroCliente = venda.bairroCliente;
		this.cepCliente = venda.cepCliente;
		this.cidadeCliente = venda.cidadeCliente;
		this.ufCliente = venda.ufCliente;
		this.nascimentoCliente = venda.nascimentoCliente;
		this.numeroCliente = venda.numeroCliente;
		this.via = venda.via;
		this.valorComissao = venda.valorComissao;
		this.itens = venda.itens ? venda?.itens?.map(item => new ItemVenda(item)) : [];
		this.cliente = venda.cliente || {id: 0, nome: "Sem cliente no banco de dados"};
		this.vendedor = venda.vendedor || {id: 0, nome: "Sem vendedor no banco de dados"};
		this.valorBruto = venda.valorbruto;
		this.valorTroca = venda.troca;
		this.valorPago = venda.valorpago;
		this.valorCondicional = venda.vlrcondicional;
		this.valorConsorcio = venda.valorConsorcio;
		this.vendaOrigem = venda.vendaOrigem;
		this.nfepdf = venda.nfepdf;
		this.observacao = venda.observ1;
		this.observ3 = venda.observ3;
		this.arquivoxml = venda.arquivoxml;
		this.financeiro = venda.financeiro ? venda?.financeiro?.map(financeiro => (new VendaFinanceiro(financeiro))) : [];
		this.consorcio = venda.consorcio || [];
		this.usuarioCaixa = venda.usuariocaixa;

		this.valorfrete = venda.valorfrete || 0;
		this.fretegratis = venda.fretegratis || 0;
		this.acrescimo = venda.acrescimo || 0;

		this.nfeid = venda.nfeid;

	}
}

export class ListaVendas {
	content = [];
	size = 25;
	paginaAtual = 0;
	totalPaginas = 0;
	totalItens = 0;
	anexo = {
		qtdecancelada: 0,
		qtdecondicional: 0,
		qtdetroca: 0,
		qtdevenda: 0,
		valorbruto: 0,
		valorcancelado: 0,
		valorcondicional: 0,
		valordesconto: 0,
		valorpago: 0,
		valortroca: 0,
	};
	anexoFinanceiro = {
		prazo: 0,
		vista: 0
	};
	constructor(backendResponse) {
		if (!backendResponse) return;
		if (!backendResponse.content) return;
		this.content = backendResponse.content?.map(venda => (new Venda(venda)));
		this.size = backendResponse.size;
		this.paginaAtual = backendResponse.page;
		this.totalPaginas = backendResponse.totalPages;
		this.totalItens = backendResponse.totalElements;
		this.anexo = backendResponse.anexo;
		this.anexoFinanceiro = backendResponse.anexoFinanceiro;
	}
}
